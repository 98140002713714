import Tippy from '@tippyjs/react';
import Badge from 'components/Badge';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import Table from 'components/Table';
import { deleteReport, getInfiniteReports, getReportStatuses } from 'modules/reports/queries';
import useReportPreview from 'modules/reports/utils/useReportPreview';
import { routes } from 'navigation/routes';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChartPie } from 'react-icons/fa';
import { MdDelete, MdDownload, MdEmail, MdFlashOn } from 'react-icons/md';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useParsedPagination, useWindowSize } from 'utils';

import { ReportAutomation, ReportStatus, ReportTypes } from '../common';
import SendReportMailForm from '../components/SendReportMailForm';

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    grid-area: 1 / 3 / 3 / 4;
    gap: 0.5rem;
  }
`;

export const WrapperCenter = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const TextRight = styled.div`
  text-align: right;
`;

const FlashIcon = styled(MdFlashOn)`
  margin-left: 1rem;
  color: ${p => p.theme.colors.error};
`;

const TableWrapper = styled.div`
  width: 80vw;
  height: 100%;
  margin: auto;
  overflow: auto;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    width: 100%;
    padding: 2.4rem 1rem;
  }
`;

export const ListWrapper = styled.div`
  height: 100%;
`;

enum Columns {
  TYPE = 'type',
  VEHICLES = 'vehicles',
  DATE = 'date',
  STATUS = 'status',
  DATEGENERATION = 'dateGeneration',
  ACTIONS = 'actions',
}

type ReportRow = {
  id: number;
  type: ReportAutomation;
  report: ReportTypes;
  objects: string[];
  dateEnd: string;
  dateStart: string;
  dateUpdate: string;
  status: ReportStatus;
  notes: string;
  path: string;
};

type DataProps = {
  cell: {
    value: string[] | ReportRow;
  };
};

const ReportsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { isMobile } = useWindowSize();

  const {
    data: reports,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(getInfiniteReports());
  const { data: statuses } = useQuery(getReportStatuses({ refetchInterval: 60000 }));
  const [isSendReportModalOpen, setIsSendReportModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { mutate } = useMutation(deleteReport());
  const paginationData = useParsedPagination(reports?.pages[0]);
  const { reportPreviewAccess } = useReportPreview();

  const [selectedReport, setSelectedReport] = useState<ReportRow>();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const mobileRows = useMemo(() => {
    if (!reports || !isMobile) return [];

    const rows = reports.pages.flatMap(page => page.data.reports);

    if (!statuses) return rows;

    return rows.map(row => {
      const currentStatus = statuses.data?.find(status => status.id === row.id);

      return { ...row, ...currentStatus };
    });
  }, [reports, statuses, isMobile]);

  const desktopRows = useMemo(() => {
    if (!reports || isMobile) return [];

    const currentPageRows =
      reports.pages.find(page => page.data.pagination.currentPage === currentPage)?.data.reports ??
      [];

    if (!statuses) return currentPageRows;

    return currentPageRows.map(row => {
      const currentStatus = statuses.data?.find(status => status.id === row.id);

      return { ...row, ...currentStatus };
    });
  }, [isMobile, reports, statuses, currentPage]);

  const reportRows = useMemo(() => {
    if (isMobile) {
      return mobileRows;
    }

    return desktopRows;
  }, [isMobile, mobileRows, desktopRows]);

  const canFetchMore = useMemo(() => {
    if (!reports?.pages) return false;

    return !!reports.pages[reports.pages.length - 1].data.reports.length;
  }, [reports]);

  const reportsData = useMemo(
    () =>
      reportRows.map(i => ({
        [Columns.TYPE]: i.report,
        [Columns.VEHICLES]: i.objects,
        [Columns.DATE]: [i.dateStart, i.dateEnd],
        [Columns.STATUS]: i.status,
        [Columns.DATEGENERATION]: i,
        [Columns.ACTIONS]: i,
      })),
    [reportRows],
  );

  const handleCloseSendModal = useCallback(() => {
    setSelectedReport(undefined);
    setIsSendReportModalOpen(false);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setSelectedReport(undefined);
    setIsDeleteModalOpen(false);
  }, []);

  /**
   * Opens modal to delete report
   */
  const openDeleteReportModal = useCallback((data: ReportRow) => {
    setSelectedReport(data);
    setIsDeleteModalOpen(true);
  }, []);

  /**
   * Opens modal to send report
   */
  const openSendReportModal = useCallback((data: ReportRow) => {
    setSelectedReport(data);
    setIsSendReportModalOpen(true);
  }, []);

  /**
   * Redirect to selected report
   */
  const handleRedirectToReport = useCallback((data: ReportRow) => {
    const { id, report } = data;
    navigate(routes.reportsPreview(id, report));
  }, []);

  const handleDeleteReport = useCallback(() => {
    if (!selectedReport) return;
    mutate(
      { reportId: selectedReport.id.toString() },
      {
        onSuccess: handleCloseDeleteModal,
      },
    );
  }, [selectedReport]);

  const handlePageChange = useCallback(
    (page?: number) => {
      const pageNumber = page ?? currentPage;
      setCurrentPage(pageNumber + 1);
      fetchNextPage({ pageParam: pageNumber + 1 });
    },
    [fetchNextPage, currentPage],
  );

  const columns = useMemo(
    () => [
      {
        Header: t('type'),
        accessor: Columns.TYPE as const,
      },
      {
        Header: t('objects'),
        accessor: Columns.VEHICLES as const,
        Cell: ({ cell: { value } }: DataProps) => (
          <>
            {(value as string[]).map((text: string, index) => (
              <Badge key={`${index}-${text}`}>{text}</Badge>
            ))}
          </>
        ),
      },
      {
        Header: t('date'),
        accessor: Columns.DATE as const,
        Cell: ({ cell: { value } }: DataProps): JSX.Element[] =>
          (value as string[]).map((date: string, index) => (
            <div key={`${index}-${date}`}>{date}</div>
          )),
      },
      {
        Header: t('status'),
        accessor: Columns.STATUS as const,
        Cell: ({ cell: { value } }: DataProps) => <div>{value} %</div>,
      },
      {
        Header: t('dateGeneration'),
        accessor: Columns.DATEGENERATION as const,
        Cell: ({ cell: { value } }: DataProps) => (
          <TextRight>
            {(value as ReportRow).dateUpdate}
            {(value as ReportRow).type === ReportAutomation.ON && <FlashIcon />}
          </TextRight>
        ),
      },
      {
        Header: t('actions'),
        accessor: Columns.ACTIONS as const,
        Cell: ({ cell: { value } }: DataProps) => {
          const rowData = value as ReportRow;

          return (
            <Buttons>
              {(value as ReportRow).status === ReportStatus.FINISH && (
                <>
                  <Tippy content={t('send')}>
                    <div>
                      <IconButton
                        icon={<MdEmail data-testid="openSendReportModal" />}
                        onClick={() => openSendReportModal(value as ReportRow)}
                      />
                    </div>
                  </Tippy>

                  <Tippy content={t('download')}>
                    <div>
                      <IconButton
                        icon={<MdDownload data-testid="downloadReportModal" />}
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_OLD_VERSION_URL}/${(value as ReportRow).path}`,
                            '_blank',
                          )
                        }
                      />
                    </div>
                  </Tippy>
                </>
              )}

              <Tippy content={t('remove')}>
                <div>
                  <IconButton
                    icon={<MdDelete data-testid="openDeleteReportModal" />}
                    onClick={() => openDeleteReportModal(value as ReportRow)}
                  />
                </div>
              </Tippy>

              {(value as ReportRow).status === ReportStatus.FINISH && (
                <>
                  {reportPreviewAccess(rowData.report) && (
                    <Tippy content={t('graphicalPreview')}>
                      <div>
                        <IconButton
                          icon={<FaChartPie />}
                          onClick={() => handleRedirectToReport(value as ReportRow)}
                        />
                      </div>
                    </Tippy>
                  )}
                </>
              )}
            </Buttons>
          );
        },
      },
    ],
    [],
  );

  return (
    <ListWrapper>
      <Modal isOpen={isSendReportModalOpen} onClose={handleCloseSendModal} size={'big'}>
        <SendReportMailForm
          selectedReport={selectedReport as ReportRow}
          onClose={handleCloseSendModal}
        />
      </Modal>
      <ConfirmationModal
        size="small"
        isOpen={isDeleteModalOpen}
        title={t('confirmYourChoice')}
        message={t('confirmDeleteReport')}
        confirmText={t('delete')}
        onConfirm={handleDeleteReport}
        onClose={handleCloseDeleteModal}
      />
      {!reportsData.length && !isFetching && (
        <>
          <WrapperCenter>{t('reportListIsEmpty')}</WrapperCenter>
          <WrapperCenter>
            <Button secondary size="sm" onClick={() => navigate(routes.reportsForm)}>
              {t('addReport')}
            </Button>
          </WrapperCenter>
        </>
      )}
      <TableWrapper>
        <Table
          styleLastRow
          data={reportsData}
          columns={columns}
          pagination={paginationData}
          onPageChange={handlePageChange}
          isLoading={isFetching || isFetchingNextPage}
          canFetchMore={canFetchMore}
        />
      </TableWrapper>
    </ListWrapper>
  );
};

export default React.memo(ReportsList);

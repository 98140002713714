import Tippy from '@tippyjs/react';
import DateTimePicker from 'components/DateTimePicker';
import { memo } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ fillWidth?: boolean }>`
  display: ${p => (p.fillWidth ? `flex` : `inline-flex`)};
  gap: 1rem;
  position: relative;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    gap: 0.5rem;
    width: 100%;
  }
`;

export const ArrowWrapper = styled.div`
  background: ${p => p.theme.colors.primaryBackground};
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    width: 2.5rem;
    height: 2.5rem;
  }

  svg {
    height: 2rem;
    width: auto;
    color: ${p => p.theme.colors.separator};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export enum DateTimeBoundaryFields {
  START = 'start',
  END = 'end',
}

export type DateTimeBoundaryProps = {
  onChange?: (data: { field: DateTimeBoundaryFields; value: Date }) => void;
  start?: string;
  end?: string;
  minStart?: string;
  maxStart?: string;
  minEnd?: string;
  maxEnd?: string;
  disableTime?: boolean;
  fillWidth?: boolean;
  dayClassName?: (date: Date) => string | null;
  infoText?: string;
};

const DateTimeBoundary = ({
  onChange,
  start,
  end,
  minStart,
  maxStart,
  maxEnd,
  disableTime = false,
  fillWidth = false,
  dayClassName,
  infoText,
}: DateTimeBoundaryProps) => (
  <Wrapper fillWidth={fillWidth}>
    <DateTimePicker
      name="dateFrom"
      onChange={(value: Date) => onChange?.({ field: DateTimeBoundaryFields.START, value })}
      value={start}
      min={new Date(minStart ?? '')}
      max={new Date(maxStart ?? '')}
      disableTime={disableTime}
      dayClassName={dayClassName}
    />

    {!!infoText && (
      <Tippy content={infoText}>
        <ArrowWrapper>
          <MdKeyboardArrowRight />
        </ArrowWrapper>
      </Tippy>
    )}

    {!infoText && (
      <ArrowWrapper>
        <MdKeyboardArrowRight />
      </ArrowWrapper>
    )}

    <DateTimePicker
      name="dateTo"
      onChange={(value: Date) => onChange?.({ field: DateTimeBoundaryFields.END, value })}
      value={end}
      max={new Date(maxEnd ?? '')}
      disableTime={disableTime}
      dayClassName={dayClassName}
    />
  </Wrapper>
);

export default memo(DateTimeBoundary);

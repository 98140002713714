export type ValidatorFunction<T> = (val?: T) => string | undefined | Promise<string | undefined>;

export const composeValidators =
  <T>(...validators: (ValidatorFunction<T> | false)[]) =>
  async (value?: T) => {
    const filteredValidators = validators.filter(validator => !!validator) as Array<
      ValidatorFunction<T>
    >;

    const validateResults = await Promise.all(
      filteredValidators.map(validator => validator(value)),
    );

    return validateResults.filter(val => !!val).join('\n');
  };

import styled from 'styled-components';

const IconBox = styled.button<{ size?: number; p?: number; isSelected?: boolean }>`
  all: unset;
  padding: ${p => p.theme.spacing.xSmall};
  border-radius: 8px;
  width: ${p => p.p ?? 15}px;
  height: ${p => p.p ?? 15}px;
  background-color: ${p =>
    p.isSelected ? p.theme.colors.primary500 : p.theme.colors.secondaryBackgroundDark};
  cursor: pointer;
  transition: 0.2s ease;
  display: grid;
  place-items: center;
  color: ${p => (p.isSelected ? p.theme.colors.primaryBackground : p.theme.colors.textGray)};
  font-size: ${p => p.size ?? 1.2}rem;
  font-weight: 600;

  :hover {
    background-color: ${p =>
      p.isSelected ? p.theme.colors.primary500 : p.theme.colors.secondaryBackground};
  }

  :disabled {
    opacity: 50%;
    cursor: not-allowed;

    :hover {
      background-color: ${p => p.theme.colors.secondaryBackgroundDark};
    }
  }
`;

export default IconBox;

export enum Modes {
  SIEHS = 'siehs',
  WW_LIGHT = 'wwLight',
  WW_DARK = 'wwDark',
}

export const objectStatusColor = {
  moving: '#03E019',
  noGps: '#FFDD2A',
  engineOff: '#FF2B2B',
  pause: '#3bb0d1',
};

const polylineColors = [
  '#34754d',
  '#db9509',
  '#09aadb',
  '#c609db',
  '#db0909',
  '#0e7900',
  '#06858a',
  '#404a4a',
  '#18067a',
  '#77ed45',
];

const chartsColors = {
  red: '#e11d48',
  purple: '#7c3aed',
  blue: '#0ea5e9',
};

const siehsColors = {
  primaryBackground: '#FFFFFF',
  secondaryBackground: '#F5F6F8',
  secondaryBackgroundDark: '#EDEEF1',
  text: '#1C2B48',
  textLight: '#374151',
  textUltraLight: '#616d81',
  textGray: '#576275',
  textLightGray: '#9ea5b2',
  onPrimaryBorder: '#C0C5D0',
  separator: '#D0D5DF',
  white: '#FFFFFF',
  black: '#111111',
  error: '#F44542',
  primary100: '#FEFCF8',
  primary200: '#FBF2E1',
  primary300: '#F8D798',
  primary400: '#F5C46E',
  primary500: '#FAB42A',
  primary600: '#F4A712',
  primary700: '#EC9208',
  gray100: '#F5F7FA',
  gray200: '#EAEEF6',
  gray300: '#D0D5DF',
  gray400: '#8693AA',
  gray500: '#394B6A',
  gray600: '#1C2B48',
  gray700: '#121B2B',
  gray800: '#384454',
  green: '#25B060',
  info100: '#E7EEF7',
  info200: '#66A8FF',
  info300: '#2A7FEF',
  error100: '#FDF0F0',
  error200: '#FA918B',
  error300: '#F6544C',
  success100: '#DEF3E7',
  success200: '#12DE67',
  success300: '#25B060',
  success400: '#038945',
  borderGray: '#c0c5d0',
  orange200: '#FFEAC3',
  orange300: '#FAB42A',
  objectStatusColor,
  polylineColors,
  chartsColors,
};

const wwLightColors = {
  primaryBackground: '#FFFFFF',
  secondaryBackground: '#F5F6F8',
  secondaryBackgroundDark: '#EDEEF1',
  text: '#1C2B48',
  textLight: '#374151',
  textUltraLight: '#616d81',
  textGray: '#576275',
  textLightGray: '#9ea5b2',
  onPrimaryBorder: '#C0C5D0',
  separator: '#D0D5DF',
  white: '#FFFFFF',
  black: '#111111',
  error: '#F44542',
  primary100: '#FDF2F2',
  primary200: '#FDE8E8',
  primary300: '#F8B4B4',
  primary400: '#F98080',
  primary500: '#F44542',
  primary600: '#E02424',
  primary700: '#C81E1E',
  gray100: '#F5F6F8',
  gray200: '#EDEEF1',
  gray300: '#C0C5D0',
  gray400: '#9FA6B2',
  gray500: '#576275',
  gray600: '#374151',
  gray700: '#1A202E',
  gray800: '#384454',
  green: '#25B060',
  info100: '#E7EEF7',
  info200: '#66A8FF',
  info300: '#2A7FEF',
  error100: '#FDF0F0',
  error200: '#FA918B',
  error300: '#F6544C',
  success100: '#DEF3E7',
  success200: '#12DE67',
  success300: '#25B060',
  success400: '#038945',
  orange200: '#FFEAC3',
  orange300: '#FAB42A',
  borderGray: '#c0c5d0',
  objectStatusColor,
  polylineColors,
  chartsColors,
};

const wwDarkColors = {
  primaryBackground: '#1A202E',
  secondaryBackground: '#252E3B',
  secondaryBackgroundDark: '#252E3B',
  text: '#FFFFFF',
  textLight: '#FFFFFF',
  textUltraLight: '#FFFFFF',
  textGray: '#FFFFFF',
  textLightGray: '#FFFFFF',
  onPrimaryBorder: '#374151',
  separator: '#374151',
  white: '#FFFFFF',
  black: '#111111',
  error: '#F44542',
  primary100: '#FDF2F2',
  primary200: '#FDE8E8',
  primary300: '#F8B4B4',
  primary400: '#F98080',
  primary500: '#F44542',
  primary600: '#E02424',
  primary700: '#C81E1E',
  gray100: '#F5F6F8',
  gray200: '#EDEEF1',
  gray300: '#C0C5D0',
  gray400: '#9FA6B2',
  gray500: '#576275',
  gray600: '#374151',
  gray700: '#1A202E',
  gray800: '#384454',
  green: '#25B060',
  info100: '#E7EEF7',
  info200: '#66A8FF',
  info300: '#2A7FEF',
  error100: '#FDF0F0',
  error200: '#FA918B',
  error300: '#F6544C',
  success100: '#DEF3E7',
  success200: '#12DE67',
  success300: '#25B060',
  success400: '#038945',
  borderGray: '#c0c5d0',
  orange200: '#FFEAC3',
  orange300: '#FAB42A',
  objectStatusColor,
  polylineColors,
  chartsColors,
};

const shadows = {
  s: '0px 1px 0px rgba(129, 129, 129, 0.25)',
  m: '0px 3px 4px rgba(0, 0, 0, 0.06)',
  l: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  xl: '0px 4px 44px rgba(0, 0, 0, 0.25)',
};

interface MQ {
  [key: string]: string;
}

interface Breakpoints {
  [key: string]: number;
}

const breakpoints: Breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

const spacing = {
  xxSmall: '2px',
  xSmall: '4px',
  small: '8px',
  regular: '12px',
  large: '16px',
  xLarge: '24px',
  xxLarge: '32px',
};

const sizing = {
  topbar: '142px',
  settingsTopbar: '77px',
};

const layersOrder = {
  modal: 9999,
  listWrapper: 20,
  topbar: 401,
  toast: 6,
};

const mq = Object.keys(breakpoints).reduce<MQ>((acc, breakpoint) => {
  acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;

  return acc;
}, {});

const commons = {
  shadows,
  breakpoints,
  layersOrder,
  mq,
  objectStatusColor,
  sizing,
  spacing,
};

const siehs = {
  colors: siehsColors,
  ...commons,
};

const wwLight = {
  colors: wwLightColors,
  ...commons,
};

const wwDark = {
  colors: wwDarkColors,
  ...commons,
};

export type Theme = typeof siehs;

export const themes = {
  [Modes.SIEHS]: siehs,
  [Modes.WW_LIGHT]: wwLight,
  [Modes.WW_DARK]: wwDark,
};

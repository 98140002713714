import { Coordinates } from 'common';
import { ObjectInfoTypes } from 'components/ObjectInfo';
import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import { createContext, Dispatch, SetStateAction } from 'react';

import { AnimateValues, RouteClickModes, RouteItem } from '../common';
import { MobileViewVariants } from '../common/MobileViewVariants';
import { RouteDetailsRow } from '../dtos';

export type RouteContextProps = {
  objectDetailsId?: number;
  setObjectDetailsId: (id: number) => void;
  selectedObjectData?: Object;
  setSelectedObjectData: (obj: Object) => void;
  detailsData?: RouteDetailsRow[];
  selectedItems: RouteItem[];
  setSelectedItems: (items: RouteItem[]) => void;
  checkedVehicles: string[];
  setCheckedVehicles: (ids: string[]) => void;
  zoomObjectId?: number;
  setZoomObjectId: (id: number) => void;
  collapse: boolean;
  setCollapse: (collapse: boolean) => void;
  timeInterval?: number;
  setTimeInterval: (interval: number) => void;
  animateType: AnimateValues;
  setAnimateType: (type: AnimateValues) => void;
  animateIndex?: number;
  setAnimateIndex: (index: number) => void;
  routeClickMode: RouteClickModes;
  setRouteClickMode: (mode: RouteClickModes) => void;
  selectedFragmentIndex?: number | [number, number];
  setSelectedFragmentIndex: (index?: number | [number, number]) => void;
  showRouteSettings: boolean;
  setShowRouteSettings: Dispatch<SetStateAction<boolean>>;
  showCharts: boolean;
  setShowCharts: Dispatch<SetStateAction<boolean>>;
  showStops: boolean;
  setShowStops: Dispatch<SetStateAction<boolean>>;
  chartRouteZoom?: Coordinates;
  setChartRouteZoom: (data: Coordinates) => void;
  viewVariant: MobileViewVariants;
  setViewVariant: Dispatch<SetStateAction<MobileViewVariants>>;
  markerInfo: { index: number; type: ObjectInfoTypes } | undefined;
  setMarkerInfo: Dispatch<SetStateAction<{ index: number; type: ObjectInfoTypes } | undefined>>;
};

export const RouteContext = createContext<RouteContextProps>({
  objectDetailsId: undefined,
  setObjectDetailsId: () => {},
  selectedObjectData: undefined,
  setSelectedObjectData: () => {},
  detailsData: [],
  selectedItems: [],
  setSelectedItems: () => {},
  checkedVehicles: [],
  setCheckedVehicles: () => {},
  zoomObjectId: undefined,
  setZoomObjectId: () => {},
  collapse: false,
  setCollapse: () => {},
  timeInterval: undefined,
  setTimeInterval: () => {},
  animateType: AnimateValues.STOP,
  setAnimateType: () => {},
  animateIndex: undefined,
  setAnimateIndex: () => {},
  routeClickMode: RouteClickModes.POINT,
  setRouteClickMode: () => {},
  selectedFragmentIndex: undefined,
  setSelectedFragmentIndex: () => {},
  showRouteSettings: false,
  setShowRouteSettings: () => {},
  showCharts: false,
  setShowCharts: () => {},
  showStops: false,
  setShowStops: () => {},
  chartRouteZoom: undefined,
  setChartRouteZoom: () => {},
  viewVariant: MobileViewVariants.HALF,
  setViewVariant: () => {},
  markerInfo: undefined,
  setMarkerInfo: () => {},
});

import Map from 'components/Map';
import { getAllObjects } from 'modules/objects/queries';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { getObjectIcon, useAuth } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import { getStatusList } from '../queries';

const Wrapper = styled.div`
  height: 100%;
`;

const DriverMap = () => {
  const { isAuthenticated } = useAuth();

  const { data } = useQuery(getAllObjects(true, 60, { enabled: isAuthenticated }));
  const { data: status } = useQuery(getStatusList({ enabled: isAuthenticated }));

  const marker = useMemo(
    () =>
      data?.data?.map(object => ({
        position: { lat: object.last.y, lng: object.last.x },
        objectId: object.objectId,
        title: object.number,
        icon: `${getObjectIcon(object.type)}?key=${0}`,
        rotation: object.last.angle,
        status: object.status,
        color: getObjectFilterColor(object.status),
        driverStatuses: status?.data,
      })),
    [data, status],
  );

  return (
    <Wrapper>
      <Map markers={marker}></Map>
    </Wrapper>
  );
};

export default DriverMap;

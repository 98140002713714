import Button from 'components/Button';
import { ObjectStatus } from 'components/common/ObjectStatus';
import MainLayoutContext from 'components/MainLayout/context/MainLayoutContext';
import MobileModal from 'components/MobileModal/MobileModal';
import { ObjectsResponseDto } from 'modules/objects/dtos';
import { getGroupObjects } from 'modules/objects/queries';
import { useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useMainLayout, useWindowSize } from 'utils';

import SpeditionDesktop from '../components/SpeditionDesktop';
import SpeditionMobile from '../components/SpeditionMobile';
import SpeditionSummary from '../components/SpeditionSummary';
import { getDriversAccounts } from '../queries';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  background: ${({ theme }) => theme.colors.gray100};
  height: 100%;
  gap: 2rem;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 2rem;
  font-weight: 600;
  line-height: 36px;
`;

export const SpeditionWrapper = styled.div`
  border-radius: 6px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface SummaryDataType {
  objectId: number;
  type: string | boolean;
  status: ObjectStatus;
  number: string;
  comment?: string;
  prefix?: string;
  phone?: string;
  url?: string;
  id?: number;
  hash?: string;
}

const speditionContainer = document.getElementById('spedition-container');

const Spedition = () => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const { showMobileModal, setShowMobileModal } = useMainLayout();

  const { objectsGroupId } = useContext(MainLayoutContext);
  const { data: objects } = useQuery(getGroupObjects(objectsGroupId));
  // const { data: objects } = useQuery(getAllObjects());
  const { data: driversAccounts } = useQuery(getDriversAccounts());

  const [selectedItems, setSelectedItems] = useState<SummaryDataType[]>([]);
  const [searchInputValue, setSearchInputValue] = useState<string | undefined>('');
  const [isAllBindedItemsChecked, setIsAllbindedItemsChecked] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const summaryData = useMemo(
    () =>
      ((objects as ObjectsResponseDto)?.data || []).map(object => {
        const statusData = driversAccounts?.find(account => account.objectId === object.objectId);
        return {
          id: statusData?.id,
          objectId: object.objectId,
          type: object.type,
          status: object.status,
          number: object.number,
          comment: object.comment,
          prefix: statusData?.prefix,
          phone: statusData?.phone,
          url: statusData?.Url,
          hash: statusData?.hash,
        };
      }),
    [objects, driversAccounts],
  );

  const checkAllBindedItems = () => {
    if (!summaryData) return;
    setIsAllbindedItemsChecked(!isAllBindedItemsChecked);
  };

  useEffect(() => {
    if (!summaryData) return;

    if (isAllBindedItemsChecked) setSelectedItems(summaryData.filter(obj => obj.url));
  }, [isAllBindedItemsChecked, summaryData]);

  const handleSelectedItems = (item: SummaryDataType) => {
    if (selectedItems.find(obj => obj.objectId === item.objectId)) {
      setSelectedItems(selectedItems.filter(obj => obj.objectId !== item.objectId));
    } else {
      setSelectedItems([item]);
    }
  };

  const handleGoback = () => setShowMobileModal(false);

  const objectsWithActiveLink = summaryData?.filter(object => object.url);

  return (
    <>
      {isMobile ? (
        <SpeditionMobile
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          searchInputValue={searchInputValue}
          handleInputChange={handleInputChange}
          handleSelectedItems={handleSelectedItems}
          summaryData={summaryData ?? []}
          checkAllBindedItems={checkAllBindedItems}
        />
      ) : (
        <SpeditionDesktop
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          searchInputValue={searchInputValue}
          handleInputChange={handleInputChange}
          handleSelectedItems={handleSelectedItems}
          summaryData={summaryData ?? []}
          checkAllBindedItems={checkAllBindedItems}
        />
      )}
      {showMobileModal &&
        createPortal(
          <MobileModal>
            <SpeditionSummary
              objectsWithActiveLink={objectsWithActiveLink?.length ? objectsWithActiveLink : []}
              selectedItems={selectedItems}
              resetSelectedItems={() => setSelectedItems([])}
            />
            <Button secondary fillWidth onClick={handleGoback} style={{ marginBottom: '1rem' }}>
              {t('goBack')}
            </Button>
          </MobileModal>,
          speditionContainer!,
        )}
    </>
  );
};

export default Spedition;

import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import { LINK_HASH } from 'common';
import Button from 'components/Button';
import MobileModal from 'components/MobileModal/MobileModal';
import { Overlay } from 'components/Overlay';
import { StyledMobileTitle } from 'modules/admin/components/GroupForm';
import { GroupColor } from 'modules/admin/components/GroupItem';
import { BadgeSection, StyledBadge } from 'modules/admin/components/GroupObjectsList';
import { GroupNameWrapper } from 'modules/admin/components/LoginGroupList';
import { GroupItem } from 'modules/admin/dtos';
import { ItemName } from 'modules/driver/components/SpeditionObjectsList';
import { Title } from 'modules/driver/pages/Spedition';
import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { useWindowSize } from 'utils';

import { ShareLinkDataDto } from '../dtos/ShareLink.dto';
import { shareGroup } from '../queries';
import GeneratedLinkBox from './GeneratedLinkBox';
import ShareForm from './ShareForm';

const Container = styled.div<{ isMobile: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  height: ${({ isMobile }) => (isMobile ? '100%' : 'inherit')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'start')};
`;

const StyledGroupNameWrapper = styled(GroupNameWrapper)`
  width: 50%;
  margin: ${({ theme }) => `${theme.spacing.xLarge} auto 0 ${theme.spacing.xLarge}`};
`;

const StyledBadgeSection = styled(BadgeSection)`
  margin: ${({ theme }) => `${theme.spacing.xLarge} auto 0 ${theme.spacing.xLarge}`};
`;

const StyledTitle = styled(Title)`
  margin: ${({ theme }) => `${theme.spacing.large} 0 0 ${theme.spacing.xLarge}`};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
`;

const StyledButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing.xLarge};
  width: 20%;
`;

export type LinkHashResponse = {
  id: number;
  hash: string;
};

const SharingBox = ({
  selectedGroupId,
  selectedItems,
  handleSelectedGroupId,
  selectedGroup,
  closeModal,
  resetSelectedItems,
}: {
  selectedGroupId?: number;
  selectedItems?: Object[];
  handleSelectedGroupId?: (id?: number) => void;
  selectedGroup?: GroupItem;
  closeModal?: () => void;
  resetSelectedItems?: () => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const {
    mutate: shareMutation,
    isLoading: isShareGroupLoading,
    isSuccess: isLinkSharingSuccess,
  } = useMutation(shareGroup());

  const queryClient = useQueryClient();
  const linkHash = queryClient.getQueryData(LINK_HASH) as LinkHashResponse;

  const handleShareMutation = (data: ShareLinkDataDto) => {
    shareMutation(data);
  };

  const selectedObjectsIds = isLinkSharingSuccess ? [] : selectedItems?.map(el => el.objectId);

  const handleNewLink = () => {
    queryClient.resetQueries({ queryKey: LINK_HASH, exact: true });
    if (selectedGroup) {
      handleSelectedGroupId?.(undefined);
    } else {
      resetSelectedItems?.();
    }
  };

  const handleGoBack = () => {
    handleNewLink();
    if (selectedGroup) {
      handleSelectedGroupId?.(undefined);
    } else {
      resetSelectedItems?.();
      closeModal?.();
    }
  };

  return (
    <Container isMobile={isMobile}>
      {isMobile ? (
        <StyledMobileTitle onClick={handleGoBack}>
          <ArrowLeft />
          {t('share')}
        </StyledMobileTitle>
      ) : (
        <StyledTitle>{t('share')}</StyledTitle>
      )}

      {isMobile && selectedGroup && (
        <StyledGroupNameWrapper>
          <GroupColor color={selectedGroup?.color ?? ''} />
          <ItemName>{selectedGroup?.name}</ItemName>
        </StyledGroupNameWrapper>
      )}
      {isMobile && !isLinkSharingSuccess && selectedItems?.length && (
        <StyledBadgeSection>
          {selectedItems.slice(0, 20).map((item, index) => (
            <StyledBadge key={`selected-item-${index}`}>
              {item.number}
              <span>&#10005;</span>
            </StyledBadge>
          ))}
          {selectedItems.length > 20 && '...'}
        </StyledBadgeSection>
      )}
      <ShareForm
        isShareGroupLoading={isShareGroupLoading}
        selectedGroupId={selectedGroupId}
        shareMutation={handleShareMutation}
        selectedObjectsIds={selectedObjectsIds}
        isLinkSharingSuccess={!!linkHash}
      />
      {linkHash &&
        (isMobile ? (
          <>
            <Overlay />
            <MobileModal>
              <GeneratedLinkBox linkHash={linkHash?.hash} closeModal={handleGoBack} />
              <Button secondary fillWidth onClick={handleGoBack}>
                {t('goBack')}
              </Button>
            </MobileModal>
          </>
        ) : (
          <Wrapper>
            <GeneratedLinkBox linkHash={linkHash?.hash} />
            {!isMobile && (
              <StyledButton onClick={handleNewLink} secondary>
                {t('newLink')}
              </StyledButton>
            )}
          </Wrapper>
        ))}
    </Container>
  );
};
export default SharingBox;

import { css } from 'styled-components/macro';
import { Theme } from 'styles/themes';

type StyledInputProps = {
  dark?: boolean;
  medium?: boolean;
  hasLeftIcon?: boolean;
  error?: string;
  theme: Theme;
  disabled?: boolean;
  noBackground?: boolean;
};

export const sharedInputStyles = (p: StyledInputProps) => css`
  height: ${p.medium ? `42px` : `56px`};
  width: 100%;
  padding: 2px 20px;
  background: ${p.dark ? p.theme.colors.gray500 : p.theme.colors.gray200};
  border: 1px solid
    ${p.dark || (p.dark && p.disabled)
      ? p.theme.colors.gray500
      : p.disabled
      ? p.theme.colors.gray100
      : p.theme.colors.gray200};
  border-radius: ${p.medium ? `6px` : `8px`};
  ${p.noBackground &&
  css`
    border: 1px solid transparent;
    background: none;
  `};
  color: ${p.dark
    ? p.theme.colors.white
    : p.disabled
    ? p.theme.colors.gray300
    : p.theme.colors.gray600};
  font-weight: 600;
  font-size: ${p.medium ? `14px` : `16px`};
  line-height: ${p.medium ? `22px` : `24px`};

  &::placeholder {
    color: ${p.disabled ? p.theme.colors.gray300 : p.theme.colors.gray400};
    font-weight: 600;
  }

  &:hover:not([disabled]),
  &:focus,
  &:focus-visible {
    border: 1px solid ${p.theme.colors.gray400};
    outline: none;
  }

  ${!p.medium &&
  `&:focus::placeholder {
  font-size: 12px;
  position: absolute;
}`}

  &:disabled {
    background: ${p.dark ? p.theme.colors.gray500 : p.theme.colors.gray100};
  }

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    font-size: 1.6rem;
  }
`;

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { memo } from 'react';
import ReactDOM from 'react-dom';

import { Background, CloseButton, Content, Wrapper } from './style';

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: 'small' | 'big';
  noScrollY?: boolean;
  noScrollX?: boolean;
  isScrollBarYHidden?: boolean;
};

const Modal = ({
  children,
  onClose,
  size = 'small',
  isOpen = false,
  noScrollY,
  noScrollX,
  isScrollBarYHidden,
}: ModalProps) =>
  ReactDOM.createPortal(
    isOpen && (
      <Background>
        <Wrapper size={size} data-testid="modal">
          <CloseButton data-testid="closeModalButton" onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          <Content
            isScrollBarYHidden={isScrollBarYHidden}
            noScrollX={noScrollX}
            noScrollY={noScrollY}
          >
            {children}
          </Content>
        </Wrapper>
      </Background>
    ),
    document.getElementById('modal-container')!,
  );

export default memo(Modal);

import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

type ProtectedRouteProps = {
  children: ReactNode;
  redirectTo: string;
  condition: boolean;
};

const ProtectedRoute = ({ children, redirectTo, condition }: ProtectedRouteProps) => {
  const location = useLocation();

  return condition ? (
    (children as JSX.Element)
  ) : (
    <Navigate to={redirectTo} replace state={{ previous: location.pathname }} />
  );
};

export default ProtectedRoute;

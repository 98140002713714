import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div`
  position: relative;
`;

const thumbStyles = () => css`
  width: 2.8rem;
  height: 2.8rem;
  background: ${p => p.theme.colors.primaryBackground};
  border: 7px solid ${p => p.theme.colors.primary600};
  border-radius: 50%;
  margin-top: -10px;
  box-sizing: border-box;
  box-shadow: ${p => p.theme.shadows.m};
`;

const trackStyles = () => css`
  width: 100%;
  height: 1rem;
  cursor: pointer;
  background: ${p => p.theme.colors.gray200};
  border-radius: 0.6rem;
`;

const InputRange = styled.input`
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${thumbStyles};
  }

  &::-moz-range-thumb {
    ${thumbStyles};
  }

  &::-moz-range-track {
    ${trackStyles};
  }

  &::-webkit-slider-runnable-track {
    ${trackStyles};
  }
`;

const Label = styled.div<{ left?: number }>`
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${p => p.theme.colors.textGray};
  text-align: right;

  ${p =>
    p.left &&
    css`
      position: absolute;
      left: ${p.left}px;
      transform: translateX(-50%);
    `}
`;

const LabelsGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export type RangeInputProps = {
  value?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  minValue?: number;
  maxValue?: number;
} & (
  | {
      timeLabel?: boolean;
      minLabel?: never;
      maxLabel?: never;
    }
  | {
      timeLabel?: never;
      minLabel?: string;
      maxLabel?: string;
    }
);

const thumbWidth = 28;

const RangeInput = ({
  value = 0,
  onChange,
  timeLabel,
  minLabel,
  maxLabel,
  minValue = 0,
  maxValue = 60,
  ...restProps
}: RangeInputProps) => {
  const [labelPosition, setLabelPosition] = useState(0);
  const formattedValue = useMemo(() => {
    if (!value) return;
    if (value === 60) return '01:00';

    return (
      '00:' +
      Math.floor(value % 60)
        .toString()
        .padStart(2, '0')
    );
  }, [value]);

  const isMaxValue = useMemo(() => maxValue === value, [maxValue, value]);
  const isMinValue = useMemo(() => minValue === value, [minValue, value]);

  const ref = useCallback(
    node => {
      if (node !== null) {
        const { clientWidth } = node;
        const ratio = (value - minValue) / (maxValue - minValue);
        const left = ratio * (clientWidth - thumbWidth) + thumbWidth / 2;

        setLabelPosition(left);
      }
    },
    [value],
  );

  const showLabelAboveThumb = useMemo(
    () => !timeLabel && !isMaxValue && !isMinValue,
    [timeLabel, isMaxValue, isMinValue],
  );

  return (
    <Wrapper>
      {timeLabel && <Label>{formattedValue}</Label>}
      {showLabelAboveThumb && <Label left={labelPosition}>{value}</Label>}
      {minLabel && (
        <LabelsGroup>
          <Label>{minLabel}</Label>
          <Label>{maxLabel}</Label>
        </LabelsGroup>
      )}
      <InputRange
        ref={ref}
        type="range"
        min={minValue}
        max={maxValue}
        value={value}
        onChange={onChange}
        {...restProps}
      />
    </Wrapper>
  );
};

export default memo(RangeInput);

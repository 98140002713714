import SelectDropdown from 'components/Dropdown/SelectDropdown';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AvailableLanguagesTypes } from '../../common';

const LanguageSelect = ({ noBackground = false }: { noBackground?: boolean }) => {
  const { i18n, t } = useTranslation();

  const options = useMemo(
    () =>
      Object.entries(AvailableLanguagesTypes).map(([key, value]) => ({
        label: key.toUpperCase(),
        value: value,
      })),
    [],
  );

  const handleSelectedLanguage = useCallback(
    (value: string) => {
      i18n.changeLanguage(value);
      location.reload();
    },
    [i18n],
  );

  return (
    <SelectDropdown
      placeholderText={t('selectLanguage')}
      items={options}
      onChange={handleSelectedLanguage}
      value={i18n.language}
      noBackground={noBackground}
      size="md"
      disableInitialWidth
      minWidth={5}
    />
  );
};

export default memo(LanguageSelect);

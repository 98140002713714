import { ObjectStatus } from 'components/common/ObjectStatus';

export const getObjectFilterColor = (status: ObjectStatus) => {
  switch (status) {
    case ObjectStatus.MOVING:
      return 'sepia(89%) saturate(5) hue-rotate(87deg) brightness(80%) contrast(100%)';
    case ObjectStatus.NO_GPS:
      return 'grayscale(1) sepia(1) saturate(6) hue-rotate(15deg) brightness(170%)';
    case ObjectStatus.ENGINE_OFF:
      return 'invert(10%) sepia(1) saturate(1600%) hue-rotate(0deg) brightness(60%) contrast(500%)';
    case ObjectStatus.PAUSE:
      return 'invert(5%) sepia(102%) saturate(900%) hue-rotate(146deg) brightness(102%) contrast(86%)';
    default:
      return '';
  }
};

import { LocalStorageKeys, MapTypes } from 'common';
import { SelectItem } from 'components/common';
import { SelectDropdownProps } from 'components/Dropdown/SelectDropdown';
import { MainLayoutContextProps } from 'components/MainLayout/context/MainLayoutContext';
import { ToggleProps } from 'components/Toggle';
import i18n from 'locales/i18n';
import { ChangeEvent, ReactNode } from 'react';
import {
  MdAssignment,
  MdDirections,
  MdDirectionsCar,
  MdRefresh,
  MdSearch,
  MdShowChart,
} from 'react-icons/md';

i18n.loadNamespaces('general');

export type Setting = {
  icon?: ReactNode;
  label?: string;
  description?: string;
  toggleOptions?: ToggleProps;
  selectOptions?: SelectDropdownProps;
};

enum RefreshingOptions {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

enum VehicleViewOptions {
  GROUPS = 'groups',
  SEPARATELY = 'separately',
}

enum VehicleNamesOptions {
  PLATES = 'plates',
  COMMENT = 'comment',
}

export enum DisplayingObjectsOptions {
  VEHICLES = 'vehicles',
  ARROWS = 'arrows',
  SEQUENCE_NUMBER = 'sequenceNumber',
}

enum TrafficOptions {
  ON = 'on',
  OFF = 'off',
}

export const getMapSettings = (
  context: MainLayoutContextProps,
  objectsOptions: SelectItem[],
  handleCenterObject: (id: string) => void,
): Setting[] => [
  {
    icon: <MdRefresh />,
    label: i18n.t('refreshing'),
    description: i18n.t('refreshingDescription'),
    toggleOptions: {
      firstOption: { label: i18n.t('automatic'), value: RefreshingOptions.AUTOMATIC },
      secondOption: { label: i18n.t('manual'), value: RefreshingOptions.MANUAL },
      value: context.isAutomaticRefresh ? RefreshingOptions.AUTOMATIC : RefreshingOptions.MANUAL,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value !== RefreshingOptions.MANUAL;
        localStorage.setItem(LocalStorageKeys.IS_AUTOMATIC_REFRESH, newValue.toString());
        if (!context.setIsAutomaticRefresh) return;
        context.setIsAutomaticRefresh(newValue);
      },
    },
  },
  {
    icon: <MdSearch />,
    label: i18n.t('objectTracking'),
    description: i18n.t('objectTrackingDescription'),
    selectOptions: {
      placeholderText: i18n.t('select'),
      items: objectsOptions,
      onChange: (value: string) => {
        handleCenterObject(value);
      },
      value: context.selectedVehicleId,
    },
  },
  {
    icon: <MdDirectionsCar />,
    label: i18n.t('vehicleView'),
    description: i18n.t('vehicleViewDescription'),
    toggleOptions: {
      firstOption: { label: i18n.t('groups'), value: VehicleViewOptions.GROUPS },
      secondOption: { label: i18n.t('separately'), value: VehicleViewOptions.SEPARATELY },
      value: context.groupObjectsOn ? VehicleViewOptions.GROUPS : VehicleViewOptions.SEPARATELY,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value === VehicleViewOptions.GROUPS;
        localStorage.setItem(LocalStorageKeys.GROUP_OBJECTS_ON, newValue.toString());
        if (!context.setGroupObjectsOn) return;
        context.setGroupObjectsOn(newValue);
      },
    },
  },
  {
    icon: <MdAssignment />,
    label: i18n.t('vehicleNames'),
    description: i18n.t('vehicleNamesDescription'),
    toggleOptions: {
      firstOption: { label: i18n.t('plates'), value: VehicleNamesOptions.PLATES },
      secondOption: { label: i18n.t('comment'), value: VehicleNamesOptions.COMMENT },
      value: context.plateNamesOn ? VehicleNamesOptions.PLATES : VehicleNamesOptions.COMMENT,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value === VehicleNamesOptions.PLATES;
        localStorage.setItem(LocalStorageKeys.PLATE_NAMES_ON, newValue.toString());
        if (!context.setPlateNamesOn) return;
        context.setPlateNamesOn(newValue);
      },
    },
  },

  {
    icon: <MdDirections />,
    label: i18n.t('displayingObjects'),
    description: i18n.t('displayingObjectsDescription'),
    toggleOptions: {
      firstOption: { label: i18n.t('vehicles'), value: DisplayingObjectsOptions.VEHICLES },
      secondOption: { label: i18n.t('arrows'), value: DisplayingObjectsOptions.ARROWS },
      thirdOption: { label: i18n.t('number'), value: DisplayingObjectsOptions.SEQUENCE_NUMBER },
      value: context.vehiclesIconsType,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        localStorage.setItem(LocalStorageKeys.VEHICLES_ICONS_TYPE, newValue.toString());
        if (!context.setVehiclesIconsType) return;
        context.setVehiclesIconsType(newValue);
      },
    },
  },
  {
    icon: <MdShowChart />,
    label: i18n.t('traffic'),
    description:
      context.mapType === MapTypes.HERE
        ? i18n.t('hereMapsTrafficDescription')
        : i18n.t('trafficDescription'),
    toggleOptions: {
      firstOption: { label: i18n.t('on'), value: TrafficOptions.ON },
      secondOption: { label: i18n.t('off'), value: TrafficOptions.OFF },
      value: context.trafficLayerOn ? TrafficOptions.ON : TrafficOptions.OFF,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value === TrafficOptions.ON;
        localStorage.setItem(LocalStorageKeys.TRAFFIC_LAYER_ON, newValue.toString());
        if (!context.setTrafficLayerOn) return;
        context.setTrafficLayerOn(newValue);
      },
    },
  },
];

import { LocalStorageKeys } from 'common';
import DriverTopbar from 'components/DriverTopbar';
import ErrorBoundary from 'components/ErrorBoundary';
import MaintenanceNotification from 'components/MaintenanceNotification/MaintenanceNotification';
import MobileTopbar from 'components/MobileTopbar';
import Topbar from 'components/Topbar';
import { showMessages } from 'modules/auth/queries';
import { authDriver } from 'modules/driver/queries';
import { routes } from 'navigation/routes';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { isDriverAccount, useAuth, useWindowSize } from 'utils';

import MainLayoutContextProvider from './context/MainLayoutContextProvider';

const Wrapper = styled(Div100vh)`
  width: 100vw;
  max-width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const Content = styled.div<{
  smallPadding?: boolean;
  noPadding?: boolean;
  isNotificationOpen?: boolean;
}>`
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  padding-top: ${p =>
    p.smallPadding ? p.theme.sizing.settingsTopbar : p.noPadding ? 'unset' : p.theme.sizing.topbar};
  overflow-x: hidden;

  ${({ isNotificationOpen, theme }) =>
    isNotificationOpen &&
    css`
      padding-top: calc(${theme.sizing.settingsTopbar + '10.5rem'});
    `}

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    padding-top: unset;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
  }
`;

const MainLayout = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const { isMobile } = useWindowSize();
  const location = useLocation();

  const isSettingsPage = useMemo(() => location.pathname.includes('settings'), [location]);
  const isDriverPage = isDriverAccount();

  const [searchParams] = useSearchParams();

  const hash = searchParams.get('hash');

  const { mutate, isLoading, isSuccess } = useMutation(authDriver());

  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);

  const { data: messages } = useQuery(showMessages({ refetchInterval: 300000 }));

  const ls = localStorage.getItem(LocalStorageKeys.SEEN_MESSAGE_IDS);
  const idsInLs = ls && JSON.parse(ls);
  const isInLs = messages?.data?.filter(mess => !idsInLs?.includes(mess.id));

  useEffect(() => {
    if (messages?.data?.length && isInLs?.length) {
      setIsNotificationOpen(true);
    }
    if (isInLs?.length === 0) {
      setIsNotificationOpen(false);
    }
  }, [isInLs, messages, isInLs]);

  useEffect(() => {
    if (hash) mutate({ hash: hash });
  }, [hash]);

  useEffect(() => {
    if (isSuccess) setIsAuthenticated(true);
  }, [isSuccess]);

  /**
   * Renders variants of topbars
   */
  const renderTopbar = useMemo(() => {
    if (isDriverPage) return <DriverTopbar />;
    if (isMobile) return <MobileTopbar />;

    return <Topbar isNotificationOpen={isNotificationOpen} />;
  }, [isDriverPage, isMobile, isNotificationOpen]);

  /**
   * This flag allow to render some views
   * in unauth views
   */
  const renderStandalone = useMemo(() => {
    if (location.pathname.includes(routes.sharePreviewLink(''))) return true;

    return false;
  }, []);

  if (!isAuthenticated || isLoading || renderStandalone) {
    return <>{children}</>;
  }

  return (
    <MainLayoutContextProvider>
      <Wrapper>
        {isNotificationOpen && (
          <MaintenanceNotification
            handleClose={() => setIsNotificationOpen(false)}
            messages={idsInLs ? isInLs : messages?.data}
            isOpen={isNotificationOpen}
          />
        )}

        {renderTopbar}

        <ErrorBoundary>
          <Content
            smallPadding={isSettingsPage}
            noPadding={isDriverPage}
            isNotificationOpen={isNotificationOpen}
          >
            {children}
          </Content>
        </ErrorBoundary>
      </Wrapper>
    </MainLayoutContextProvider>
  );
};

export default MainLayout;

import Tippy from '@tippyjs/react';
import ConfirmationModal from 'components/ConfirmationModal';
import IconBox from 'components/Dashboard/IconBox';
import { updateIgnitionControlls } from 'modules/objects/queries';
import { routes } from 'navigation/routes';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { IoFlashOff } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Toggle from 'react-styled-toggle';

import { StyledCarToggleSwitchLabel, StyledCarToggleSwitchWrapper } from './styles';

interface CarToggleSwitchProps {
  initialStatus?: boolean;
  isPending?: boolean;
  objectId?: number;
  withoutBorder?: boolean;
  withBackground?: boolean;
  isCanAvailable?: boolean;
}

const CarToggleSwitch: React.FC<CarToggleSwitchProps> = ({
  initialStatus,
  withoutBorder,
  withBackground,
  isPending,
  isCanAvailable,
  objectId,
}) => {
  const { t } = useTranslation();
  const [isObjectOn, setIsObjectOn] = useState<boolean>(initialStatus ?? true);
  const [isPendingState, setIsPendingState] = useState<boolean>(isPending ?? false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { mutateAsync: updateControlls } = useMutation(updateIgnitionControlls());

  /**
   * Handles the toggle action for the CarToggleSwitch component.
   */
  const handleToggle = useCallback(() => {
    setIsConfirmationModalOpen(true);
  }, [setIsConfirmationModalOpen]);

  /**
   * Handles the confirmation of a change in the toggle switch value.
   *
   * @param {boolean} newValue - The new value of the toggle switch.
   * @returns {void}
   */
  const handleConfirmChange = useCallback(
    async (newValue: boolean) => {
      setIsConfirmationModalOpen(false);
      if (!objectId) return;

      setIsPendingState(true);
      updateControlls({ objectId: objectId, state: newValue });
    },
    [setIsConfirmationModalOpen, setIsObjectOn],
  );

  return (
    <>
      <StyledCarToggleSwitchWrapper withoutBorder={withoutBorder} withBackground={withBackground}>
        <StyledCarToggleSwitchLabel isHighlight={!isObjectOn}>
          {t('off')}
        </StyledCarToggleSwitchLabel>
        <Tippy content={isPendingState ? t('commandInProgress') : t('changeState')}>
          <div>
            <Toggle
              checked={isObjectOn}
              backgroundColorChecked={isPendingState ? 'orange' : '#12de67'}
              backgroundColorUnchecked={isPendingState ? 'orange' : '#edeef1'}
              name={'toggle'}
              onChange={handleToggle}
              disabled={isPendingState ?? false}
              value={'false'}
              labelRight={''}
              labelLeft={''}
              height={24}
              sliderHeight={16}
              sliderWidth={16}
              width={50}
              aria-label={isObjectOn ? t('on') : t('off')}
            />
          </div>
        </Tippy>
        <StyledCarToggleSwitchLabel isHighlight={isObjectOn}>{t('on')}</StyledCarToggleSwitchLabel>

        <Tippy content={isCanAvailable ? t('canUnlocked') : t('canLocked')}>
          <div>
            <IconBox disabled>{isCanAvailable ? <FaLockOpen /> : <FaLock />}</IconBox>
          </div>
        </Tippy>

        <Tippy content={t('history')} placement="top">
          <IconBox onClick={() => navigate(routes.controllsHistory(objectId ?? ''))}>
            <IoFlashOff />
          </IconBox>
        </Tippy>
      </StyledCarToggleSwitchWrapper>

      <ConfirmationModal
        size="small"
        isOpen={isConfirmationModalOpen}
        title={t('confirm')}
        message={t(isObjectOn ? 'confirmTurnOff' : 'confirmTurnOn')}
        confirmText={t('yes')}
        onDecline={() => setIsConfirmationModalOpen(false)}
        onConfirm={() => handleConfirmChange(!isObjectOn)}
        onClose={() => setIsConfirmationModalOpen(false)}
        disabled={false}
        isLoading={false}
        aria-label={t('confirmationModal')}
      />
    </>
  );
};

export default CarToggleSwitch;

import i18n from 'locales/i18n';

i18n.loadNamespaces('general');

export const minLength = (length: number, errorMessage?: string) => (value?: string) => {
  if (!value?.length) {
    return;
  }

  if (value.length >= length) {
    return;
  }

  return errorMessage ?? i18n.t('validation.minLength', { value: length });
};

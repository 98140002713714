import FormField from 'components/FormField';
import Input, { InputProps } from 'components/Input';
import { FieldAttributes, useField } from 'formik';
import React from 'react';

export type InputFormFieldProps = FieldAttributes<InputProps> & {
  name: string;
  noMargin?: boolean;
  label?: string;
};

const InputFormField = ({ noMargin, label, width, ...props }: InputFormFieldProps) => {
  const [field, meta] = useField(props);
  const errorId = `${field.name}-error`;

  return (
    <FormField
      errorId={errorId}
      touched={meta.touched}
      error={meta.error}
      noMargin={noMargin}
      label={label}
      width={width}
    >
      <Input {...field} {...props} width={width} />
    </FormField>
  );
};

export default React.memo(InputFormField);

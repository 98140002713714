import ErrorIcon from 'assets/error-icon.svg';
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components/macro';
import { useAppContext } from 'utils/appContext';

import { sharedInputStyles } from '../common';

export type InputProps = React.ComponentPropsWithoutRef<'input'> & {
  rightIcon?: string;
  onRightIconClick?: () => void;
  leftIcon?: string;
  dark?: boolean;
  inputSize?: 'md';
  error?: string;
  width?: string;
  unit?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  noLabel?: boolean;
};

type StyledInputProps = React.HTMLProps<HTMLInputElement> & {
  dark?: boolean;
  medium?: boolean;
  hasLeftIcon?: boolean;
  error?: string;
};

type IconWrapperProps = React.HTMLProps<HTMLDivElement> & {
  disabled?: boolean;
  right?: boolean;
  error?: boolean;
};

const Wrapper = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  position: relative;
  ${p =>
    p.width &&
    css`
      width: ${p.width};
    `}
`;

const StyledInput = styled.input<StyledInputProps>`
  ${p => sharedInputStyles(p)};
  ${p => p.hasLeftIcon && `padding-left: 44px;`}
  ${p => p.error && `border: 1px solid ${p.theme.colors.error300};`}
  width: 100%;

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='datetime-local']::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
  }

  &[type='datetime-local']::-webkit-calendar-picker-indicator,
  &[type='date']::-webkit-calendar-picker-indicator,
  &[type='month']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  -webkit-appearance: textfield;
  -moz-appearance: textfield;
`;

const Label = styled.label`
  position: absolute;
  top: 2px;
  left: 20px;

  font-size: 12px;
  color: ${p => p.theme.colors.gray400};
  font-weight: 600;
`;

const IconWrapper = styled.div<IconWrapperProps>`
  position: absolute;
  ${p =>
    p.right
      ? css`
          right: 20px;
        `
      : css`
          left: 12px;
        `}

  ${p =>
    !p.error
      ? css`
          path {
            fill: ${p.disabled ? p.theme.colors.gray300 : p.theme.colors.gray400};
          }
        `
      : `cursor: pointer;`}
`;

const UnitWrapper = styled.div`
  position: absolute;
  right: 2rem;
  padding-left: 1rem;
  border-left: 1px solid ${p => p.theme.colors.borderGray};
  font-size: 1.4rem;
  font-weight: 600;
  color: ${p => p.theme.colors.textLight};
`;

const Input = ({
  rightIcon,
  onRightIconClick,
  leftIcon,
  inputSize,
  disabled,
  dark,
  error,
  value,
  onChange,
  width,
  unit,
  noLabel,
  ...rest
}: InputProps) => {
  const [inputValue, setInputValue] = useState('');
  const { darkMode } = useAppContext();

  useEffect(() => {
    if (value) {
      setInputValue(value as string);
    }
  }, [value]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (!onChange) return;
    onChange(e);
  };

  return (
    <>
      <Wrapper width={width}>
        {inputValue && inputSize !== 'md' && !noLabel && <Label>{rest.placeholder}</Label>}
        <StyledInput
          placeholder={rest.placeholder}
          onChange={handleOnChange}
          disabled={disabled}
          dark={dark || darkMode}
          medium={inputSize === 'md'}
          hasLeftIcon={!!leftIcon}
          error={error}
          value={inputValue}
          {...rest}
        />
        {rightIcon && (
          <IconWrapper right disabled={disabled} onClick={onRightIconClick}>
            <SVG src={rightIcon} width={24} style={{ cursor: 'pointer' }} />
          </IconWrapper>
        )}
        {leftIcon && (
          <IconWrapper disabled={disabled}>
            <SVG src={leftIcon} width={24} />
          </IconWrapper>
        )}
        {unit && <UnitWrapper>{unit}</UnitWrapper>}
        {error && (
          <IconWrapper right error onClick={() => setInputValue('')}>
            <SVG src={ErrorIcon} width={20} />
          </IconWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default memo(Input);

import styled from 'styled-components';

interface AddGroupStepsProps {
  icon: React.ReactNode;
  number: number;
  text: string;
}

const StepContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xLarge};
  padding: ${({ theme }) =>
    `${theme.spacing.xLarge} ${theme.spacing.xLarge} 0 ${theme.spacing.xLarge}`};
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Number = styled.div`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

const StepTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const AddGroupSteps = ({ icon, number, text }: AddGroupStepsProps) => (
  <StepContainer>
    {icon}
    <DescriptionWrapper>
      <Number>{number}/2</Number>
      <StepTitle>{text}</StepTitle>
    </DescriptionWrapper>
  </StepContainer>
);

export default AddGroupSteps;

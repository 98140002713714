import { AllowedModules } from 'common';
import { ReportTypes } from 'modules/reports/common';
import { useCallback } from 'react';
import { useCheckModuleAccess } from 'utils';

export enum AvailablePreviewReports {
  DISTANCE_COMBUSTION_DRIVER = ReportTypes.DISTANCE_COMBUSTION_DRIVER,
}

const AVAILABLE_REPORTS_LIST = [AvailablePreviewReports.DISTANCE_COMBUSTION_DRIVER];

function useReportPreview() {
  const checkModuleAccess = useCheckModuleAccess();

  /**
   * This function is used to check availability
   * to display graphical preview
   */
  const reportPreviewAccess = useCallback((type: ReportTypes) => {
    if (!checkModuleAccess(AllowedModules.REPORTS_GRAPHICAL_PREVIEW)) return false;

    return AVAILABLE_REPORTS_LIST.includes(type as unknown as AvailablePreviewReports);
  }, []);

  return { reportPreviewAccess };
}

export default useReportPreview;

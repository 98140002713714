import FormField from 'components/FormField';
import Toggle, { ToggleProps } from 'components/Toggle';
import { FieldAttributes, useField } from 'formik';
import { memo } from 'react';

export type ToggleFormFieldProps = FieldAttributes<ToggleProps> & {
  name: string;
  noMargin?: boolean;
  label?: string;
};

const ToggleFormField = ({ label, noMargin, ...props }: ToggleFormFieldProps) => {
  const [field, meta] = useField(props);
  const errorId = `${field.name}-error`;

  return (
    <FormField
      errorId={errorId}
      touched={meta.touched}
      error={meta.error}
      noMargin={noMargin}
      label={label}
    >
      <Toggle id={field.name} {...field} {...props} />
    </FormField>
  );
};

export default memo(ToggleFormField);

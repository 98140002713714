import { RefObject, useEffect } from 'react';

type IntersectionObserverProps = {
  root?: RefObject<HTMLElement>;
  target?: RefObject<HTMLElement>;
  onChange?: (entry: IntersectionObserverEntry) => void;
  onIntersect?: (entry: IntersectionObserverEntry) => void;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
};

export const useIntersectionObserver = ({
  root,
  target,
  onIntersect,
  onChange,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
}: IntersectionObserverProps) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          if (onIntersect && entry.isIntersecting) {
            onIntersect(entry);
          }
          if (onChange) {
            onChange(entry);
          }
        }),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      },
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.unobserve(el);
    };
  }, [enabled, onChange, onIntersect, root, rootMargin, target, threshold]);
};

import i18n from 'locales/i18n';

i18n.loadNamespaces('general');

export const isRequired = (errorMessage?: string) => (value?: unknown) => {
  if (typeof value === 'string' && value?.length) {
    return;
  }

  if (typeof value === 'number') {
    return;
  }

  if (Array.isArray(value) && value.length) {
    return;
  }

  if (!Array.isArray(value) && value) {
    return;
  }

  return errorMessage ?? i18n.t('validation.requiredField');
};

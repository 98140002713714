import Arrow from 'assets/arrow-right.svg';
import Button from 'components/Button';
import MobileModal from 'components/MobileModal/MobileModal';
import { Overlay } from 'components/Overlay';
import { useSelect } from 'downshift';
import { useField } from 'formik';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { useMainLayout, useWindowSize } from 'utils';

import { colorPalette } from '../utils/colorPalette';
import { GroupColor } from './GroupItem';

const ColorSelectWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  width: ${({ isMobile }) => (isMobile ? '100%' : '312px')};
  padding: 12px;
  align-items: start;
  gap: ${({ theme }) => theme.spacing.large};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  cursor: pointer;

  font-weight: 400;
  color: #000;
  border: 1px solid transparent;

  &:hover:not([disabled]),
  &:focus,
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.gray400};
    outline: none;
  }
`;

const PaletteContainer = styled.ul<{ isHidden: boolean }>`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  width: 312px;
  padding: ${({ theme }) => theme.spacing.large};
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  background: ${({ theme }) => theme.colors.white};

  box-shadow: ${({ theme }) => theme.shadows.m};
  outline: none;
  margin-top: 6px;
`;

const PaletteItem = styled.option<{ color: string; isHighlighted: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 48px;
  background: ${({ color }) => color};
  cursor: pointer;
  border: ${({ isHighlighted, theme }) =>
    isHighlighted ? 'border: 2px solid #000' : `1px solid ${theme.colors.gray300}`};
`;

const Icon = styled.div`
  transform: rotate(90deg);
  cursor: pointer;

  path {
    fill: ${p => p.theme.colors.primary600};
  }
`;

const SelectedColor = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const modalContainer = document.getElementById('modal-container');

const ColorSelect = ({ initialValue }: { initialValue: string }) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { showMobileModal, setShowMobileModal } = useMainLayout();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, helpers] = useField('color');

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: colorPalette,
  });

  useEffect(() => {
    if (!selectedItem) return;
    helpers.setValue(selectedItem.value);
  }, [selectedItem]);

  return (
    <>
      {showMobileModal && <Overlay />}
      <div {...getToggleButtonProps()}>
        <ColorSelectWrapper
          isMobile={isMobile}
          onClick={() => isMobile && setShowMobileModal(true)}
        >
          <SelectedColor>
            <GroupColor color={selectedItem ? selectedItem.value : initialValue || '#FFF'} />{' '}
            {selectedItem ? t('selectedColor') : t('selectColor')}
          </SelectedColor>
          <Icon>
            <SVG src={Arrow} width={24} />
          </Icon>
        </ColorSelectWrapper>
      </div>

      {!isMobile && (
        <PaletteContainer {...getMenuProps()} isHidden={!isOpen}>
          {isOpen &&
            colorPalette.map((item, index) => (
              <PaletteItem
                color={item.value}
                key={item.value}
                isHighlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              />
            ))}
        </PaletteContainer>
      )}
      {showMobileModal &&
        createPortal(
          <MobileModal>
            <Title>{t('selectColor')}</Title>
            <PaletteContainer isHidden={false} {...getMenuProps()}>
              {colorPalette.map((item, index) => (
                <PaletteItem
                  color={item.value}
                  key={item.value}
                  isHighlighted={highlightedIndex === index}
                  {...getItemProps({ item, index })}
                />
              ))}
            </PaletteContainer>
            <Button secondary fillWidth onClick={() => setShowMobileModal(false)}>
              {t('confirmColor')}
            </Button>
          </MobileModal>,
          modalContainer!,
        )}
    </>
  );
};

export default ColorSelect;

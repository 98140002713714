import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AddGroupItem from './AddGroupItem';
import { AddButton, GroupItemsProps, TopWrapper } from './DesktopGroupList';
import GroupItem from './GroupItem';
import MobileObjectsList from './MobileObjectsList';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
  margin-top: ${({ theme }) => theme.spacing.large};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.large};

  *::-webkit-scrollbar {
    display: block;
  }
`;

export const StyledTopWrapper = styled(TopWrapper)`
  justify-content: space-between;
  height: 6rem;
`;

export const MobileGroupListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 34rem);
  overflow-y: auto;
`;

const MobileGroupList = ({
  groups,
  handleGroupForm,
  handleGroupId,
  searchInputValue,
  handleInputChange,
  handleGroupClick,
  selectedGroupId,
}: GroupItemsProps) => {
  const { t } = useTranslation();

  const groupData = groups.find(group => group.value === selectedGroupId);

  return selectedGroupId ? (
    <MobileObjectsList
      objects={groupData?.objects!}
      handleGroupClick={handleGroupClick}
      groupId={selectedGroupId}
      color={groupData?.color}
      label={groupData?.label}
      handleGroupForm={handleGroupForm}
      handleGroupId={handleGroupId}
    />
  ) : (
    <Container>
      <StyledTopWrapper>
        <SpeditionSearchInput
          searchInputValue={searchInputValue}
          handleInputChange={e => handleInputChange?.(e)}
          placeholder={t('searchGroup')}
        />
        <AddButton icon={<AddIcon />} onClick={handleGroupForm} />
      </StyledTopWrapper>
      <MobileGroupListWrapper>
        {groups.map(group => (
          <GroupItem
            key={group.value.toString()}
            label={group.label}
            comment={group.comment}
            objectsNumber={group.objects.length}
            color={group.color}
            groupId={group.value}
            onClickHandler={handleGroupClick}
            handleGroupForm={handleGroupForm}
            handleGroupId={handleGroupId}
          />
        ))}
      </MobileGroupListWrapper>
      <AddGroupItem addButtonText={t('addGroup')} clickHandler={handleGroupForm} />
    </Container>
  );
};

export default MobileGroupList;

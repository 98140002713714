import { getObjects } from 'modules/objects/queries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdSend } from 'react-icons/io';
import { MdAccountCircle } from 'react-icons/md';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import {
  Circle,
  MessageContent,
  MessageItemContainer,
  StyledTextArea,
  TextAreaWrapper,
  TopMessageWrapper,
  VehiclesNumber,
} from '../components/CurrentChat/style';
import MessageItem from '../components/MessageItem';
import {
  IsTypingResponse,
  MessagesData,
  MessagesResponse,
  ReceiversData,
  ReceiversResponse,
  socket,
} from '../queries/socket';

const DriverChatTopbar = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.regular};
  padding: ${({ theme }) => theme.spacing.large};
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  border-width: 1px 0 1px 0;
`;

const DriversName = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  height: 100%;
  min-height: 100%;
`;

const DriverChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DriverChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing.xLarge};
  padding: ${({ theme }) => theme.spacing.xLarge};
  overflow-y: auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const DriverMessenger = () => {
  const { t } = useTranslation();
  const [receivedMsg, setReceivedMsg] = useState<ReceiversData[]>([]);
  const [messages, setMessages] = useState<MessagesData[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isSpeditorTyping, setIsSpeditorTyping] = useState<boolean>(false);

  const { data: objectData } = useQuery(
    getObjects(
      { 'object-filtering': [messages[0]?.Data.object.objectId.toString()] },
      { enabled: !!messages.length, refetchInterval: 5000 },
    ),
  );

  useEffect(() => {
    socket.connect();

    socket.emit('server:receivers', (response: ReceiversResponse) => {
      setReceivedMsg(response.receivers);
    });

    socket.on('client:message', response => {
      setMessages(messages.concat(response.messages));
    });

    socket.on('client:typing', (response: IsTypingResponse) => {
      if (response.id) {
        setIsSpeditorTyping(response.isTyping);
      }
    });

    return () => {
      socket.off('server:receivers');
      socket.off('client:message');
      socket.off('client:typing');
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.emit(
      'server:messages',
      { id: receivedMsg[0]?.Account.id },
      (response: MessagesResponse) => {
        setMessages(response.messages);
      },
    );
  }, [receivedMsg]);

  useEffect(() => {
    socket.on('client:message', response => {
      setMessages(messages.concat(response.messages));
    });
  }, [messages]);

  const sendMessage = () => {
    if (!receivedMsg[0]) return;

    socket.emit('server:message', { content: inputValue, id: receivedMsg[0].Account.id }, () => {
      setInputValue('');
    });
  };

  const handleTyping = (id: number, isTyping: boolean) => {
    socket.emit('server:typing', { id, isTyping: isTyping });
  };

  let timeout: NodeJS.Timeout | undefined;

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>, id: number) => {
    if (e.key !== 'Enter') {
      handleTyping(id, true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleTyping(id, false);
      }, 3000);
    } else {
      handleTyping(id, false);
    }
  };

  const handleSendMessage = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      sendMessage();
    } else {
      handleKeyPress(e, receivedMsg[0].Account.id);
    }
  };

  const driversMsgs = messages.filter((msg: MessagesData) => !msg.Data.account.speditorAccount);
  const lastDriversName = driversMsgs[driversMsgs.length - 1]?.Message.name;

  return (
    <DriverChatContainer>
      <DriverChatTopbar>
        <MdAccountCircle color="red" size={24} />
        <DriversName>{lastDriversName}</DriversName>
      </DriverChatTopbar>
      <DriverChatWrapper>
        {messages?.map(message => (
          <MessageItem
            key={message.Message.id}
            message={message}
            objectData={objectData?.data[0]}
            isMobile
            speditorName={t('spedition')}
          />
        ))}
        {isSpeditorTyping && (
          <MessageItemContainer>
            <Circle>
              <MdAccountCircle size={24} color={'#F44542'} />
            </Circle>
            <div style={{ width: '100%' }}>
              <TopMessageWrapper>
                <VehiclesNumber>{messages?.[0]?.Data?.account?.name}</VehiclesNumber>
              </TopMessageWrapper>
              <MessageContent>{t('isTyping') + '...'}</MessageContent>
            </div>
          </MessageItemContainer>
        )}
        <TextAreaWrapper isMobile>
          <StyledTextArea
            onChange={e => {
              setInputValue(e.target.value);
            }}
            onKeyDown={e => handleSendMessage(e)}
            name={'message'}
            placeholder={t('enterMessage')}
            value={inputValue}
          />
          <IoMdSend color={'rgb(244, 69, 66)'} size={24} onClick={sendMessage} />
        </TextAreaWrapper>
      </DriverChatWrapper>
    </DriverChatContainer>
  );
};

export default DriverMessenger;

import i18n from 'locales/i18n';
import { routes } from 'navigation/routes';

i18n.loadNamespaces('general');

export const routesTranslations: Record<string, string> = {
  [routes.settings]: i18n.t('settings.settings'),
  [routes.contactDetailsSettings]: i18n.t('settings.contactDetails'),
  [routes.colorModeSettings]: i18n.t('settings.colorMode'),
  [routes.vehicleUnplugSettings]: i18n.t('settings.deviceDisconnect'),
  [routes.distanceSourceSettings]: i18n.t('settings.distanceSource'),
  [routes.logBookSettings]: i18n.t('settings.logBook'),
  [routes.mapSettings]: i18n.t('settings.mapSettings'),
  [routes.routeSettings]: i18n.t('settings.route'),
  [routes.rpmSettings]: i18n.t('settings.rpm'),
  [routes.serviceSettings]: i18n.t('settings.service'),
  [routes.speedLimitSettings]: i18n.t('settings.speedLimit'),
  [routes.vatUsSettings]: i18n.t('settings.vatUs'),
  [routes.engineStartSettings]: i18n.t('settings.vehicleStart'),
  [routes.workingDistanceSettings]: i18n.t('settings.workingDistance'),
  [routes.otherObjectsSettings]: i18n.t('settings.other'),
  [routes.otherMenuBar]: i18n.t('settings.otherMenuBar'),
  [routes.changePasswordSettings]: i18n.t('settings.password'),
  [routes.batteryVoltageSettings]: i18n.t('settings.batteryVoltage'),
  [routes.objectsSettings]: i18n.t('settings.objects'),
};

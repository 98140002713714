import styled from 'styled-components/macro';
import { Theme } from 'styles/themes';

import { Status } from '../common';

interface WrapperProps {
  type: string;
}

const handleBgColor = (type: string, theme: Theme) => {
  switch (type) {
    case Status.Information:
      return theme.colors.info300;
    case Status.Positive:
      return theme.colors.success300;
    case Status.Negative:
      return theme.colors.error300;
    case Status.Warning:
      return theme.colors.primary600;
    case Status.Neutral:
      return theme.colors.gray500;
    default:
  }
};

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ type, theme }) => handleBgColor(type, theme)};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  padding: 1rem 5.4rem;
  text-align: center;
  border-radius: 0.25rem;
  bottom: 3rem;
  left: 0;
  right: 0;
  margin: auto;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;

  svg {
    display: block;
    fill: ${({ theme }) => theme.colors.white};
  }
`;

import LoadingIndicator from 'components/LoadingIndicator';
import React, { memo } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';
import { useAppContext } from 'utils/appContext';

type ButtonProps = React.ComponentPropsWithoutRef<'button'> & {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  primary?: boolean;
  secondary?: boolean;
  icon?: React.ReactElement;
  isDropdown?: boolean;
  isLoading?: boolean;
  hideArrow?: boolean;
  fillWidth?: boolean;
  backgroundColor?: string;
  dark?: boolean;
  isIconOrange?: boolean;
  iconColor?: string;
};

const sizeStyles = {
  xs: css`
    border-radius: 0.4rem;
    padding: 0.25rem 1.5rem;
    height: 2.3rem;
    font-size: 1rem;
  `,
  sm: css`
    border-radius: 0.4rem;
    padding: 0.5rem 1.6rem;
    height: 3.2rem;
  `,
  md: css`
    border-radius: 0.6rem;
    padding: 1rem 1.2rem;
    height: 4.2rem;
  `,
  lg: css`
    border-radius: 0.8rem;
    padding: 1.3rem 3.2rem;
    font-size: 1.8rem;
    height: 5.6rem;
    line-height: 3rem;
  `,
};

const StyledButton = styled.button<ButtonProps>`
  ${p =>
    p.fillWidth &&
    css`
      width: 100%;
    `};
  ${({ disabled }) => !disabled && `cursor: pointer;`}
  color: ${p => (p.primary ? p.theme.colors.white : p.theme.colors.text)};
  background: ${p =>
    p.primary ? p.theme.colors.primary500 : p.secondary ? p.theme.colors.white : 'none'};
  ${p =>
    p.dark &&
    !p.primary &&
    css`
      background: none;
    `};
  ${p =>
    p.backgroundColor &&
    css`
      background: ${p.backgroundColor};
    `}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: ${p => (p.secondary ? `1px solid ${p.theme.colors.gray300}` : `none`)};
  font-weight: 600;
  font-size: 1.4rem;
  transition: 0.2s ease;
  ${({ size }) => size && sizeStyles[size]}
  transition: .2s ease;

  &:hover {
    background: ${p =>
      p.primary
        ? p.theme.colors.primary600
        : p.secondary
        ? p.theme.colors.white
        : p.theme.colors.gray200};
    ${p =>
      p.secondary &&
      css`
        border-color: ${p.theme.colors.primary700};
      `}
    ${p =>
      p.dark &&
      css`
        background: ${p => p.theme.colors.secondaryBackground};
      `}
  }

  &:active {
    background: ${p =>
      p.primary
        ? p.theme.colors.primary700
        : p.secondary
        ? p.theme.colors.primary200
        : p.theme.colors.gray300};
  }

  &:disabled {
    color: ${p => (p.dark ? p.theme.colors.gray300 : p.theme.colors.text)};
    background: ${p => (p.dark ? p.theme.colors.gray400 : p.theme.colors.gray200)};
    ${p =>
      p.secondary && `border-color: ${p.dark ? p.theme.colors.gray400 : p.theme.colors.gray200};`}
    opacity: 0.5;
  }
`;

const IconWrapper = styled.div<{ orange?: boolean; isDropdown?: boolean }>`
  margin-left: 0.8rem;
  display: flex;

  svg {
    height: 2rem;
    width: auto;
    ${({ orange, theme }) => orange && css`color: ${theme.colors.primary600}};`}
    ${p => p.isDropdown && `transform: rotate(90deg);`}
  }
`;

const LeftIcon = styled.div<{ orange?: boolean; iconColor?: string }>`
  svg {
    height: 2rem;
    width: auto;
    ${({ orange, iconColor, theme }) =>
      orange &&
      css`
        color ${iconColor ?? theme.colors.primary600};
      `}
  }

  margin-right: 1rem;
`;

const Button = ({
  size = 'md',
  primary,
  secondary,
  children,
  icon,
  iconColor,
  isDropdown,
  isLoading,
  hideArrow = false,
  isIconOrange = true,
  ...rest
}: ButtonProps) => {
  const { darkMode } = useAppContext();

  return (
    <StyledButton size={size} primary={primary} secondary={secondary} dark={darkMode} {...rest}>
      {icon && (
        <LeftIcon orange={!primary && isIconOrange} iconColor={iconColor}>
          {icon}
        </LeftIcon>
      )}
      {isLoading ? <LoadingIndicator /> : children}
      {!hideArrow && (size === 'lg' || (!primary && !secondary)) && !isLoading && (
        <IconWrapper orange={!primary} isDropdown={isDropdown}>
          <MdKeyboardArrowRight />
        </IconWrapper>
      )}
    </StyledButton>
  );
};

export default memo(Button);

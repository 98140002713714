import dayjs from 'dayjs';
import { axios } from 'lib/axios';

import {
  DistanceParamsDto,
  DistanceResponseDto,
  RefiilingsResponseDto,
  RefillingsParamsDto,
  RouteDetailsParamsDto,
  RouteDetailsResponseDto,
  RouteStatisticsParamsDto,
  RouteStatisticsResponseDto,
  SimpleRouteDto,
} from '../dtos';
import { endpoints } from '.';

const formatDate = (date: string) => dayjs(date).format('YYYY-MM-DD HH:mm:ss');

export const getSimpleRoute = ({ objectId, params }: SimpleRouteDto) =>
  axios
    .get(endpoints.routeSimple(objectId), {
      params: {
        ...params,
        start: formatDate(params.start),
        end: formatDate(params.end),
      },
    })
    .then(res => res.data)
    .catch(e => e.response.data);

export const getRouteDetails = ({
  objectId,
  params,
}: {
  objectId: string;
  params: RouteDetailsParamsDto;
}) =>
  axios
    .get<RouteDetailsResponseDto>(endpoints.routeDetails(objectId), {
      params: {
        ...params,
        start: formatDate(params.start),
        end: formatDate(params.end),
        react: true,
      },
    })
    .then(res => ({ data: res.data }))
    .catch(e => e.response.data);

export const getDistance = (params: DistanceParamsDto) =>
  axios
    .get<DistanceResponseDto>(endpoints.distance, { params })
    .then(res => res.data)
    .catch(e => e.response.data);

export const getRefillings = (params: RefillingsParamsDto) =>
  axios
    .get<RefiilingsResponseDto>(endpoints.refillings, { params })
    .then(res => res.data)
    .catch(e => e.response.data);

export const getRouteStatistics = ({
  objectId,
  params: { start, end },
}: RouteStatisticsParamsDto) =>
  axios
    .get<RouteStatisticsResponseDto>(endpoints.routeStatistics(objectId), {
      params: { start: formatDate(start), end: formatDate(end) },
    })
    .then(res => res.data);

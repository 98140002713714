import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'utils';

import { GroupItem as GroupItemProps, LoginItem } from '../dtos';
import DesktopLoginsList from './DesktopLoginsList';
import List from './List';
import MobileLoginGroupsList from './MobileLoginGroupsList';
import MobileLoginsList from './MobileLoginsList';

export interface LoginsListProps {
  logins?: LoginItem[];
  groups?: GroupItemProps[];
  handleLoginForm: () => void;
  handleSelectedLoginId: (id: number | null) => void;
  selectedLoginId: number | null;
  handleForm?: () => void;
  handleChangePasssword?: () => void;
}

const LoginsList = ({
  logins,
  groups,
  handleLoginForm,
  handleSelectedLoginId,
  selectedLoginId,
  handleChangePasssword,
}: LoginsListProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInputValue(e.target.value);

  const filteredLoginsList = searchInputValue
    ? logins?.filter(login => login.user.toLowerCase().includes(searchInputValue.toLowerCase()))
    : logins;

  if (isMobile && selectedLoginId) {
    return (
      <MobileLoginGroupsList
        searchInputValue={searchInputValue}
        handleInputChange={handleInputChange}
        handleForm={handleLoginForm}
        selectedLoginId={selectedLoginId}
        handleSelectedLoginId={handleSelectedLoginId}
        handleChangePasssword={handleChangePasssword}
      />
    );
  }
  if (isMobile && !selectedLoginId) {
    return (
      <MobileLoginsList
        handleSelectedLoginId={handleSelectedLoginId}
        logins={filteredLoginsList ?? []}
        handleLoginForm={handleLoginForm}
        selectedLoginId={selectedLoginId}
        handleForm={handleLoginForm}
        searchInputValue={searchInputValue}
        handleInputChange={handleInputChange}
      />
    );
  }

  return (
    <List
      title={t('logins')}
      searchInputValue={searchInputValue}
      inputPlaceholder={t('searchLogin')}
      imagePlaceholderText={t('noLoginSelected')}
      handleInputChange={handleInputChange}
      selectedLoginId={selectedLoginId}
      handleSelectedLoginId={handleSelectedLoginId}
      handleForm={handleLoginForm}
      addButtonText={t('addLogin')}
      groups={groups}
    >
      <DesktopLoginsList
        handleSelectedLoginId={handleSelectedLoginId}
        logins={filteredLoginsList ?? []}
        handleLoginForm={handleLoginForm}
        selectedLoginId={selectedLoginId}
        handleForm={handleLoginForm}
        handleChangePasssword={handleChangePasssword}
      />
    </List>
  );
};

export default LoginsList;

import { useTranslation } from 'react-i18next';

import {
  Container,
  SpeditionWrapper,
  SummaryDataType,
  Title,
  TopWrapper,
} from '../pages/Spedition';
import PlaceHolder from './PlaceHolder';
import SpeditionObjectsList from './SpeditionObjectsList';
import SpeditionSearchInput from './SpeditionSearchInput';
import SpeditionSummary from './SpeditionSummary';

const SpeditionDesktop = ({
  selectedItems,
  setSelectedItems,
  searchInputValue,
  handleInputChange,
  handleSelectedItems,
  summaryData,
  checkAllBindedItems,
}: {
  selectedItems: SummaryDataType[];
  setSelectedItems: (items: SummaryDataType[]) => void;
  searchInputValue?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectedItems: (item: SummaryDataType) => void;
  summaryData: SummaryDataType[];
  checkAllBindedItems: () => void;
}) => {
  const { t } = useTranslation();

  const objectsWithActiveLink = summaryData?.filter(object => object.url);

  return (
    <Container>
      <TopWrapper>
        <Title>{t('spedition')}</Title>
        <SpeditionSearchInput
          searchInputValue={searchInputValue}
          handleInputChange={handleInputChange}
        />
      </TopWrapper>
      <SpeditionWrapper>
        <SpeditionObjectsList
          selectedItems={selectedItems}
          handleSelectedItems={handleSelectedItems}
          summaryData={summaryData ?? []}
          searchInputValue={searchInputValue}
          checkAllBindedItems={checkAllBindedItems}
          handleInputChange={handleInputChange}
        />
        {!selectedItems?.length && <PlaceHolder text={t('noVehicleSelected')} />}
        {selectedItems.length > 0 && (
          <SpeditionSummary
            objectsWithActiveLink={objectsWithActiveLink?.length ? objectsWithActiveLink : []}
            selectedItems={selectedItems}
            resetSelectedItems={() => setSelectedItems([])}
          />
        )}
      </SpeditionWrapper>
    </Container>
  );
};

export default SpeditionDesktop;

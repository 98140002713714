import Tabs from 'components/Tabs';
import { Title } from 'modules/driver/pages/Spedition';
import { TabsWrapper } from 'modules/reports/pages/Reports';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useWindowSize } from 'utils';

import Groups from '../components/Groups';
import Logins from '../components/Logins';

export enum GroupsAndLoginsTabs {
  GROUPS = 'groups',
  LOGINS = 'logins',
}

export const Container = styled.div<{ isMobile: boolean }>`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  padding: ${({ isMobile }) => (isMobile ? 0 : '40px')};
  height: 100%;
`;

export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledTabsWrapper = styled(TabsWrapper)`
  width: auto;
`;

const LoginsAndGroups = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<GroupsAndLoginsTabs>(GroupsAndLoginsTabs.GROUPS);
  const { isMobile } = useWindowSize();

  const tabs = useMemo(
    () => [
      { label: t('objectsGroups'), value: GroupsAndLoginsTabs.GROUPS },
      { label: t('logins'), value: GroupsAndLoginsTabs.LOGINS },
    ],
    [isMobile],
  );

  const handleTabClick = (value: string) => setActiveTab(value as GroupsAndLoginsTabs);

  return (
    <Container isMobile={isMobile}>
      {!isMobile && (
        <TopWrapper>
          <Title>{t('logins&groups')}</Title>
          <StyledTabsWrapper>
            <Tabs options={tabs} activeTab={activeTab} onClick={handleTabClick} />
          </StyledTabsWrapper>
        </TopWrapper>
      )}
      {activeTab === GroupsAndLoginsTabs.GROUPS ? (
        <Groups activeTab={activeTab} tabs={tabs} handleTabClick={handleTabClick} />
      ) : (
        <Logins activeTab={activeTab} tabs={tabs} handleTabClick={handleTabClick} />
      )}
    </Container>
  );
};

export default LoginsAndGroups;

import Tippy from '@tippyjs/react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import BaseButton from 'components/Button';
import Checkbox from 'components/Checkbox';
import { SelectItem } from 'components/common';
import IconButton, { Icon } from 'components/IconButton';
import LoadingIndicator from 'components/LoadingIndicator';
import StyledObjectInfoContent from 'components/ObjectInfo/ObjectInfoContent.styled';
import Select from 'components/Select';
import {
  useCombobox,
  UseComboboxGetToggleButtonPropsOptions,
  UseComboboxState,
  UseComboboxStateChange,
  UseComboboxStateChangeOptions,
  useMultipleSelection,
} from 'downshift';
import { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { MdUnfoldMore } from 'react-icons/md';
import styled from 'styled-components/macro';
import { useClose, useWindowSize } from 'utils';

import {
  Badge,
  BadgesWrapper,
  ButtonsWrapper,
  FullScreenListWrapper,
  Label,
  LabelIcon,
  List,
  ListItem,
  ListItemHeader,
  ListItemHeaderLabel,
  ListWrapper,
  SearchInput,
  SearchInputWrapper,
  SelectAll,
  SelectedInfo,
  Title,
  Wrapper,
} from './style';

const Button = styled(BaseButton)`
  flex: 1;
`;

const MenuWrapper = ({
  condition,
  showOnTop,
  screenHeight,
  children,
  preventOffScreenDisplay,
  maxHeight,
  selectWidth,
  closeCallback,
  isOpen,
}: {
  condition: boolean;
  showOnTop?: boolean;
  screenHeight: number | null;
  children: ReactNode;
  preventOffScreenDisplay?: boolean;
  maxHeight?: number;
  selectWidth?: number;
  closeCallback: () => void;
  isOpen: boolean;
}) => {
  const closeRef = useRef(null);
  useClose(closeRef, closeCallback);
  return condition ? (
    <FullScreenListWrapper ref={closeRef} screenHeight={screenHeight} isOpen={isOpen}>
      {children}
    </FullScreenListWrapper>
  ) : (
    <ListWrapper
      showOnTop={showOnTop}
      preventOffScreenDisplay={preventOffScreenDisplay}
      maxHeight={maxHeight}
      selectWidth={selectWidth}
      ref={closeRef}
      isOpen={isOpen}
      minWidth={50}
    >
      {children}
    </ListWrapper>
  );
};

const DropdownIcon = ({
  withTooltip,
  tippyContent,
  disabledTooltip,
  iconButton,
  toggleButtonProps,
}: {
  withTooltip: boolean;
  tippyContent: string;
  disabledTooltip: boolean;
  iconButton: Icon;
  toggleButtonProps: (options?: UseComboboxGetToggleButtonPropsOptions | undefined) => unknown;
}) =>
  withTooltip ? (
    <Tippy content={tippyContent} disabled={disabledTooltip}>
      <div>
        <IconButton icon={iconButton} {...toggleButtonProps} />
      </div>
    </Tippy>
  ) : (
    <IconButton icon={iconButton} {...toggleButtonProps} />
  );

export type SearchDropdownProps = {
  items: SelectItem[];
  titleInnerBox?: string;
  columns?: string[];
  values?: SelectItem[];
  placeholderText?: string;
  buttonText?: string;
  handleSubmit?: () => void;
  onChange?: (items: SelectItem[]) => void;
  wrapOptions?: boolean;
  showSelectedInfo?: boolean;
  fillWidth?: boolean;
  isLoading?: boolean;
  iconButton?: Icon;
  fullScreen?: boolean;
  disableInitialWidth?: boolean;
  disabled?: boolean;
  multiple?: boolean;
};

type Sort = {
  index: number;
  sort: string[];
};

const SearchDropdown = ({
  columns,
  items,
  titleInnerBox,
  placeholderText,
  buttonText,
  handleSubmit,
  onChange,
  wrapOptions,
  values,
  fillWidth,
  isLoading,
  showSelectedInfo,
  iconButton,
  fullScreen,
  disableInitialWidth = false,
  disabled = false,
  multiple = true,
}: SearchDropdownProps) => {
  const { t } = useTranslation();
  const screenHeight = use100vh();
  const [searchInputValue, setSearchInputValue] = useState<string | undefined>('');
  const [sortInfo, setSortInfo] = useState<Sort>({ index: 0, sort: [] });
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
  } = useMultipleSelection({
    initialSelectedItems: values || [],
  });

  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (!values) return;

    if (values.length !== selectedItems.length) {
      setSelectedItems(values);
    }
  }, [values]);

  // getFilteredItems must be a function
  const getFilteredItems = useCallback(() => {
    const itemsFiltered = items.filter(item => {
      if (searchInputValue && item.label) {
        return item.label.toLowerCase().includes(searchInputValue.toLowerCase());
      }

      if (searchInputValue && item.labels) {
        return item.labels.some(i => i.toLowerCase().includes(searchInputValue.toLowerCase()));
      }

      return [];
    });

    if (sortInfo.sort.length) {
      itemsFiltered.sort((a, b) => {
        const asort = a.labels?.[sortInfo.index] ?? '';
        const bsort = b.labels?.[sortInfo.index] ?? '';

        if (sortInfo.sort[sortInfo.index] !== 'asc') {
          return bsort.localeCompare(asort);
        } else {
          return asort.localeCompare(bsort);
        }
      });
    }

    return itemsFiltered;
  }, [searchInputValue, sortInfo, items]);

  const stateReducer = (
    state: UseComboboxState<SelectItem>,
    actionAndChanges: UseComboboxStateChangeOptions<SelectItem>,
  ): Partial<UseComboboxState<SelectItem>> => {
    const { changes, type } = actionAndChanges;

    switch (type) {
      case useCombobox.stateChangeTypes.ItemClick:
        return {
          ...changes,
          isOpen: true,
        };
      case useCombobox.stateChangeTypes.FunctionCloseMenu:
        return {
          ...changes,
          isOpen: false,
        };
      case useCombobox.stateChangeTypes.InputBlur:
        return {
          ...changes,
          isOpen: true,
        };
      case useCombobox.stateChangeTypes.InputKeyDownEscape:
        return {
          ...changes,
          isOpen: false,
        };

      default:
        return changes;
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const onStateChange = ({
    inputValue,
    type,
    selectedItem,
  }: UseComboboxStateChange<SelectItem>) => {
    const { InputChange, ItemClick } = useCombobox.stateChangeTypes;

    switch (type) {
      case InputChange:
        setSearchInputValue(inputValue);
        break;
      case ItemClick:
        inputRef.current?.blur();

        if (!multiple && selectedItem) {
          setSelectedItems([selectedItem]);
          return;
        }

        if (selectedItem) {
          if (selectedItems.find(i => i.value === selectedItem.value)) {
            setSelectedItems(selectedItems.filter(i => i.value !== selectedItem.value));
          } else {
            addSelectedItem(selectedItem);
          }
        }
        break;
      default:
        break;
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    closeMenu,
  } = useCombobox({
    inputValue: searchInputValue,
    defaultHighlightedIndex: 0,
    selectedItem: null,
    items: getFilteredItems(),
    stateReducer,
    onStateChange,
  });
  useEffect(() => {
    if (!onChange || isOpen) return;
    onChange(selectedItems);
  }, [selectedItems, isOpen]);

  const handleRemoveItem = (e: React.MouseEvent<HTMLElement>, selectedItem: SelectItem) => {
    e.stopPropagation();
    removeSelectedItem(selectedItem);
  };

  const handleSelectAll = useCallback(() => {
    if (!multiple) return;

    if (items.length === selectedItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(items);
    }
  }, [items, selectedItems, setSelectedItems, multiple]);

  const handleSortItems = useCallback(
    (column: number) => {
      const prevSortInfo = sortInfo.sort;
      prevSortInfo[column] = prevSortInfo[column] === 'asc' ? 'desc' : 'asc';
      setSortInfo({ index: column, sort: prevSortInfo });
    },
    [sortInfo],
  );

  const onSubmit = useCallback(() => {
    handleSubmit?.();
    closeMenu();
  }, []);

  const selectRef = useRef<HTMLDivElement>(null);

  const showDropdownOnTop = useMemo(() => {
    const { bottom = 0 } = selectRef.current?.getBoundingClientRect() ?? {};

    return window.innerHeight - bottom <= 300;
  }, [selectRef.current]);

  const preventOffScreenDisplay = useMemo(() => {
    if (!selectRef?.current) return;

    const listHalfWidth = 150;
    const leftEdgeDistance = selectRef.current?.getBoundingClientRect().x ?? 0;

    return leftEdgeDistance < listHalfWidth;
  }, [selectRef?.current]);

  const maxListHeight = useMemo(() => {
    const maxHeight = screenHeight ?? 0;
    const selectBottomPosition = selectRef.current?.getBoundingClientRect().bottom ?? 0;

    return Math.max(Math.abs(maxHeight - selectBottomPosition), 700);
  }, [selectRef?.current, screenHeight]);

  const showFullScreen = useMemo(() => fullScreen || isMobile, [fullScreen, isMobile]);

  return (
    <Wrapper>
      {iconButton ? (
        <DropdownIcon
          iconButton={iconButton}
          withTooltip
          tippyContent={t('vehicleFiltering')}
          disabledTooltip={isMobile}
          toggleButtonProps={getToggleButtonProps()}
        />
      ) : (
        <Select
          size="md"
          isLoading={isLoading}
          placeholder={placeholderText}
          wrapOptions={wrapOptions}
          fillWidth={fillWidth}
          disableInitialWidth={disableInitialWidth}
          {...getToggleButtonProps({ disabled })}
          selectedOptions={isOpen ? [] : selectedItems}
          ref={selectRef}
        />
      )}

      <MenuWrapper
        condition={showFullScreen}
        showOnTop={showDropdownOnTop}
        screenHeight={screenHeight}
        preventOffScreenDisplay={preventOffScreenDisplay}
        maxHeight={maxListHeight}
        selectWidth={selectRef.current?.clientWidth}
        closeCallback={() => closeMenu()}
        isOpen={isOpen}
      >
        <StyledObjectInfoContent.CloseIcon onClick={() => closeMenu()} />
        {titleInnerBox && <Title>{titleInnerBox}</Title>}
        <SearchInputWrapper {...getComboboxProps()}>
          <SearchInput
            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen, ref: inputRef }))}
            placeholder={t('search')}
          />
          <SearchIcon />
        </SearchInputWrapper>
        <List {...getMenuProps()} maxHeight={maxListHeight / 2}>
          <BadgesWrapper>
            {selectedItems.map((selectedItem: SelectItem, index: number) => (
              <Badge
                key={`selected-item-${index}`}
                {...getSelectedItemProps({ selectedItem, index })}
              >
                {selectedItem.labels?.[0] ?? selectedItem.label}
                <span onClick={e => handleRemoveItem(e, selectedItem)}>&#10005;</span>
              </Badge>
            ))}
          </BadgesWrapper>
          <SelectAll>
            <Checkbox
              checkboxSize="md"
              label={t('selectAll')}
              onClick={handleSelectAll}
              disabled={!multiple}
              checked={selectedItems.length === items.length}
            />
            {showSelectedInfo && (
              <SelectedInfo>
                {selectedItems.length} / {items.length} {t('selected')}
              </SelectedInfo>
            )}
          </SelectAll>
          {columns && (
            <ListItemHeader>
              <LabelIcon />
              {columns?.map((column, index) => (
                <ListItemHeaderLabel key={index} onClick={() => handleSortItems(index)}>
                  <MdUnfoldMore /> {column}
                </ListItemHeaderLabel>
              ))}
              <ListItemHeaderLabel />
            </ListItemHeader>
          )}
          {isLoading ? (
            <LoadingIndicator centerVertically />
          ) : !getFilteredItems().length ? (
            <ListItem>{t('noResults')}</ListItem>
          ) : (
            getFilteredItems().map((item, index) => (
              <ListItem key={`${item}${index}`} {...getItemProps({ item, index })}>
                {item.icon && <LabelIcon htmlFor={item.value}>{item.icon}</LabelIcon>}
                {!item.labels && <Label htmlFor={item.value}>{item.label}</Label>}
                {item.labels &&
                  item.labels?.map((column, index) => <label key={index}>{column}</label>)}
                <Checkbox checked={!!selectedItems.find(i => i.value === item.value)} />
              </ListItem>
            ))
          )}
        </List>
        <ButtonsWrapper>
          {showFullScreen && (
            <Button secondary onClick={() => closeMenu()}>
              {t('close')}
            </Button>
          )}
          <Button primary fillWidth={!fullScreen} onClick={onSubmit}>
            {buttonText || t('save')}
          </Button>
        </ButtonsWrapper>
      </MenuWrapper>
    </Wrapper>
  );
};

export default memo(SearchDropdown);

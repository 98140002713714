import { MdLocationOn } from 'react-icons/md';
import styled from 'styled-components/macro';

const StyledCoordinatesWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const StyledCoordinatesLocationMarker = styled(MdLocationOn)`
  height: 2rem;
  width: auto;
`;

const StyledCoordinatesCordsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const StyledCoordinatesRedirectLink = styled.a`
  all: unset;
`;

export {
  StyledCoordinatesCordsWrapper,
  StyledCoordinatesLocationMarker,
  StyledCoordinatesRedirectLink,
  StyledCoordinatesWrapper,
};

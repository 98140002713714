import styled, { keyframes } from 'styled-components';

const skeletonAnimation = ({ from, to }: { from: string; to: string }) => keyframes`
  0% {
    background-color: ${from};
  }
  100% {
    background-color: ${to};
  }`;

const Skeleton = styled.div<{ w?: number; h?: number }>`
  border-radius: 4px;
  width: 100%;
  max-width: ${p => p.w}px;
  height: ${p => p.h ?? 16}px;
  animation: ${p =>
      skeletonAnimation({
        from: p.theme.colors.secondaryBackground,
        to: p.theme.colors.borderGray,
      })}
    1.5s linear infinite alternate;
`;

export default Skeleton;

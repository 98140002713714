import styled from 'styled-components/macro';

interface WrapperProps {
  size: 'small' | 'big';
}

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: ${p => p.theme.layersOrder.modal};
  background-color: #00000094;
`;

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4rem 2.4rem;
  max-width: ${({ size }) => (size === 'small' ? '45rem' : '62rem')};
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.6rem;
  filter: drop-shadow(0px 4px 44px rgba(0, 0, 0, 0.25));
  overflow-y: auto;
  max-height: 80vh;
  z-index: ${p => p.theme.layersOrder.modal};
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 2rem;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  svg {
    display: block;
    fill: ${({ theme }) => theme.colors.gray400};
  }
`;

export const Content = styled.div<{
  noScrollX?: boolean;
  noScrollY?: boolean;
  isScrollBarYHidden?: boolean;
}>`
  max-height: 70vh;
  overflow-y: ${({ noScrollY }) => (noScrollY ? 'hidden' : 'auto')};
  overflow-x: ${({ noScrollX }) => (noScrollX ? 'hidden' : 'auto')};

  ::-webkit-scrollbar {
    width: ${({ isScrollBarYHidden }) => (isScrollBarYHidden ? '0' : '0.6rem')};
  }

  ::-webkit-scrollbar-track {
    border-radius: 4.8rem;
    background: ${({ theme }) => theme.colors.gray200};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4.8rem;
    background: ${({ theme }) => theme.colors.gray400};
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.primary500};
  color: ${({ theme }) => theme.colors.textLight};
  border-radius: 0.8rem;
  padding: 1.5rem 2.5rem;
  border: none;
  cursor: pointer;
`;

import styled from 'styled-components/macro';

import { List, ListItem, ListWrapper } from '../Dropdown/style';

export const StyledSubMenuListWrapper = styled(ListWrapper)`
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  padding: 1rem;
  margin-top: 1.3rem;
  left: 75%;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 999;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
    width: 0;
    height: 0;
  }
`;

export const StyledSubMenuList = styled(List)`
  padding-right: 0;
`;

export const StyledSubMenuMoreList = styled(StyledSubMenuList)`
  all: unset;
  display: grid;
  gap: ${p => p.theme.spacing.small};
`;

export const StyledSubMenuConfigureOrderWrapper = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  border-top: 1px solid ${p => p.theme.colors.gray300};
  padding: ${p => p.theme.spacing.large};
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledSubMenuListItem = styled(ListItem)`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
  }
`;

import { ReactComponent as CloseIcon } from 'assets/icons/close-circle.svg';
import { Urls } from 'common';
import Button from 'components/Button';
import { routes } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useWindowSize } from 'utils';
import { copyText } from 'utils/copyText';

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.xLarge}`};
  gap: ${({ isMobile, theme }) => (isMobile ? '12px' : theme.spacing.xLarge)};
  background: ${({ theme }) => theme.colors.info100};
  margin-left: ${({ theme }) => theme.spacing.xLarge};
`;

const LinkLabel = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 14px;
  line-height: 22px;
`;

const LinkWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;

  grid-gap: ${({ isMobile, theme }) => (isMobile ? theme.spacing.large : theme.spacing.xxLarge)};
  align-content: stretch;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      grid-template-columns: 75% 20%;
    `}
  ${({ isMobile }) =>
    isMobile &&
    css`
      grid-template-rows: 1fr 0.5fr;
    `}
`;

const StyledLink = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: ${({ theme }) => `${theme.spacing.xxSmall} ${theme.spacing.large}`};
  font-size: 12px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.info300};
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
  font-weight: 600;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  justify-self: end;
  width: 130px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const LinkLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GeneratedLinkBox = ({
  linkHash,
  closeModal,
}: {
  linkHash: string;
  closeModal?: () => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const fullUrl = Urls.sharingPreviewNewUrl(linkHash);

  const copyLink = () => copyText(fullUrl);

  return (
    <Container isMobile={isMobile}>
      <LinkLabelWrapper>
        <LinkLabel>{t('generatedLink')}</LinkLabel>
        {isMobile && <StyledCloseIcon onClick={closeModal} />}
      </LinkLabelWrapper>
      <LinkWrapper isMobile={isMobile}>
        <StyledLink onClick={() => window.open(routes.sharePreviewLink(linkHash))}>
          {fullUrl}
        </StyledLink>
        <StyledButton secondary onClick={copyLink} size="sm">
          {t('copyLink')}
        </StyledButton>
      </LinkWrapper>
    </Container>
  );
};

export default GeneratedLinkBox;

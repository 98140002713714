import Button from 'components/Button';
import { Form, Formik } from 'formik';
import i18n from 'locales/i18n';
import { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useWindowSize } from 'utils';
import * as Yup from 'yup';

import { createLogin } from '../queries';
import { ButtonWrapper, NewGroupWrapper, StyledInputFormField } from './GroupForm';

const LoginFormSchema = (t: TFunction) =>
  Yup.object().shape({
    user: Yup.string()
      .min(3, t('minCharacters', { number: 3 }))
      .max(30, t('maxCharacters', { number: 30 }))
      .required(t('required')),
    comment: Yup.string()
      .required(t('required'))
      .max(30, t('maxCharacters', { number: 30 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/, t('validation.validPassword')),
  });

interface LoginFormValuesProps {
  user: string;
  comment: string;
}

const initialValues: LoginFormValuesProps = {
  user: '',
  comment: '',
};

interface CreateLoginProps {
  handleSelectedLoginId: (id: number | null) => void;
  handleLoginForm: () => void;
}

const CreateLoginForm = ({ handleSelectedLoginId, handleLoginForm }: CreateLoginProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const [scheme, setScheme] = useState<Yup.AnyObjectSchema>(LoginFormSchema(t));

  const { mutate: createLoginMutation } = useMutation(createLogin());

  useEffect(() => {
    async function onLanguageChanged() {
      setScheme(LoginFormSchema(t));
    }

    i18n.on('languageChanged', onLanguageChanged);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [i18n, t, LoginFormSchema]);

  const handleSubmit = (data: LoginFormValuesProps) => {
    createLoginMutation(data);
    handleSelectedLoginId(null);
    handleLoginForm();
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={scheme}>
      {formikProps => (
        <Form>
          <NewGroupWrapper isMobile={isMobile}>
            <StyledInputFormField
              width={isMobile ? '100%' : '312px'}
              name={'user'}
              label={t('loginName')}
              placeholder={t('addName')}
              noLabel
              inputSize="md"
              type="text"
              autoComplete={'off'}
            />

            <StyledInputFormField
              width={isMobile ? '100%' : '312px'}
              name={'comment'}
              label={t('addPassword')}
              placeholder={t('addPassword')}
              noLabel
              inputSize="md"
              type="password"
              autoComplete={'new-password'}
            />
          </NewGroupWrapper>
          <ButtonWrapper isMobile={isMobile}>
            {!isMobile && (
              <Button secondary onClick={handleLoginForm}>
                {t('goBack')}
              </Button>
            )}
            <Button type={'submit'} primary fillWidth={isMobile} disabled={!formikProps.dirty}>
              {t('addLogin')}
            </Button>
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default CreateLoginForm;

import i18n from 'locales/i18n';

i18n.loadNamespaces('general');

export const minValue = (minValue: number, errorMessage?: string) => (value?: number) => {
  if ((value ?? 0) >= minValue) {
    return;
  }

  return errorMessage ?? i18n.t('validation.minValue', { value: minValue });
};

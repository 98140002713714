import { ObjectStatus } from 'components/common/ObjectStatus';
import { memo } from 'react';
import styled, { css } from 'styled-components/macro';

const StatusDot = styled.div<{ status: ObjectStatus }>`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;

  ${p =>
    p.status === ObjectStatus.MOVING &&
    css`
      background: ${p.theme.colors.objectStatusColor.moving};
    `}

  ${p =>
    p.status === ObjectStatus.NO_GPS &&
    css`
      background: ${p.theme.colors.objectStatusColor.noGps};
    `}

    ${p =>
    p.status === ObjectStatus.ENGINE_OFF &&
    css`
      background: ${p.theme.colors.objectStatusColor.engineOff};
    `}

    ${p =>
    p.status === ObjectStatus.PAUSE &&
    css`
      background: ${p.theme.colors.objectStatusColor.pause};
    `}

    ${p =>
    p.status &&
    css`
      background: ${p.status};
    `}
`;

export default memo(StatusDot);

export const LOGIN = 'LOGIN';
export const COMMUNIQUE = 'COMMUNIQUE';
export const OBJECTS = 'OBJECTS';
export const OBJECTS_ALL = 'OBJECTS_ALL';
export const OBJECTS_PREVIOUS = 'OBJECTS_PREVIOUS';
export const OBJECTS_GROUPS = 'OBJECTS_GROUPS';
export const OBJECTS_EXTENDED_DATA = 'OBJECTS_EXTENDED_DATA';
export const GEOCODING = 'GEOCODING';
export const VEHICLE_TYPES = 'VEHICLE_TYPES';
export const SIMPLE_ROUTE = 'SIMPLE_ROUTE';
export const ROUTE_DETAILS = 'ROUTE_DETAILS';
export const ROUTE_STATISTICS = 'ROUTE_STATISTICS';
export const DISTANCE = 'DISTANCE';
export const REFILLINGS = 'REFILLINGS';
export const DRIVERS = 'DRIVERS';
export const DRIVERS_ALL = 'DRIVERS_ALL';
export const REPORTS = 'REPORTS';
export const REPORT_STATUSES = 'REPORT_STATUSES';
export const VAT_US_DATA_STEP_ONE = 'VAT_US_DATA_STEP_ONE';
export const VAT_US_ROUTE_DRIVERS = 'VAT_US_ROUTE_DRIVERS';
export const VAT_US_ROUTE_GOALS = 'VAT_US_ROUTE_GOALS';
export const AUTOMATION_REPORTS = 'AUTOMATION_REPORTS';
export const VERIFY_PASSWORD_TOKEN = 'VERIFY_PASSWORD_TOKEN';
export const INTEGRATION_SAMSUNG = 'INTEGRATION_SAMSUNG';
export const STATUSES = 'STATUSES';
export const STATUSES_ALL = 'STATUSES_ALL';
export const MESSAGES = 'MESSAGES';
export const GROUPS = 'GROUPS';
export const LOGINS = 'LOGINS';
export const LINK_HASH = 'LINK_HASH';
export const UNREAD_STATISTICS = 'UNREAD_STATISTICS';
export const LAST_DRIVERS_STATUSES = 'LAST_DRIVERS_STATUSES';
export const DOCUMENTS_ALL = 'DOCUMENTS_ALL';
export const ROAD_CARD = 'ROAD_CARD';
export const MAIN_PUESC = 'MAIN_PUESC';
export const DOCUMENTS_PUESC = 'DOCUMENTS_PUESC';
export const NOTIFICATION_PUESC = 'NOTIFICATION_PUESC';
export const PUESC_HISTORY = 'PUESC_HISTORY';
export const IGNITION_HISTORY = 'IGNITION_HISTORY';
export const SHARE_LIST = 'SHARE_LIST';

import { MdInfo, MdNotifications } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';

export const Common = styled.div<{ justify?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  ${p =>
    p.justify &&
    css`
      justify-content: space-between;
    `}
  padding: 1.2rem 1.6rem;
`;

export const Img = styled.img<{ small?: boolean }>`
  max-width: 246px;
  height: 50px;
  width: 100%;
  min-width: 120px;

  ${p =>
    p.small &&
    css`
      max-width: 124px;
    `}
`;

export const MainTopbar = styled(Common)<{ isNotificationOpen?: boolean }>`
  align-items: center;
  position: absolute;
  top: ${({ isNotificationOpen }) => (isNotificationOpen ? '10.5rem' : '0')};
  width: 100%;
  z-index: ${p => p.theme.layersOrder.topbar + 2};
  height: 7.7rem;
  background: ${p => p.theme.colors.primaryBackground};
`;

export const BottomTopbar = styled(Common)<{ isNotificationOpen?: boolean }>`
  align-items: flex-start;
  position: absolute;
  top: ${({ isNotificationOpen }) => (isNotificationOpen ? '18.2rem' : '7.7rem')};
  width: 100%;
  z-index: ${p => p.theme.layersOrder.topbar + 1};
  height: 6.6rem;
  background: ${p => p.theme.colors.secondaryBackground};
  border-bottom: 1px solid ${p => p.theme.colors.gray200};
  border-top: 1px solid ${p => p.theme.colors.gray200};
`;

export const GroupActions = styled.div<{ right?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 1.5rem;
  ${p =>
    p.right &&
    css`
      margin-left: auto;
    `}
`;

export const IconsWrapper = styled.div`
  background-color: ${p => p.theme.colors.primaryBackground};
  display: grid;
  align-items: center;
  margin-right: 2rem;
  border-left: 1px solid ${p => p.theme.colors.gray200};
  padding-left: ${p => p.theme.spacing.regular};
  margin-left: ${p => p.theme.spacing.small};

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    padding-left: ${p => p.theme.spacing.small};
    margin-left: ${p => p.theme.spacing.xSmall};
    margin-right: 1rem;
  }
`;

export const StyledIcon = styled.div`
  font-size: 1.8rem;
  color: ${p => p.theme.colors.gray500};
  display: flex;
  transition: 0.2s ease;
  padding: ${p => p.theme.spacing.xSmall};
  cursor: pointer;
`;

export const NotificationsIcon = styled(MdNotifications)<{ disabled?: boolean }>`
  color: ${p => p.theme.colors.gray400};
  height: 2rem;
  width: auto;

  ${p =>
    p.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

export const MenuItems = styled.div`
  display: inline-flex;
  gap: 0.5rem;
`;

export const InfoIcon = styled(MdInfo)`
  min-height: 2rem;
  min-width: 2rem;
  color: ${p => p.theme.colors.textGray};
  cursor: pointer;
`;

export const MapType = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ChatItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.small};
`;

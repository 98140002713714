type paletteItemProps = {
  value: string;
};

export const colorPalette: paletteItemProps[] = [
  { value: '#000000' },
  { value: '#434343' },
  { value: '#666666' },
  { value: '#999999' },
  { value: '#B7B7B7' },
  { value: '#CCCCCC' },
  { value: '#D9D9D9' },
  { value: '#EFEFEF' },
  { value: '#F3F3F3' },
  { value: '#FFFFFF' },
  { value: '#94020C' },
  { value: '#F8061D' },
  { value: '#FB972E' },
  { value: '#FFFB45' },
  { value: '#49FB3D' },
  { value: '#47FFFE' },
  { value: '#4F8BE4' },
  { value: '#0033F9' },
  { value: '#9434F9' },
  { value: '#F835FA' },
  { value: '#E4B8B1' },
  { value: '#F2CCCD' },
  { value: '#FBE4CF' },
  { value: '#FFF1CF' },
  { value: '#DAE9D4' },
  { value: '#D1E0E3' },
  { value: '#CADBF6' },
  { value: '#D0E3F2' },
  { value: '#D9D3E8' },
  { value: '#E9D2DC' },
  { value: '#D97E6F' },
  { value: '#E6999B' },
  { value: '#F7CAA0' },
  { value: '#FEE3A0' },
  { value: '#B9D6AB' },
  { value: '#A5C4C9' },
  { value: '#A6C4F1' },
  { value: '#A2C6E6' },
  { value: '#B3A9D4' },
  { value: '#D3A7BD' },
  { value: '#822014' },
  { value: '#95020C' },
  { value: '#B05E1E' },
  { value: '#BD8E26' },
  { value: '#3F7427' },
  { value: '#1B505B' },
  { value: '#185DC7' },
  { value: '#155691' },
  { value: '#342472' },
  { value: '#711E47' },
  { value: '#590F05' },
  { value: '#630105' },
  { value: '#763E11' },
  { value: '#7E5F16' },
  { value: '#2B4D19' },
  { value: '#11343C' },
  { value: '#1F4984' },
  { value: '#0D3961' },
  { value: '#1F164B' },
  { value: '#4A1330' },
];

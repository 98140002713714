import React, { useEffect } from 'react';

/**
 * Send request about notifications permissions
 * @constructor
 */
const UseNotificationPermissions: React.FC = () => {
  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('Browser does not support desktop notification');
    } else {
      Notification.requestPermission().then();
    }
  }, []);

  return null;
};

export default UseNotificationPermissions;

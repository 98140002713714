import { AllowedModules } from 'common';
import { useCallback } from 'react';

import { useUserData } from './useUserData';
type Module = AllowedModules | AllowedModules[];
export const useCheckModuleAccess = () => {
  const { allowedModules } = useUserData();
  const checkModuleAccess = useCallback(
    (checkModule?: Module) => {
      if (typeof checkModule === 'string') {
        return allowedModules.includes(checkModule);
      }
      return (checkModule ?? []).every(m => allowedModules.includes(m));
    },
    [allowedModules],
  );
  return checkModuleAccess;
};

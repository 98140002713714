import Tabs from 'components/Tabs';
import { routes } from 'navigation/routes';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAssignment, MdHandyman, MdList } from 'react-icons/md';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useWindowSize } from 'utils';

export const Wrapper = styled.div`
  padding: 2rem;
  min-height: 100%;
  background: ${p => p.theme.colors.secondaryBackground};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    padding: 0;
  }
`;

export const TabsWrapper = styled.div`
  max-width: 50vw;
  margin: auto;
  margin-bottom: 3.2rem;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
`;

const Content = styled.div``;

enum ReportTabs {
  FORM = 'form',
  AUTOMATION = 'automation',
  LIST = 'list',
}

const tabRoutes = {
  [ReportTabs.FORM]: routes.reportsForm,
  [ReportTabs.AUTOMATION]: routes.reportsAutomation,
  [ReportTabs.LIST]: routes.reportsList,
};

const Reports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<ReportTabs>(ReportTabs.FORM);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (pathname === routes.reportsAutomation) {
      setActiveTab(ReportTabs.AUTOMATION);
    }

    if (pathname === routes.reportsList) {
      setActiveTab(ReportTabs.LIST);
    }

    if (pathname === routes.reports) {
      navigate(routes.reportsForm);
      setActiveTab(ReportTabs.FORM);
    }
  }, [pathname]);

  const tabs = useMemo(
    () => [
      { label: t('form'), value: ReportTabs.FORM, icon: !isMobile && <MdAssignment /> },
      { label: t('automation'), value: ReportTabs.AUTOMATION, icon: !isMobile && <MdHandyman /> },
      { label: t('list'), value: ReportTabs.LIST, icon: !isMobile && <MdList /> },
    ],
    [isMobile],
  );

  const handleTabClick = useCallback(
    (value: string) => {
      setActiveTab(value as ReportTabs);
      navigate(tabRoutes[value as ReportTabs]);
    },
    [pathname, navigate],
  );

  return (
    <Wrapper>
      <TabsWrapper>
        <Tabs
          options={tabs}
          activeTab={activeTab}
          onClick={handleTabClick}
          noBorderRadius={isMobile}
        />
      </TabsWrapper>
      <Content>
        <Outlet />
      </Content>
    </Wrapper>
  );
};

export default memo(Reports);

import Tippy from '@tippyjs/react';
import { ReactComponent as CloseIcon } from 'assets/icons/close-circle.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import { getObjectIcon, useMainLayout, useWindowSize } from 'utils';
import { copyText } from 'utils/copyText';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import { SummaryDataType, Title } from '../pages/Spedition';
import { createDriverAccount, deleteDriversLink } from '../queries';
import GenerateLinkForm, {
  BottomSection,
  PhoneFormData,
  PhoneInputWrapper,
  PhoneLabel,
  PhoneSection,
  StyledButton,
} from './GenerateLinkForm';
import { ItemName } from './SpeditionObjectsList';

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: ${({ isMobile }) => (isMobile ? '0' : '24px')};
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  gap: ${({ isMobile }) => (isMobile ? '0' : '3rem')};
  width: 100%;
`;

const SummaryBox = styled.div<{
  isEditable?: boolean;
  isMobile: boolean;
  showMobileModal?: boolean;
}>`
  background: ${({ theme }) => theme.colors.gray100};
  padding-bottom: ${({ isMobile }) => (isMobile ? '0' : '1rem')};
  border-radius: 4px 4px 0 0;

  ${({ isEditable, isMobile, theme }) =>
    isEditable &&
    theme &&
    !isMobile &&
    css`
      border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
    `}
`;

const TopSection = styled.section<{ isMobile: boolean }>`
  display: flex;
  gap: 1.3rem;
  align-items: center;
  padding: ${({ isMobile }) => (isMobile ? '12px 24px' : '2.4rem')};
  background: ${({ theme }) => theme.colors.secondaryBackgroundDark};
  border-radius: 4px 4px 0 0;
`;

const StyledItemName = styled(ItemName)`
  width: 100%;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;

const NumberContent = styled.div<{ withBorder?: boolean }>`
  color: ${({ theme }) => theme.colors.text};
  height: 3rem;
  border: none;
  margin-left: 0.5rem;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &:nth-of-type(1) {
    margin: 0 0.5rem 0 0;
    ${({ withBorder, theme }) =>
      withBorder &&
      theme &&
      css`
        border-right: 1px solid ${({ theme }) => theme.colors.gray400} !important;
      `}
  }
`;

const UrlContent = styled(NumberContent)<{ isMobile: boolean }>`
  color: ${({ theme }) => theme.colors.info300};
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '12px')};
`;

const StyledPhoneInputWrapper = styled(PhoneInputWrapper)`
  grid-template-columns: 9fr 0.5fr;
`;

const LinkSection = styled.div<{ isMobile?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '4fr 1fr')};
  grid-gap: ${({ isMobile }) => (isMobile ? '1.33rem' : '0.75rem')};
  width: 100%;
  align-items: center;
  justify-content: space-between;

  ${({ isMobile }) =>
    isMobile &&
    css`
      button {
        justify-self: end;
      }
    `}
`;

const StyledLinkIcon = styled(LinkIcon)<{ inactive?: boolean }>`
  margin-left: auto;
  opacity: ${p => (p.inactive ? '0.5' : '1')};
  filter: ${p => (p.inactive ? 'grayscale(100%)' : 'none')};
`;

const formatPhoneNo = (number: string) =>
  Number(number).toLocaleString('pl-PL', {
    useGrouping: true,
  });

const SpeditionSummary = ({
  selectedItems,
  resetSelectedItems,
  objectsWithActiveLink,
}: {
  selectedItems: SummaryDataType[];
  objectsWithActiveLink: SummaryDataType[];
  resetSelectedItems: () => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { showMobileModal, setShowMobileModal } = useMainLayout();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const { mutate, isLoading, isSuccess: isAccountSuccess } = useMutation(createDriverAccount());
  const { mutate: deleteLink, isLoading: isDeletingLoading } = useMutation(deleteDriversLink());

  const generateLink = (objectId: number, prefix: string, phone: string) =>
    selectedItems.length && mutate({ object: objectId, prefix: prefix, phone: phone });

  const copyLink = (url: string) => copyText(url);

  const handleSubmit = (objectId: number, values: PhoneFormData) => {
    if (!selectedItems.length) return;
    generateLink(objectId, values.prefix, values.phone);
  };

  const handleClose = () => {
    if (isMobile) {
      setShowMobileModal(false);
    }
    resetSelectedItems();
    setIsConfirmationModalOpen(false);
  };

  const handleDelete = (id: string) => {
    id && deleteLink({ accountId: id });
    handleClose();
  };

  useEffect(() => {
    if (isAccountSuccess) handleClose();
  }, [isAccountSuccess]);

  const objectListItems = selectedItems.length ? selectedItems : objectsWithActiveLink;

  return (
    <Container isMobile={isMobile}>
      {!isMobile && <Title>{t('summary')}</Title>}

      {/* <--- render elements ---> */}
      {objectListItems.map(
        ({ type, status, number, prefix, phone, url, hash, id, objectId, comment }) => {
          const isObjectBinded = !!hash;
          const isAccountCreated = !hash && !!url;

          return (
            <SummaryBox
              isEditable={!isObjectBinded}
              key={objectId}
              isMobile={isMobile}
              showMobileModal={showMobileModal}
            >
              <TopSection isMobile={isMobile}>
                <img src={getObjectIcon(type)} style={{ filter: getObjectFilterColor(status) }} />
                {!hash ? (
                  <>
                    <StyledItemName>
                      {number} {comment ? `(${comment})` : ''}
                    </StyledItemName>
                    {isAccountCreated && (
                      <Tippy content={t('accountCreated')} placement="top">
                        <StyledLinkIcon inactive />
                      </Tippy>
                    )}
                    <StyledCloseIcon onClick={handleClose} />
                  </>
                ) : (
                  <>
                    <ItemName>{number}</ItemName>
                    <Tippy content={t('accountShared')} placement="top">
                      <StyledLinkIcon inactive={!hash} />
                    </Tippy>
                  </>
                )}
              </TopSection>
              {!isObjectBinded ? (
                <GenerateLinkForm
                  objectId={objectId}
                  handleSubmit={handleSubmit}
                  isLoading={isLoading}
                />
              ) : (
                <BottomSection isMobile={isMobile}>
                  <PhoneSection>
                    <PhoneLabel>{t('phoneNumber')}</PhoneLabel>
                    <PhoneInputWrapper>
                      <NumberContent withBorder>{prefix}</NumberContent>
                      <NumberContent>{formatPhoneNo(phone ?? '')}</NumberContent>
                    </PhoneInputWrapper>
                  </PhoneSection>
                  <PhoneSection>
                    <PhoneLabel>{t('link')}</PhoneLabel>
                    <LinkSection isMobile={isMobile}>
                      <StyledPhoneInputWrapper>
                        <UrlContent isMobile={isMobile}>{url}</UrlContent>
                        <StyledTrashIcon onClick={() => setIsConfirmationModalOpen(true)} />
                      </StyledPhoneInputWrapper>
                      <StyledButton
                        secondary
                        size={isMobile ? 'sm' : 'md'}
                        onClick={() => copyLink(url ?? 'invalid link')}
                      >
                        {t('copyLink')}
                      </StyledButton>
                    </LinkSection>
                  </PhoneSection>
                  <ConfirmationModal
                    size="small"
                    isOpen={isConfirmationModalOpen}
                    title={t('confirmYourChoice')}
                    message={t('confirmDeleteLink')}
                    confirmText={t('delete')}
                    onConfirm={() => id && handleDelete(id?.toString())}
                    onClose={handleClose}
                    disabled={isDeletingLoading}
                  />
                </BottomSection>
              )}
            </SummaryBox>
          );
        },
      )}
    </Container>
  );
};

export default SpeditionSummary;

import { ComponentPropsWithoutRef, memo } from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  gap: 1rem;
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => theme.colors.white};
      border-radius: 8px;
    `};
`;

const StyledRadio = styled.input`
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  height: ${p => (p.height ? '1.4rem' : '2.4rem')};
  width: ${p => (p.height ? '1.4rem' : '2.4rem')};
  border: 1px solid ${p => p.theme.colors.onPrimaryBorder};
  border-radius: 50%;
  transform: translateY(-0.075em);
  cursor: pointer;

  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: ${p => (p.height ? '0.8rem' : '1.4rem')};
    height: ${p => (p.height ? '0.8rem' : '1.4rem')};
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background: ${p => p.theme.colors.primary500};
  }

  &:checked::before {
    transform: scale(1);
  }
`;

const Label = styled.div`
  color: ${p => p.theme.colors.text};
  font-size: 1.4rem;
`;

export type RadioProps = ComponentPropsWithoutRef<'input'> & {
  label?: string;
  height?: 'sm';
  isActive?: boolean;
};

// TODO: Handle onChange
const Radio = ({ label, height, isActive, ...props }: RadioProps) => (
  <Wrapper isActive={isActive}>
    <StyledRadio height={height} type="radio" onChange={() => {}} {...props} />
    <Label>{label}</Label>
  </Wrapper>
);

export default memo(Radio);

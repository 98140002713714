import Car from 'assets/car.svg';
import DeleteIcon from 'assets/icons/trash.svg';
import Pencil from 'assets/pencil.svg';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { useWindowSize } from 'utils';

export const ItemContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  justify-content: space-between;

  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.secondaryBackground : theme.colors.white};
  margin-right: 1rem;
  cursor: pointer;

  &:active,
  :hover {
    background: ${({ theme }) => theme.colors.secondaryBackground};
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const GroupColor = styled.div<{ color: string }>`
  width: 2rem;
  height: 2rem;
  background-color: ${({ color, theme }) => color || theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
`;

const GroupNameWrapper = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
`;

export const GroupName = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const GroupComment = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const GroupObjectsWrapper = styled.div`
  color: ${({ theme }) => theme.colors.gray400};
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const ObjectsNumber = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const Icon = styled.div<{ url: string }>`
  background-image: ${({ url }) => `url(${url})`};
  background-position: center;
  width: 24px;
  height: 24px;
`;

export const EditIcon = styled(Icon)`
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;

  &:hover {
    border: none;
  }
`;

export const DeleteGroupButton = styled.button`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:hover {
    border: none;
  }
`;

const NoComment = styled.div`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

interface GroupItemProps {
  groupId: number;
  label: string;
  comment: string;
  objectsNumber: number;
  color: string;
  onClickHandler: (id: number) => void;
  handleDeleteGroup?: () => void;
  handleGroupForm: () => void;
  handleGroupId: (ids: number) => void;
  isItemActive?: boolean;
  isSharingComponent?: boolean;
}

const GroupItem = ({
  groupId,
  label,
  comment,
  objectsNumber,
  color,
  onClickHandler,
  handleDeleteGroup,
  handleGroupForm,
  handleGroupId,
  isItemActive,
  isSharingComponent,
}: GroupItemProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const handleEdit = (id: number) => {
    handleGroupId(id);
    handleGroupForm();
  };

  return (
    <ItemContainer isActive={isItemActive} onClick={() => onClickHandler(groupId)}>
      <Section>
        <GroupColor color={color} />
        <GroupNameWrapper>
          <GroupName>{label}</GroupName>
          {comment ? (
            <GroupComment>
              {!isMobile && `${t('comment')}: `} {comment}
            </GroupComment>
          ) : (
            <NoComment>{t('noComment')}</NoComment>
          )}
        </GroupNameWrapper>
      </Section>
      <Section>
        <GroupObjectsWrapper>
          {!isSharingComponent && (
            <>
              <ObjectsNumber>{objectsNumber}</ObjectsNumber>
              <Icon url={Car} />
            </>
          )}
        </GroupObjectsWrapper>
        {!isMobile && (
          <>
            <EditIcon url={Pencil} onClick={() => handleEdit(groupId)} />
            <DeleteGroupButton onClick={handleDeleteGroup}>
              <SVG
                src={DeleteIcon}
                width={16}
                height={16}
                preProcessor={code => code.replace(/fill=".*?"/g, 'fill="#252E3B"')}
              />
            </DeleteGroupButton>
          </>
        )}
      </Section>
    </ItemContainer>
  );
};

export default GroupItem;

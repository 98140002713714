import 'pure-react-carousel/dist/react-carousel.es.css';

import ArrowRight from 'assets/arrow-right.svg';
import CloseCircle from 'assets/icons/close-circle.svg';
import { LocalStorageKeys } from 'common';
import { MessageDto, NotificationType } from 'modules/auth/dtos/Messages.dto';
import { ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { MdInfoOutline } from 'react-icons/md';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { useWindowSize } from 'utils';

export const getNotificationColor = (notification: NotificationType) => {
  switch (notification) {
    case 'warning':
      return '#F19202';
    case 'info':
      return '#2A7FEF';
    case 'danger':
      return '#F6544C';
    case 'success':
      return '#25B060';
    default:
      return '';
  }
};

const StyledCarouselProvider = styled(CarouselProvider)<{ isOpen: boolean }>`
  &.animation {
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};

    transition: opacity 0.5s linear;
  }
`;

const MaintenanceContainer = styled.div<{
  bgColor: string;
  isSingleItem?: boolean;
}>`
  position: relative;
  width: 100%;
  top: 0;
  padding: 1.5rem;
  background: ${({ bgColor }) => bgColor};
  display: grid;
  grid-template-columns: ${({ isSingleItem }) =>
    isSingleItem ? '3fr 0.5fr' : '3fr 0.2fr 0.5fr 0.2fr'};
  align-items: center;
  justify-content: center;
  height: 10.5rem;

  svg {
    justify-self: end;
  }
`;

const IconWrapper = styled.div`
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageText = styled.div<{ isMobile: boolean }>`
  color: ${({ theme }) => theme.colors.gray700};
  font-size: ${({ isMobile }) => (isMobile ? '10px' : '14px')};
  font-weight: 600;
  line-height: ${({ isMobile }) => (isMobile ? '12px' : '22px')};

  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: end;
  cursor: pointer;
`;

const buttonStyles = `
  background: transparent;
  border: none;
  width: auto;

  svg {
    fill: #fff;

    path {
      fill: #fff;
    }
  }
`;

const StyledButtonNext = styled(ButtonNext)`
  ${buttonStyles};
`;

interface MaintenanceNotificationProps {
  handleClose: () => void;
  messages?: MessageDto[];
  isOpen: boolean;
}
const MaintenanceNotification = ({
  handleClose,
  messages,
  isOpen,
}: MaintenanceNotificationProps) => {
  const { isMobile } = useWindowSize();

  const handleLocalstorage = (id: number) => {
    if (!messages || !messages[0].id) return;
    const newIds = [];
    const storedIdsToParse = localStorage.getItem(LocalStorageKeys.SEEN_MESSAGE_IDS);
    const storedIdsParsed = storedIdsToParse ? JSON.parse(storedIdsToParse) : [];
    newIds.push(...storedIdsParsed);

    localStorage.setItem(LocalStorageKeys.SEEN_MESSAGE_IDS, JSON.stringify([...newIds, id]));
  };

  const closeOnClick = (id: number) => {
    handleLocalstorage(id);
    handleClose();
  };

  if (messages?.length === 1) {
    return (
      <MaintenanceContainer bgColor={getNotificationColor(messages[0].color)} isSingleItem>
        <MessageText isMobile={isMobile}>
          <IconWrapper>
            <MdInfoOutline color={getNotificationColor(messages[0].color)} width={24} height={24} />{' '}
          </IconWrapper>
          {messages[0].message}
        </MessageText>
        <CloseIconWrapper>
          <SVG
            onClick={() => closeOnClick(messages[0].id)}
            src={CloseCircle}
            width={24}
            preProcessor={code => code.replace(/fill=".*?"/g, `fill="#fff"`)}
          />
        </CloseIconWrapper>
      </MaintenanceContainer>
    );
  }

  return (
    <StyledCarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={8}
      totalSlides={messages?.length ?? 0}
      isIntrinsicHeight
      isOpen={isOpen}
    >
      <Slider classNameAnimation="animation">
        {messages?.map((message, i) => (
          <Slide index={i} key={message.id}>
            <MaintenanceContainer bgColor={getNotificationColor(message.color)}>
              <MessageText isMobile={isMobile}>
                <IconWrapper>
                  <MdInfoOutline
                    color={getNotificationColor(message.color)}
                    width={24}
                    height={24}
                  />{' '}
                </IconWrapper>
                {message.message}
              </MessageText>
              <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>{`${
                i + 1
              }/${messages.length}`}</p>

              {i !== messages.length - 1 && (
                <StyledButtonNext onClick={() => handleLocalstorage(message.id)}>
                  <SVG src={ArrowRight} />
                </StyledButtonNext>
              )}
              <CloseIconWrapper>
                <SVG
                  onClick={() => closeOnClick(message.id)}
                  src={CloseCircle}
                  width={24}
                  preProcessor={code => code.replace(/fill=".*?"/g, `fill="#fff"`)}
                />
              </CloseIconWrapper>
            </MaintenanceContainer>
          </Slide>
        ))}
      </Slider>
    </StyledCarouselProvider>
  );
};

export default MaintenanceNotification;

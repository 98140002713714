import Button from 'components/Button';
import MobileModal from 'components/MobileModal/MobileModal';
import { t } from 'i18next';
import styled from 'styled-components';
import { useMainLayout } from 'utils';

import { statusElementType } from '../dtos';
import StatusList from './StatusList';

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)<{ isEditable?: boolean }>`
  background: ${({ isEditable, theme }) =>
    isEditable ? theme.colors.primary500 : theme.colors.white}!important;
  color: ${({ isEditable, theme }) => (isEditable ? theme.colors.white : theme.colors.text)};
`;

interface DriverStatusTogglerProps {
  handleToggle: (val: string | number) => void;
  isStatusFetching?: boolean;
  statusList: statusElementType[];
  handleConfirm: () => void;
  isEditable: boolean;
  handleStatusItemClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const DriverStatusToggler = ({
  handleToggle,
  handleConfirm,
  isStatusFetching,
  statusList,
  handleStatusItemClick,
  isEditable,
}: DriverStatusTogglerProps) => {
  const { setShowMobileModal } = useMainLayout();

  return (
    <MobileModal>
      <StatusList
        isStatusFetching={isStatusFetching}
        statusList={statusList}
        isEditable={isEditable}
        handleStatusItemClick={handleStatusItemClick}
        handleToggle={handleToggle}
      />
      <ButtonsWrapper>
        <StyledButton secondary onClick={() => setShowMobileModal(false)} fillWidth>
          {t('goBack')}
        </StyledButton>
        <StyledButton
          secondary
          disabled={!isEditable}
          fillWidth
          onClick={handleConfirm}
          isEditable={isEditable}
        >
          {t('confirmUpdate')}
        </StyledButton>
      </ButtonsWrapper>
    </MobileModal>
  );
};

export default DriverStatusToggler;

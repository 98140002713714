import CommonForm, { CommonFormData } from 'modules/reports/components/CommonForm';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { createIntegrationObject } from '../queries';

const SamsungIntegrationForm = () => {
  const { t } = useTranslation();
  const { mutate } = useMutation(createIntegrationObject());

  const handleSubmit = (data: CommonFormData) => {
    if (!data) return;

    const payload = {
      object: data.vehicles as number[],
    };

    mutate({ payload });
  };

  return (
    <CommonForm
      noAutomation
      noInterval
      buttonText={`${t('save')}`}
      vehiclesSelect
      onSubmit={handleSubmit}
      header={`${t('samsung')} - integracja`}
    />
  );
};

export default SamsungIntegrationForm;

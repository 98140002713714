import { ReactComponent as ToggleIcon } from 'assets/icons/toggle.svg';
import Map, { MarkerProps } from 'components/Map';
import ObjectInfo from 'components/ObjectInfo';
import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import styled, { css } from 'styled-components';

import { IsTypingResponse, MessagesData, ReceiversData } from '../queries/socket';
import ChatList from './ChatList';
import CurrentChat from './CurrentChat';

const ChatContainer = styled.div`
  background: transparent;
  box-shadow: ${({ theme }) => theme.shadows.m};
  position: relative;
  height: 100%;
`;

const ChatWrapper = styled.div`
  display: flex;
  width: 55vw;
  z-index: 9999;
  position absolute;
  height: 100%;
  background: ${p => p.theme.colors.white};
`;

export const ToggleButton = styled.button<{ isChatOpen: boolean }>`
  border: none;
  outline: none;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 52px;
  position: absolute;
  top: 0;
  z-index: 9999;

  ${({ isChatOpen }) =>
    isChatOpen
      ? css`
          transform: rotate(180deg);
          left: 100%;
          background-color: ${p => p.theme.colors.white};
          border-radius: 0.5rem 0 0 0;
        `
      : css`
          box-shadow: ${({ theme }) => theme.shadows.m};
          background-color: ${p => p.theme.colors.secondaryBackgroundDark};
          border-radius: 0 0 0.5rem 0;
        `};
`;

interface SpeditionchatDesktopProps {
  searchInputValue?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChatOpen: boolean;
  messagesReceived: ReceiversData[];
  handleMessageClicked: (data: ReceiversData) => void;
  messageClicked: ReceiversData;
  objectsData?: Object[];
  driversName: string;
  handleChat: () => void;
  currentChat: MessagesData[];
  handleCurrentChat: (messages: MessagesData[]) => void;
  handleDriversName: (id: number, name: string) => void;
  handleMessagesReceived: (response: ReceiversData[]) => void;
  markers?: MarkerProps[];
  isTypingResponse?: IsTypingResponse;
}

const SpeditionChatDesktop = ({
  searchInputValue,
  handleInputChange,
  isChatOpen,
  messagesReceived,
  handleMessageClicked,
  messageClicked,
  objectsData,
  driversName,
  handleChat,
  currentChat,
  handleCurrentChat,
  handleDriversName,
  handleMessagesReceived,
  markers,
  isTypingResponse,
}: SpeditionchatDesktopProps) => (
  <ChatContainer>
    {isChatOpen && (
      <ChatWrapper>
        <ChatList
          searchInputValue={searchInputValue}
          handleInputChange={handleInputChange}
          messagesReceived={messagesReceived}
          handleMessageClicked={handleMessageClicked}
          messageClickedId={messageClicked?.Account.id}
          objectsData={objectsData}
          isTypingResponse={isTypingResponse}
        />
        <CurrentChat
          message={messageClicked}
          driversName={driversName}
          handleChat={handleChat}
          currentChat={currentChat}
          handleCurrentChat={handleCurrentChat}
          messageClickedId={messageClicked?.Account.id}
          objectData={objectsData?.find(
            obj => obj.objectId === messageClicked?.Account.object.objectId,
          )}
          handleDriversName={handleDriversName}
          handleMessagesReceived={handleMessagesReceived}
        />
      </ChatWrapper>
    )}
    {!isChatOpen && (
      <ToggleButton type="button" onClick={handleChat} isChatOpen={isChatOpen}>
        <ToggleIcon />
      </ToggleButton>
    )}
    {markers?.length && (
      <Map markers={(markers as MarkerProps[]) || []}>
        {markers.length &&
          markers.map(marker => (
            <ObjectInfo
              key={marker?.objectId}
              objectId={marker?.objectId ?? 0}
              location={marker?.position}
            />
          ))}
      </Map>
    )}
  </ChatContainer>
);

export default SpeditionChatDesktop;

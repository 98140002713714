import { Status } from 'components/common';
import html2canvas from 'html2canvas';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'toast';

export enum ImageExtensions {
  PNG = 'png',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PDF = 'pdf',
}

export const useSaveAsImage = () => {
  const printRef = useRef<HTMLDivElement>(null);
  const toast = useToast();
  const { t } = useTranslation();

  const saveImage = useCallback(async (fileName: string, extension: ImageExtensions) => {
    const element = printRef.current;
    if (!element) return;

    const canvas = await html2canvas(element, { useCORS: true, allowTaint: false });

    const data = canvas.toDataURL(`image/${extension}`);
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = `${fileName}.${extension}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.showToast({ content: t('saved'), type: Status.Positive });
    } else {
      window.open(data);
    }
  }, []);

  return { saveImage, printRef };
};

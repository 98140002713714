import { Details, getSubMenuItemData } from 'common/menuItems';
import { AppModuleDto } from 'modules/auth/dtos/UpdateMenuResponse.dto';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

type SingleMenuItemProps = {
  name: string;
  details: Details;
  closeMenu: () => void;
  disabled?: boolean;
  subModules?: AppModuleDto[];
};

const IconWrapper = styled.div`
  svg {
    height: 2rem;
    width: auto;
  }

  margin-right: 1.5rem;
`;

const ArrowIconWrapper = styled.div<{ isExpanded: boolean }>`
  svg {
    color: ${p => p.theme.colors.primary600};
    height: 2rem;
    width: auto;
  }

  margin-left: 1rem;

  ${p =>
    p.isExpanded &&
    css`
      transform: rotate(180deg);
    `};
`;

const MenuItemWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  width: 100%;
  padding: 1.5rem 4rem;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  color: ${p => p.theme.colors.text};

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const ExpandedMenuWrapper = styled.div`
  padding-left: 7.5rem;
  color: ${p => p.theme.colors.text};
  font-size: 1.6rem;
  font-weight: 600;
  position: relative;

  div {
    padding: 1rem 0;
    cursor: pointer;
  }
`;

const LeftLine = styled.div`
  width: 0.2rem;
  height: 100%;
  background: ${p => p.theme.colors.primary600};
  position: absolute;
  left: 4.8rem;
`;

const DropdownOption = styled.div`
  margin-bottom: 2rem;
`;

const SingleMenuItem = ({
  name,
  details,
  closeMenu,
  disabled = false,
  subModules,
}: SingleMenuItemProps) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleItemClick = useCallback(() => {
    if (disabled) return;

    if (details.isDropdown) {
      const isExpanded = expandedItems.find(e => e === name);
      setExpandedItems(
        isExpanded ? expandedItems.filter(e => e !== name) : [...expandedItems, name],
      );
    } else {
      // TODO: remove OR when all routes will be added
      navigate(details.route || '/');
      closeMenu();
    }
  }, [disabled, details, expandedItems, closeMenu, navigate]);

  const isExpanded = expandedItems.includes(name);

  const handleOptionClick = (route: string) => {
    navigate(route);
    closeMenu();
  };

  return (
    <>
      <MenuItemWrapper onClick={handleItemClick} disabled={disabled}>
        <IconWrapper>{details.icon}</IconWrapper> {t(details.label)}
        {details.isDropdown && (
          <ArrowIconWrapper isExpanded={isExpanded}>
            <MdKeyboardArrowDown />
          </ArrowIconWrapper>
        )}
      </MenuItemWrapper>

      {isExpanded && details.options && (
        <ExpandedMenuWrapper>
          <LeftLine />

          {subModules?.map(subModule => {
            const subModuleData = getSubMenuItemData(name, subModule.id);
            if (!subModuleData) return null;

            return (
              <DropdownOption
                key={subModuleData.label}
                onClick={() => handleOptionClick(subModuleData.route)}
              >
                {t(subModuleData.label)}
              </DropdownOption>
            );
          })}
        </ExpandedMenuWrapper>
      )}
    </>
  );
};

export default memo(SingleMenuItem);

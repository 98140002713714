import { axios } from 'lib/axios';

import {
  AuthDriverDto,
  CreateDriverAccountDto,
  DeleteLinkDto,
  DocumentContentDto,
  LastDriversStatusResponse,
  StatusParamsDto,
  StatusResponseDto,
  UnreadStatisticsResponseDto,
  UpdateStatusDto,
} from '../dtos';
import { endpoints } from '.';

export const authDriver = (payload: AuthDriverDto) =>
  axios.post(endpoints.authDriver, payload).then(res => res.data.data);

export const getStatusList = () =>
  axios
    .get<StatusResponseDto>(endpoints.statuses)
    .then(res => res.data)
    .catch(e => e.response.data);

export const updateStatus = (body: UpdateStatusDto) =>
  axios
    .patch(endpoints.statuses, body)
    .then(res => res.data)
    .catch(e => e.response.data);

export const getDriversAccounts = (params?: StatusParamsDto) =>
  axios.get(endpoints.account, params ? { params: params } : {}).then(res => res.data.data);

export const createDriverAccount = (payload: CreateDriverAccountDto) =>
  axios.post(endpoints.account, payload).then(res => res.data.data);

export const deleteDriversLink = (payload: DeleteLinkDto) =>
  axios.delete(endpoints.deleteDriversLink(payload.accountId)).then(res => res.data);

export const getUnreadStatistics = () =>
  axios
    .get<UnreadStatisticsResponseDto>(endpoints.unreadStatistics)
    .then(res => res.data)
    .catch(e => e.response.data);

export const getLastDriversStatus = () =>
  axios
    .get<LastDriversStatusResponse>(endpoints.statusUpdates)
    .then(res => res.data)
    .catch(e => e.response.data);

/** documents API */

export const getDocumentsList = () =>
  axios
    .get<StatusResponseDto>(endpoints.documentsList)
    .then(res => res.data)
    .catch(e => e.response.data);

export const addDocument = (payload: DocumentContentDto) =>
  axios.post(endpoints.addDocument, payload).then(res => res.data);

export const deleteDocument = (id: number) =>
  axios.delete(endpoints.deleteDocument(id)).then(res => res.data);

import { memo, ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import { useAppContext } from 'utils/appContext';

const StyledHeader = styled.h3<{
  noMargin?: boolean;
  darkMode?: boolean;
  smallFont?: boolean;
  smallMarginBottom?: boolean;
  marginBottomOnMobile?: boolean;
}>`
  font-size: ${p => (p.smallFont ? '1.8rem' : '2.4rem')};
  line-height: 3.6rem;
  color: ${p => (p.darkMode ? p.theme.colors.white : p.theme.colors.gray600)};
  margin-bottom: ${p => (p.smallMarginBottom ? '2rem' : '3rem')};
  ${p =>
    p.noMargin &&
    css`
      margin: 0;
    `}

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    margin-bottom: ${p => (p.marginBottomOnMobile ? '1rem' : 0)};
  }
`;

type HeaderProps = {
  children: ReactNode;
  noMargin?: boolean;
  smallFont?: boolean;
  smallMarginBottom?: boolean;
  marginBottomOnMobile?: boolean;
};

const Header = ({
  children,
  noMargin,
  smallFont,
  smallMarginBottom,
  marginBottomOnMobile,
}: HeaderProps) => {
  const { darkMode } = useAppContext();

  return (
    <StyledHeader
      darkMode={darkMode}
      noMargin={noMargin}
      smallFont={smallFont}
      smallMarginBottom={smallMarginBottom}
      marginBottomOnMobile={marginBottomOnMobile}
    >
      {children}
    </StyledHeader>
  );
};

export default memo(Header);

import { SIMPLE_ROUTE } from 'common';
import Checkbox from 'components/Checkbox';
import IconButton from 'components/IconButton';
import dayjs from 'dayjs';
import { getAllObjects } from 'modules/objects/queries';
import { RouteContext } from 'modules/route/contexts';
import { SimpleRouteRequestParamsDto } from 'modules/route/dtos';
import { memo, useCallback, useContext, useMemo } from 'react';
import { MdZoomIn } from 'react-icons/md';
import { useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components/macro';
import { getObjectIcon, useWindowSize } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.text};
  margin-bottom: 1rem;
`;

const Row = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: max-content auto max-content max-content;
  align-items: center;
  gap: 1rem;
  border-radius: 0.6rem;
  padding: 1.2rem 1.6rem;
  background: ${p => p.theme.colors.primaryBackground};
  border: 1px solid ${p => p.theme.colors.separator};

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    grid-template-columns: max-content auto max-content;
    gap: 0.5rem;
    align-items: center;
  }
`;

const Name = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

const DateTime = styled.div`
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${p => p.theme.colors.textGray};

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    grid-area: 2 / 2 / 3 / 3;
    font-size: 1.2rem;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    grid-area: 1 / 3 / 3 / 4;
    gap: 0.5rem;
  }
`;

const Icon = styled.img`
  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    grid-area: 1 / 1 / 3 / 2;
  }
`;

const ListItem = ({ params }: { params: SimpleRouteRequestParamsDto & { objectId: string } }) => {
  const { data: objectsData } = useQuery(getAllObjects());
  const {
    setObjectDetailsId,
    checkedVehicles,
    setCheckedVehicles,
    setSelectedItems,
    selectedItems,
    setZoomObjectId,
    setSelectedObjectData,
  } = useContext(RouteContext);
  const queryClient = useQueryClient();
  const { isMobile } = useWindowSize();

  const vehicleData = useMemo(
    () => objectsData?.data.find(i => i.objectId === +params.objectId),
    [objectsData],
  );

  const uncheckObject = useCallback(
    (objectId: string) => setCheckedVehicles(checkedVehicles.filter(i => i !== objectId)),
    [checkedVehicles],
  );

  const handleRemove = useCallback(() => {
    const { objectId } = params;

    queryClient.removeQueries([SIMPLE_ROUTE, { objectId }]);
    uncheckObject(objectId);
    setSelectedItems(selectedItems.filter(i => i.params.objectId !== objectId));
  }, [selectedItems]);

  const handleDetails = useCallback(() => {
    setObjectDetailsId(+params.objectId);
    setZoomObjectId(0);
    setCheckedVehicles([]);
    if (vehicleData) setSelectedObjectData(vehicleData);
  }, []);

  const handleCheckbox = useCallback(() => {
    const { objectId } = params;

    if (checkedVehicles.includes(objectId)) {
      uncheckObject(objectId);
    } else {
      setCheckedVehicles([...checkedVehicles, objectId]);
    }
  }, [checkedVehicles]);

  const handleZoom = useCallback(() => {
    if (!checkedVehicles.includes(params.objectId)) {
      setCheckedVehicles([...checkedVehicles, params.objectId]);
    }

    setZoomObjectId(+params.objectId);
  }, [params, checkedVehicles]);

  const startDate = useMemo(() => dayjs(params.start).format('DD.MM.YYYY HH:mm'), [params]);
  const endDate = useMemo(() => dayjs(params.end).format('DD.MM.YYYY HH:mm'), [params]);

  return (
    <Wrapper>
      <Checkbox
        onClick={handleCheckbox}
        checked={checkedVehicles.includes(params.objectId)}
        checkboxSize={isMobile ? 'md' : undefined}
      />
      <Row>
        <Icon
          src={getObjectIcon(vehicleData?.type)}
          style={vehicleData?.status && { filter: getObjectFilterColor(vehicleData.status) }}
        />
        <Name>{`${vehicleData?.number} (${vehicleData?.comment})`}</Name>
        <DateTime>
          {isMobile ? (
            <>
              <div>{startDate}</div>
              <div>{endDate}</div>
            </>
          ) : (
            `${startDate} > ${endDate}`
          )}
        </DateTime>
        <Buttons>
          <IconButton icon="close" onClick={handleRemove} />
          {!isMobile && <IconButton icon={<MdZoomIn />} onClick={handleZoom} />}
          <IconButton icon="car" onClick={handleDetails} />
        </Buttons>
      </Row>
    </Wrapper>
  );
};
export default memo(ListItem);

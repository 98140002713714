import { GROUPS, HttpError, LOGINS } from 'common';
import { Status } from 'components/common/Status';
import queryClient from 'lib/react-query';
import { requests } from 'modules/admin/api';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useToast } from 'toast';

import {
  AddGroupsToLoginDto,
  AddObjectsToGroupDto,
  CreateGroupDto,
  CreateLoginDto,
  DeleteGroupDto,
  DeleteLoginDto,
  GroupsResponseDto,
  LoginsResponseDto,
  UpdateLoginDto,
} from '../dtos';

export const getGroupsList = (
  config: UseQueryOptions<GroupsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [GROUPS],
  queryFn: () => requests.getGroupsList(),
  ...config,
});

export const createGroup = (
  config: UseMutationOptions<GroupsResponseDto, HttpError<unknown>, CreateGroupDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.createGroup,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([GROUPS]);
      showToast({ content: t('createGroupSuccess') });
    },
    onError: () => showToast({ content: t('createGroupFailure'), type: Status.Negative }),
  };
};

export const addObjectsToGroup = (
  config: UseMutationOptions<unknown, HttpError<unknown>, AddObjectsToGroupDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.addObjectsToGroup,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([GROUPS]);
      showToast({ content: t('addObjectsToGroupSuccess') });
    },
    onError: () => showToast({ content: t('addObjectsToGroupFailure'), type: Status.Negative }),
  };
};

export const deleteGroup = (
  config: UseMutationOptions<unknown, HttpError<unknown>, DeleteGroupDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.deleteGroup,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([GROUPS]);
      showToast({ content: t('deleteGroupSuccess') });
    },
    onError: () => showToast({ content: t('deleteGroupFailure'), type: Status.Negative }),
  };
};

export const getLoginsList = (
  config: UseQueryOptions<LoginsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [LOGINS],
  queryFn: () => requests.getLoginsList(),
  ...config,
});

export const createLogin = (
  config: UseMutationOptions<GroupsResponseDto, HttpError<unknown>, CreateLoginDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.createLogin,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([LOGINS]);
      showToast({ content: t('createLoginSuccess') });
    },
    onError: () => showToast({ content: t('createLoginFailure'), type: Status.Negative }),
  };
};

export const deleteLogin = (
  config: UseMutationOptions<unknown, HttpError<unknown>, DeleteLoginDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.deleteLogin,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([LOGINS]);
      showToast({ content: t('deleteLoginSuccess') });
    },
    onError: () => showToast({ content: t('deleteLoginFailure'), type: Status.Negative }),
  };
};

export const addGroupsToLogin = (
  config: UseMutationOptions<unknown, HttpError<unknown>, AddGroupsToLoginDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.addGroupsToLogin,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([LOGINS]);
      showToast({ content: t('addGroupsToLoginSuccess') });
    },
    onError: () => showToast({ content: t('addGroupsToLoginFailure'), type: Status.Negative }),
  };
};

export const updateLogin = (
  config: UseMutationOptions<GroupsResponseDto, HttpError<unknown>, UpdateLoginDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.updateLogin,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([LOGINS]);
      showToast({ content: t('updateLoginSuccess') });
    },
    onError: () => showToast({ content: t('updateLoginFailure'), type: Status.Negative }),
  };
};

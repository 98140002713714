import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import IconButton from 'components/IconButton';
import PlaceHolder from 'modules/driver/components/PlaceHolder';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { Title } from 'modules/driver/pages/Spedition';
import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import SharingBox from 'modules/sharing/components/SharingBox';
import styled from 'styled-components';

import { GroupItem as GroupItemProps } from '../dtos';
import AddGroupItem from './AddGroupItem';
import LoginGroupList from './LoginGroupList';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
  margin: 8px 0;
`;

export const StyledTitle = styled(Title)`
  margin-right: auto;
`;

export const AddButton = styled(IconButton)`
  display: flex;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  background: ${({ theme }) => theme.colors.white};
`;

export const GroupListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 44rem;
  overflow-y: scroll;
`;

interface ListProps {
  title: string;
  searchInputValue: string;
  inputPlaceholder: string;
  imagePlaceholderText: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  selectedLoginId?: number | null;
  selectedGroupId?: number;
  handleSelectedGroupId?: (groupId?: number) => void;
  handleSelectedLoginId?: (id: number | null) => void;
  handleForm?: () => void;
  addButtonText?: string;
  groups?: GroupItemProps[];
  selectedItems?: Object[];
  resetSelectedItems?: () => void;
  isSharingBoxOpen?: boolean;
}
const List = ({
  title,
  searchInputValue,
  inputPlaceholder,
  handleInputChange,
  handleSelectedLoginId,
  children,
  selectedLoginId,
  handleForm,
  addButtonText,
  groups,
  imagePlaceholderText,
  selectedGroupId,
  handleSelectedGroupId,
  selectedItems,
  resetSelectedItems,
  isSharingBoxOpen,
}: ListProps) => (
  <Container>
    <Wrapper>
      <TopWrapper>
        <StyledTitle>{title}</StyledTitle>
        <SpeditionSearchInput
          searchInputValue={searchInputValue}
          handleInputChange={e => handleInputChange?.(e)}
          placeholder={inputPlaceholder}
        />
        {addButtonText && <AddButton icon={<AddIcon />} onClick={handleForm} />}
      </TopWrapper>
      {children}
      {addButtonText && (
        <AddGroupItem clickHandler={handleForm} addButtonText={addButtonText ?? ''} />
      )}
    </Wrapper>
    {selectedLoginId ? (
      <LoginGroupList
        groups={groups}
        selectedLoginId={selectedLoginId}
        handleSelectedLoginId={handleSelectedLoginId}
      />
    ) : isSharingBoxOpen ? (
      <SharingBox
        selectedGroupId={selectedGroupId}
        handleSelectedGroupId={handleSelectedGroupId}
        selectedItems={selectedItems}
        resetSelectedItems={resetSelectedItems}
      />
    ) : (
      <PlaceHolder text={imagePlaceholderText} />
    )}
  </Container>
);

export default List;

import styled, { css } from 'styled-components/macro';

const Paragraph = styled.p<{ setMarginTop?: boolean }>`
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: ${p => p.theme.colors.gray500};
  margin-bottom: 1.5rem;
  ${p =>
    p.setMarginTop &&
    css`
      margin-top: 1.5rem;
    `}
`;

export default Paragraph;

import styled from 'styled-components';

const Label = styled.div<{ noBorder?: boolean; justify?: string; color?: string; bgc?: string }>`
  padding: ${p => p.theme.spacing.xxSmall} ${p => p.theme.spacing.small};
  background-color: ${p => p.bgc ?? p.theme.colors.secondaryBackground};
  display: flex;
  border-radius: 4px;
  border: ${p => (p.noBorder ? 'unset' : `1px solid ${p.theme.colors.secondaryBackgroundDark}`)};
  gap: ${p => p.theme.spacing.regular};
  align-items: end;
  font-size: 11px;
  color: ${p => p.color ?? p.theme.colors.textGray};
  letter-spacing: 0.3px;
  justify-content: ${p => p.justify || 'start'};
`;

export default Label;

export const endpoints = {
  groupList: 'api/group/list',
  createGroup: '/api/group/save',
  addObjectsToGroup: '/api/group/associate',
  deleteGroup: '/api/group/delete',
  loginsList: 'api/login/list',
  createLogin: '/api/login/create',
  updateLogin: '/api/login/update',
  deleteLogin: '/api/login/delete',
  addGroupsToLogin: '/api/login/associate',
};

import { HttpError, LOGIN } from 'common';
import { Status } from 'components/common';
import queryClient from 'lib/react-query';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions } from 'react-query';
import { useToast } from 'toast';

import { requests } from '../api';
import { ChangePasswordDto, UserSettingsDto } from '../dtos';

export const updateUserSettings = (
  config: UseMutationOptions<unknown, HttpError<unknown>, UserSettingsDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.updateSettings,
    ...config,
    onSuccess: () => {
      showToast({ content: t('updateSettingsSuccess') });
      queryClient.refetchQueries(LOGIN);
    },
    onError: () => showToast({ content: t('updateSettingsFailure'), type: Status.Negative }),
  };
};

export const changePassword = (
  config: UseMutationOptions<unknown, HttpError<unknown>, ChangePasswordDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.changePassword,
    ...config,
    onSuccess: () => {
      showToast({ content: t('changePasswordSuccessInfo') });
    },
  };
};

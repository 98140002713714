import { OBJECTS, OBJECTS_ALL, OBJECTS_PREVIOUS } from 'common';
import { useQuery, useQueryClient } from 'react-query';

import { requests } from '../api';
import { ObjectsParamsDto, ObjectsResponseDto } from '../dtos';
import { ObjectsResponsePrevDto } from '../dtos/ObjectsResponse.dto';

export const getObjectsWithPrevData = (
  objectsParams: ObjectsParamsDto | undefined,
  isAutomaticRefresh: boolean | undefined,
  automaticRefreshTime: number,
) => {
  const queryClient = useQueryClient();
  const prevData = queryClient.getQueryData<ObjectsResponseDto | ObjectsResponsePrevDto>([
    OBJECTS,
    OBJECTS_ALL,
  ]);

  const { data, isFetching, refetch } = useQuery({
    queryKey: objectsParams ? [OBJECTS, objectsParams] : [OBJECTS, OBJECTS_PREVIOUS],
    queryFn: () => requests.getObjectsWithPrevious(objectsParams, prevData),
    refetchOnMount: 'always',
    ...(isAutomaticRefresh &&
      automaticRefreshTime && {
        refetchInterval: automaticRefreshTime * 1000,
      }),
  });

  return { data, isFetching, refetch };
};

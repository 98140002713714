import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg';
import Button from 'components/Button';
import MobileModal from 'components/MobileModal/MobileModal';
import { Overlay } from 'components/Overlay';
import { routes } from 'navigation/routes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useToast } from 'toast';

import { ButtonContent, ButtonWrapper, StyledButtonWrapper } from '../components/AddAttachement';
import { DocumentsListDto } from '../dtos';
import { deleteDocument, getDocumentsList } from '../queries';
import { socket } from '../queries/socket';

const GalleryContainer = styled.div``;

const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xLarge};
  gap: ${({ theme }) => theme.spacing.large};
`;

const TopGalleryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PhotoWrapper = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.large};
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const PhotoTile = styled.div<{ url: string }>`
  width: 180px;
  height: 180px;
  background: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  background-position: center;
`;

const AddPhotoTile = styled.div`
  width: 180px;
  height: 180px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.small};

  font-size: 14px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  line-height: 22px;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.gray400};
    }
  }
`;

const GalleryTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  background: ${({ theme }) => theme.colors.gray100};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.xLarge}`};
`;

const TopTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.gray400};
    }
  }
`;

const ClickedPhotoWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
`;

const DriverGallery = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [imageClicked, setImageClicked] = useState<DocumentsListDto | null>(null);

  const { data: images } = useQuery(getDocumentsList());
  const { mutate: deletePhoto } = useMutation(deleteDocument());

  const handlePhoto = () => navigate(routes.driverCamera);

  const onClose = () => setImageClicked(null);

  const sendPhoto = () => {
    socket.connect();

    socket.emit(
      'server:message',
      { content: '', id: imageClicked?.accountId, document: imageClicked?.id },
      () => {
        showToast({ content: t('sendDocumentSuccess') });
        socket.disconnect();
      },
    );
    onClose();
  };

  const handlePhotoDelete = () => {
    if (!imageClicked) return;

    deletePhoto(imageClicked.id);
    onClose();
  };

  return (
    <>
      {imageClicked && <Overlay />}
      <GalleryContainer>
        <GalleryTitle>{t('documents')}</GalleryTitle>
        <GalleryWrapper>
          <TopGalleryWrapper>
            <TopTitle>{t('photos')}</TopTitle>
            <Button secondary icon={<AddIcon />} onClick={handlePhoto}>
              {t('addPhoto')}
            </Button>
          </TopGalleryWrapper>
          <PhotoWrapper>
            {images?.map(image => (
              <PhotoTile
                key={image.id}
                url={image.content}
                onClick={() => setImageClicked(image)}
              />
            ))}
            <AddPhotoTile onClick={handlePhoto}>
              <AddIcon />
              {t('addPhoto')}
            </AddPhotoTile>
          </PhotoWrapper>
        </GalleryWrapper>
        {imageClicked && images && (
          <MobileModal noPadding>
            <ButtonWrapper>
              <ClickedPhotoWrapper>
                <PhotoTile url={imageClicked.content} />
              </ClickedPhotoWrapper>
              <Button secondary fillWidth onClick={sendPhoto}>
                <ButtonContent>
                  <SendIcon /> {t('sendToSpeditor')}
                </ButtonContent>
              </Button>
              <Button secondary fillWidth onClick={handlePhotoDelete}>
                <ButtonContent>
                  <DeleteIcon />
                  {t('deletePhoto')}
                </ButtonContent>
              </Button>
            </ButtonWrapper>
            <StyledButtonWrapper>
              <Button secondary fillWidth onClick={onClose}>
                {t('goBack')}
              </Button>
            </StyledButtonWrapper>
          </MobileModal>
        )}
      </GalleryContainer>
    </>
  );
};

export default DriverGallery;

import i18n from 'locales/i18n';

i18n.loadNamespaces('general');

export const sameAs = (compareToValue: unknown, errorMessage: string) => (value?: string) => {
  if (!value?.length || compareToValue === value) {
    return;
  }

  return errorMessage;
};

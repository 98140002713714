import { axios } from 'lib/axios';

import {
  CreateReportDto,
  CreateRouteItemDto,
  DeleteReportDto,
  SendReportMailDto,
  StepOneVatUsDto,
  UpdateVatUsReportDto,
} from '../dtos';
import { endpoints } from '.';

export const createReport = (data: CreateReportDto) =>
  axios.post(endpoints.createReport(data.type), data.payload).then(res => res.data.data);

export const createVatUsStepOne = (data: StepOneVatUsDto) =>
  axios.post(endpoints.createReport(data.type), data.payload).then(res => res.data.data);

export const createGoalOrDriver = (data: CreateRouteItemDto) =>
  axios.post(endpoints.createGoalOrDriver, data).then(res => res.data.data);

export const updateVatUsReport = (data: UpdateVatUsReportDto) =>
  axios.post(endpoints.updateVatUsReport(data.reportId), data.payload).then(res => res.data);

export const getDrivers = () => axios.get(endpoints.getDrivers).then(res => res.data);

export const getRouteDrivers = () =>
  axios.get(endpoints.getRouteDrivers).then(res => res.data.data);

export const getRouteGoals = () => axios.get(endpoints.getRouteGoals).then(res => res.data.data);

type ReportsParams = {
  page?: number;
  automation?: boolean;
};

export const getReports = (params?: ReportsParams) =>
  axios.get(endpoints.getReports, { params }).then(res => res.data);

export const sendReportMail = (data: SendReportMailDto) =>
  axios.post(endpoints.sendReportMail, data).then(res => res.data);

export const deleteReport = (data: DeleteReportDto) =>
  axios.delete(endpoints.deleteReport(data.reportId)).then(res => res.data);

export const getReportStatuses = () => axios.get(endpoints.getReportStautses).then(res => res.data);

export const getReportData = (reportId: string) =>
  axios.get(endpoints.getReportData(reportId)).then(res => res.data.data);

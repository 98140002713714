import Button from 'components/Button';
import Radio from 'components/Radio';
import RadioFormField from 'components/RadioFormField';
import TextAreaFormField from 'components/TextAreaFormField';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  padding: ${({ isMobile, theme }) =>
    `${theme.spacing.large} ${isMobile ? theme.spacing.large : theme.spacing.xxLarge}`};

  flex-direction: column;
  justify-content: center;
  gap: ${({ isMobile, theme }) => (isMobile ? theme.spacing.large : theme.spacing.xLarge)};

  border-radius: 7px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  width: ${({ isMobile }) => (isMobile ? '100%' : '54rem')};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 14px;
  line-height: 22px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTextareaFormField = styled(TextAreaFormField)`
  background: ${({ theme }) => theme.colors.white};
  padding: 5px 12px 37px 12px;
  font-weight: 400;

  &:placeholder {
    font-weight: 400;
  }
`;

const UpdateReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.large};
`;

const RadioGroup = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ isMobile, theme }) => (isMobile ? theme.spacing.small : theme.spacing.large)};

  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  div {
    padding: ${({ isMobile, theme }) => isMobile && theme.spacing.xSmall};
  }
`;

const StyledRadio = styled(Radio)<{ isMobile: boolean }>`
  margin-right: ${({ isMobile }) => (isMobile ? '27px' : '1.5rem')};
`;

const ConfirmationBox = ({
  handleSave,
  handleUndo,
  isMobile,
}: {
  handleSave: () => void;
  handleUndo: () => void;
  isMobile: boolean;
}) => {
  const { t } = useTranslation();
  const [isOtherChecked, setIsOtherChecked] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, helpers] = useField('vrnReason');

  useEffect(() => {
    helpers.setValue(value);
  }, [value]);

  const handleRadioChange = (value: string) => {
    setValue(value);
    setIsOtherChecked(false);
  };

  return (
    <Container isMobile={isMobile}>
      <div>
        <Title>{t('vrnUpdateConfirmation')}</Title>
        <Paragraph>{t('confirmation')}</Paragraph>
      </div>
      <UpdateReasonWrapper>
        <Paragraph>{t('updateReason')}</Paragraph>
        <RadioGroup isMobile={isMobile}>
          <RadioFormField
            height="sm"
            label={t('reregistration')}
            name="vrnReason"
            value={t('reregistration')}
            checked={field.value === t('reregistration') && !isOtherChecked}
            onChange={() => handleRadioChange(t('reregistration'))}
            noMargin
            isActive={field.value === t('reregistration')}
          />
          <RadioFormField
            height="sm"
            label={t('deviceTransfer')}
            name="vrnReason"
            value={t('deviceTransfer')}
            checked={field.value === t('deviceTransfer') && !isOtherChecked}
            onChange={() => handleRadioChange(t('deviceTransfer'))}
            noMargin
            isActive={field.value === t('deviceTransfer')}
          />
          <StyledRadio
            isMobile={isMobile}
            height="sm"
            label={t('other')}
            onChange={() => {
              helpers.setValue('');
              setIsOtherChecked(true);
            }}
            checked={!!isOtherChecked}
            isActive={!!isOtherChecked}
          />
          {isOtherChecked && (
            <StyledTextareaFormField name="vrnReason" placeholder={t('enterMessage')} />
          )}
        </RadioGroup>
      </UpdateReasonWrapper>
      <ButtonWrapper>
        <Button size={isMobile ? 'sm' : 'md'} secondary onClick={handleUndo} fillWidth={isMobile}>
          {t('undo')}
        </Button>
        <Button primary size={isMobile ? 'sm' : 'md'} onClick={handleSave} fillWidth={isMobile}>
          {t('confirm')}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default ConfirmationBox;

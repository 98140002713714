import Check from 'assets/check.svg';
import React, { ChangeEvent, memo } from 'react';
import styled from 'styled-components/macro';
import { useAppContext } from 'utils/appContext';

export type CheckboxProps = React.ComponentPropsWithoutRef<'input'> & {
  checkboxSize?: 'md';
  dark?: boolean;
  label?: string;
  disabled?: boolean;
  onClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
};

type StyledCheckboxProps = CheckboxProps & {
  medium: boolean;
};

type LabelProps = React.HTMLProps<HTMLLabelElement> & {
  dark?: boolean;
};

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const StyledCheckbox = styled.input<StyledCheckboxProps>`
  width: ${p => (p.medium ? `16px` : `24px`)};
  height: ${p => (p.medium ? `16px` : `24px`)};
  min-width: ${p => (p.medium ? `16px` : `24px`)};
  min-height: ${p => (p.medium ? `16px` : `24px`)};
  background: ${p => (p.dark ? p.theme.colors.gray500 : p.theme.colors.gray200)};
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  ${p =>
    !p.disabled &&
    `&:hover,
  &:checked {
    border: 1px solid ${p.theme.colors.primary500};`}

  &:before {
    display: block;
    text-align: center;
    content: '';
    content: ${p => (p.medium ? '' : `url(${Check})`)};
    width: ${p => (p.medium ? `10px` : `16px`)};
    height: ${p => (p.medium ? `10px` : `16px`)};
    background: ${p => (p.disabled ? p.theme.colors.gray300 : p.theme.colors.primary500)};
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    border-radius: 2px;
  }

  &:checked:before {
    transform: scale(1);
  }
`;

const Label = styled.label<LabelProps>`
  margin-left: 12px;
  font-size: 16px;
  color: ${p => (p.disabled ? p.theme.colors.gray400 : p.theme.colors.textLight)};
`;
// TODO: Handle onChange
const Checkbox = ({ label, disabled, checkboxSize, dark, onClick, checked }: CheckboxProps) => {
  const { darkMode } = useAppContext();
  return (
    <Wrapper>
      <StyledCheckbox
        type="checkbox"
        disabled={disabled}
        medium={checkboxSize === 'md'}
        dark={dark || darkMode}
        onClick={onClick}
        checked={checked}
        onChange={() => {}}
      />
      <Label htmlFor={label} disabled={disabled} dark={dark}>
        {label}
      </Label>
    </Wrapper>
  );
};

export default memo(Checkbox);

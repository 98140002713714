import { useMemo } from 'react';

type Data = {
  data: {
    pagination: {
      totalItems: number;
      itemsPerPage: number;
    };
  };
};

export function useParsedPagination<T extends Data>(data?: T) {
  return useMemo(() => {
    if (!data?.data.pagination) return;
    const {
      pagination: { totalItems, itemsPerPage },
    } = data.data;
    return { totalItems, itemsPerPage };
  }, [data]);
}

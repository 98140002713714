import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { BiDownload } from 'react-icons/bi';
import styled from 'styled-components';

const ImageContainer = styled.div`
  background: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: ${p => p.theme.layersOrder.modal};
  background-color: #00000094;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.large};
`;

const ParentWrapper = styled.div`
  position: relative;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: -40px;
  width: 100%;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Image = styled.img`
  position: relative;
  border-radius: 8px;
  box-shadow: ${p => p.theme.shadows.xl};
`;

const ImageDocEnlarged = ({ url, onClose }: { url: string; onClose: () => void }) => (
  <ImageContainer>
    <ParentWrapper>
      <IconButtonWrapper>
        <CloseIcon onClick={onClose} />
        <a href={url} download="wwImage" target="_blank" rel="noreferrer">
          <BiDownload size={24} />
        </a>
      </IconButtonWrapper>
      <Image src={url} />
    </ParentWrapper>
  </ImageContainer>
);

export default ImageDocEnlarged;

import { ReactComponent as GroupIcon } from 'assets/icons/group.svg';
import LockIcon from 'assets/icons/lock.svg';
import { ReactComponent as LoginIcon } from 'assets/icons/login.svg';
import DeleteIcon from 'assets/icons/trash.svg';
import Pencil from 'assets/pencil.svg';
import Badge from 'components/Badge';
import ConfirmationModal from 'components/ConfirmationModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { deleteLogin } from '../queries';
import { GroupListWrapper } from './DesktopGroupList';
import {
  DeleteGroupButton as GroupButton,
  EditIcon,
  GroupName,
  GroupObjectsWrapper,
  ItemContainer,
  ObjectsNumber,
  Section,
} from './GroupItem';
import { LoginsListProps } from './LoginsList';

export const StyledBadge = styled(Badge)`
  background: ${({ theme }) => theme.colors.gray100};
`;

export const StyledGroupName = styled(GroupName)`
  color: ${({ theme }) => theme.colors.gray700};
`;

const DesktopLoginsList = ({
  logins,
  handleSelectedLoginId,
  selectedLoginId,
  handleForm,
  handleChangePasssword,
}: LoginsListProps) => {
  const { t } = useTranslation();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const handleModal = () => setIsConfirmationModalOpen(prev => !prev);

  const { mutate, isLoading: isDeletingLoading } = useMutation(deleteLogin());

  const handleDeleteLogin = (id: number) => {
    mutate({ id: id });
  };

  const handleConfirm = (id: number) => {
    handleDeleteLogin(id);
    handleModal();
    handleSelectedLoginId?.(null);
  };

  return (
    <GroupListWrapper>
      {logins?.map(login => (
        <>
          <ItemContainer
            isActive={selectedLoginId === login.loginId}
            onClick={() => handleSelectedLoginId?.(login.loginId)}
            key={login.loginId}
          >
            <Section>
              <LoginIcon />
              <StyledGroupName>{login.user}</StyledGroupName>
              <StyledBadge>@kandk</StyledBadge>
            </Section>
            <Section>
              <GroupObjectsWrapper>
                <ObjectsNumber>{login.groups.length}</ObjectsNumber>
                <GroupIcon />
              </GroupObjectsWrapper>
              <EditIcon url={Pencil} onClick={handleForm} />
              <GroupButton
                onClick={() => {
                  handleForm?.();
                  handleChangePasssword?.();
                }}
              >
                <SVG
                  src={LockIcon}
                  width={16}
                  height={16}
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill="#252E3B"')}
                />
              </GroupButton>
              <GroupButton onClick={handleModal}>
                <SVG
                  src={DeleteIcon}
                  width={16}
                  height={16}
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill="#252E3B"')}
                />
              </GroupButton>
            </Section>
          </ItemContainer>
          <ConfirmationModal
            size="small"
            isOpen={isConfirmationModalOpen}
            title={t('confirmYourChoice')}
            message={t('confirmDeleteLogin')}
            confirmText={t('delete')}
            onConfirm={() => selectedLoginId && handleConfirm(selectedLoginId)}
            onClose={() => setIsConfirmationModalOpen(false)}
            disabled={isDeletingLoading}
            isLoading={isDeletingLoading}
          />
        </>
      ))}
    </GroupListWrapper>
  );
};

export default DesktopLoginsList;

import Tabs, { SingleTab } from 'components/Tabs';
import GroupItem from 'modules/admin/components/GroupItem';
import { StyledTabsWrapper } from 'modules/admin/components/Groups';
import {
  Container,
  MobileGroupListWrapper,
  StyledTopWrapper,
} from 'modules/admin/components/MobileGroupList';
import { GroupItem as GroupItemProps } from 'modules/admin/dtos';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { TopWrapper } from 'modules/driver/pages/Spedition';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SharingTabs } from '../pages/Sharing';
import SharingBox from './SharingBox';

export const StyledContainer = styled(Container)`
  height: 100%;
`;

export const SharingStyledTopWrapper = styled(StyledTopWrapper)`
  label {
    width: 100%;
  }
`;

export const TabsStyledTopWrapper = styled(TopWrapper)`
  justify-content: center;
  align-items: center;
`;

export const SharingStyledTabsWrapper = styled(StyledTabsWrapper)`
  padding: 32px 16px 16px 16px;
`;

interface MobileSharingGroupsProps {
  searchInputValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  groups?: GroupItemProps[];
  selectedGroupId?: number | null;
  handleSelectedGroupId: (id?: number) => void;
  activeTab: SharingTabs;
  tabs: SingleTab[];
  handleTabClick: (value: string) => void;
}

const MobileSharingGroups = ({
  searchInputValue,
  handleInputChange,
  groups,
  selectedGroupId,
  handleSelectedGroupId,
  activeTab,
  tabs,
  handleTabClick,
}: MobileSharingGroupsProps) => {
  const { t } = useTranslation();

  const handleGroupClick = (id?: number) => handleSelectedGroupId(id);

  const selectedGroup = groups?.find(g => g.groupId === selectedGroupId);

  if (selectedGroupId) {
    return (
      <SharingBox handleSelectedGroupId={handleSelectedGroupId} selectedGroup={selectedGroup} />
    );
  }

  return (
    <>
      <TabsStyledTopWrapper>
        <SharingStyledTabsWrapper isMobile>
          <Tabs options={tabs} activeTab={activeTab} onClick={handleTabClick} />
        </SharingStyledTabsWrapper>
      </TabsStyledTopWrapper>
      <StyledContainer>
        <SharingStyledTopWrapper>
          <SpeditionSearchInput
            searchInputValue={searchInputValue}
            handleInputChange={e => handleInputChange?.(e)}
            placeholder={t('searchGroup')}
          />
        </SharingStyledTopWrapper>
        <MobileGroupListWrapper>
          {groups?.map(group => (
            <GroupItem
              key={group.groupId.toString()}
              label={group.name}
              comment={group.comment}
              objectsNumber={group.objects.length}
              color={group.color}
              groupId={group.groupId}
              onClickHandler={() => handleGroupClick(group.groupId)}
              handleGroupForm={() => {}}
              handleGroupId={() => handleSelectedGroupId(group.groupId)}
              isSharingComponent
            />
          ))}
        </MobileGroupListWrapper>
      </StyledContainer>
    </>
  );
};

export default MobileSharingGroups;

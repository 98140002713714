import { getSubMenuItemData } from 'common/menuItems';
import Button from 'components/Button';
import Flex from 'components/Dashboard/Flex';
import Label from 'components/Dashboard/Label';
import { Wrapper } from 'components/Dropdown/style';
import { AppModuleDto } from 'modules/auth/dtos/UpdateMenuResponse.dto';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useClose } from 'utils';

import {
  StyledSubMenuList,
  StyledSubMenuListItem,
  StyledSubMenuListWrapper,
} from './SubMenu.styled';

export const EmptyListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.2rem;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};

  label {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

interface SubMenuProps {
  items: Array<AppModuleDto>;
  backgroundColor: string;
  icon?: React.ReactElement;
  onClick: () => void;
  callback: () => void;
  disabled?: boolean;
  label: string;
  isOpen: boolean;
  parentMenuItem: string;
  isNew?: boolean;
}

const SubMenu = ({
  items,
  icon,
  onClick,
  backgroundColor,
  label,
  disabled,
  callback,
  isOpen,
  parentMenuItem,
  isNew,
}: SubMenuProps) => {
  const navigate = useNavigate();
  const ref = useRef(null);

  useClose(ref, callback);

  const { t } = useTranslation();

  const handleListItemClick = (route: string) => {
    navigate(route);
    callback();
  };

  /**
   * Renders menu items based on submodules
   */
  const renderElements = useMemo(() => {
    if (items.length === 0) return <EmptyListItem>{t('noIntegrations')}</EmptyListItem>;

    return items.map(item => {
      const subModuleData = getSubMenuItemData(parentMenuItem, item.id);
      if (!subModuleData) return null;

      return (
        <StyledSubMenuListItem
          key={`${subModuleData.value}`}
          onClick={() => handleListItemClick(subModuleData.route)}
        >
          <Flex gap={10}>
            {subModuleData.label}
            {item.isNew && <Label>NEW</Label>}
          </Flex>
        </StyledSubMenuListItem>
      );
    });
  }, [items, parentMenuItem, t]);

  return (
    <Wrapper>
      <Button
        icon={icon}
        isDropdown
        backgroundColor={backgroundColor}
        disabled={disabled}
        onClick={onClick}
      >
        <Flex gap={10} noWrap>
          {label}
          {isNew && <Label>NEW</Label>}
        </Flex>
      </Button>

      {/* <--- render list ---> */}
      {isOpen && (
        <StyledSubMenuListWrapper maxHeight={300} ref={ref} isOpen={isOpen}>
          <StyledSubMenuList maxHeight={250}>{renderElements}</StyledSubMenuList>
        </StyledSubMenuListWrapper>
      )}
    </Wrapper>
  );
};

export default SubMenu;

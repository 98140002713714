import i18n from 'locales/i18n';

i18n.loadNamespaces('general');

const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const isEmail = (errorMessage?: string) => (value?: string) => {
  if (!value?.length) {
    return;
  }

  const isValid = emailRegexp.test(value);

  if (isValid) {
    return;
  }

  return errorMessage ?? i18n.t('validation.invalidEmail');
};

export enum ExtendedDataOptionsDto {
  GROUPS = 'groups',
  WORKTIME = 'worktime',
  DISTANCE = 'distance',
  PLANNING_REMAINING_DISTANCE = 'planning-remaining-distance',
  START_IGNITION = 'start-ignition',
  TACHOGRAPH_ALERTS = 'tachograph-alerts',
  AVERAGE_FUEL_USAGE = 'average-fuel-usage',
}

export type ObjectsExtendedDataDto = {
  objects: number[];
  request: ExtendedDataOptionsDto[];
};

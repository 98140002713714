import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { ItemName, ItemNameWrapper } from 'modules/driver/components/SpeditionObjectsList';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { Title } from 'modules/driver/pages/Spedition';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { useWindowSize } from 'utils';

import { GroupItem } from '../dtos';
import { addGroupsToLogin, getLoginsList } from '../queries';
import { GroupColor } from './GroupItem';
import {
  BadgeSection,
  ConfirmSection,
  ListWrapper,
  ObjectsList,
  StyledBadge,
  StyledObjectItem,
  TopSection,
} from './GroupObjectsList';

export const GroupNameWrapper = styled.div`
  border-radius: ${({ theme }) => theme.spacing.xSmall};
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  padding: ${({ theme }) => `8px ${theme.spacing.large} 8px 8px`};
  width: auto;
  display: flex;
  align-items: center;
  gap: 12px;
`;

interface LoginGroupListProps {
  groups?: GroupItem[];
  selectedLoginId: number | null;
  handleSelectedLoginId?: (id: number | null) => void;
}

const LoginGroupList = ({
  groups,
  selectedLoginId,
  handleSelectedLoginId,
}: LoginGroupListProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { data: logins } = useQuery(getLoginsList());
  const { mutate, isLoading: isAddingGroupsToLoginLoading } = useMutation(addGroupsToLogin());

  const loginGroups = logins?.data.logins.find(login => login.loginId === selectedLoginId)?.groups;

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<GroupItem[] | undefined>(loginGroups);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const handleSelectedItems = (item: GroupItem) => {
    if (!selectedItems) return;

    if (selectedItems?.find(obj => obj.groupId === item.groupId)) {
      setSelectedItems(selectedItems?.filter(obj => obj.groupId !== item.groupId));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
    setIsButtonDisabled(false);
  };

  useEffect(() => {
    setSelectedItems(loginGroups);
  }, [loginGroups]);

  const filteredGroupsList = searchInputValue
    ? groups?.filter(group => group.name.toLowerCase().includes(searchInputValue))
    : groups;

  const handleAddGroupsToLogin = (id: number) => {
    mutate({ login: id, groups: selectedItems ? selectedItems?.map(item => item.groupId) : [] });
    handleSelectedLoginId?.(null);
  };

  return (
    <ListWrapper>
      <TopSection>
        <Title>{t('objectsGroups')}</Title>
        <SpeditionSearchInput
          placeholder={t('searchGroup')}
          searchInputValue={searchInputValue}
          handleInputChange={e => setSearchInputValue(e.target.value)}
        />
      </TopSection>
      {selectedItems && selectedItems?.length > 0 && (
        <BadgeSection>
          {selectedItems?.map((item, index) => (
            <StyledBadge key={`selected-item-${index}`} onClick={() => handleSelectedItems(item)}>
              {item.name}
              <span>&#10005;</span>
            </StyledBadge>
          ))}
        </BadgeSection>
      )}
      <ObjectsList>
        {(filteredGroupsList as GroupItem[])?.map(group => (
          <StyledObjectItem
            key={group.groupId}
            isMobile={isMobile}
            isActive={false}
            onClick={() => handleSelectedItems(group as unknown as GroupItem)}
          >
            <ItemNameWrapper>
              <Checkbox
                checkboxSize={'md'}
                checked={!!selectedItems?.find(item => item.groupId === group.groupId)}
              />
              <GroupNameWrapper>
                <GroupColor color={group.color ?? ''} />
                <ItemName>{group.name}</ItemName>
              </GroupNameWrapper>
            </ItemNameWrapper>
          </StyledObjectItem>
        ))}
      </ObjectsList>
      <ConfirmSection>
        <Button
          primary
          disabled={isButtonDisabled || isAddingGroupsToLoginLoading}
          onClick={() => selectedLoginId && handleAddGroupsToLogin(selectedLoginId)}
        >
          {`${t('confirmGroups')} (${selectedItems?.length})`}
        </Button>
      </ConfirmSection>
    </ListWrapper>
  );
};

export default LoginGroupList;

import Tippy from '@tippyjs/react';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { getObjectIcon, useWindowSize } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import { SummaryDataType } from '../pages/Spedition';
import SpeditionSearchInput from './SpeditionSearchInput';

export const ListWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  padding: 1.5rem;
  margin-top: 0.5rem;
  border-radius: 6px;
  gap: ${({ isMobile }) => isMobile && '0.5rem'};
  overflow: auto;
  height: 70vh;
`;

export const ListTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

const ObjectsList = styled.ul<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '0' : '1.5rem')};
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  ${p =>
    p.isMobile &&
    css`
      height: 70vh;
      margin-top: 1rem;
    `}
`;

export const ObjectItem = styled.li<{ isActive: boolean; isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.1rem 1.3rem;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  font-weight: 600;
  border-radius: 6px;
  transition: 0.2s ease;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.gray600 : theme.colors.text)};
  border: ${({ theme, isActive }) =>
    isActive
      ? `1px solid ${theme.colors.onPrimaryBorder}`
      : `1px solid ${theme.colors.primaryBackground}`};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.gray100 : theme.colors.white};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.gray600};
    background-color: ${({ theme }) => theme.colors.gray100};
    border: ${({ isMobile, theme }) =>
      isMobile
        ? `1px solid ${theme.colors.onPrimaryBorder}`
        : `1px soldid ${theme.colors.primaryBackground}`};
  }
`;

export const ItemName = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const StyledLinkIcon = styled(LinkIcon)<{ inactive?: boolean }>`
  opacity: ${p => (p.inactive ? '0.5' : '1')};
  filter: ${p => (p.inactive ? 'grayscale(100%)' : 'none')};
`;

const CheckAll = styled.div`
  padding: 1.2rem;

  label {
    color: ${({ theme }) => theme.colors.error};
    margin-left: 3rem;
    font-size: 14px;
  }
`;

export const ItemNameWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  visibility: hidden;
  width: 153px;
`;

const SpeditionObjectsList = ({
  summaryData,
  selectedItems,
  handleSelectedItems,
  searchInputValue,
  checkAllBindedItems,
  handleInputChange,
}: {
  summaryData: SummaryDataType[];
  selectedItems: SummaryDataType[];
  handleSelectedItems: (item: SummaryDataType) => void;
  searchInputValue: string | undefined;
  checkAllBindedItems: () => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const listItems = searchInputValue
    ? summaryData.filter(obj =>
        obj.number.toLowerCase().includes(searchInputValue.toLocaleLowerCase()),
      )
    : summaryData;

  const handleHoverEnter = (e: React.MouseEvent<HTMLLIElement>) => {
    const target = e.target as HTMLLIElement;
    if (target && target.querySelector('button')) {
      target.querySelector('button')!.style.visibility = 'visible';
    }
  };

  const handleHoverLeave = (e: React.MouseEvent<HTMLLIElement>) => {
    const target = e.target as HTMLLIElement;
    if (target && target.querySelector('button')) {
      target.querySelector('button')!.style.visibility = 'hidden';
    }
  };

  return (
    <ListWrapper isMobile={isMobile}>
      <ListTitle>{t('vehiclesList')}</ListTitle>
      {isMobile && (
        <SpeditionSearchInput
          searchInputValue={searchInputValue}
          handleInputChange={handleInputChange}
        />
      )}
      <ObjectsList isMobile={isMobile}>
        {!isMobile && (
          <CheckAll>
            <Checkbox
              onClick={checkAllBindedItems}
              checkboxSize={'md'}
              label={t('selectAllLinkedItems')}
            />
          </CheckAll>
        )}
        {Array.isArray(listItems) &&
          listItems.map(obj => (
            <ObjectItem
              key={obj.objectId}
              onMouseEnter={e => !obj.url && handleHoverEnter(e)}
              onMouseLeave={e => !obj.url && handleHoverLeave(e)}
              isActive={!!selectedItems?.find(el => el.objectId === obj.objectId)}
              onClick={() => handleSelectedItems(obj)}
              isMobile={isMobile}
            >
              <ItemNameWrapper>
                <Checkbox
                  checkboxSize={'md'}
                  checked={!!selectedItems?.find(el => el.objectId === obj.objectId)}
                />
                <img
                  src={getObjectIcon(obj.type)}
                  style={{ filter: getObjectFilterColor(obj.status) }}
                />
                <ItemName>
                  {obj.number} {obj?.comment ? `(${obj?.comment})` : ''}
                </ItemName>

                {obj.url && (
                  <Tippy
                    content={t(obj?.hash ? 'accountShared' : 'accountCreated')}
                    placement="top"
                  >
                    <StyledLinkIcon inactive={!obj?.hash} />
                  </Tippy>
                )}
              </ItemNameWrapper>

              {!obj.url && (
                <StyledButton secondary size="sm">
                  {t('linkVehicle')}
                </StyledButton>
              )}
            </ObjectItem>
          ))}
      </ObjectsList>
    </ListWrapper>
  );
};

export default SpeditionObjectsList;

export enum ReportTypes {
  TACHO = 'tacho',
  SPECIAL = 'special',
  TEMPOMAT = 'tempomat',
  RETARDER = 'retarder',
  WORKTIME_DISTANCE = 'distance-worktime',
  TEMPERATURE_SENSOR = 'temperature-sensor',
  WORKTIME_CALENDAR = 'calendar-worktime',
  LOGBOOK = 'karta-drogowa',
  LOGBOOK_SHORT = 'karta-drogowa-simple',
  LOGBOOK_XLS = 'karta-drogowa-xls',
  LOGBOOK_SHORT_XLS = 'karta-drogowa-simple-xls',
  LOGBOOK_WORKTIME = 'karta-drogowa-worktime',
  LOGBOOK_WORKTIME_XLS = 'karta-drogowa-worktime-xls',
  LOGBOOK_RESTORE = 'karta-drogowa-restore-xls',
  MILOG = 'milog',
  MILOG_XLS = 'milog-xls',
  MILOG_XLS_TACHOMASTER = 'milog-xls-tachomaster',
  MILOG_XLS_DRIVERS = 'milog-xls-drivers',
  BORDERCROSS = 'milog-csv-cross',
  DISTANCE_COMBUSTION = 'karta-drogowa-fuel',
  DISTANCE_COMBUSTION_DRIVER = 'karta-drogowa-driver-fuel',
  REFUELING = 'fuel-tanks',
  VAT_US = 'vat-us',
  VAT_US_XLS = 'vat-us-xls',
}

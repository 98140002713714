import styled from 'styled-components';

const Wrapper = styled.div<{ padding?: string }>`
  width: 100%;
  overflow-x: auto;
  padding: ${p => p.padding ?? p.theme.spacing.regular};
  border-radius: 8px;

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    :hover {
      background-color: ${p => p.theme.colors.gray500};
    }
  }
`;

const Table = styled.table`
  width: 100%;
`;

const Head = styled.thead`
  width: 100%;
`;

const Body = styled.tbody`
  width: 100%;
`;

const Row = styled.tr`
  border-bottom: 1px solid ${p => p.theme.colors.secondaryBackgroundDark};
  width: 100%;
  background-color: ${p => p.theme.colors.primaryBackground};
  gap: ${p => p.theme.spacing.regular};

  :last-child {
    border-bottom: none;
  }
`;

const RowHead = styled.tr`
  background-color: ${p => p.theme.colors.secondaryBackground};
  color: ${p => p.theme.colors.textLight};
  width: 100%;
`;

const HeadCell = styled.th<{ width?: number }>`
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.regular};
  letter-spacing: 0.3px;
  font-weight: bold;
  font-size: 1.1rem;
  text-align: start;
  min-width: ${p => (p.width ? `${p.width}px` : '200px')};
  color: ${p => p.theme.colors.textGray};
`;

const Cell = styled.td`
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.regular};
  font-size: 1.1rem;
  color: ${p => p.theme.colors.textGray};
`;

const StyledTable = {
  Wrapper,
  Table,
  Head,
  Body,
  Row,
  RowHead,
  HeadCell,
  Cell,
};

export default StyledTable;

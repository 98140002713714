/**
 * This util function is used to
 * redirect to an external source
 * @param link
 * @param target
 */
const redirectToExternal = (link?: string, target?: string): void => {
  if (!link) return;

  window.open(link, target ?? '_blank');
};

export default redirectToExternal;

import CloseIcon from 'assets/close.svg';
import { AllowedModules } from 'common';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import IconButton from 'components/IconButton';
import InputFormField from 'components/InputFormField';
import SelectDropdownFormField from 'components/SelectDropdownFormField';
import TextAreaFormField from 'components/TextAreaFormField';
import { Form, Formik } from 'formik';
import i18n from 'locales/i18n';
import { getVehiclesTypes, updateObjectWithVRN } from 'modules/objects/queries';
import Separator from 'modules/reports/components/Separator';
import { useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { getObjectIcon, useCheckModuleAccess, useWindowSize } from 'utils';
import * as Yup from 'yup';

import { vehiclesTranslations } from '../EditCommentAndVehicle/vehiclesTranslations';
import ConfirmationBox from './ConfirmationBox';
import MobileVehiclesList from './MobileVehiclesList';

interface EditFormProps {
  objectId: number;
  comment: string;
  type: string;
  licensePlate: string;
  vrnReason?: string;
  onClose?: () => void;
  closeMobileModal?: () => void;
}

type EditFormValuesProps = EditFormProps;

const FormContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.large};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

const VehicleInfo = styled.div`
  display: flex;
  padding-right: 0px;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  flex: 1 0 0;
`;

const VehicleType = styled.div<{ isVRNEditable?: boolean; isMobile: boolean }>`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  width: ${({ isMobile }) => (isMobile ? '27rem' : '234px')};
  height: 42px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  display: flex;
  align-items: center;
  justify-content: ${({ isVRNEditable }) => (isVRNEditable ? 'space-between' : 'start')};
  padding: ${({ isVRNEditable, theme }) => (isVRNEditable ? theme.spacing.large : '0')};
  border-radius: 8px;

  svg {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const StyledTextAreaFormField = styled(TextAreaFormField)<{
  isMobile: boolean;
  isFieldDirty: boolean;
}>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '54rem')};
  height: 84px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border: ${({ isFieldDirty, theme }) =>
    isFieldDirty ? `1px solid ${theme.colors.error} !important` : 'none'};
`;

const CardField = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small};
`;

const CardContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile, theme }) => (isMobile ? theme.spacing.small : theme.spacing.xLarge)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.large};
  margin-top: ${({ theme }) => theme.spacing.regular};
`;

const StyledInputFormField = styled(InputFormField)<{ isMobile: boolean; isVRNEditable: boolean }>`
  height: 42px;
  border-radius: 8px;
  border: ${({ isMobile, isVRNEditable, theme }) =>
    isMobile && isVRNEditable ? `1px solid ${theme.colors.error}` : 'none'};
`;

const StyledSeparator = styled(Separator)`
  margin: 1rem 0;
`;

const VehicleEditionFormSchemaReasonDisabled = (t: TFunction) =>
  Yup.object().shape({
    objectId: Yup.number().required(t('required')),
    type: Yup.string(),
    licensePlate: Yup.string()
      .matches(/^(?=.*[aA-zZ\s])(?=.*[0-9])/, t('validation.validVRN'))
      .max(20, t('maxCharacters', { number: 20 })),
    comment: Yup.string().max(100, t('maxCharacters', { number: 100 })),
  });

const EditForm = ({
  objectId,
  comment,
  type,
  licensePlate,
  onClose,
  closeMobileModal,
}: EditFormProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const checkModuleAccess = useCheckModuleAccess();
  const isVRNReasonDisabled = checkModuleAccess(AllowedModules.DISABLE_REASON_VRN);

  const initialValues: EditFormValuesProps = {
    type: type,
    licensePlate: licensePlate,
    comment: comment ?? '',
    objectId: objectId,
    vrnReason: '',
  };

  const VehicleEditionFormSchema = (t: TFunction) =>
    Yup.object().shape({
      objectId: Yup.number().required(t('required')),
      type: Yup.string(),
      licensePlate: Yup.string()
        .max(20, t('maxCharacters', { number: 20 }))
        .matches(/^(?=.*[aA-zZ\s])(?=.*[0-9])/, t('validation.validVRN')),
      comment: Yup.string().max(100, t('maxCharacters', { number: 100 })),
      vrnReason: Yup.string().when('licensePlate', {
        is: (licensePlate: string) => licensePlate !== initialValues.licensePlate,
        then: schema =>
          schema
            .required(t('required'))
            .max(100, t('maxCharacters', { number: 100 }))
            .min(3, t('minCharacters', { number: 3 })),
      }),
    });

  const [scheme, setScheme] = useState<Yup.AnyObjectSchema>(
    isVRNReasonDisabled ? VehicleEditionFormSchemaReasonDisabled(t) : VehicleEditionFormSchema(t),
  );

  useEffect(() => {
    async function onLanguageChanged() {
      setScheme(VehicleEditionFormSchema(t));
    }

    i18n.on('languageChanged', onLanguageChanged);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [i18n, t, VehicleEditionFormSchema]);

  const [isTypeEditable, setIsTypeEditable] = useState<boolean>(false);
  const [isVRNEditable, setIsVRNEditable] = useState<boolean>(false);
  const [isVehiclesListOpen, setIsVehiclesListOpen] = useState<boolean>(false);

  const { data, isLoading } = useQuery(getVehiclesTypes());
  const { mutate, isLoading: isUpdateLoading } = useMutation(updateObjectWithVRN());

  const handleSubmit = (values: EditFormValuesProps) => {
    mutate(values);
    onClose?.();
  };

  const handleTypeEdit = () => {
    if (isMobile) {
      setIsVehiclesListOpen(true);
    } else {
      setIsTypeEditable(true);
    }
  };

  const handleVRNEdit = () => {
    setIsVRNEditable(prev => !prev);
  };

  const handleSave = () => {
    handleVRNEdit();
  };

  const vehicleOptions = useMemo(
    () =>
      (data ?? []).map((el: string) => ({
        value: el,
        label: vehiclesTranslations(t)[el] || '',
        icon: require(`assets/vehicles/${el}.svg`).default,
      })),
    [data, vehiclesTranslations],
  );

  const selectedOption = useMemo(
    () => vehicleOptions.find(o => o.value === type),
    [type, vehicleOptions],
  );

  const initialOption = vehicleOptions.find(o => (type ? o.value === type : o.value === 'car'));

  const handleGoBack = () => {
    if (isVehiclesListOpen) {
      setIsVehiclesListOpen(false);
    } else {
      closeMobileModal?.();
    }
  };

  const getVehiclesType = (formType: boolean | string, type: boolean | string) => {
    if (formType) {
      return typeof formType === 'boolean' ? 'car' : formType;
    } else if (typeof type === 'boolean') {
      return 'car';
    } else {
      return type;
    }
  };

  return (
    <FormContainer>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={scheme}>
        {({ values, setFieldValue, dirty, errors }) => (
          <Form>
            {isVehiclesListOpen ? (
              <>
                <Title>{t('icon')}</Title>
                <MobileVehiclesList
                  vehiclesTypes={data}
                  setFieldValue={setFieldValue}
                  typeValue={values.type}
                />
              </>
            ) : (
              <CardContainer isMobile={isMobile}>
                <CardField>
                  <Label>{t('icon')}</Label>
                  <VehicleInfo>
                    {isTypeEditable ? (
                      <SelectDropdownFormField
                        noMargin
                        name="type"
                        size="md"
                        items={vehicleOptions}
                        onChange={value => setFieldValue('type', value)}
                        value={selectedOption?.value}
                        isLoading={isLoading}
                        fillWidth
                        defaultItem={initialOption}
                      />
                    ) : (
                      <VehicleType isMobile={isMobile}>
                        <img src={getObjectIcon(values.type ? values.type : type)} />
                        {vehiclesTranslations(t)[getVehiclesType(values.type, type)] || ''}
                      </VehicleType>
                    )}
                    <IconButton icon="pencil" onClick={handleTypeEdit} />
                  </VehicleInfo>
                </CardField>
                <CardField>
                  <Label>{t('vrn')}</Label>
                  <VehicleInfo>
                    {isVRNEditable ? (
                      <>
                        <div>
                          <StyledInputFormField
                            name="licensePlate"
                            noLabel
                            placeholder={licensePlate}
                            onRightIconClick={handleVRNEdit}
                            rightIcon={CloseIcon}
                            isMobile={isMobile}
                            isVRNEditable
                          />
                          {!isVRNReasonDisabled && (
                            <ConfirmationBox
                              handleUndo={() => {
                                setFieldValue('vrnReason', '');
                                handleVRNEdit();
                              }}
                              handleSave={values.vrnReason ? handleSave : () => {}}
                              isMobile={isMobile}
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <VehicleType style={{ paddingLeft: '11px' }} isMobile={isMobile}>
                          {values.licensePlate ?? licensePlate}
                        </VehicleType>
                        <IconButton icon="pencil" onClick={handleVRNEdit} />
                      </>
                    )}
                  </VehicleInfo>
                  {!isMobile && errors.vrnReason && (
                    <ErrorMessage>
                      {t('vrnReason')}
                      {': '}
                      {errors.vrnReason}
                    </ErrorMessage>
                  )}
                </CardField>
                <CardField>
                  <Label>{t('comment')}</Label>
                  <StyledTextAreaFormField
                    name="comment"
                    noMargin
                    isMobile={isMobile}
                    placeholder={t('enterComment')}
                    isFieldDirty={isMobile && values.comment !== initialValues.comment}
                  >
                    {comment}
                  </StyledTextAreaFormField>
                </CardField>
              </CardContainer>
            )}
            <StyledSeparator />
            <ButtonWrapper>
              {isMobile ? (
                <>
                  <IconButton icon={'back'} onClick={handleGoBack} />
                  <Button type="submit" disabled={!dirty || isUpdateLoading} primary fillWidth>
                    {t('save')}
                  </Button>
                </>
              ) : (
                <>
                  <Button secondary onClick={onClose}>
                    {t('goBack')}
                  </Button>
                  <Button primary type="submit" disabled={!dirty || isUpdateLoading}>
                    {t('save')}
                  </Button>
                </>
              )}
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

export default EditForm;

import styled from 'styled-components';

// This component is used to style the wrapper of the toggle switch
export const StyledCarToggleSwitchWrapper = styled.div<{
  withoutBorder?: boolean;
  withBackground?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${p => p.theme.spacing.xxSmall} ${p => p.theme.spacing.small};
  padding: ${p => p.theme.spacing.small};
  border: ${p => (p.withoutBorder ? 'unset' : `1px solid ${p.theme.colors.borderGray}`)};
  border-radius: 8px;
  background-color: ${p => (p.withBackground ? p.theme.colors.primaryBackground : 'unset')};
  margin-bottom: ${p => (p.withBackground ? p.theme.spacing.regular : 'unset')};
`;

// This component is used to style the label of the toggle switch
export const StyledCarToggleSwitchLabel = styled.div<{ isHighlight?: boolean }>`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${p => (p.isHighlight ? p.theme.colors.text : p.theme.colors.textLightGray)};
`;

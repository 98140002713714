import Checkbox from 'components/Checkbox';
import { SingleTab } from 'components/Tabs';
import { GroupColor } from 'modules/admin/components/GroupItem';
import { ObjectsList, StyledObjectItem } from 'modules/admin/components/GroupObjectsList';
import List from 'modules/admin/components/List';
import { GroupNameWrapper } from 'modules/admin/components/LoginGroupList';
import { GroupItem } from 'modules/admin/dtos';
import { getGroupsList } from 'modules/admin/queries';
import { ItemName, ItemNameWrapper } from 'modules/driver/components/SpeditionObjectsList';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useWindowSize } from 'utils';

import { SharingTabs } from '../pages/Sharing';
import MobileSharingGroups from './MobileSharingGroups';

export interface SharingProps {
  activeTab: SharingTabs;
  tabs: SingleTab[];
  handleTabClick: (value: string) => void;
  searchInputValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledObjectsList = styled(ObjectsList)`
  margin-bottom: ${({ theme }) => theme.spacing.xLarge};
  height: calc(100vh - 45rem);
  overflow: auto;

  li {
    width: calc(100% - 16px);
  }
`;

const SharingGroups = ({
  searchInputValue,
  handleInputChange,
  activeTab,
  tabs,
  handleTabClick,
}: SharingProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const [isSharingBoxOpen, setIsSharingBoxOpen] = useState<boolean>(false);

  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>();

  const { data: groups } = useQuery(getGroupsList());

  const filteredGroupsList = searchInputValue
    ? groups?.data?.groups.filter(group => group.name.toLowerCase().includes(searchInputValue))
    : groups?.data?.groups;

  const handleSelectedGroupId = (id?: number) => setSelectedGroupId(id);

  return isMobile ? (
    <MobileSharingGroups
      searchInputValue={searchInputValue}
      handleInputChange={handleInputChange}
      groups={filteredGroupsList}
      selectedGroupId={selectedGroupId}
      handleSelectedGroupId={handleSelectedGroupId}
      activeTab={activeTab}
      tabs={tabs}
      handleTabClick={handleTabClick}
    />
  ) : (
    <List
      title={t('addGroup')}
      inputPlaceholder={t('searchGroup')}
      imagePlaceholderText={t('noGroupSelected')}
      searchInputValue={searchInputValue}
      handleInputChange={handleInputChange}
      selectedGroupId={selectedGroupId}
      handleSelectedGroupId={handleSelectedGroupId}
      isSharingBoxOpen={isSharingBoxOpen}
    >
      <StyledObjectsList>
        {(filteredGroupsList as GroupItem[])?.map(group => (
          <StyledObjectItem
            key={group.groupId}
            isMobile={isMobile}
            isActive={false}
            onClick={() => {
              setIsSharingBoxOpen(true);
              handleSelectedGroupId(group.groupId);
            }}
          >
            <ItemNameWrapper>
              <Checkbox checkboxSize={'md'} checked={selectedGroupId === group.groupId} />
              <GroupNameWrapper>
                <GroupColor color={group.color ?? ''} />
                <ItemName>{group.name}</ItemName>
              </GroupNameWrapper>
            </ItemNameWrapper>
          </StyledObjectItem>
        ))}
      </StyledObjectsList>
    </List>
  );
};

export default SharingGroups;

import i18n from 'locales/i18n';

import { isStringNumber } from './isStringNumber';

i18n.loadNamespaces('general');

export const isNumber = (errorMessage?: string) => (value?: string | number) => {
  if (!value) {
    return;
  }

  if (typeof value === 'number') {
    return;
  }

  if (typeof value === 'string' && isStringNumber(value)) {
    return;
  }

  return errorMessage ?? i18n.t('validation.invalidNumber');
};

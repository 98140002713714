import { memo } from 'react';
import styled, { css } from 'styled-components/macro';

const Label = styled.div<{ marginTop?: boolean }>`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin: 0.8rem 0;

  & > span {
    font-weight: 400;
  }

  ${p =>
    p.marginTop &&
    css`
      margin-top: 2.4rem;
    `}

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`;

export default memo(Label);

import { DateKeys } from 'common';
import { ArrowWrapper, DateTimeBoundaryProps, Wrapper } from 'components/DateTimeBoundary';
import DateTimePickerFormField from 'components/DateTimePickerFormField';
import FormField from 'components/FormField';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import { memo, useCallback } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import styled from 'styled-components/macro';

const CustomWrapper = styled(Wrapper)`
  gap: 1.5rem;
  & > div:first-child,
  & > div:last-child {
    display: contents;

    & > div {
      width: 50%;
    }
  }
`;

export type DateTimeBoundaryFormFieldProps = Omit<
  FieldAttributes<DateTimeBoundaryProps>,
  'name'
> & {
  noMargin?: boolean;
  label?: string;
  minStart?: Date;
  maxStart?: Date;
  maxEnd?: Date;
};

const DateTimeBoundaryFormField = ({
  noMargin,
  label,
  fillWidth,
  disableTime,
  maxEnd,
  minStart,
  maxStart,
  ...props
}: DateTimeBoundaryFormFieldProps) => {
  // @ts-ignore
  const [dateFromField, dateFromMeta] = useField({ ...props, name: DateKeys.DATE_FROM });
  const dateFromErrorId = `${dateFromField.name}-error`;
  // @ts-ignore
  const [dateToField, dateToMeta] = useField({ ...props, name: DateKeys.DATE_TO });
  const dateToErrorId = `${dateToField.name}-error`;
  const { setFieldValue } = useFormikContext();

  const handleDateFromChange = useCallback(
    (value: Date) => {
      setFieldValue(DateKeys.DATE_FROM, value);
    },
    [setFieldValue],
  );

  const handleDateToChange = useCallback(
    (value: Date) => {
      setFieldValue(DateKeys.DATE_TO, value);
    },
    [setFieldValue],
  );

  return (
    <CustomWrapper fillWidth={fillWidth}>
      <FormField
        errorId={dateFromErrorId}
        touched={dateFromMeta.touched}
        error={dateFromMeta.error}
        noMargin={noMargin}
        label={label}
      >
        <DateTimePickerFormField
          {...dateFromField}
          min={minStart}
          max={maxStart}
          onDateChange={handleDateFromChange}
          disableTime={disableTime}
        />
      </FormField>
      <ArrowWrapper>
        <MdKeyboardArrowRight />
      </ArrowWrapper>
      <FormField
        errorId={dateToErrorId}
        touched={dateToMeta.touched}
        error={dateToMeta.error}
        noMargin={noMargin}
        label={label}
      >
        <DateTimePickerFormField
          {...dateToField}
          max={maxEnd}
          onDateChange={handleDateToChange}
          disableTime={disableTime}
        />
      </FormField>
    </CustomWrapper>
  );
};

export default memo(DateTimeBoundaryFormField);

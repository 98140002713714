import Skeleton from 'components/Dashboard/Skeleton';
import StyledTable from 'components/StyledTable';

const SharingListSkeleton = () => (
  <>
    {new Array(5).fill(0).map((_, index) => (
      <StyledTable.Row key={index}>
        <StyledTable.Cell>
          <Skeleton h={34} />
        </StyledTable.Cell>
        <StyledTable.Cell>
          <Skeleton h={34} />
        </StyledTable.Cell>
        <StyledTable.Cell>
          <Skeleton h={34} />
        </StyledTable.Cell>
      </StyledTable.Row>
    ))}
  </>
);

export default SharingListSkeleton;

import { routesTranslations } from 'navigation/routesTranslations';
import { memo } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding: 1.2rem 1.6rem;
  height: 7.7rem;
  background: ${p => p.theme.colors.primaryBackground};
`;

const BackIconWrapper = styled.div`
  svg {
    height: 2rem;
    width: auto;
    color: ${p => p.theme.colors.primary400};
  }

  height: 80%;
  padding-right: 1rem;
  border-right: 1px solid ${p => p.theme.colors.separator};

  display: flex;
  align-items: center;
`;

const Title = styled.div`
  margin: auto;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${p => p.theme.colors.text};
`;

const MobileNavigation = ({ location }: { location: string }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <MdKeyboardArrowLeft />
      </BackIconWrapper>
      <Title>{routesTranslations[location] ?? ''}</Title>
    </Wrapper>
  );
};
export default memo(MobileNavigation);

import styled, { css } from 'styled-components/macro';

interface WrapperProps {
  active: boolean;
  noBorderRadius?: boolean;
  small?: boolean;
}

export const Wrapper = styled.button<WrapperProps>`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: ${p => (p.small ? `0.6rem 1rem` : `1.2rem 2rem`)};
  outline: none;
  color: ${({ theme, active }) => (active ? theme.colors.text : theme.colors.gray400)};
  background-color: ${p => (p.active ? p.theme.colors.primaryBackground : `transparent`)};
  font-size: ${p => (p.small ? `1rem` : `1.4rem`)};
  font-weight: 600;
  border: 1px solid ${p => p.theme.colors.gray300};
  cursor: pointer;
  z-index: 1;
  transition: .2s ease;

  &:disabled {
    color: ${({ theme }) => theme.colors.gray300};
    opacity: 0.8;
    cursor: not-allowed;
  }

  ${p =>
    p.active &&
    css`
        border-bottom: 1px solid ${p.theme.colors.primary500};
        overflow: hidden;
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          height: 1px;
          width: 100%;
          background-color: ${p.theme.colors.primary500};
        }
      }
    `}


  ${p =>
    !p.active &&
    css`
      border-bottom: 1px solid ${p.theme.colors.gray300};
    `}

  &:hover {
      border-bottom: 1px solid ${p => p.theme.colors.gray300};
      background-color: ${p => p.theme.colors.primaryBackground};
    }
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }

  &:first-of-type {
    border-left: 1px solid ${({ theme }) => theme.colors.gray300};

    ${p =>
      !p.noBorderRadius &&
      css`
        border-top-left-radius: 0.8rem;
        border-bottom-left-radius: 0.8rem;
      `}
  }

  &:last-of-type {
    ${p =>
      !p.noBorderRadius &&
      css`
        border-top-right-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
      `}
  }
`;

/* eslint-disable react/prop-types */
import Arrow from 'assets/arrow-right.svg';
import LoadingIndicator from 'components/LoadingIndicator';
import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components/macro';
import { useWindowSize } from 'utils';
import { useAppContext } from 'utils/appContext';

import { sharedInputStyles } from '../common';

export type SelectProps = React.HTMLProps<HTMLDivElement> & {
  placeholder?: string;
  selectedOptions: { label: string; value: string }[];
  size?: 'md';
  dark?: boolean;
  icon?: React.ReactElement;
  noBackground?: boolean;
  wrapOptions?: boolean;
  fillWidth?: boolean;
  isLoading?: boolean;
  disableInitialWidth?: boolean;
};

type StyledDivProps = React.HTMLProps<HTMLDivElement>;

type WrapperProps = StyledDivProps & {
  dark?: boolean;
  medium?: boolean;
  noBackground?: boolean;
  width?: number;
  fillWidth?: boolean;
};

type ChipProps = StyledDivProps & { disabled?: boolean };

const Wrapper = styled.div<WrapperProps>`
  ${p => sharedInputStyles(p)};
  padding: 2px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
  ${p =>
    p.width &&
    !p.fillWidth &&
    css`
      width: ${p.width}px;
    `}
    ${p =>
    p.fillWidth &&
    css`
      width: 100%;
    `}
`;

const StyledSelect = styled.div`
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Icon = styled.div<StyledDivProps>`
  transform: rotate(90deg);
  cursor: pointer;

  path {
    fill: ${p => p.theme.colors.primary600};
  }
`;

const ImgIcon = styled.img`
  height: 3rem;
  width: auto;
`;

const LeftIcon = styled.div`
  color: ${p => p.theme.colors.primary600};
  margin-right: 0.5rem;
  svg {
    height: 2rem;
    width: auto;
  }
`;

const Chip = styled.div<ChipProps>`
  display: inline-flex;
  color: ${p => p.theme.colors.white};
  background: ${p => (p.disabled ? p.theme.colors.gray300 : p.theme.colors.gray400)};
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
  white-space: nowrap;
`;

const Select = forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
  const {
    placeholder,
    selectedOptions,
    size,
    dark,
    disabled,
    onClick,
    icon,
    noBackground,
    wrapOptions,
    fillWidth,
    isLoading,
    disableInitialWidth = false,
  } = props;
  const { isMobile } = useWindowSize();
  const selectRef = useRef<HTMLDivElement>(null);
  const [initialWidth, setInitialWidth] = useState<number>(0);
  const { darkMode } = useAppContext();

  useEffect(() => {
    if (!selectRef?.current || disableInitialWidth) return;
    setInitialWidth(selectRef.current.offsetWidth + (isMobile ? 0 : 35));
  }, []);

  const oneOptionLabel = useMemo(
    () => (selectedOptions?.length === 1 ? selectedOptions[0]?.label : false),
    [selectedOptions],
  );

  const showMoreOptions = useMemo(() => {
    if (selectedOptions?.length <= 1) return;

    if (wrapOptions) {
      const shortestLabel = selectedOptions.reduce((acc: string, item) => {
        if (acc.length > item.label.length) {
          return item.label;
        }
        return acc;
      }, selectedOptions[0].label);
      return (
        <>
          <Chip disabled={disabled}>{shortestLabel}</Chip>
          <Chip disabled={disabled}>{`${selectedOptions.length - 1}+`}</Chip>
        </>
      );
    }

    return selectedOptions.map(o => (
      <Chip key={`option_${o.value}`} disabled={disabled}>
        {o.label}
      </Chip>
    ));
  }, [selectedOptions]);

  return (
    <Wrapper
      ref={selectRef}
      medium={size === 'md'}
      dark={dark || darkMode}
      disabled={disabled}
      onClick={onClick}
      noBackground={noBackground}
      width={initialWidth}
      fillWidth={fillWidth}
    >
      {icon && typeof icon === 'string' ? <ImgIcon src={icon} /> : <LeftIcon>{icon}</LeftIcon>}
      <StyledSelect ref={ref}>
        {oneOptionLabel}
        {showMoreOptions}
        {!oneOptionLabel && !showMoreOptions && placeholder}
        {isLoading && <LoadingIndicator small />}
      </StyledSelect>
      <Icon>
        <SVG src={Arrow} width={24} />
      </Icon>
    </Wrapper>
  );
});

export default memo(Select);

import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AvailableLanguagesTypes } from '../../common';
import StringBuilder from '../../utils/stringBuilder';
import {
  StyledCoordinatesCordsWrapper,
  StyledCoordinatesLocationMarker,
  StyledCoordinatesRedirectLink,
  StyledCoordinatesWrapper,
} from './Coordinates.styled';

/**
 * This factory is used to get Google domain by user's language
 * @param language
 */
const googleDomainFactory = (language: AvailableLanguagesTypes | string): string => {
  switch (language) {
    case AvailableLanguagesTypes.pl: {
      return 'https://www.google.pl';
    }

    case AvailableLanguagesTypes.en: {
      return 'https://www.google.com';
    }

    case AvailableLanguagesTypes.de: {
      return 'https://www.google.de';
    }

    default: {
      return 'https://www.google.com';
    }
  }
};

/**
 * This function is used to open Google Maps site in new tab.
 * Sets the starting point of the route.
 * @param lat
 * @param lon
 * @param lang
 */
const handleRedirectToGoogleMaps = (lang: string, lat?: number, lon?: number): string => {
  if (lat === undefined || lon === undefined) return '';

  const sb = new StringBuilder();
  sb.add(googleDomainFactory(lang))
    .add('/maps/dir/')
    .add(lat ?? '')
    .add(',')
    .add(lon ?? '');

  return sb.getText();
};

const Coordinates = ({ lat, lon }: { lat?: number; lon?: number }) => {
  const { i18n, t } = useTranslation();

  /**
   * Renders an icon that redirect to Google Maps
   */
  const renderRedirectIcon = useMemo(() => {
    if (lat === undefined || lon === undefined) return null;

    return (
      <StyledCoordinatesRedirectLink
        href={handleRedirectToGoogleMaps(i18n.language, lat, lon)}
        target={'_blank'}
        rel="noreferrer"
      >
        <StyledCoordinatesLocationMarker cursor={'pointer'} title={t('planRouteInGoogleMaps')} />
      </StyledCoordinatesRedirectLink>
    );
  }, []);

  return (
    <StyledCoordinatesWrapper>
      {renderRedirectIcon}

      <StyledCoordinatesCordsWrapper>
        <div>{lat}</div>
        <div>{lon}</div>
      </StyledCoordinatesCordsWrapper>
    </StyledCoordinatesWrapper>
  );
};

export default memo(Coordinates);

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Status } from 'components/common';
import { memo } from 'react';

import { CloseButton, Wrapper } from './style';

interface Props {
  type?: Status;
  text: string;
  onClose: () => void;
}

const Toast = ({ type = Status.Positive, text, onClose }: Props) => (
  <Wrapper type={type}>
    <span>{text}</span>
    <CloseButton type="button" onClick={onClose}>
      <CloseIcon />
    </CloseButton>
  </Wrapper>
);

export default memo(Toast);

import DateTimePicker, { DateTimePickerProps } from 'components/DateTimePicker';
import FormField from 'components/FormField';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import { memo, useCallback } from 'react';

export type DateTimePickerFormFieldProps = FieldAttributes<
  Omit<DateTimePickerProps, 'onChange'>
> & {
  name: string;
  noMargin?: boolean;
  label?: string;
  width?: string;
  onDateChange?: (value: Date) => void;
};

const DateTimePickerFormField = ({
  noMargin,
  label,
  width,
  onDateChange,
  ...props
}: DateTimePickerFormFieldProps) => {
  const [field, meta] = useField(props);
  const errorId = `${field.name}-error`;
  const { setFieldValue } = useFormikContext();

  const handleOnChange = useCallback(
    (value: Date) => {
      if (onDateChange) {
        onDateChange(value);
        return;
      }

      setFieldValue(field.name, value);
    },
    [field.name, onDateChange],
  );

  return (
    <FormField
      errorId={errorId}
      touched={meta.touched}
      error={meta.error}
      noMargin={noMargin}
      label={label}
      width={width}
    >
      <DateTimePicker {...field} {...props} onChange={handleOnChange} />
    </FormField>
  );
};

export default memo(DateTimePickerFormField);

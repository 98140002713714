import 'tippy.js/dist/tippy.css';

import LoadingIndicator from 'components/LoadingIndicator';
import MainLayout from 'components/MainLayout';
import queryClient from 'lib/react-query';
import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import { ThemeProvider } from 'styles/ThemeProvider';
import { ToastContextProvider } from 'toast';
import { AppContextProvider } from 'utils/AppContextProvider';
import { environment } from 'utils/environment';

import UseNotificationPermissions from '../utils/hooks/useNotificationPermissions';
import AuthContextProvider from './AuthContextProvider';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const { i18n } = useTranslation();

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <ThemeProvider>
            <AuthContextProvider>
              <ToastContextProvider>
                <Suspense fallback={<LoadingIndicator centerVertically />}>
                  <Helmet
                    titleTemplate="%s - Widzisz Wszystko"
                    defaultTitle="Widzisz Wszystko"
                    htmlAttributes={{ lang: i18n.language }}
                  >
                    <meta name="description" content="Widzisz Wszystko" />
                  </Helmet>
                  <GlobalStyle />
                  <UseNotificationPermissions />
                  {environment.development && <ReactQueryDevtools />}
                  <Router>
                    <MainLayout>{children}</MainLayout>
                  </Router>
                </Suspense>
              </ToastContextProvider>
            </AuthContextProvider>
          </ThemeProvider>
        </AppContextProvider>
      </QueryClientProvider>
    </CookiesProvider>
  );
};

import { LoginResponseDto } from 'modules/auth/dtos';
import { createContext, useContext } from 'react';

export type AuthContextProps = {
  loginSuccess: (body: LoginResponseDto) => void;
  isAuthenticated: boolean;
  logout: () => void;
  setIsAuthenticated: (on: boolean) => void;
};

export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  loginSuccess: () => {},
  logout: () => {},
  setIsAuthenticated: () => {},
});

export const useAuth = () => useContext(AuthContext);

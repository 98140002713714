import WWLogo from 'assets/ww-logo-small.svg';
import { AllowedModules } from 'common';
import { getMenuItemData } from 'common/menuItems';
import Button from 'components/Button';
import Flex from 'components/Dashboard/Flex';
import IconButton from 'components/IconButton';
import LanguageSelect from 'components/LanguageSelect';
import MobileNavigation from 'components/MobileNavigation';
import FilterVehicles from 'components/Topbar/FilterVehicles';
import GroupVehicles from 'components/Topbar/GroupVehicles';
import { IconsWrapper, NotificationsIcon, StyledIcon } from 'components/Topbar/style';
import { logout } from 'modules/auth/queries';
import { routes } from 'navigation/routes';
import { memo, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaYoutube } from 'react-icons/fa';
import { MdKeyboardReturn, MdLogout, MdPeople, MdPerson, MdSettings } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { temporarilyHidden, useAuth, useCheckModuleAccess, useUserData } from 'utils';
import useResetMap from 'utils/hooks/useResetMap';
import redirectToExternal from 'utils/redirectToExternal';

import SingleMenuItem from './SingleMenuItem';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1.6rem;
  height: 7.7rem;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
`;

export const Img = styled.img`
  height: 40px;
`;

export const GroupElements = styled.div`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;

const MenuWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 7rem;
  background-color: ${p => p.theme.colors.secondaryBackground};
  overflow: auto;
  z-index: ${p => p.theme.layersOrder.topbar};
  padding-bottom: 70px;
`;

const MenuItemWrapper = styled.div<{ noBorderTop?: boolean; disabled?: boolean }>`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  padding: 1.5rem 4rem;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  color: ${p => p.theme.colors.text};
  ${p =>
    !p.noBorderTop &&
    css`
      border-top: 1px solid ${p.theme.colors.borderGray};
    `}

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
    `}
`;

const Section = styled.div<{ column?: boolean }>`
  padding: 1.2rem 4rem;
  border-bottom: 1px solid ${p => p.theme.colors.borderGray};
  display: flex;
  gap: 1rem;
  align-items: center;

  ${p =>
    p.column &&
    css`
      flex-direction: column;
    `}
`;

const User = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${p => p.theme.colors.text};
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

const menuContainer = document.getElementById('menu-container');

const MobileTopbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();
  const { logout: authLogout } = useAuth();
  const navigate = useNavigate();
  const { mutate } = useMutation(logout());
  const { clientEmail, appModules } = useUserData();
  const checkModuleAccess = useCheckModuleAccess();

  const isObjectsPage = useMemo(() => location.pathname === routes.objects, [location]);

  const handleMenu = () => setShowMenu(!showMenu);

  const handleSettings = useCallback(() => {
    navigate(routes.settings);
    setShowMenu(false);
  }, [navigate]);

  const handleLogout = useCallback(() => {
    authLogout();
    mutate();
  }, [mutate, authLogout]);

  const redirectToOldAppVersion = useCallback(() => {
    window.open(process.env.REACT_APP_OLD_VERSION_URL);
  }, []);

  const { handleRefreshClick } = useResetMap();

  /**
   * Options should be disabled if user have access to PUESC_LIMITED module
   */
  const isSettingsDisabled = useMemo(() => checkModuleAccess(AllowedModules.PUESC_LIMITED), []);

  if (location.pathname.includes(routes.settings)) {
    return <MobileNavigation location={location.pathname} />;
  }

  return (
    <Wrapper>
      <Flex>
        <Img src={WWLogo} onClick={() => navigate(routes.objects)} />
        <IconsWrapper>
          <StyledIcon
            onClick={() => redirectToExternal('https://www.facebook.com/widziszwszystko')}
            title={'Facebook'}
          >
            <FaFacebook />
          </StyledIcon>

          <StyledIcon
            onClick={() => redirectToExternal('https://www.youtube.com/@WidziszWszystko_pl')}
            title={'Youtube'}
          >
            <FaYoutube />
          </StyledIcon>
        </IconsWrapper>
      </Flex>
      {showMenu && (
        <Button
          secondary
          hideArrow
          icon={<MdSettings />}
          onClick={handleSettings}
          disabled={isSettingsDisabled}
        >
          {t('settings.settings')}
        </Button>
      )}

      <GroupElements>
        <NotificationsIcon disabled={temporarilyHidden} />
        <IconButton icon={showMenu ? 'close' : 'menu'} onClick={handleMenu} />
      </GroupElements>
      {showMenu &&
        createPortal(
          <MenuWrapper>
            <Section column>
              <User>
                <MdPerson />
                {clientEmail}
              </User>
              <Flex>
                <LanguageSelect />
                {isObjectsPage && <IconButton icon="refresh" onClick={handleRefreshClick} />}
              </Flex>
            </Section>
            <Section>
              <GroupVehicles />
              <FilterVehicles />
            </Section>

            {/* <--- render menu elements ---> */}
            {appModules.map(appModule => {
              const appModuleData = getMenuItemData(appModule.id);

              return (
                <SingleMenuItem
                  key={appModule.id}
                  name={appModule.id}
                  details={appModuleData}
                  closeMenu={() => setShowMenu(false)}
                  disabled={appModuleData.disabled}
                  subModules={appModule.subModules}
                />
              );
            })}

            {/* <--- additional elements ---> */}
            <MenuItemWrapper onClick={redirectToOldAppVersion}>
              <MdKeyboardReturn />
              {t('returnToOldApp')}
            </MenuItemWrapper>
            <MenuItemWrapper noBorderTop disabled={temporarilyHidden}>
              <MdPeople />
              {t('multilogin')}
            </MenuItemWrapper>
            <MenuItemWrapper onClick={handleLogout} noBorderTop>
              <MdLogout />
              {t('logout')}
            </MenuItemWrapper>
          </MenuWrapper>,
          menuContainer!,
        )}
    </Wrapper>
  );
};

export default memo(MobileTopbar);

import { ReactComponent as VehiclesPlaceholder } from 'assets/vehicles-list-placeholder.svg';
import { NoVehicle, Placeholder } from 'modules/route/components/ObjectsList';
import styled from 'styled-components';

const StyledPlaceHolder = styled(Placeholder)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 90%;
`;

const PlaceHolder = ({ text }: { text: string }) => (
  <StyledPlaceHolder>
    <VehiclesPlaceholder />
    <NoVehicle>{text}</NoVehicle>
  </StyledPlaceHolder>
);

export default PlaceHolder;

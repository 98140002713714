import { axios } from 'lib/axios';

import { CreateIntegrationObjectDto } from '../dtos';
import { DeleteIntegrationObjectDto } from '../dtos/DeleteIntegrationObject.dto';
import { UpdateIntegrationObjectDto } from '../dtos/UpdateIntegrationStatus.dto';
import { endpoints } from '.';

export interface PaginatedListParams {
  page?: number;
}
export const createIntegrationObject = (data: CreateIntegrationObjectDto) =>
  axios.post(endpoints.createIntegrationObject, data.payload).then(res => res.data.data);

/* export const updateIntegrationObject = (data: any) =>
  axios.put(endpoints.updateIntegrationObject(data.type), data.payload).then(res => res.data.data);
*/

export const updateIntegrationStatusObject = (data: UpdateIntegrationObjectDto) =>
  axios
    .post(endpoints.updateIntegrationStatusObject(data.id), { status: data.status })
    .then(res => res.data.data);

export const deleteIntegrationObject = (data: DeleteIntegrationObjectDto) =>
  axios.get(endpoints.deleteIntegrationObject(data.objectId)).then(res => res.data);

export const getIntegrationObjects = () =>
  axios.get(endpoints.getIntegrationObjects).then(res => res.data);

import Checkbox from 'components/Checkbox';
import {
  BadgeSection,
  ObjectsList,
  StyledBadge,
  StyledObjectItem,
} from 'modules/admin/components/GroupObjectsList';
import List from 'modules/admin/components/List';
import { ItemName, ItemNameWrapper } from 'modules/driver/components/SpeditionObjectsList';
import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import { getAllObjects } from 'modules/objects/queries';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { getObjectIcon, useWindowSize } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import MobileSharingObjects from './MobileSharingObjects';
import { SharingProps } from './SharingGroups';

const StyledObjectsList = styled(ObjectsList)`
  margin-bottom: ${({ theme }) => theme.spacing.xLarge};
  height: calc(100vh - 45rem);
  overflow: auto;

  li {
    width: calc(100% - 16px);
  }
`;

const SharingObjects = ({
  searchInputValue,
  handleInputChange,
  activeTab,
  tabs,
  handleTabClick,
}: SharingProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const [isSharingBoxOpen, setIsSharingBoxOpen] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<Object[]>([]);

  const handleSelectedItems = (item: Object) => {
    setIsSharingBoxOpen(true);

    if (selectedItems?.find(obj => obj.objectId === item.objectId)) {
      setSelectedItems(selectedItems?.filter(obj => obj.objectId !== item.objectId));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const resetSelectedItems = () => setSelectedItems([]);

  const objectsIds = selectedItems?.map(el => el.objectId);

  const { data: allObjects, isFetching: isObjectsListFetching } = useQuery(getAllObjects());

  const filteredObjectsList = searchInputValue
    ? allObjects?.data?.filter(object => object.number.toLowerCase().includes(searchInputValue))
    : allObjects?.data;

  return isMobile ? (
    <MobileSharingObjects
      searchInputValue={searchInputValue}
      handleInputChange={handleInputChange}
      objects={filteredObjectsList}
      isObjectsListFetching={isObjectsListFetching}
      handleSelectedItems={handleSelectedItems}
      resetSelectedItems={resetSelectedItems}
      activeTab={activeTab}
      tabs={tabs}
      handleTabClick={handleTabClick}
      objectsIds={objectsIds}
      selectedItems={selectedItems}
    />
  ) : (
    <List
      title={t('addObjects')}
      inputPlaceholder={t('searchVehicle')}
      imagePlaceholderText={t('noVehicleSelected')}
      searchInputValue={searchInputValue}
      handleInputChange={handleInputChange}
      selectedItems={selectedItems}
      resetSelectedItems={resetSelectedItems}
      isSharingBoxOpen={isSharingBoxOpen}
    >
      {selectedItems?.length > 0 && (
        <BadgeSection>
          {selectedItems?.map((item, index) => (
            <StyledBadge key={`selected-item-${index}`} onClick={() => handleSelectedItems(item)}>
              {item.number}
              <span>&#10005;</span>
            </StyledBadge>
          ))}
        </BadgeSection>
      )}
      <StyledObjectsList>
        {filteredObjectsList?.map(obj => (
          <StyledObjectItem
            key={obj.objectId}
            isMobile={isMobile}
            isActive={false}
            onClick={() => {
              handleSelectedItems(obj);
            }}
          >
            <ItemNameWrapper>
              <Checkbox checkboxSize={'md'} checked={objectsIds?.includes(obj.objectId)} />
              <img
                src={getObjectIcon(obj.type)}
                style={{ filter: getObjectFilterColor(obj.status) }}
              />
              <ItemName>
                {obj.number} {obj.comment ? `(${obj.comment})` : null}
              </ItemName>
            </ItemNameWrapper>
          </StyledObjectItem>
        ))}
      </StyledObjectsList>
    </List>
  );
};

export default SharingObjects;

import { axios } from 'lib/axios';
import { UpdateControllsIgnitionDto } from 'modules/objects/dtos/ControllsHistory.dto';

import {
  GeocodingResponseDto,
  ObjectsExtendedDataDto,
  ObjectsExtendedDataResponseDto,
  ObjectsParamsDto,
  ObjectsResponseDto,
  ObjectsResponsePrevDto,
  UpdateObjectDto,
  UpdateObjectVRNDto,
} from '../dtos';
import { GeocodingParams } from '../types';
import { endpoints } from '.';

export const getObjects = (params?: ObjectsParamsDto) =>
  axios
    .get<ObjectsResponseDto>(
      endpoints.object,
      params ? { params: { ...params, ...(params.group && { type: 'group' }) } } : {},
    )
    .then(res => ({
      ...res.data,
      data: res.data.data.map((element, index) => ({ ...element, listIndex: index + 1 })),
    }))
    .catch(e => e.response.data);

export const getObjectsWithPrevious = async (
  params?: ObjectsParamsDto,
  previous?: ObjectsResponseDto | ObjectsResponsePrevDto,
) => {
  const { data } = await axios.get<ObjectsResponseDto>(
    endpoints.object,
    params ? { params: { ...params, ...(params.group && { type: 'group' }) } } : {},
  );

  const dataWithIndex = {
    ...data,
    data: data.data.map((element, index) => ({ ...element, listIndex: index + 1 })),
  };

  return {
    previous: (previous as ObjectsResponsePrevDto)?.current || previous,
    current: dataWithIndex,
  };
};

export const getObjectsExtendedData = (data: ObjectsExtendedDataDto) =>
  axios
    .post<ObjectsExtendedDataResponseDto>(endpoints.extendedData, data)
    .then(res => res.data)
    .catch(e => e.response.data);

export const getGeocoding = (params: GeocodingParams) =>
  axios
    .get<GeocodingResponseDto>(endpoints.getGeocoding, { params })
    .then(res => res.data)
    .catch(e => e.response.data);

export const getVehiclesTypes = () =>
  axios
    .get(endpoints.vehiclesTypes)
    .then(res => res.data.data)
    .catch(e => e.response.data);

export const updateCommentAndVehicle = (body: UpdateObjectDto) =>
  axios
    .post(endpoints.updateObject, body)
    .then(res => res.data)
    .catch(e => e.response.data);

export const updateObjectWithVRN = (body: UpdateObjectVRNDto) =>
  axios.put(endpoints.updateObjectWithVRN(body.objectId), body).then(res => res.data);

export const getRoadCard = (objectId: string) =>
  axios
    .get(endpoints.getRoadCard(objectId))
    .then(res => res.data)
    .catch(e => e.response.data);

export const getIgnitionControllsHistory = (objectId: string) =>
  axios
    .get(endpoints.getIgnitionControllsHistory(objectId))
    .then(res => res.data)
    .catch(e => e.response.data);

export const updateIgnitionControlls = (body: UpdateControllsIgnitionDto) =>
  axios
    .put(endpoints.updateIgnitionControlls(String(body.objectId)), { state: body.state })
    .then(res => res.data)
    .catch(e => e.response.data);

import { axios } from 'lib/axios';

import { ChangePasswordDto, UserSettingsDto } from '../dtos';
import { endpoints } from '.';

export const updateSettings = (body: UserSettingsDto) =>
  axios
    .post(endpoints.userSettings, body)
    .then(res => res.data)
    .catch(e => e.response.data);

export const changePassword = (body: ChangePasswordDto) =>
  axios
    .post(endpoints.changePassword, body)
    .then(res => res.data)
    .catch(e => e.response);

import Tippy from '@tippyjs/react';
import { DateFormat } from 'common';
import Desc from 'components/Dashboard/Desc';
import Flex from 'components/Dashboard/Flex';
import IconBox from 'components/Dashboard/IconBox';
import StyledTable from 'components/StyledTable';
import { ShareListItemDto } from 'modules/sharing/dtos/ShareList.dto';
import { deleteListItem, setListItem } from 'modules/sharing/queries';
import { routes } from 'navigation/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoTrashBin } from 'react-icons/io5';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
// import { RiMessage3Fill } from 'react-icons/ri';
import { useMutation } from 'react-query';
import dateF from 'utils/dateF';

interface SharingListItemProps {
  data: ShareListItemDto;
}

/**
 * This function redirects to the preview object page
 * @param hash
 */
const redirectToPreview = (hash: string) => {
  window.open(routes.sharePreviewLink(hash), '_blank');
};

const SharingListItem: React.FC<SharingListItemProps> = ({ data }) => {
  const { mutate: deleteItem, isLoading: isItemDeleting } = useMutation(deleteListItem());
  const { mutate: setItemState, isLoading: isItemSetting } = useMutation(setListItem());
  const { t } = useTranslation();

  return (
    <StyledTable.Row key={data.id}>
      <StyledTable.Cell>
        {!!data?.group && (
          <Flex gap={5}>
            <h3>{data?.group}</h3> <Desc>• {t('group')}</Desc>
          </Flex>
        )}

        {data.objects?.map(object => (
          <Flex key={object.objectId} gap={5}>
            <h3>{object?.number}</h3> <Desc>• {object?.comment}</Desc>
          </Flex>
        ))}

        <Tippy content={t('showLocation')}>
          <IconBox className={'mt-1'} onClick={() => redirectToPreview(data.hash)}>
            <FaMapMarkerAlt />
          </IconBox>
        </Tippy>
      </StyledTable.Cell>

      <StyledTable.Cell>
        <Desc>• {dateF(data.dateStart, DateFormat)}</Desc>
        <Desc>* {dateF(data.dateEnd, DateFormat)}</Desc>
      </StyledTable.Cell>

      <StyledTable.Cell>
        <Flex gap={5}>
          {/* <Tippy content={t('notifications')}>
            <IconBox p={20} size={1.5}>
              <RiMessage3Fill />
            </IconBox>
          </Tippy> */}

          <Tippy content={t(data?.enable ? 'hide' : 'show')}>
            <IconBox
              p={20}
              size={1.5}
              disabled={isItemSetting}
              onClick={() => setItemState({ enable: !data?.enable, id: data.id })}
            >
              {data?.enable ? <MdVisibility /> : <MdVisibilityOff />}
            </IconBox>
          </Tippy>

          <Tippy content={t('delete')}>
            <IconBox
              p={20}
              size={1.5}
              disabled={isItemDeleting}
              onClick={() => deleteItem({ id: data.id })}
            >
              <IoTrashBin />
            </IconBox>
          </Tippy>
        </Flex>
      </StyledTable.Cell>
    </StyledTable.Row>
  );
};

export default SharingListItem;

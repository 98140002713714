import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as DocsIcon } from 'assets/icons/docs.svg';
import Button from 'components/Button';
import MobileModal from 'components/MobileModal/MobileModal';
import { Overlay } from 'components/Overlay';
import { routes } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme }) => `${theme.spacing.large} 25%`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
`;

export const StyledButtonWrapper = styled(ButtonWrapper)`
  border: none;
  padding-top: ${({ theme }) => theme.spacing.xSmall};
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.regular};
  align-items: center;
  justify-content: center;
`;

const AddAttachement = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePhoto = () => {
    onClose();
    navigate(routes.driverCamera);
  };

  const handleGallery = () => {
    onClose();
    navigate(routes.driverGallery);
  };

  return (
    <>
      <Overlay />
      <MobileModal noPadding>
        <ButtonWrapper>
          <Button secondary fillWidth onClick={handlePhoto}>
            <ButtonContent>
              <CameraIcon /> {t('takePhoto')}
            </ButtonContent>
          </Button>
          <Button secondary fillWidth onClick={handleGallery}>
            <ButtonContent>
              <DocsIcon />
              {t('documents')}
            </ButtonContent>
          </Button>
        </ButtonWrapper>
        <StyledButtonWrapper>
          <Button secondary fillWidth onClick={onClose}>
            {t('goBack')}
          </Button>
        </StyledButtonWrapper>
      </MobileModal>
    </>
  );
};

export default AddAttachement;

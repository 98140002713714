import { ReactComponent as ToggleIcon } from 'assets/icons/toggle.svg';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import { Title } from 'modules/driver/pages/Spedition';
import {
  IsTypingResponse,
  MessagesData,
  MessagesResponse,
  ReceiversData,
  ReceiversResponse,
  socket,
} from 'modules/driver/queries/socket';
import { ButtonWrapper } from 'modules/objects/components/EditForm';
import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IoMdSend } from 'react-icons/io';
import { MdAccountCircle } from 'react-icons/md';
import { useUserData } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import ImageDocEnlarged from '../ImageDocEnlarged';
import MessageItem from '../MessageItem';
import { ToggleButton } from '../SpeditionChatDesktop';
import { InputWrapper } from '../SpeditionChatMobile/style';
import {
  Circle,
  Container,
  DriverInfo,
  DriversName,
  Label,
  MessageContent,
  MessageItemContainer,
  MessagesWrapper,
  StyledInput,
  StyledTextArea,
  TextAreaWrapper,
  TopMessageWrapper,
  TopWrapper,
  VehicleInfo,
  VehiclesNumber,
} from './style';

interface CurrentChatProps {
  driversName: string;
  message?: ReceiversData;
  handleChat: () => void;
  currentChat: MessagesData[];
  handleCurrentChat: (messages: MessagesData[]) => void;
  objectData?: Object;
  handleDriversName: (id: number, name: string) => void;
  handleMessagesReceived: (response: ReceiversData[]) => void;
  messageClickedId: number;
}

const CurrentChat = ({
  driversName,
  handleChat,
  currentChat,
  handleCurrentChat,
  objectData,
  handleDriversName,
  handleMessagesReceived,
  messageClickedId,
}: CurrentChatProps) => {
  const { t } = useTranslation();
  const { clientEmail } = useUserData();

  const [isDriverNameModalOpen, setIsDriverNameModalOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [driverInputValue, setDriverInputValue] = useState<string>(driversName);
  const [isDriverTyping, setIsDriverTyping] = useState<boolean>(false);
  const [imageClickedUrl, setImageClickedUrl] = useState<string | undefined>();

  const handleImageClicked = (url: string) => setImageClickedUrl(url);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => setDriverInputValue(driversName), [driversName]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  const ref = useRef<HTMLTextAreaElement>(null);

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      ref.current.style.height = `${e.target.scrollHeight - 16}px`;
    }
  };

  const handleNameEdit = () => setIsDriverNameModalOpen(prev => !prev);

  useEffect(() => {
    socket.on('client:message', response => {
      handleCurrentChat(currentChat.concat(response.messages));
    });

    socket.on('client:typing', (response: IsTypingResponse) => {
      if (response.id !== messageClickedId) return;

      setIsDriverTyping(response.isTyping);
    });
  }, [currentChat]);

  useEffect(() => {
    socket.emit('server:messages', { id: messageClickedId }, (response: MessagesResponse) => {
      handleCurrentChat(response.messages);
    });
  }, []);

  const sendMessage = (content: string) => {
    if (!messageClickedId) return;

    socket.emit('server:message', { content: content, id: messageClickedId }, () => {
      setInputValue('');
    });

    socket.emit('server:receivers', (response: ReceiversResponse) => {
      handleMessagesReceived(response.receivers);
    });
  };

  const handleEditDriversName = () => {
    handleDriversName(messageClickedId, driverInputValue);
    handleNameEdit();
  };

  const handleTyping = (id: number, isTyping: boolean) => {
    socket.emit('server:typing', { id, isTyping: isTyping });
  };

  let timeout: NodeJS.Timeout | undefined;

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>, id: number) => {
    if (e.key !== 'Enter') {
      handleTyping(id, true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleTyping(id, false);
      }, 3000);
    } else {
      handleTyping(id, false);
    }
  };

  const handleSendMessage = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      sendMessage(inputValue);
    } else handleKeyPress(e, messageClickedId);
  };

  return (
    <Container>
      <TopWrapper>
        <VehicleInfo>
          <img
            src={
              require(`assets/vehicles/${objectData?.type ? objectData?.type : 'car'}.svg`).default
            }
            style={{ filter: getObjectFilterColor(objectData?.status ?? 1) }}
          />
          <VehiclesNumber>{objectData?.number ?? ''}</VehiclesNumber>
        </VehicleInfo>

        <DriverInfo>
          <MdAccountCircle color="red" size={24} style={{ marginLeft: '12px' }} />
          <DriversName>{driversName}</DriversName>
          <IconButton width={'32px'} icon="pencil" onClick={handleNameEdit} />
        </DriverInfo>
        <ToggleButton type="button" onClick={handleChat} isChatOpen>
          <ToggleIcon />
        </ToggleButton>
      </TopWrapper>
      <MessagesWrapper>
        {currentChat?.map(message => (
          <>
            <MessageItem
              message={message}
              objectData={objectData}
              isMobile={false}
              speditorName={clientEmail}
              handleImageClicked={handleImageClicked}
            />
            <div ref={messagesEndRef} />
          </>
        ))}
        {isDriverTyping && (
          <MessageItemContainer>
            <Circle>
              <MdAccountCircle size={24} color={'#F44542'} />
            </Circle>
            <div>
              <TopMessageWrapper>
                <VehiclesNumber>{driversName}</VehiclesNumber>
              </TopMessageWrapper>
              <MessageContent>{t('isTyping') + '...'}</MessageContent>
            </div>
          </MessageItemContainer>
        )}
      </MessagesWrapper>
      <TextAreaWrapper>
        <StyledTextArea
          ref={ref}
          onChange={e => {
            setInputValue(e.target.value);
          }}
          onKeyDown={e => handleSendMessage(e)}
          name={'message'}
          placeholder={t('enterMessage')}
          onInput={handleInput}
          value={inputValue}
        />
        <IoMdSend color={'rgb(244, 69, 66)'} size={24} onClick={() => sendMessage(inputValue)} />
      </TextAreaWrapper>

      {isDriverNameModalOpen && (
        <Modal size="small" isOpen={isDriverNameModalOpen} onClose={handleNameEdit}>
          <Title>{t('driversUpdate')}</Title>
          <InputWrapper>
            <Label htmlFor="driversName">{t('driversNameAndSurname')}</Label>
            <StyledInput
              name="driversName"
              onChange={e => setDriverInputValue(e.target.value)}
              value={driverInputValue}
            />
          </InputWrapper>
          <ButtonWrapper>
            <Button secondary size="sm" onClick={handleNameEdit}>
              {t('goBack')}
            </Button>
            <Button primary size="sm" onClick={handleEditDriversName}>
              {t('edit')}
            </Button>
          </ButtonWrapper>
        </Modal>
      )}
      {!!imageClickedUrl &&
        createPortal(
          <ImageDocEnlarged url={imageClickedUrl} onClose={() => handleImageClicked('')} />,
          document.getElementById('modal-container')!,
        )}
    </Container>
  );
};

export default CurrentChat;

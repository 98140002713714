import React, { useCallback, useMemo, useState } from 'react';
import { FaSort, FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import styled from 'styled-components';

const StyledIcon = styled.button`
  all: unset;
  padding: ${p => p.theme.spacing.xSmall};
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: ${p => p.theme.colors.secondaryBackground};
  cursor: pointer;
  transition: 0.2s ease;
  display: grid;
  place-items: center;
  color: ${p => p.theme.colors.textGray};

  :hover {
    background-color: ${p => p.theme.colors.primaryBackground};
  }
`;

enum Directions {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

function useSorting<T>(data: T[]): {
  sortedData: T[];
  requestSort: (path: string, key: string) => void;
  sortConfig: { path: string; direction: string; key: string | null };
  sortingIcon: (type: string) => React.ReactNode;
} {
  const [sortConfig, setSortConfig] = useState<{
    path: string;
    direction: Directions | string;
    key: string | null;
  }>({
    path: '',
    direction: '',
    key: null,
  });

  /**
   * This function is used to get the value of the object by key string
   * ex: integration.sent.last
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getObjectValueByKey = (obj: any, key: string): any => {
    const keys = key.split('.');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value: any = obj;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
      if (!value) {
        break;
      }
    }
    return value ?? '';
  };

  /**
   * Returns sorted data based on sortConfig
   */
  const sortedData = useMemo(() => {
    const sortableData = [...data];

    if (!sortConfig.path) return data;

    if (sortConfig.path) {
      sortableData.sort((a, b) => {
        if (getObjectValueByKey(a, sortConfig.path) < getObjectValueByKey(b, sortConfig.path)) {
          return sortConfig.direction === Directions.ASCENDING ? -1 : 1;
        }
        if (getObjectValueByKey(a, sortConfig.path) > getObjectValueByKey(b, sortConfig.path)) {
          return sortConfig.direction === Directions.ASCENDING ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableData;
  }, [data, sortConfig]);

  /**
   * This function is used to request sort by path
   */
  const requestSort = (path: string, key: string) => {
    // default direction is ascending
    let configuredDirection: string = Directions.ASCENDING;
    let configuredPath: string = path;
    let configuredKey: string | null = key;

    // if key is same and direction is ascending then change direction to descending
    if (sortConfig.path === path && sortConfig.direction === Directions.ASCENDING) {
      configuredDirection = Directions.DESCENDING;
    }

    if (sortConfig.path === path && sortConfig.direction === Directions.DESCENDING) {
      configuredDirection = '';
      configuredPath = '';
      configuredKey = null;
    }

    setSortConfig({ path: configuredPath, direction: configuredDirection, key: configuredKey });
  };

  /**
   * This is a sorting icon based on sortConfig
   */
  const sortingIcon = useCallback(
    (iconKey: string) => {
      if (sortConfig.key !== iconKey) {
        return (
          <StyledIcon>
            <FaSort fontSize={'small'} />
          </StyledIcon>
        );
      }

      switch (sortConfig.direction) {
        case Directions.ASCENDING:
          return (
            <StyledIcon>
              <FaSortAmountUp fontSize={'small'} />
            </StyledIcon>
          );

        case Directions.DESCENDING:
          return (
            <StyledIcon>
              <FaSortAmountDown fontSize={'small'} />
            </StyledIcon>
          );

        default:
          return (
            <StyledIcon>
              <FaSort fontSize={'small'} />
            </StyledIcon>
          );
      }
    },
    [sortConfig.direction, sortConfig.key],
  );

  return { sortedData, requestSort, sortConfig, sortingIcon };
}

export default useSorting;

import { Marker as GoogleMarker } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import RouteMarkerIcon from 'assets/route-marker.svg';
import { AllowedModules } from 'common';
import { ObjectStatus } from 'components/common/ObjectStatus';
import MainLayoutContext from 'components/MainLayout/context/MainLayoutContext';
import { statusElementType } from 'modules/driver/dtos';
import { getObjectStatusColor } from 'modules/objects/components/ObjectItem/style';
import { memo, useContext, useMemo } from 'react';
import { useTheme } from 'styled-components/macro';
import { useCheckModuleAccess } from 'utils';

import { MarkerProps as SingleMarker } from '.';
import InfoBoxComponent from './InfoBoxComponent';
import { refillingIconPath } from './refillingIconPath';

type IconTransformationData = {
  rotation?: number;
  icon?: string;
  color?: string;
};

export const applyIconTransformation = ({ icon, rotation, color }: IconTransformationData) => {
  const preventRotation = icon?.includes('preventRotation=true');
  const sheet = document.getElementById('stylesheet-transformations');
  if (!sheet) return;

  const css = `img[src="${icon}"] { transform: rotate(${
    preventRotation ? 0 : rotation
  }deg); filter: ${color}; }`;

  sheet.innerHTML = sheet.innerHTML + css;
};

export enum MarkerIconTypes {
  STOP = 'STOP',
  REFILLING = 'REFILLING',
  CIRCLE = 'CIRCLE',
  START = 'START',
}

type MarkerProps = Omit<SingleMarker, 'icon'> & {
  clusterer?: Clusterer;
  hideMarkerInfo?: boolean;
  icon?: string | MarkerIconTypes;
  label?: string;
  driverStatuses?: statusElementType[];
};

const Marker = ({
  position,
  icon,
  objectId,
  title,
  rotation,
  color,
  status,
  clusterer,
  onClick,
  hideMarkerInfo,
  label,
  driverStatuses,
}: MarkerProps) => {
  const { colors } = useTheme();
  const { setIsInfoboxVisible, statusFilters } = useContext(MainLayoutContext);
  const isObjectMoving = status === ObjectStatus.MOVING;
  const checkModuleAccess = useCheckModuleAccess();
  const isDriverStatusAccount = checkModuleAccess(AllowedModules.DRIVER_STATUS);

  const markerIcon = useMemo(() => {
    if (icon === MarkerIconTypes.CIRCLE) {
      return {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: colors.primary600,
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: colors.primary600,
        scale: 6,
        color: '#fff',
      };
    }

    if (icon === MarkerIconTypes.REFILLING) {
      return {
        path: refillingIconPath,
        fillColor: '#3cb371',
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#333',
        scale: 0.4,
        anchor: new google.maps.Point(18, 18),
        labelOrigin: new google.maps.Point(25, 50),
      };
    }

    if (icon === MarkerIconTypes.STOP) {
      return {
        url: RouteMarkerIcon,
        anchor: new google.maps.Point(15, 15),
      };
    }

    if (icon === MarkerIconTypes.START) {
      return {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: colors.primary600,
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: colors.primary600,
        scale: 10,
        color: '#fff',
      };
    }

    if (icon) {
      return {
        url: icon,
        size: new google.maps.Size(36, 36),
        anchor: new google.maps.Point(18, 18),
      };
    }

    return {
      path: isObjectMoving
        ? google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        : google.maps.SymbolPath.CIRCLE,
      scale: isObjectMoving ? 3.5 : 5,
      rotation,
      fillColor: status ? getObjectStatusColor(status) : undefined,
      fillOpacity: 1,
      strokeWeight: 1,
    };
  }, [icon, isObjectMoving, rotation, status]);

  const handleOnMarkerClick = () => {
    onClick?.(objectId);
    setIsInfoboxVisible(true);
  };

  const isMarkerVisible = driverStatuses?.length
    ? driverStatuses?.find(status => statusFilters?.includes(status.label) && status.state === 1)
    : true;

  return (
    <GoogleMarker
      position={position}
      icon={markerIcon}
      title={objectId?.toString()}
      onLoad={() => {
        applyIconTransformation({ icon, rotation, color });
      }}
      onIconChanged={() => {
        icon && applyIconTransformation({ icon, rotation, color });
      }}
      onClick={handleOnMarkerClick}
      clusterer={clusterer}
      label={
        label && {
          text: label ?? '',
          color: '#fff',
          fontSize: '10px',
        }
      }
      visible={isDriverStatusAccount ? !!isMarkerVisible : true}
    >
      {!clusterer && !hideMarkerInfo && (
        <InfoBoxComponent
          position={position}
          handleOnMarkerClick={handleOnMarkerClick}
          title={title}
          status={status}
          driverStatuses={driverStatuses}
          isMarkerVisible={isDriverStatusAccount ? !!isMarkerVisible : true}
        />
      )}
    </GoogleMarker>
  );
};

export default memo(Marker);

import { InfoBox } from '@react-google-maps/api';
import { MapTypes } from 'common';
import MainLayoutContext from 'components/MainLayout/context/MainLayoutContext';
import { memo, useContext } from 'react';
import { useMainLayout } from 'utils';

import ObjectInfoContent from './ObjectInfoContent';

export enum ObjectInfoTypes {
  STOP = 'STOP',
  REFILLING = 'REFILLING',
  DEFAULT = 'DEFAULT',
  ROUTE = 'ROUTE',
}

export type ObjectInfoProps = {
  objectId: number;
  location?: {
    lat: number;
    lng: number;
  };
  onClose?: () => void;
  type?: ObjectInfoTypes;
  fuelData?: string;
  date?: string;
  duration?: string;
  stopTime?: string;
};

const ObjectInfo = (props: ObjectInfoProps) => {
  const { mapType } = useMainLayout();
  const { isInfoboxVisible } = useContext(MainLayoutContext);

  if (mapType === MapTypes.GOOGLE && props.location) {
    return (
      <InfoBox
        position={
          { lat: props.location.lat, lng: props.location.lng } as unknown as google.maps.LatLng
        }
        options={{
          boxStyle: {
            boxShadow: '13px -14px 24px -20px rgba(66, 68, 90, 1)',
          },
          closeBoxURL: '',
          enableEventPropagation: true,
          alignBottom: false,
          zIndex: 100,
          visible: isInfoboxVisible,
        }}
      >
        <ObjectInfoContent {...props} />
      </InfoBox>
    );
  }

  return null;
};

export default memo(ObjectInfo);

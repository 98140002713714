import { AllowedModules } from 'common';
import LoadingIndicator from 'components/LoadingIndicator';
import styled from 'styled-components';
import { useCheckModuleAccess } from 'utils';

import { statusElementType } from '../dtos';
import StatusTile from './StatusTile';

export const StatusListWrapper = styled.div`
  display: flex;
  gap: 1.3rem;
  flex-direction: column;
  width: 100%;
`;

interface StatusListProps {
  statusList?: statusElementType[];
  isEditable?: boolean;
  isStatusFetching?: boolean;
  handleStatusItemClick?: (e: React.MouseEvent<HTMLElement>) => void;
  handleToggle: (val: number | string) => void;
}

const StatusList = ({
  statusList,
  isEditable,
  isStatusFetching,
  handleStatusItemClick,
  handleToggle,
}: StatusListProps) => {
  const checkModuleAccess = useCheckModuleAccess();
  const isDriverStatusAccount = checkModuleAccess(AllowedModules.DRIVER_STATUS);

  return (
    <StatusListWrapper>
      {isStatusFetching && <LoadingIndicator />}
      {!isStatusFetching &&
        statusList?.map(({ color, state, label, id, secondaryColor }) => (
          <StatusTile
            key={id}
            color={color}
            secondaryColor={secondaryColor}
            isOn={!!state}
            label={label}
            isEditable={isEditable}
            onClickHandler={
              isDriverStatusAccount
                ? () => handleToggle(label)
                : (e: React.MouseEvent<HTMLElement>) => handleStatusItemClick?.(e)
            }
            handleToggle={handleToggle}
            id={id}
            state={state}
          />
        ))}
    </StatusListWrapper>
  );
};

export default StatusList;

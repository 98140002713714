import { HttpError, LINK_HASH, SHARE_LIST } from 'common';
import { Status } from 'components/common';
import queryClient from 'lib/react-query';
import { ObjectsResponseDto } from 'modules/objects/dtos';
import {
  DeleteListItemDto,
  SetListItemDto,
  ShareListResponseDto,
} from 'modules/sharing/dtos/ShareList.dto';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions, useQueryClient, UseQueryOptions } from 'react-query';
import { useToast } from 'toast';

import { requests } from '../api';
import { ShareLinkDataDto } from '../dtos/ShareLink.dto';

export const shareGroup = (
  config: UseMutationOptions<unknown, HttpError<unknown>, ShareLinkDataDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    mutationFn: requests.share,
    ...config,
    onSuccess: (data: ShareLinkDataDto) => {
      showToast({ content: t('shareLinkSuccess') });
      queryClient.setQueryData(LINK_HASH, data);
      queryClient.invalidateQueries(SHARE_LIST);
    },
    onError: () => showToast({ content: t('shareLinkFailure'), type: Status.Negative }),
  };
};

export const getShareList = (
  config: UseQueryOptions<ShareListResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [SHARE_LIST],
  queryFn: () => requests.getShareList(),
  ...config,
});

export const getSharedPreview = (
  hash: string,
  config: UseQueryOptions<ObjectsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [SHARE_LIST, { hash }],
  queryFn: () => requests.getSharedPreview(hash),
  ...config,
});

export const deleteListItem = (
  config: UseMutationOptions<unknown, HttpError<unknown>, DeleteListItemDto> = {},
) => ({
  mutationFn: requests.deleteItem,
  onSuccess: () => {
    queryClient.invalidateQueries(SHARE_LIST);
  },
  ...config,
});

export const setListItem = (
  config: UseMutationOptions<unknown, HttpError<unknown>, SetListItemDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.setItem,
    ...config,
    onSuccess: (data: SetListItemDto) => {
      queryClient.setQueryData([SHARE_LIST], (prev: unknown) => {
        const oldData = prev as ShareListResponseDto;

        if (!oldData) return oldData;

        return oldData.map(item => (item.id === data.id ? { ...item, enable: data.enable } : item));
      });
      showToast({ content: t('changeItemStatusSuccessInfo') });
    },
  };
};

import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const getWindowSize = () => ({
    width: window.outerWidth,
    height: window.outerHeight,
  });

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => setWindowSize(getWindowSize());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...windowSize, isMobile: windowSize.width <= 768 };
};

import { memo, useCallback, useMemo } from 'react';
import { useAppContext } from 'utils/appContext';

import { Pagination as Pages, PaginationDetails, PaginationItem, PaginationWrapper } from './style';

const maxDisplayPageCount = 5;

type PaginationProps = {
  pageIndex: number;
  pageCount: number;
  onPageChange?: (page: number) => void;
  goToPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  hideOnSinglePage?: boolean;
};

const Pagination = ({
  pageIndex,
  pageCount,
  onPageChange,
  goToPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  hideOnSinglePage = false,
}: PaginationProps) => {
  const { darkMode } = useAppContext();

  const hidePagination = useMemo(
    () => pageCount === 1 && hideOnSinglePage,
    [pageCount, hideOnSinglePage],
  );

  const pagesData = useMemo(() => {
    const maxPagesBeforeCurrentPage = Math.floor(maxDisplayPageCount / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxDisplayPageCount / 2) - 1;
    const currentPage = pageIndex + 1;
    const totalPagesArray = [...Array(pageCount).keys()].map(i => i + 1);

    if (pageCount <= maxDisplayPageCount) return totalPagesArray;

    if (maxPagesBeforeCurrentPage >= currentPage) {
      return totalPagesArray.splice(0, maxDisplayPageCount);
    }

    if (currentPage + maxPagesAfterCurrentPage >= pageCount) {
      return totalPagesArray.splice(pageCount - maxDisplayPageCount, pageCount);
    }

    return totalPagesArray.splice(currentPage - 1 - maxPagesBeforeCurrentPage, maxDisplayPageCount);
  }, [pageCount, pageIndex]);

  const goToFirstPage = useCallback(() => {
    onPageChange?.(1);
    goToPage(0);
  }, [goToPage, onPageChange]);

  const goToPreviousPage = useCallback(() => {
    onPageChange?.(pageIndex - 1);
    previousPage();
  }, [previousPage, pageIndex, onPageChange]);

  const goToNextPage = useCallback(() => {
    onPageChange?.(pageIndex + 1);
    nextPage();
  }, [nextPage, pageIndex, onPageChange]);

  const goToLastPage = useCallback(() => {
    onPageChange?.(pageCount - 1);
    goToPage(pageCount - 1);
  }, [goToPage, onPageChange, pageCount]);

  const goToSelectedPage = useCallback(
    (page: number) => {
      const selectedIndex = page - 1;
      onPageChange?.(selectedIndex);
      goToPage(selectedIndex);
    },
    [goToPage, onPageChange],
  );

  if (hidePagination) return null;

  return (
    <PaginationWrapper>
      <Pages>
        <PaginationItem onClick={goToFirstPage} disabled={!canPreviousPage} dark={darkMode}>
          {'<<'}
        </PaginationItem>
        <PaginationItem onClick={goToPreviousPage} disabled={!canPreviousPage} dark={darkMode}>
          {'<'}
        </PaginationItem>
        {pagesData.map(page => (
          <PaginationItem
            key={`paginationItem-${page}`}
            isActive={page === pageIndex + 1}
            onClick={() => goToSelectedPage(page)}
          >
            {page}
          </PaginationItem>
        ))}
        <PaginationItem onClick={goToNextPage} disabled={!canNextPage} dark={darkMode}>
          {'>'}
        </PaginationItem>
        <PaginationItem onClick={goToLastPage} disabled={!canNextPage} dark={darkMode}>
          {'>>'}
        </PaginationItem>
      </Pages>
      <PaginationDetails>
        {pageIndex + 1} / {pageCount}
      </PaginationDetails>
    </PaginationWrapper>
  );
};

export default memo(Pagination);

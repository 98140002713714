import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Circle } from '../CurrentChat/style';

export const ListWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: ${({ theme }) => theme.colors.white};
  padding: 1.5rem;
  margin-top: 0.5rem;
  border-radius: 6px;
  gap: ${({ theme }) => theme.spacing.large};
  overflow: auto;
  height: 80vh;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const TopList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small};
`;

export const ChatItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
  overflow: auto;
`;

export const FormWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small};
`;

export const ChatListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
  overflow-y: scroll;
`;

export const MobileCircle = styled(Circle)`
  width: 36px;
  height: 36px;
`;

import { SelectItem } from 'components/common';
import SearchDropdown from 'components/Dropdown/SearchDropdown';
import { ObjectsResponseDto, ObjectsResponsePrevDto } from 'modules/objects/dtos';
import { getGroupObjects } from 'modules/objects/queries';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components/macro';
import { getObjectIcon, useMainLayout, useWindowSize } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

const Wrapper = styled.div`
  position: relative;
`;

const Count = styled.div`
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;

  font-size: 1.2rem;
  font-weight: 600;
  color: ${p => p.theme.colors.white};
  background: ${p => p.theme.colors.primary600};
  height: 2rem;
  width: 2rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: ${p => p.theme.shadows.xl};
`;

const FilterVehicles = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const { objectsGroupId, filteredObjectIds, setFilterdObjectIds, setFitMapBounds } =
    useMainLayout();
  const { data } = useQuery(getGroupObjects(objectsGroupId));

  const vehiclesOptions = useMemo(
    () =>
      (
        (data as ObjectsResponseDto)?.data ||
        (data as ObjectsResponsePrevDto)?.current?.data ||
        []
      ).map(item => ({
        icon: (
          <img
            src={getObjectIcon(item.type)}
            style={{ filter: getObjectFilterColor(item.status) }}
          />
        ),
        label: '',
        labels: [item.number, item.comment],
        value: item.objectId?.toString(),
      })),
    [data],
  );

  const handleVehiclesFilter = useCallback((items: SelectItem[]) => {
    if (!setFilterdObjectIds) return;
    const itemsIds = items.reduce(
      (acc, i) => ({
        ...acc,
        [i.value]: true,
      }),
      {},
    );

    setFilterdObjectIds(itemsIds);
    setFitMapBounds(true);
  }, []);

  const values = useMemo(
    () => vehiclesOptions.filter(i => filteredObjectIds?.includes(i.value)),
    [filteredObjectIds, vehiclesOptions],
  );

  return (
    <Wrapper>
      <SearchDropdown
        fillWidth
        wrapOptions
        iconButton="car"
        showSelectedInfo
        items={vehiclesOptions}
        columns={[t('name'), t('description')]}
        onChange={handleVehiclesFilter}
        titleInnerBox={t('selectCarsToShow')}
        placeholderText={t('selectVehicles')}
        values={values}
        fullScreen={isMobile}
      />

      {!!values.length && <Count>{values.length}</Count>}
    </Wrapper>
  );
};

export default memo(FilterVehicles);

import { MarkerProps } from 'components/Map';
import { ObjectsParamsDto } from 'modules/objects/dtos';
import { getObjects } from 'modules/objects/queries';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getObjectIcon, useWindowSize } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import SpeditionChatDesktop from '../components/SpeditionChatDesktop';
import SpeditionChatMobile from '../components/SpeditionChatMobile';
import { getStatusList } from '../queries';
import {
  IsTypingResponse,
  MessagesData,
  MessagesResponse,
  ReceiversData,
  ReceiversResponse,
  socket,
} from '../queries/socket';

const SpeditionChat = () => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const [searchInputValue, setSearchInputValue] = useState<string | undefined>('');
  const [isChatOpen, setIsChatOpen] = useState<boolean>(true);
  const [messagesReceived, setMessagesReceived] = useState<ReceiversData[]>([]);
  const [messagesReceivedUpdated, setMessagesReceivedUpdated] = useState<ReceiversData[]>([]);
  const [messageClicked, setMessageClicked] = useState<ReceiversData>(messagesReceived[0]);
  const [currentChat, setCurrenChat] = useState<MessagesData[]>([]);
  const [driversName, setDriversName] = useState<string>(t('driversNameAndSurname'));
  const [IsTypingResponse, setIsTypingResponse] = useState<IsTypingResponse>();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const handleChat = () => setIsChatOpen(prev => !prev);

  const handleCurrentChat = (messages: MessagesData[]) => setCurrenChat(messages);

  const handleMessagesReceived = (response: ReceiversData[]) => setMessagesReceived(response);

  const handleMessageClicked = (data: ReceiversData) => {
    setMessageClicked(data);

    if (!data.Message) setCurrenChat([]);

    socket.emit('server:messages', { id: data.Account.id }, (response: MessagesResponse) => {
      setCurrenChat(response.messages);
    });
  };

  const handleDriversName = (id: number, name: string) => {
    socket.emit('server:receiver:rename', { id, name }, () => {
      setDriversName(name);
    });
  };

  const objectIds = messagesReceived.map(mess => mess.Account.object.objectId);

  const objectsParams = useMemo(() => {
    if (!objectIds.length) return undefined;

    return {
      ...(objectIds.length && { 'object-filtering': [...objectIds] }),
    };
  }, [objectIds]);

  const { data } = useQuery(
    getObjects(objectsParams as ObjectsParamsDto, { refetchInterval: 60000 }),
  );
  const { data: status } = useQuery(getStatusList());

  useEffect(() => {
    socket.connect();

    socket.emit('server:receivers', (response: ReceiversResponse) => {
      setMessagesReceived(response.receivers);
      setMessageClicked(response.receivers[0]);
    });

    socket.on('client:message', response => {
      handleCurrentChat(currentChat.concat(response.messages));
    });

    socket.on('client:receivers:update', () => {
      socket.emit('server:receivers', (response: ReceiversResponse) => {
        setMessagesReceivedUpdated(response.receivers);
      });
    });

    return () => {
      socket.off('server:receivers');
      socket.off('client:message');
      socket.off('client:receivers:update');
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      socket.emit(
        'server:messages',
        { id: messagesReceived[0]?.Account.id },
        (response: MessagesResponse) => {
          handleCurrentChat(response.messages);
        },
      );
    }
    socket.on('client:typing', (response: IsTypingResponse) => {
      setIsTypingResponse(response);
    });

    return () => {
      socket.off('client:typing');
    };
  }, [messagesReceived]);

  useEffect(() => {
    setDriversName(messageClicked?.Account.account.name);
  }, [messageClicked]);

  const markers = useMemo(() => {
    if (!data?.data || !messageClicked?.Account.object.objectId) return [];

    const selectedMarker = data?.data
      ?.map(object => ({
        position: { lat: object.last.y, lng: object.last.x },
        objectId: object.objectId,
        title: object.number,
        icon: `${getObjectIcon(object.type)}?key=${0}`,
        rotation: object.last.angle,
        status: object.status,
        color: getObjectFilterColor(object.status),
        driverStatuses: status?.data,
      }))
      .find(marker => marker.objectId === messageClicked?.Account.object.objectId);

    if (selectedMarker) {
      return [selectedMarker];
    }

    return [];
  }, [data, status, messageClicked]);

  return isMobile ? (
    <SpeditionChatMobile
      searchInputValue={searchInputValue}
      handleInputChange={handleInputChange}
      messagesReceived={messagesReceivedUpdated.length ? messagesReceivedUpdated : messagesReceived}
      handleMessagesReceived={handleMessagesReceived}
      messageClicked={messageClicked}
      objectsData={data?.data}
      handleMessageClicked={handleMessageClicked}
      currentChat={currentChat}
      handleCurrentChat={handleCurrentChat}
      driversName={driversName}
      handleDriversName={handleDriversName}
      isTypingResponse={IsTypingResponse}
    />
  ) : (
    <SpeditionChatDesktop
      searchInputValue={searchInputValue}
      handleInputChange={handleInputChange}
      isChatOpen={isChatOpen}
      messagesReceived={messagesReceivedUpdated.length ? messagesReceivedUpdated : messagesReceived}
      objectsData={data?.data}
      driversName={driversName}
      handleDriversName={handleDriversName}
      handleChat={handleChat}
      currentChat={currentChat}
      handleCurrentChat={handleCurrentChat}
      handleMessagesReceived={handleMessagesReceived}
      messageClicked={messageClicked}
      handleMessageClicked={handleMessageClicked}
      markers={markers as MarkerProps[]}
      isTypingResponse={IsTypingResponse}
    />
  );
};

export default SpeditionChat;

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/group.svg';
import { ReactComponent as LoginIcon } from 'assets/icons/login.svg';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AddGroupItem from './AddGroupItem';
import { AddButton } from './DesktopGroupList';
import { StyledGroupName } from './DesktopLoginsList';
import { GroupObjectsWrapper, ItemContainer, ObjectsNumber, Section } from './GroupItem';
import { StyledBadge } from './GroupObjectsList';
import { LoginsListProps } from './LoginsList';
import { Container, MobileGroupListWrapper, StyledTopWrapper } from './MobileGroupList';

interface MobileLoginsListProps extends LoginsListProps {
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchInputValue: string;
}

const StyledItemContainer = styled(ItemContainer)`
  gap: 8px;
`;

const StyledSection = styled(Section)`
  gap: 8px;
`;

const MobileLoginsList = ({
  logins,
  handleSelectedLoginId,
  selectedLoginId,
  handleLoginForm,
  handleInputChange,
  searchInputValue,
}: MobileLoginsListProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledTopWrapper>
        <SpeditionSearchInput
          searchInputValue={searchInputValue}
          handleInputChange={e => handleInputChange?.(e)}
          placeholder={t('searchLogin')}
        />
        <AddButton icon={<AddIcon />} onClick={handleLoginForm} />
      </StyledTopWrapper>
      <MobileGroupListWrapper>
        {logins?.map(login => (
          <StyledItemContainer
            isActive={selectedLoginId === login.loginId}
            onClick={() => handleSelectedLoginId?.(login.loginId)}
            key={login.loginId}
          >
            <StyledSection>
              <LoginIcon />
              <StyledGroupName>{login.user}</StyledGroupName>
              <StyledBadge>@kandk</StyledBadge>
            </StyledSection>
            <Section>
              <GroupObjectsWrapper>
                <ObjectsNumber>{login.groups.length}</ObjectsNumber>
                <GroupIcon />
              </GroupObjectsWrapper>
            </Section>
          </StyledItemContainer>
        ))}
      </MobileGroupListWrapper>
      <AddGroupItem addButtonText={t('addLogin')} clickHandler={handleLoginForm} />
    </Container>
  );
};

export default MobileLoginsList;

import MockAdapter from 'axios-mock-adapter';
import { axios } from 'lib/axios';

import { environment } from '../environment';

// we want to provide mockedApi only while testing
// as unknown as MockAdapter is trick to omit typechecking
export const apiMock: MockAdapter = environment.test
  ? new MockAdapter(axios, { delayResponse: 20 })
  : (undefined as unknown as MockAdapter);

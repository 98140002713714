import { HttpError, INTEGRATION_SAMSUNG } from 'common';
import { Status } from 'components/common';
import { routes } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions, useQueryClient, UseQueryOptions } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'toast';

import { requests } from '../api';
import { CreateIntegrationObjectDto } from '../dtos';
import { DeleteIntegrationObjectDto } from '../dtos/DeleteIntegrationObject.dto';
import { IntegrationObjectsListResponseDto } from '../dtos/IntegrationObjectResponse.dto';
import { UpdateIntegrationObjectDto } from '../dtos/UpdateIntegrationStatus.dto';

interface ErrorType {
  status: number;
  logged: boolean;
  message: string;
}
export const createIntegrationObject = (
  config: UseMutationOptions<unknown, HttpError<ErrorType>, CreateIntegrationObjectDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return {
    mutationFn: requests.createIntegrationObject,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries(INTEGRATION_SAMSUNG);
      showToast({ content: t('createIntegrationObjectSuccess') });
      navigate(routes.samsungIntegrationList);
    },
    onError: (err: HttpError<ErrorType>) => {
      showToast({
        content:
          err.response?.data?.status === 409
            ? t('duplicateObject')
            : t('createIntegrationObjectFailure'),
        type: Status.Negative,
      });
    },
  };
};

export const getIntegrationObjects = (
  config: UseQueryOptions<IntegrationObjectsListResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [INTEGRATION_SAMSUNG],
  queryFn: () => requests.getIntegrationObjects(),
  ...config,
});

export const deleteIntegrationObject = (
  config: UseMutationOptions<unknown, HttpError<unknown>, DeleteIntegrationObjectDto> = {},
) => ({
  mutationFn: requests.deleteIntegrationObject,
  ...config,
});

export const updateIntegrationStatusObject = (
  config: UseMutationOptions<unknown, HttpError<unknown>, UpdateIntegrationObjectDto> = {},
) => ({
  mutationFn: requests.updateIntegrationStatusObject,
  ...config,
});

import { InfoBox } from '@react-google-maps/api';
import { AllowedModules } from 'common';
import MainLayoutContext from 'components/MainLayout/context/MainLayoutContext';
import { memo, useContext } from 'react';
import styled from 'styled-components/macro';
import { useCheckModuleAccess } from 'utils';

import { MarkerProps } from '.';
import { InfoBoxContent } from './InfoBoxContent';

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-bottom: 1.5rem;
`;

const Tail = styled.div`
  height: 1.2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 2.5rem;

  &::after {
    background: ${p => p.theme.colors.gray600};
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    content: '';
    height: 1.2rem;
    left: 0;
    position: absolute;
    top: -0.1rem;
    width: 2.5rem;
  }
`;

const ObjectData = styled.div`
  background: ${p => p.theme.colors.gray600};
  color: ${p => p.theme.colors.white};
  font-weight: 600;
  font-size: 1.3rem;
  padding: 0.8rem;
  border-radius: 6px;
  white-space: nowrap;

  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

type ClusterInfoBoxProps = {
  position?: google.maps.LatLng;
  markers: MarkerProps[];
};

const ClusterInfoBox = ({ position, markers }: ClusterInfoBoxProps) => {
  if (!position) return null;
  const { setIsInfoboxVisible, vehiclesNameShown, statusFilters } = useContext(MainLayoutContext);

  const checkModuleAccess = useCheckModuleAccess();
  const isDriverStatusAccount = checkModuleAccess(AllowedModules.DRIVER_STATUS);

  const handleObjectClick = (props: MarkerProps) => {
    props.onClick?.(props.objectId ?? 0);
    setIsInfoboxVisible(true);
  };

  const notPairedMarkers = markers.filter(marker => !marker.driverStatuses?.length);

  const pairedAndFilteredMarkers = markers
    .filter(marker => marker.driverStatuses?.length)
    .filter(el =>
      el.driverStatuses?.find(
        status => statusFilters?.includes(status.label) && status.state === 1,
      ),
    );

  const driverStatusMarkers = [...notPairedMarkers, ...pairedAndFilteredMarkers];

  const allMarkers = isDriverStatusAccount ? driverStatusMarkers : markers;

  return (
    <InfoBox
      position={position}
      options={{
        closeBoxURL: '',
        enableEventPropagation: true,
        disableAutoPan: true,
        boxClass: 'infoBox-cluster',
        visible: vehiclesNameShown,
      }}
    >
      <Content>
        {allMarkers.map((props: MarkerProps) => (
          <ObjectData key={props.objectId} onClick={() => handleObjectClick(props)}>
            <InfoBoxContent
              title={props.title}
              status={props.status}
              driverStatuses={props.driverStatuses ?? []}
            />
          </ObjectData>
        ))}
        <Tail />
      </Content>
    </InfoBox>
  );
};

export default memo(ClusterInfoBox);

import { ObjectInfoTypes } from 'components/ObjectInfo';
import dayjs from 'dayjs';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Coordinates } from '../../../common';
import { Status } from '../../../components/common';
import { useToast } from '../../../toast';
import {
  getEndOfCurrentDay,
  getStartOfCurrentDay,
  useQueryParams,
  useVariants,
} from '../../../utils';
import { ObjectsParamsDto } from '../../objects/dtos';
import { Object } from '../../objects/dtos/ObjectsResponse.dto';
import { getObjects } from '../../objects/queries';
import { AnimateValues, RouteClickModes, RouteItem } from '../common';
import { MobileViewVariants } from '../common/MobileViewVariants';
import { RouteDetailsRow } from '../dtos';
import { getRouteDetails } from '../queries';
import { RouteContext } from './RouteContext';

const RouteContextProvider = ({ children }: { children: ReactNode }) => {
  // Items are handled this way, because useQueryClient doesn't update when used in ObjectsList
  const [selectedItems, setSelectedItems] = useState<RouteItem[]>([]);
  const [objectDetailsId, setObjectDetailsId] = useState<number>();
  const [selectedObjectData, setSelectedObjectData] = useState<Object>();
  const [checkedVehicles, setCheckedVehicles] = useState<string[]>([]);
  const [zoomObjectId, setZoomObjectId] = useState<number>();
  const [collapse, setCollapse] = useState(false);
  const [timeInterval, setTimeInterval] = useState<number>(1);
  const [animateType, setAnimateType] = useState<AnimateValues>(AnimateValues.STOP);
  const [animateIndex, setAnimateIndex] = useState<number>();
  const [routeClickMode, setRouteClickMode] = useState<RouteClickModes>(RouteClickModes.POINT);
  const [selectedFragmentIndex, setSelectedFragmentIndex] = useState<number | [number, number]>();
  const [showRouteSettings, setShowRouteSettings] = useState(false);
  const [showCharts, setShowCharts] = useState(false);
  const [showStops, setShowStops] = useState<boolean>(false);
  const [chartRouteZoom, setChartRouteZoom] = useState<Coordinates>();
  const [markerInfo, setMarkerInfo] = useState<
    { index: number; type: ObjectInfoTypes } | undefined
  >();

  const {
    queryParams: { objectId, objectName, start: startParam, end: endParam },
  } = useQueryParams();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const objectsParams = useMemo(() => {
    if (!objectId) return undefined;

    return {
      ...(objectId && { 'object-filtering': [objectId] }),
    };
  }, [objectId]);

  const { data: objectData } = useQuery(getObjects(objectsParams as ObjectsParamsDto));

  const [viewVariant, setViewVariant] = useVariants<MobileViewVariants>(MobileViewVariants.HALF);

  useEffect(() => {
    if (objectId) setObjectDetailsId(+objectId);
    if (objectId && objectData) setSelectedObjectData(objectData.data[0]);
  }, [objectData, objectId]);

  const selectedData = useMemo(
    () => selectedItems.find(i => +i.params.objectId === objectDetailsId),
    [objectDetailsId, selectedItems],
  );

  const routeDetailsParams = useMemo(() => {
    if (!selectedData) {
      return {
        start: (startParam as string) ?? getStartOfCurrentDay(),
        end: (endParam as string) ?? getEndOfCurrentDay(),
      };
    }

    const {
      params: { start, end, filtering, maxSpeed, minSpeed },
    } = selectedData;

    return { start, end, filtering, maxSpeed, minSpeed };
  }, [selectedData]);

  const { data, isFetched } = useQuery(
    getRouteDetails((objectDetailsId ?? '').toString(), routeDetailsParams, {
      enabled: !!objectDetailsId && (!!selectedData || !!objectId),
    }),
  );

  useEffect(() => {
    if (!objectName) return;
    if (isFetched && !data?.data) {
      setObjectDetailsId(undefined);
      showToast({
        content: t('noDataFoundFor', { data: objectName }),
        type: Status.Warning,
      });
    }
  }, [data]);

  const detailsData = useMemo(() => {
    if (!objectDetailsId || !data || !Array.isArray(data.data)) return [];

    if (!timeInterval) return data.data;

    return data.data?.reduce((acc: RouteDetailsRow[], row: RouteDetailsRow) => {
      const previousRow = acc[acc.length - 1];

      if (!previousRow) return [row];

      const previousDate = dayjs(previousRow?.message.gpsTime);
      const currentDate = dayjs(row.message.gpsTime);

      if (previousRow && currentDate.diff(previousDate, 'second') >= timeInterval) {
        return [...acc, row];
      }

      return acc;
    }, []);
  }, [objectDetailsId, timeInterval, data?.data]);

  return (
    <RouteContext.Provider
      value={{
        setObjectDetailsId,
        objectDetailsId,
        selectedObjectData,
        setSelectedObjectData,
        selectedItems,
        setSelectedItems,
        checkedVehicles,
        setCheckedVehicles,
        zoomObjectId,
        setZoomObjectId,
        collapse,
        setCollapse,
        timeInterval,
        setTimeInterval,
        animateType,
        setAnimateType,
        animateIndex,
        setAnimateIndex,
        detailsData,
        routeClickMode,
        setRouteClickMode,
        selectedFragmentIndex,
        setSelectedFragmentIndex,
        showRouteSettings,
        setShowRouteSettings,
        showCharts,
        setShowCharts,
        showStops,
        setShowStops,
        chartRouteZoom,
        setChartRouteZoom,
        viewVariant,
        setViewVariant,
        markerInfo,
        setMarkerInfo,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

export default RouteContextProvider;

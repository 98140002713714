import SearchDropdown, { SearchDropdownProps } from 'components/Dropdown/SearchDropdown';
import FormField from 'components/FormField';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import { memo, useCallback } from 'react';

type SearchDropdownFormFieldProps = FieldAttributes<SearchDropdownProps> & {
  name: string;
  noMargin?: boolean;
  label?: string;
};

const SearchDropdownFormField = ({ noMargin, label, ...props }: SearchDropdownFormFieldProps) => {
  const [field, meta] = useField(props);
  const errorId = `${field.name}-error`;
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    value => {
      if (setFieldValue) {
        setFieldValue(field.name, value);
        return;
      }

      props.onChange?.(value);
    },
    [setFieldValue, field],
  );

  return (
    <FormField
      errorId={errorId}
      touched={meta.touched}
      error={meta.error}
      noMargin={noMargin}
      label={label}
    >
      <SearchDropdown {...field} {...props} onChange={handleChange} />
    </FormField>
  );
};

export default memo(SearchDropdownFormField);

import { io } from 'socket.io-client';

export interface ReceiversData {
  Account: {
    id: number;
    object: {
      comment: string;
      number: string;
      objectId: number;
      type: string;
      status: number;
    };
    account: {
      loginId: number;
      name: string;
      speditorAccount: boolean;
    };
  };
  Message: null | {
    id: number;
    accountId: number;
    name: string;
    document: string;
    content: string;
    isRead: boolean;
    isSpeditorAccount: boolean;
    createdAt: string;
    updatedAt: string;
  };
  Statistics: {
    count: number;
  };
}

export interface ReceiversResponse {
  receivers: ReceiversData[];
}

export interface MessagesData {
  Data: {
    account: {
      loginId: number;
      name: string;
      speditorAccount: boolean;
    };
    id: number;
    object: {
      number: string;
      comment: string;
      objectId: number;
      status: number;
      type: string;
    };
  };
  Message: {
    document: null | string;
    id: number;
    accountId: number;
    content: string;
    isRead: boolean;
    isSpeditorAccount: boolean;
    createdAt: string;
    updatedAt: string;
    name: string;
  };
}

export interface MessagesResponse {
  messages: MessagesData[];
}

export interface IsTypingResponse {
  id: number;
  isTyping: boolean;
}

export const socket = io({
  path: '/rest-api/messenger/io',
  autoConnect: false,
  reconnection: false,
});

/**
 * @sockets - reserved
 */

socket.on('disconnect', reason => {
  console.log('on: disconnect:', { reason });
});

socket.on('connect_error', err => {
  console.log('on: connect_error:', err.message);
});

socket.on('error:client', data => {
  console.log('on: error:client:', { data });
});

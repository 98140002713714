import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import LoadingIndicator from 'components/LoadingIndicator';
import Tabs, { SingleTab } from 'components/Tabs';
import { StyledObjectItem } from 'modules/admin/components/GroupObjectsList';
import { StyledObjectsList } from 'modules/admin/components/MobileObjectsList';
import { ItemName, ItemNameWrapper } from 'modules/driver/components/SpeditionObjectsList';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getObjectIcon } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import { SharingTabs } from '../pages/Sharing';
import {
  SharingStyledTabsWrapper,
  SharingStyledTopWrapper,
  StyledContainer,
  TabsStyledTopWrapper,
} from './MobileSharingGroups';
import SharingBox from './SharingBox';

interface MobileSharingObjectsProps {
  searchInputValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  objects?: Object[];
  handleSelectedItems?: (object: Object) => void;
  activeTab: SharingTabs;
  tabs: SingleTab[];
  handleTabClick: (value: string) => void;
  isObjectsListFetching: boolean;
  objectsIds: number[];
  selectedItems: Object[];
  resetSelectedItems: () => void;
}

const MobileSharingObjects = ({
  searchInputValue,
  handleInputChange,
  objects,
  handleSelectedItems,
  activeTab,
  tabs,
  handleTabClick,
  isObjectsListFetching,
  objectsIds,
  selectedItems,
  resetSelectedItems,
}: MobileSharingObjectsProps) => {
  const { t } = useTranslation();
  const [isSharingBoxOpen, setIsSharingBoxOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsSharingBoxOpen(false);
  };

  if (isSharingBoxOpen) {
    return (
      <SharingBox
        selectedItems={selectedItems}
        closeModal={closeModal}
        resetSelectedItems={resetSelectedItems}
      />
    );
  }

  return (
    <>
      <TabsStyledTopWrapper>
        <SharingStyledTabsWrapper isMobile>
          <Tabs options={tabs} activeTab={activeTab} onClick={handleTabClick} />
        </SharingStyledTabsWrapper>
      </TabsStyledTopWrapper>
      <StyledContainer>
        <SharingStyledTopWrapper style={{ height: '3rem' }}>
          <SpeditionSearchInput
            searchInputValue={searchInputValue}
            handleInputChange={e => handleInputChange?.(e)}
            placeholder={t('searchVehicle')}
          />
        </SharingStyledTopWrapper>
        <StyledObjectsList isNewGroup={!objects?.length}>
          {isObjectsListFetching ? (
            <LoadingIndicator />
          ) : (
            objects?.map(object => (
              <StyledObjectItem
                key={object.objectId}
                isMobile
                isActive={false}
                onClick={() => handleSelectedItems?.(object)}
              >
                <ItemNameWrapper>
                  <Checkbox checkboxSize={'md'} checked={objectsIds?.includes(object.objectId)} />
                  <img
                    src={getObjectIcon(object.type)}
                    style={{ filter: getObjectFilterColor(object.status) }}
                  />
                  <ItemName>{object.number}</ItemName>
                </ItemNameWrapper>
              </StyledObjectItem>
            ))
          )}
        </StyledObjectsList>
        <Button
          style={{ marginBottom: '20px' }}
          fillWidth
          primary
          disabled={!objectsIds.length}
          onClick={() => setIsSharingBoxOpen(true)}
        >
          {t('select')}
        </Button>
      </StyledContainer>
    </>
  );
};

export default MobileSharingObjects;

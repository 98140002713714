import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import { useTranslation } from 'react-i18next';
import { MdAccountCircle } from 'react-icons/md';
import styled from 'styled-components';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import { MessagesData } from '../queries/socket';
import { DateTime } from './ChatListItem';
import { VehiclesNumber } from './CurrentChat/style';
import { prepareTime } from './utils';

export const MessageItemWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.large};
  padding-right: ${({ theme }) => theme.spacing.large};
  width: 100%;
`;

export const Circle = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: ${({ isMobile }) => (isMobile ? '36px' : '56px')};
  height: ${({ isMobile }) => (isMobile ? '36px' : '56px')};
  padding: ${({ theme }) => theme.spacing.small};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.secondaryBackgroundDark};
`;

export const TopMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
`;

export const MessageContent = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  width: 100%;
  word-break: break-all;
`;

const ImageDoc = styled.div<{ url: string }>`
  width: 200px;
  height: 150px;
  background: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  background-position: center;
  cursor: pointer;
`;

interface MessageItemProps {
  message: MessagesData;
  objectData?: Object;
  isMobile: boolean;
  speditorName: string;
  handleImageClicked?: (url: string) => void;
}

const MessageItem = ({
  message,
  objectData,
  isMobile,
  speditorName,
  handleImageClicked,
}: MessageItemProps) => {
  const { t } = useTranslation();

  return (
    <MessageItemWrapper key={message.Message.id}>
      <Circle isMobile={isMobile}>
        {message?.Data?.account.speditorAccount ? (
          <MdAccountCircle size={24} color={'#F44542'} />
        ) : (
          <img
            src={
              require(`assets/vehicles/${objectData?.type ? objectData?.type : 'car'}.svg`).default
            }
            style={{
              height: '32px',
              width: '32px',
              filter: getObjectFilterColor(objectData ? objectData.status : 1),
            }}
          />
        )}
      </Circle>
      <div>
        <TopMessageWrapper>
          <VehiclesNumber>
            {message.Data?.account.speditorAccount ? speditorName : message.Message.name}
          </VehiclesNumber>
          <DateTime>{prepareTime(message.Message.updatedAt, t)}</DateTime>
        </TopMessageWrapper>
        {message.Message.document ? (
          <ImageDoc
            url={message.Message.document}
            onClick={() => handleImageClicked?.(message.Message.document || '')}
          />
        ) : (
          <MessageContent>{message.Message.content}</MessageContent>
        )}
      </div>
    </MessageItemWrapper>
  );
};

export default MessageItem;

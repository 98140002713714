import { axios } from 'lib/axios';
import { DeleteListItemDto, SetListItemDto } from 'modules/sharing/dtos/ShareList.dto';

import { ShareLinkDataDto } from '../dtos/ShareLink.dto';
import { endpoints } from './endpoints';

export const share = (data: ShareLinkDataDto) =>
  axios.post(endpoints.share, data).then(res => res.data.data);

export const getShareList = () => axios.get(endpoints.share).then(res => res.data.data);

export const deleteItem = (data: DeleteListItemDto) =>
  axios.delete(endpoints.shareItem(data.id)).then(res => res.data);

export const setItem = (data: SetListItemDto) =>
  axios
    .put(endpoints.shareItem(data.id), { data: { enable: data.enable } })
    .then(res => res.data.data);

export const getSharedPreview = (hash: string) =>
  axios.get(endpoints.shareItem(hash)).then(res => res.data);

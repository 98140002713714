import { ObjectStatus } from 'components/common/ObjectStatus';
import { statusElementType } from 'modules/driver/dtos';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import StatusDot from './StatusDot';

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  min-width: 90px;
`;

export const StatusDotList = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledStatusDot = styled(StatusDot)`
  cursor: pointer;
`;

export const StatusDotWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
`;

export const StatusLabel = styled.p<{ isLabelShown: boolean; label: string }>`
  font-size: 1rem;
  overflow: hidden;
  text-wrap: break-word;
  text-overflow: ellipsis;
  position: relative;
  width: 0;
  transition: width 1s linear;
  ${p =>
    p.isLabelShown &&
    p.label &&
    css`
      width: 100px;
    `}
`;

interface InfoBoxContentProps {
  status?: ObjectStatus;
  title?: string;
  driverStatuses: statusElementType[];
}

export const InfoBoxContent = ({ status, title, driverStatuses }: InfoBoxContentProps) => {
  const { t } = useTranslation();

  const [statusShown, setStatusShown] = useState<number | null>(null);

  const isDriverStatusActive = !!driverStatuses.length;

  if (!isDriverStatusActive) {
    return (
      <>
        <StatusDot status={status} /> {title}
      </>
    );
  } else {
    return (
      <StatusWrapper>
        {title}
        <StatusDotList>
          {driverStatuses
            .filter(status => status.state)
            .map(status => (
              <StatusDotWrapper key={status.id}>
                <StyledStatusDot
                  status={status.color}
                  onMouseOver={() => setStatusShown(status.id)}
                  onMouseLeave={() => setStatusShown(null)}
                />

                <StatusLabel label={status.label} isLabelShown={statusShown === status.id}>
                  {t(status.label)}
                </StatusLabel>
              </StatusDotWrapper>
            ))}
        </StatusDotList>
      </StatusWrapper>
    );
  }
};

export default InfoBoxContent;

import { IntegrationObject } from 'modules/integrations/dtos/IntegrationObjectResponse.dto';

export enum Columns {
  VRN = 'vrn',
  STATUS = 'status',
  INTEGRATIONDATE = 'integrationDate',
  ACTIONS = 'actions',
}

export type IntegratedObjectsRow = {
  id: number;
  vrn: string;
  integrationDate: string;
  status: string;
  actions: IntegrationObject;
};

export type DataProps = {
  cell: {
    value: string;
  };
  row: {
    original: IntegratedObjectsRow;
  };
};

export interface ModalState {
  activateObject: boolean;
  deactivateObject: boolean;
  removeObject: boolean;
}

export const initialModalState = {
  activateObject: false,
  deactivateObject: false,
  removeObject: false,
};

import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import MobileModal from 'components/MobileModal/MobileModal';
import { Overlay } from 'components/Overlay';
import { ButtonWrapper } from 'modules/objects/components/EditForm';
import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import { routes } from 'navigation/routes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdSend } from 'react-icons/io';
import { MdAccountCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUserData } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import {
  IsTypingResponse,
  MessagesData,
  MessagesResponse,
  ReceiversData,
  ReceiversResponse,
  socket,
} from '../../queries/socket';
import ChatListItem from '../ChatListItem';
import {
  Label,
  MessageContent,
  MessageItemContainer,
  StyledInput,
  StyledTextArea,
  TextAreaWrapper,
  TopMessageWrapper,
  VehiclesNumber,
} from '../CurrentChat/style';
import MessageItem from '../MessageItem';
import { MobileTitle } from '../SpeditionMobile';
import { ListTitle, ListWrapper } from '../SpeditionObjectsList';
import SpeditionSearchInput from '../SpeditionSearchInput';
import {
  ChatItemsWrapper,
  ChatListContainer,
  FormWrapper,
  InputWrapper,
  MessageWrapper,
  MobileCircle,
  TopList,
} from './style';

const StyledListWrapper = styled(ListWrapper)`
  justify-content: end;
  height: 90%;
`;

interface SpeditionChatMobileProps {
  searchInputValue?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  messagesReceived: ReceiversData[];
  handleMessagesReceived: (response: ReceiversData[]) => void;
  messageClicked: ReceiversData;
  objectsData?: Object[];
  handleMessageClicked: (data: ReceiversData) => void;
  currentChat: MessagesData[];
  driversName: string;
  handleDriversName: (id: number, name: string) => void;
  isTypingResponse?: IsTypingResponse;
  handleCurrentChat: (messages: MessagesData[]) => void;
}

const SpeditionChatMobile = ({
  searchInputValue,
  handleInputChange,
  messagesReceived,
  handleMessagesReceived,
  messageClicked,
  objectsData,
  handleMessageClicked,
  currentChat,
  handleCurrentChat,
  driversName,
  handleDriversName,
  isTypingResponse,
}: SpeditionChatMobileProps) => {
  const { t } = useTranslation();
  const { clientEmail } = useUserData();
  const navigate = useNavigate();

  const [showMobileModal, setShowMobileModal] = useState<boolean>(false);
  const [showCurrentChat, setShowCurrentChat] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>('');
  const [driverInputValue, setDriverInputValue] = useState<string>(driversName);
  const [isDriverTyping, setIsDriverTyping] = useState<boolean>(false);

  const handleNameEdit = () => {
    setShowMobileModal(prev => !prev);
  };

  useEffect(() => {
    socket.on('client:message', response => {
      handleCurrentChat(currentChat.concat(response.messages));
    });

    socket.on('client:typing', (response: IsTypingResponse) => {
      if (response.id !== messageClicked.Account.id) return;

      setIsDriverTyping(response.isTyping);
    });
  }, [currentChat]);

  useEffect(() => {
    socket.emit(
      'server:messages',
      { id: messageClicked?.Account.id },
      (response: MessagesResponse) => {
        handleCurrentChat(response.messages);
      },
    );
  }, []);

  const listItems = searchInputValue
    ? messagesReceived.filter(msg =>
        msg.Account.object.number.toLowerCase().includes(searchInputValue.toLocaleLowerCase()),
      )
    : messagesReceived;

  const objectData = objectsData?.find(
    obj => obj.objectId === messageClicked?.Account.object.objectId,
  );

  const handleEditDriversName = () => {
    handleDriversName(currentChat[0].Data.id, driverInputValue);
    handleNameEdit();
  };

  useEffect(() => setDriverInputValue(driversName), [driversName]);

  const sendMessage = () => {
    if (!messageClicked.Account.id) return;

    socket.emit('server:message', { content: inputValue, id: messageClicked.Account.id }, () => {
      setInputValue('');
    });

    socket.emit('server:receivers', (response: ReceiversResponse) => {
      handleMessagesReceived(response.receivers);
    });
  };

  const handleTyping = (id: number, isTyping: boolean) => {
    socket.emit('server:typing', { id, isTyping: isTyping });
  };

  let timeout: NodeJS.Timeout | undefined;

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>, id: number) => {
    if (e.key !== 'Enter') {
      handleTyping(id, true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleTyping(id, false);
      }, 3000);
    } else {
      handleTyping(id, false);
    }
  };

  const handleSendMessage = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      sendMessage();
    } else handleKeyPress(e, messageClicked.Account.id);
  };

  const handleShowCurrentChat = () => setShowCurrentChat(prev => !prev);

  const handleGoBack = () => {
    if (showCurrentChat) {
      handleShowCurrentChat();
    } else {
      navigate(routes.objects);
    }
  };

  return (
    <>
      <MobileTitle onClick={handleGoBack}>
        <ArrowLeft />
        {showCurrentChat ? (
          <>
            <img
              src={require(`assets/vehicles/${objectData?.type || 'car'}.svg`).default}
              style={{ filter: getObjectFilterColor(objectData?.status ?? 1) }}
            />
            <p>{objectData?.number}</p>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton
                width={'32px'}
                icon="pencil"
                onClick={handleNameEdit}
                onPointerEnter={handleNameEdit}
              />
            </div>
          </>
        ) : (
          <p>{t('chat')}</p>
        )}
      </MobileTitle>

      <StyledListWrapper isMobile>
        {showCurrentChat ? (
          <>
            <MessageWrapper>
              {currentChat.map(message => (
                <MessageItem
                  key={message.Message.id}
                  message={message}
                  objectData={objectData}
                  isMobile
                  speditorName={clientEmail}
                />
              ))}
              {isDriverTyping && (
                <MessageItemContainer>
                  <MobileCircle>
                    <MdAccountCircle size={24} color={'#F44542'} />
                  </MobileCircle>
                  <div>
                    <TopMessageWrapper>
                      <VehiclesNumber>{driversName}</VehiclesNumber>
                    </TopMessageWrapper>
                    <MessageContent>{t('isTyping') + '...'}</MessageContent>
                  </div>
                </MessageItemContainer>
              )}
            </MessageWrapper>
            {showMobileModal && (
              <>
                <Overlay />
                <MobileModal>
                  <FormWrapper>
                    <InputWrapper>
                      <Label>{t('driversNameAndSurname')}</Label>
                      <StyledInput
                        onChange={e => setDriverInputValue(e.target.value)}
                        value={driverInputValue}
                      />
                    </InputWrapper>
                    <ButtonWrapper>
                      <IconButton icon={'back'} onClick={handleNameEdit} />
                      <Button primary fillWidth onClick={handleEditDriversName}>
                        {t('edit')}
                      </Button>
                    </ButtonWrapper>
                  </FormWrapper>
                </MobileModal>
              </>
            )}
            <TextAreaWrapper isMobile>
              <StyledTextArea
                name={'message'}
                placeholder={t('enterMessage')}
                onChange={e => {
                  setInputValue(e.target.value);
                }}
                onKeyDown={e => handleSendMessage(e)}
                value={inputValue}
              />
              <IoMdSend color={'rgb(244, 69, 66)'} size={24} onClick={sendMessage} />
            </TextAreaWrapper>
          </>
        ) : (
          <ChatListContainer>
            <TopList>
              <ListTitle>{t('vehiclesList')}</ListTitle>
              <SpeditionSearchInput
                searchInputValue={searchInputValue}
                handleInputChange={handleInputChange}
              />
            </TopList>
            <ChatItemsWrapper>
              {listItems.map(item => (
                <ChatListItem
                  key={item.Account.object.objectId}
                  message={item}
                  onClickHandler={() => {
                    handleMessageClicked(item);
                    handleShowCurrentChat();
                  }}
                  messageClickedId={(messageClicked as ReceiversData)?.Account.id}
                  type={
                    objectsData?.find(obj => obj.objectId === item.Account.object.objectId)?.type ??
                    'car'
                  }
                  status={
                    objectsData?.find(obj => obj.objectId === item.Account.object.objectId)
                      ?.status ?? 1
                  }
                  isTypingContent={
                    isTypingResponse?.id === item.Account.id ? isTypingResponse?.isTyping : false
                  }
                />
              ))}
            </ChatItemsWrapper>
          </ChatListContainer>
        )}
      </StyledListWrapper>
    </>
  );
};

export default SpeditionChatMobile;

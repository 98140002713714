import { Status } from 'components/common';
import { createContext } from 'react';

export type Content = {
  content: string;
  type?: Status;
};

export type ToastContextProps = {
  isVisible: boolean;
  showToast: (content: Content) => void;
};

export const ToastContext = createContext<ToastContextProps>({
  isVisible: false,
  showToast: () => {},
});

export default ToastContext;

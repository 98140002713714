import { memo } from 'react';

import { Wrapper } from './style';

interface Props {
  onClick: (value: string) => void;
  children: React.ReactNode;
  active: boolean;
  disabled?: boolean;
  value: string;
  noBorderRadius?: boolean;
  small?: boolean;
}

const Tab = ({
  onClick,
  active,
  disabled = false,
  value,
  children,
  noBorderRadius = false,
  small = false,
}: Props) => (
  <Wrapper
    active={active}
    disabled={disabled}
    onClick={() => onClick(value)}
    noBorderRadius={noBorderRadius}
    small={small}
  >
    {children}
  </Wrapper>
);

export default memo(Tab);

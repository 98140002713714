import Header from 'components/Header';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal, { ModalProps } from 'components/Modal';
import Paragraph from 'components/Paragraph';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalButton, RowInline, WrapperCenter } from './style';

export type ConfirmationModalProps = Omit<ModalProps, 'children'> & {
  title?: string;
  message?: string;
  confirmText?: string;
  declineText?: string;
  onConfirm: () => void;
  onDecline?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
};

const ConfirmationModal = ({
  title,
  message,
  confirmText,
  declineText,
  onConfirm,
  onDecline,
  disabled,
  isLoading,
  ...otherProps
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal {...otherProps}>
      <WrapperCenter>
        {title && (
          <Header smallMarginBottom smallFont>
            {title}
          </Header>
        )}
        <Paragraph>{message ?? t('areYouSure')}</Paragraph>
      </WrapperCenter>
      <RowInline>
        {onDecline && (
          <ModalButton secondary onClick={onDecline}>
            {declineText ?? t('no')}
          </ModalButton>
        )}
        <ModalButton primary onClick={onConfirm} disabled={disabled}>
          {isLoading ? <LoadingIndicator small /> : confirmText ?? t('yes')}
        </ModalButton>
      </RowInline>
    </Modal>
  );
};

export default React.memo(ConfirmationModal);

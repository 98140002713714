import {
  AUTOMATION_REPORTS,
  DRIVERS,
  HttpError,
  REPORT_STATUSES,
  REPORTS,
  VAT_US_ROUTE_DRIVERS,
  VAT_US_ROUTE_GOALS,
} from 'common';
import { Status } from 'components/common';
import queryClient from 'lib/react-query';
import { routes } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import { UseInfiniteQueryOptions, UseMutationOptions, UseQueryOptions } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'toast';

import { requests } from '../api';
import { RouteItemType } from '../common';
import {
  CreateReportDto,
  CreateRouteItemDto,
  CreateRouteItemResponseDto,
  DeleteReportDto,
  DriversResponseDto,
  ReportDistanceCombustionVehicleDto,
  ReportsResponseDto,
  ReportStatusesResponseDto,
  RouteDriversResponseDto,
  RouteGoalsResponseDto,
  SendReportMailDto,
  StepOneVatUsDto,
  StepOneVatUsResponseDto,
  UpdateVatUsReportDto,
} from '../dtos';

export const createRouteItem = (
  config: UseMutationOptions<
    CreateRouteItemResponseDto,
    HttpError<unknown>,
    CreateRouteItemDto
  > = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.createGoalOrDriver,
    ...config,
    onSuccess: (result: CreateRouteItemResponseDto, data: CreateRouteItemDto) => {
      if (data.type === RouteItemType.DRIVERS) {
        // @ts-ignore
        queryClient.setQueryData(VAT_US_ROUTE_DRIVERS, (current: RouteDriversResponseDto) => [
          // @ts-ignore
          ...current,
          { ...result, name: data.name },
        ]);
      }

      if (data.type === RouteItemType.GOALS) {
        // @ts-ignore
        queryClient.setQueryData(VAT_US_ROUTE_GOALS, (current: RouteGoalsResponseDto) => [
          // @ts-ignore
          ...current,
          { ...result, name: data.name },
        ]);
      }

      showToast({ content: t('createRouteItemSuccess') });
    },
    onError: () => showToast({ content: t('createRouteItemFailure'), type: Status.Negative }),
  };
};

export const createReport = (
  config: UseMutationOptions<unknown, HttpError<unknown>, CreateReportDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return {
    mutationFn: requests.createReport,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries(REPORTS);
      showToast({ content: t('createReportSuccess') });
      navigate(routes.reportsList);
    },
    onError: () => showToast({ content: t('createReportFailure'), type: Status.Negative }),
  };
};

export const sendOneStepReport = (
  config: UseMutationOptions<StepOneVatUsResponseDto, HttpError<unknown>, StepOneVatUsDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.createVatUsStepOne,
    ...config,
    onSuccess: () => {
      showToast({ content: t('completeTheTargetsAndDrivers') });
    },
    onError: () => showToast({ content: t('sendOneStepReportFailure'), type: Status.Negative }),
  };
};

export const generateReport = (
  config: UseMutationOptions<unknown, HttpError<unknown>, UpdateVatUsReportDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.updateVatUsReport,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries(REPORTS);
      showToast({ content: t('createReportSuccess') });
    },
    onError: () => showToast({ content: t('createReportFailure'), type: Status.Negative }),
  };
};

export const getDrivers = (
  config: UseQueryOptions<DriversResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: DRIVERS,
  queryFn: requests.getDrivers,
  ...config,
});

export const getRouteDrivers = (
  config: UseQueryOptions<RouteDriversResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: VAT_US_ROUTE_DRIVERS,
  queryFn: requests.getRouteDrivers,
  ...config,
});

export const getRouteGoals = (
  config: UseQueryOptions<RouteGoalsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: VAT_US_ROUTE_GOALS,
  queryFn: requests.getRouteGoals,
  ...config,
});

export const getReports = (
  currentPage?: number,
  config: UseQueryOptions<ReportsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [REPORTS, { page: currentPage }],
  queryFn: () => requests.getReports({ page: currentPage }),
  ...config,
});

export const getAutomationReports = (
  currentPage?: number,
  config: UseQueryOptions<ReportsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [REPORTS, AUTOMATION_REPORTS, { page: currentPage }],
  queryFn: () => requests.getReports({ automation: true, page: currentPage }),
  ...config,
});

export const sendReportMail = (
  config: UseMutationOptions<unknown, HttpError<unknown>, SendReportMailDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.sendReportMail,
    ...config,
    onSuccess: () => {
      showToast({ content: t('sendReportSuccess') });
    },
    onError: () => showToast({ content: t('sendReportFailure'), type: Status.Negative }),
  };
};

export const deleteReport = (
  config: UseMutationOptions<unknown, HttpError<unknown>, DeleteReportDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.deleteReport,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries(REPORTS);
      showToast({ content: t('deleteReportSuccess') });
    },
    onError: () => showToast({ content: t('deleteReportFailure'), type: Status.Negative }),
  };
};

type InfiniteReportsConfig = UseInfiniteQueryOptions<ReportsResponseDto, HttpError<unknown>>;
type InfiniteReportsParams = {
  automation?: boolean;
};

export const getInfiniteReports = (
  params?: InfiniteReportsParams,
  config: InfiniteReportsConfig = {},
): InfiniteReportsConfig => ({
  queryKey: [REPORTS, { ...params, infinite: true }],
  queryFn: ({ pageParam = 1 }) => requests.getReports({ ...params, page: pageParam }),
  getNextPageParam: lastPage => lastPage.data.pagination.currentPage + 1,
  getPreviousPageParam: lastPage => lastPage.data.pagination.currentPage - 1,
  ...config,
});

export const getReportStatuses = (config: UseQueryOptions<ReportStatusesResponseDto> = {}) => ({
  queryKey: [REPORTS, REPORT_STATUSES],
  queryFn: requests.getReportStatuses,
  ...config,
});

export const getReportData = (
  reportId: string,
  config: UseQueryOptions<ReportDistanceCombustionVehicleDto, HttpError<unknown>> = {},
) => ({
  queryKey: [REPORTS, { reportId }],
  queryFn: () => requests.getReportData(reportId),
  ...config,
});

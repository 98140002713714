import { axios, controller } from 'lib/axios';
import { UpdateMenuResponseDto } from 'modules/auth/dtos/UpdateMenu.dto';

import {
  ForgotPasswordDto,
  LoginDto,
  LoginResponseDto,
  ResetPasswordDto,
  ResetPasswordUnverifiedDto,
  VerifyLoginResponseDto,
} from '../dtos';
import { endpoints } from '.';

export const getCommunique = () => axios.get(endpoints.communique).then(res => res.data);

export const login = (data: LoginDto) =>
  axios.post<LoginResponseDto>(endpoints.login, data).then(res => res.data);

export const verifyLogin = () =>
  axios.get<VerifyLoginResponseDto>(endpoints.verifyLogin).then(res => res.data);

export const forgotPassword = (data: ForgotPasswordDto) =>
  axios.post(endpoints.forgotPassword, data).then(res => res.data);

export const resetPasswordUnverified = (data: ResetPasswordUnverifiedDto) =>
  axios.post(endpoints.resetPasswordUnverified, data).then(res => res.data);

export const verifyResetPasswordToken = (token: string) =>
  axios.get(endpoints.resetPassword(token), { params: { token } }).then(res => res.data);

export const resetPassword = (data: ResetPasswordDto) =>
  axios
    .post(endpoints.resetPassword(data.token), { password: data.password })
    .then(res => res.data);

export const logout = () => {
  controller.abort('logout');

  return axios.delete(endpoints.logout).then(res => res.data);
};

export const showMessages = () => axios.get(endpoints.messages).then(res => res.data);

export const updateMenu = (data: UpdateMenuResponseDto) =>
  axios.patch(endpoints.menuModules, data).then(res => res.data);

export const resetMenu = () => axios.delete(endpoints.menuModules).then(res => res.data);

import { LINK_HASH } from 'common';
import Tabs from 'components/Tabs';
import { Container, StyledTabsWrapper, TopWrapper } from 'modules/admin/pages/LoginsAndGroups';
import { Title } from 'modules/driver/pages/Spedition';
import SharingList from 'modules/sharing/components/sharing-list/SharingList';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useWindowSize } from 'utils';

import SharingGroups from '../components/SharingGroups';
import SharingObjects from '../components/SharingObjects';

export enum SharingTabs {
  GROUPS = 'groups',
  OBJECTS = 'objects',
  LIST = 'list',
}

const Sharing = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<SharingTabs>(SharingTabs.GROUPS);
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInputValue(e.target.value);

  const { isMobile } = useWindowSize();

  const tabs = useMemo(
    () => [
      { label: t('objectsGroups'), value: SharingTabs.GROUPS },
      { label: t('objects'), value: SharingTabs.OBJECTS },
      { label: t('list'), value: SharingTabs.LIST },
    ],
    [isMobile],
  );

  const queryClient = useQueryClient();

  const handleTabClick = (value: string) => {
    queryClient.resetQueries({ queryKey: LINK_HASH, exact: true });
    setActiveTab(value as SharingTabs);
  };

  return (
    <Container isMobile={isMobile}>
      {!isMobile && (
        <TopWrapper>
          <Title>{t('sharing')}</Title>
          <StyledTabsWrapper>
            <Tabs options={tabs} activeTab={activeTab} onClick={handleTabClick} />
          </StyledTabsWrapper>
        </TopWrapper>
      )}

      {activeTab === SharingTabs.GROUPS && (
        <SharingGroups
          searchInputValue={searchInputValue}
          handleInputChange={handleInputChange}
          activeTab={activeTab}
          tabs={tabs}
          handleTabClick={handleTabClick}
        />
      )}

      {activeTab === SharingTabs.OBJECTS && (
        <SharingObjects
          activeTab={activeTab}
          tabs={tabs}
          handleTabClick={handleTabClick}
          searchInputValue={searchInputValue}
          handleInputChange={handleInputChange}
        />
      )}

      {activeTab === SharingTabs.LIST && <SharingList />}
    </Container>
  );
};

export default Sharing;

export const endpoints = {
  authDriver: '/rest-api/auth/driver',
  statuses: '/rest-api/ms/driver/api/statuses',
  account: '/rest-api/ms/driver/api/management/account',
  deleteDriversLink: (id: string) => `/rest-api/ms/driver/api/management/account/${id}`,
  unreadStatistics: '/rest-api/ms/driver/api/messenger/unread',
  statusUpdates: '/rest-api/ms/driver/api/management/actualization',
  documentsList: '/rest-api/ms/driver/api/messenger/documents',
  addDocument: '/rest-api/ms/driver/api/messenger/document',
  deleteDocument: (id: number) => `/rest-api/ms/driver/api/messenger/document/${id}`,
};

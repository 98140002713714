import LogoImage from 'assets/ww-logo.svg';
import LoadingIndicator from 'components/LoadingIndicator';
import { memo } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;
const Img = styled.img`
  width: 276px;
  height: 50px;
`;

const LoadingPage = () => (
  <Wrapper>
    <Content>
      <Img src={LogoImage} />
      <LoadingIndicator />
    </Content>
  </Wrapper>
);

export default memo(LoadingPage);

export enum LocalStorageKeys {
  IS_AUTOMATIC_REFRESH = 'isAutomaticRefresh',
  GROUP_OBJECTS_ON = 'groupObjectsOn',
  PLATE_NAMES_ON = 'plateNamesOn',
  VEHICLES_ICONS_TYPE = 'vehiclesIconsType',
  HIDE_OBJECTS_NAMES = 'hideObjectsName',
  TRAFFIC_LAYER_ON = 'trafficLayerOn',
  OBJECTS_GROUP_ID = 'objectsGroupId',
  OBJECT_INFO_BUTTON = 'objectInfoButton',
  MAP_TYPE = 'mapType',
  COLOR_MODE_SETTINGS = 'colorModeSettings',
  STATUS_FILTERS = 'statusFilters',
  SEEN_MESSAGE_IDS = 'seenMessageIds',
  CURRENT_LANGUAGE = 'i18nextLng',
  OBJECT_LIST_PARAMTER = 'objectListParamter',
}

import FormField from 'components/FormField';
import RangeInput, { RangeInputProps } from 'components/RangeInput';
import { FieldAttributes, useField } from 'formik';
import { memo } from 'react';

export type RangeInputFormFieldProps = FieldAttributes<RangeInputProps> & {
  name: string;
  noMargin?: boolean;
  label?: string;
};

const RangeInputFormField = ({ noMargin, label, ...props }: RangeInputFormFieldProps) => {
  const [field, meta] = useField(props);
  const errorId = `${field.name}-error`;

  return (
    <FormField
      errorId={errorId}
      touched={meta.touched}
      error={meta.error}
      noMargin={noMargin}
      label={label}
    >
      <RangeInput {...field} {...props} />
    </FormField>
  );
};

export default memo(RangeInputFormField);

import MainLayoutContext from 'components/MainLayout/context/MainLayoutContext';
import { ObjectsParamsDto } from 'modules/objects/dtos';
import { getObjectsWithPrevData } from 'modules/objects/utils/getObjectsWithPrevData';
import { useCallback, useContext, useMemo } from 'react';

/**
 * This hook is used to reset map view
 * Fetch current objects data
 * @returns
 */
function useResetMap() {
  const {
    objectsGroupId,
    filteredObjectIds,
    setFitMapBounds,
    setIsObjectSelectedFromList,
    setIsInfoboxVisible,
    setSelectedVehicleId,
    setBubblesCoordinates,
  } = useContext(MainLayoutContext);

  /**
   * Params for objects list
   */
  const objectsParams: ObjectsParamsDto | undefined = useMemo(() => {
    if (!objectsGroupId && !filteredObjectIds?.length) return undefined;

    return {
      ...(objectsGroupId && { group: objectsGroupId }),
      ...(filteredObjectIds?.length && { 'object-filtering': filteredObjectIds }),
    };
  }, [objectsGroupId, filteredObjectIds]);

  /**
   * Refetch API call
   */
  const { refetch } = getObjectsWithPrevData(objectsParams, false, 0);

  /**
   * Refetch objects
   */
  const refetchObjects = useCallback(() => {
    setFitMapBounds(true);
    refetch();
  }, [refetch, setFitMapBounds]);

  /**
   * Reset map
   */
  const resetMap = useCallback(() => {
    setIsObjectSelectedFromList(false);
    setIsInfoboxVisible(false);
    setSelectedVehicleId?.(undefined);
    setBubblesCoordinates({ lat: 0, lng: 0 });
    /* remove a previously opened object HERE infobubble */
    document.getElementById('H_ib_objectInfo')?.remove();
  }, [
    setIsObjectSelectedFromList,
    setIsInfoboxVisible,
    setSelectedVehicleId,
    setBubblesCoordinates,
  ]);

  /**
   * Manual refresh map and object list
   */
  const handleRefreshClick = useCallback(() => {
    refetchObjects();
    resetMap();
  }, [refetchObjects, resetMap]);

  return { handleRefreshClick, resetMap, refetchObjects };
}

export default useResetMap;

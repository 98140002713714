import {
  AppModules,
  AppModulesAdmin,
  AppModulesIntegrations,
  AppModulesPremium,
} from 'common/menuItems';
import { useCallback } from 'react';

import { useUserData } from './useUserData';

const useCheckModuleAccess = () => {
  const { appModules } = useUserData();

  /**
   * Check if user has access to the app module
   * based on the user's appModules
   */
  const checkAppModuleAccess = useCallback(
    ({
      module,
      subModule,
    }: {
      module: AppModules;
      subModule?: AppModulesPremium | AppModulesAdmin | AppModulesIntegrations;
    }) => {
      if (!appModules) return false;

      if (subModule) {
        return appModules.some(
          appModule =>
            appModule.id === module &&
            appModule.subModules?.some(subModuleItem => subModuleItem.id === subModule),
        );
      }

      return appModules.some(appModule => appModule.id === module);
    },
    [appModules],
  );

  return checkAppModuleAccess;
};

export default useCheckModuleAccess;

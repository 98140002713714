import i18n from 'locales/i18n';

import { ReportTypes } from '../common';

export const endpoints = {
  createReport: (type: ReportTypes) => `/api/report/create/${type}?lang=${i18n.language}`,
  updateVatUsReport: (reportId: string) => `/api/report/generate/${reportId}`,
  deleteReport: (reportId: string) => `api/report/${reportId}`,
  createGoalOrDriver: `/api/routeItem/create`,
  getDrivers: `/api/driver`,
  getRouteDrivers: `/api/routeItem/drivers`,
  getRouteGoals: `/api/routeItem/goals`,
  getReports: `/api-react/report`,
  sendReportMail: `api/notification/mail`,
  getReportStautses: `/api/report/statuses`,
  getReportData: (reportId: string) => `/api/report/data/${reportId}`,
};

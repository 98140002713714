import Button from 'components/Button';
import styled from 'styled-components/macro';

export const WrapperCenter = styled.div`
  text-align: center;
`;

export const RowInline = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 12px;
  margin-top: ${p => p.theme.spacing.xLarge};
`;

export const ModalButton = styled(Button)`
  flex-basis: 12rem;
`;

import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import { ReactComponent as StepOneIcon } from 'assets/icons/half-circle.svg';
import { Title } from 'modules/driver/pages/Spedition';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useWindowSize } from 'utils';

import { getLoginsList } from '../queries';
import AddGroupSteps from './AddGroupSteps';
import ChangePasswordForm from './ChangePasswordForm';
import CreateLoginForm from './CreateLoginForm';
import { Container, StyledMobileTitle } from './GroupForm';
import UpdateLoginForm from './UpdateLoginForm';

interface LoginFormProps {
  handleLoginForm: () => void;
  selectedLoginId: number | null;
  handleSelectedLoginId: (id: number | null) => void;
  isChangePassword: boolean;
  handleChangePasssword: () => void;
}

const LoginForm = ({
  handleLoginForm,
  selectedLoginId,
  handleSelectedLoginId,
  isChangePassword,
  handleChangePasssword,
}: LoginFormProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const { data: logins } = useQuery(getLoginsList());

  const selectedLoginData = logins?.data.logins.find(login => login.loginId === selectedLoginId);

  return (
    <Container isMobile={isMobile}>
      {isMobile ? (
        <StyledMobileTitle onClick={handleLoginForm}>
          <ArrowLeft />
          {selectedLoginId ? t('editing') : t('addingLogin')}
        </StyledMobileTitle>
      ) : (
        <Title>{selectedLoginId ? t('edit') : t('addNewLogin')}</Title>
      )}
      {isMobile && !selectedLoginId && (
        <AddGroupSteps text={t('addLogin')} icon={<StepOneIcon />} number={1} />
      )}
      {isChangePassword && selectedLoginData ? (
        <ChangePasswordForm
          handleLoginForm={handleLoginForm}
          handleChangePasssword={handleChangePasssword}
          loginId={selectedLoginData.loginId}
          clientId={selectedLoginData.clientId}
          handleSelectedLoginId={handleSelectedLoginId}
        />
      ) : selectedLoginData ? (
        <UpdateLoginForm
          selectedLoginData={selectedLoginData}
          handleLoginForm={handleLoginForm}
          handleSelectedLoginId={handleSelectedLoginId}
        />
      ) : (
        <CreateLoginForm
          handleLoginForm={handleLoginForm}
          handleSelectedLoginId={handleSelectedLoginId}
        />
      )}
    </Container>
  );
};

export default LoginForm;

import { ObjectInfoTypes } from 'components/ObjectInfo';
import Coordinates from 'components/ObjectInfo/Coordinates';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { GeocodingResponseDto, ObjectsExtendedDataResponseDto } from 'modules/objects/dtos';
import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';

export const generalContent = (
  objectId: number,
  currentObject: Object | undefined,
  objectGeocodingData: GeocodingResponseDto | undefined,
  objectExtendedData: ObjectsExtendedDataResponseDto | undefined,
  showFullAddress: boolean | undefined,
  duration?: string,
  markerType = ObjectInfoTypes.DEFAULT,
  fuelData?: string,
  stopTime?: string,
  handleShowMoreAddress?: () => void,
) => {
  const worktime = objectExtendedData && objectExtendedData?.data[objectId]?.workTimeTxt;
  const distance = objectExtendedData && objectExtendedData?.data[objectId]?.distance;
  const startTime = objectExtendedData && objectExtendedData?.data[objectId]?.ignitionDateTime;

  return {
    dateTime: {
      label: t('dateTime'),
      value: dayjs(stopTime ?? currentObject?.last.gpsTime).format('DD-MM-YYYY HH:mm'),
    },
    ...(markerType === ObjectInfoTypes.STOP && {
      duration: { label: t('duration'), value: duration },
    }),
    ...(markerType === ObjectInfoTypes.REFILLING && {
      fuel: { label: t('fuel'), value: fuelData },
    }),
    coordinates: {
      label: t('routePlanning'),
      value: (
        <Coordinates lat={objectGeocodingData?.data.lat} lon={objectGeocodingData?.data.lon} />
      ),
    },
    address: {
      label: t('address'),
      value: objectGeocodingData?.data.display_name,
      showExpandMore: markerType === ObjectInfoTypes.DEFAULT,
      isExpanded: showFullAddress || markerType === ObjectInfoTypes.STOP,
      onExpandMoreClick: handleShowMoreAddress,
    },
    ...(markerType === ObjectInfoTypes.DEFAULT && {
      workTime: {
        label: t('workTime'),
        value: worktime ?? '-',
      },
      distance: {
        label: t('distance'),
        value: distance ? distance + ' km' : '-',
      },
      start: {
        label: t('start'),
        value: startTime ? dayjs(startTime).format('HH:mm') : '-',
      },
    }),
  };
};

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useWindowSize } from 'utils';

const SearchInputWrapper = styled.label`
  display: block;
  position: relative;

  svg {
    position: absolute;
    top: 2.1rem;
    left: 1.1rem;
    transform: translateY(-50%);
  }
`;

const SearchInput = styled.input<{ isMobile: boolean; fullWidth?: boolean }>`
  background: ${({ theme }) => theme.colors.secondaryBackgroundDark};
  color: ${({ theme }) => theme.colors.text};
  padding: 1.2rem 1.5rem 1.2rem 3.6rem;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  outline: none;
  height: 4.2rem;
  width: 100%;

  ${p =>
    p.disabled &&
    css`
      pointer-event: none;
    `}

  ${p =>
    p.isMobile &&
    css`
      height: 42px;
      width: 100%;
    `}
`;

const SpeditionSearchInput = ({
  searchInputValue,
  handleInputChange,
  placeholder,
  fullWidth,
}: {
  searchInputValue?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  fullWidth?: boolean;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  return (
    <SearchInputWrapper>
      <SearchInput
        placeholder={placeholder ?? t('searchVehicle')}
        value={searchInputValue}
        onChange={handleInputChange}
        isMobile={isMobile}
        fullWidth={fullWidth}
      />
      <SearchIcon />
    </SearchInputWrapper>
  );
};

export default SpeditionSearchInput;

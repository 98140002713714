import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import { ReactComponent as StepTwoIcon } from 'assets/icons/full-circle.svg';
import DeleteIcon from 'assets/icons/trash.svg';
import Pencil from 'assets/pencil.svg';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import ConfirmationModal from 'components/ConfirmationModal';
import LoadingIndicator from 'components/LoadingIndicator';
import { ItemName, ItemNameWrapper } from 'modules/driver/components/SpeditionObjectsList';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { getAllObjects } from 'modules/objects/queries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { getObjectIcon } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import { GroupObject } from '../dtos';
import { addObjectsToGroup, deleteGroup } from '../queries';
import AddGroupSteps from './AddGroupSteps';
import { ObjectsList, StyledObjectItem } from './GroupObjectsList';

const listHeight = (isNewGroup: boolean): string => (isNewGroup ? '35rem' : '30rem');

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${({ theme }) => theme.colors.gray100};
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.xLarge}`};
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
`;

const GroupColor = styled.div<{ color: string }>`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ color, theme }) => color || theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
`;

export const SearchSection = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.large};
  gap: 8px;
`;

export const HandleGroupButton = styled.button`
  display: flex;
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledObjectsList = styled(ObjectsList)<{ isNewGroup: boolean }>`
  padding: ${({ theme }) => theme.spacing.large};
  height: ${({ isNewGroup }) => `calc(100vh - ${listHeight(isNewGroup)})`};
`;

export const ConfirmSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.large};
  height: 4rem;
  justify-self: flex-end;
`;

interface MobileObjectsListProps {
  color?: string;
  label?: string;
  groupId: number;
  handleGroupClick: (id: number | null) => void;
  objects: GroupObject[];
  handleGroupForm: (groupId: number) => void;
  handleGroupId: (groupId: number) => void;
}

const MobileObjectsList = ({
  color,
  label,
  handleGroupClick,
  objects,
  groupId,
  handleGroupForm,
  handleGroupId,
}: MobileObjectsListProps) => {
  const { t } = useTranslation();

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<GroupObject[]>(objects);

  const { data: allObjects, isFetching: isObjectsListFetching } = useQuery(getAllObjects());
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const { mutate, isSuccess } = useMutation(addObjectsToGroup());

  const groupObjectsIds = selectedItems?.map(el => el.objectId);

  const allObjectsItemsWithFilters = allObjects?.data
    .map(el => ({
      objectId: el.objectId,
      number: el.number,
      comment: el.comment,
      status: el.status,
      type: el.type,
    }))
    .filter(obj => obj.number.toLocaleLowerCase().includes(searchInputValue));

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const { mutate: deleteGroupMutation, isLoading: isDeletingLoading } = useMutation(deleteGroup());

  const handleDeleteGroup = (id: number) => {
    deleteGroupMutation({ id: id });
  };

  const handleConfirm = (id: number) => {
    handleDeleteGroup(id);
    setIsConfirmationModalOpen(false);
    handleGroupClick?.(null);
  };

  const handleSelectedItems = (item: GroupObject) => {
    if (selectedItems?.find(obj => obj.objectId === item.objectId)) {
      setSelectedItems(selectedItems?.filter(obj => obj.objectId !== item.objectId));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
    setIsButtonDisabled(false);
  };

  useEffect(() => {
    setSelectedItems(objects);
  }, [objects]);

  const handleAddObjectsToGroup = () => {
    mutate({ groupId: groupId, objects: selectedItems?.map(item => item.objectId) });
  };

  useEffect(() => {
    if (isSuccess) handleGroupClick(null);
  }, [isSuccess]);

  useEffect(() => {
    if (
      selectedItems?.length === objects?.length &&
      selectedItems?.every(item => objects?.some(({ objectId }) => item.objectId === objectId))
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [selectedItems, objects]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInputValue(e.target.value);

  const handleEdit = (id: number) => {
    handleGroupId(id);
    handleGroupForm(id);
  };

  return (
    <Container>
      <Title onClick={() => handleGroupClick(null)}>
        <ArrowLeft />
        <GroupColor color={color ?? ''} />
        {label}
      </Title>
      {!objects.length && (
        <AddGroupSteps text={t('selectVehicles')} number={2} icon={<StepTwoIcon />} />
      )}
      <SearchSection>
        <SpeditionSearchInput
          searchInputValue={searchInputValue}
          handleInputChange={e => handleInputChange?.(e)}
        />
        <HandleGroupButton onClick={() => handleEdit(groupId)}>
          <SVG
            src={Pencil}
            width={16}
            height={16}
            preProcessor={code => code.replace(/fill=".*?"/g, 'fill="#252E3B"')}
          />
        </HandleGroupButton>
        <HandleGroupButton
          onClick={() => {
            setIsConfirmationModalOpen(true);
          }}
        >
          <SVG
            src={DeleteIcon}
            width={16}
            height={16}
            preProcessor={code => code.replace(/fill=".*?"/g, 'fill="#252E3B"')}
          />
        </HandleGroupButton>
      </SearchSection>
      <StyledObjectsList isNewGroup={!objects.length}>
        {isObjectsListFetching ? (
          <LoadingIndicator />
        ) : (
          allObjectsItemsWithFilters?.map(object => (
            <StyledObjectItem
              key={object.objectId}
              isMobile
              isActive={false}
              onClick={() => handleSelectedItems(object as unknown as GroupObject)}
            >
              <ItemNameWrapper>
                <Checkbox
                  checkboxSize={'md'}
                  checked={groupObjectsIds?.includes(object.objectId)}
                />
                <img
                  src={getObjectIcon(object.type)}
                  style={{ filter: getObjectFilterColor(object.status) }}
                />
                <ItemName>
                  {object.number} {object.comment ? `${object.comment})` : ''}
                </ItemName>
              </ItemNameWrapper>
            </StyledObjectItem>
          ))
        )}
      </StyledObjectsList>
      <ConfirmSection>
        <Button primary fillWidth disabled={isButtonDisabled} onClick={handleAddObjectsToGroup}>
          {`${t('confirmVehicles')} (${selectedItems.length})`}
        </Button>
      </ConfirmSection>
      <ConfirmationModal
        size="small"
        isOpen={isConfirmationModalOpen}
        title={t('confirmYourChoice')}
        message={t('confirmDeleteGroup')}
        confirmText={t('delete')}
        onConfirm={() => groupId && handleConfirm(groupId)}
        onClose={() => setIsConfirmationModalOpen(false)}
        disabled={isDeletingLoading}
        isLoading={isDeletingLoading}
      />
    </Container>
  );
};

export default MobileObjectsList;

import { AllowedModules } from 'common';
import { RoleType } from 'common/Roles';
import { UserSettingsDto } from 'modules/auth/dtos';
import { Groups } from 'modules/auth/dtos/LoginResponse.dto';
import { AppModuleDto } from 'modules/auth/dtos/UpdateMenuResponse.dto';
import { verifyLogin } from 'modules/auth/queries';
import { useQuery } from 'react-query';

export type UserData = {
  settings: UserSettingsDto;
  clientId: number;
  loginId: number;
  clientCode: string;
  clientEmail: string;
  allowedModules: AllowedModules[];
  roleId: RoleType;
  groups: Groups;
  appModules: AppModuleDto[];
};

export const useUserData = () => {
  const { data: userData } = useQuery(verifyLogin());

  return {
    settings: userData?.data?.userSettings ?? {},
    clientId: userData?.data?.clientId ?? 0,
    loginId: userData?.data?.loginId ?? 0,
    clientCode: userData?.data?.code ?? undefined,
    clientEmail: userData?.data?.email ?? undefined,
    allowedModules: userData?.data?.extraFeatures ?? [],
    roleId: userData?.data?.roleId,
    groups: userData?.data?.groups,
    appModules: userData?.data?.access ?? [],
  } as UserData;
};

import { DateKeys } from 'common';
import Button from 'components/Button';
import { SelectItem } from 'components/common';
import DateTimeBoundaryFormField from 'components/DateTimeBoundaryFormField';
import DateTimePickerFormField from 'components/DateTimePickerFormField';
import Header from 'components/Header';
import RangeInputFormField from 'components/RangeInputFormField';
import SearchDropdownFormField from 'components/SearchDropdownFormField';
import SelectDropdownFormField from 'components/SelectDropdownFormField';
import Toggle from 'components/Toggle';
import ToggleFormField from 'components/ToggleFormField';
import dayjs, { OpUnitType } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Form, Formik } from 'formik';
import { getObjects } from 'modules/objects/queries';
import { ReportTypes, StopTimeTypes } from 'modules/reports/common';
import { getDrivers } from 'modules/reports/queries';
import { DistanceSources, FuelSources } from 'modules/route/common';
import { ChangeEvent, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components/macro';
import { composeValidators, isRequired, useWindowSize } from 'utils';

import { ReportAutomation, ReportAutomationFrequency } from '../../common';
import Label from '../Label';
import Separator from '../Separator';

dayjs.extend(isoWeek);

const Wrapper = styled.div`
  background: ${p => p.theme.colors.primaryBackground};
  padding: 2.4rem 4rem;
  border-radius: 0.6rem;
  max-width: 50vw;
  margin: auto;
  min-height: 100%;
  overflow: visible;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    width: 100%;
    max-width: 100%;
    padding: 1.6rem;
  }
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const TimeIntervalDates = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.colors.text};
  margin-top: 0.8rem;
`;

enum TimeIntervalTypes {
  DAY = 'day',
  WEEK = 'isoWeek',
  MONTH = 'month',
}

export type CommonFormData = {
  extension?: ReportTypes;
  dateFrom: string;
  dateTo: string;
  stopTimeType: StopTimeTypes;
  stopTime: number;
  vehicles: SelectItem[] | number[];
  drivers: SelectItem[] | number[];
  driver: SelectItem[] | number[];
  automation: ReportAutomation | string;
  frequency: ReportAutomationFrequency | string;
  distanceSource?: DistanceSources;
  fuelSource?: FuelSources;
  maxCombustion?: number;
  oneDriver?: string;
  sharedVehicle?: string;
};

type Extension = SelectItem & { showDriversSelect?: boolean; showVehiclesSelect?: boolean };

type CommonFormProps = {
  noAutomation?: boolean;
  noInterval?: boolean;
  extensions?: Extension[];
  disableTime?: boolean;
  vehiclesSelect?: boolean;
  driversSelect?: boolean;
  onSubmit: (data: CommonFormData) => void;
  header?: string;
  timeIntervalType?: boolean;
  stopTime?: boolean;
  children?: ReactNode;
  childrenBeforeVehiclesSelect?: boolean;
  canSelectOneVehicle?: boolean;
  buttonText?: string;
};

const commonDateFormat = 'YYYY-MM-DDTHH:mm:ss';

const CommonForm = ({
  noAutomation,
  noInterval,
  extensions,
  disableTime,
  vehiclesSelect,
  driversSelect,
  onSubmit,
  header = '',
  timeIntervalType = false,
  stopTime = false,
  children,
  childrenBeforeVehiclesSelect,
  canSelectOneVehicle = false,
  buttonText,
}: CommonFormProps) => {
  const { t } = useTranslation();
  const [intervalType, setIntervalType] = useState(TimeIntervalTypes.DAY);
  const { isMobile } = useWindowSize();

  const initialDateFromFormat = useMemo(() => {
    if (timeIntervalType) {
      return intervalType === TimeIntervalTypes.MONTH ? 'YYYY-MM' : 'YYYY-MM-DD';
    } else {
      return commonDateFormat;
    }
  }, [intervalType, timeIntervalType]);

  const initialValues: CommonFormData = useMemo(
    () => ({
      extension: extensions?.[0].value as ReportTypes,
      dateFrom: dayjs().startOf('day').format(initialDateFromFormat),
      dateTo: dayjs().endOf('day').format(commonDateFormat),
      stopTimeType: StopTimeTypes.FROM,
      stopTime: 3,
      vehicles: [],
      drivers: [],
      driver: [],
      automation: ReportAutomation.OFF.toString(),
      frequency: ReportAutomationFrequency.WEEKLY.toString(),
      maxCombustion: 40,
      fuelSource: FuelSources.AUTO,
      oneDriver: '0',
      sharedVehicle: '0',
    }),
    [extensions, initialDateFromFormat],
  );

  const { data: vehiclesData } = useQuery(getObjects(undefined, { enabled: vehiclesSelect }));
  const { data: driversData } = useQuery(getDrivers({ enabled: driversSelect }));

  const vehicles = useMemo(
    () =>
      (vehiclesData?.data ?? []).map(vehicle => ({
        label: `${vehicle.number} (${vehicle.comment})`,
        value: vehicle.objectId.toString(),
      })),
    [vehiclesData],
  );

  const drivers = useMemo(
    () =>
      (driversData?.data ?? []).map(driver => ({
        label: driver.name,
        value: driver.driverId.toString(),
      })),
    [driversData],
  );

  const timeIntervalTypeOptions = useMemo(
    () => [
      { label: t('day'), value: TimeIntervalTypes.DAY },
      { label: t('week'), value: TimeIntervalTypes.WEEK },
      { label: t('month'), value: TimeIntervalTypes.MONTH },
    ],
    [],
  );

  const handleDateIntervalTypeChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement>,
      setFieldValue: (field: string, value: string) => void,
      current: CommonFormData,
    ) => {
      const currentInterval = e.target.value;
      setIntervalType(currentInterval as TimeIntervalTypes);

      const currentType = e.target.value as string as OpUnitType;
      const dateFormat = currentInterval === TimeIntervalTypes.MONTH ? 'YYYY-MM' : 'YYYY-MM-DD';

      setFieldValue(
        DateKeys.DATE_FROM,
        dayjs(current.dateFrom).startOf(currentType).format(dateFormat),
      );
      setFieldValue(DateKeys.DATE_TO, dayjs(current.dateTo).endOf(currentType).format(dateFormat));
    },
    [],
  );

  const handleDateChange = useCallback(
    (value: Date, setFieldValue: (field: string, value: string) => void) => {
      const dateFormat = intervalType === TimeIntervalTypes.MONTH ? 'YYYY-MM' : 'YYYY-MM-DD';

      setFieldValue(DateKeys.DATE_FROM, dayjs(value).startOf(intervalType).format(dateFormat));
      setFieldValue(DateKeys.DATE_TO, dayjs(value).endOf(intervalType).format(dateFormat));
    },
    [intervalType],
  );

  const handleIntervalTypeDates = useCallback(
    (date: string) => {
      const type = intervalType as string as OpUnitType;

      return {
        dateFrom: dayjs(date).startOf(type).format(commonDateFormat),
        dateTo: dayjs(date).endOf(type).format(commonDateFormat),
      };
    },
    [intervalType],
  );

  const formatTimeIntervalDate = useCallback(
    (date: string, type: 'start' | 'end') => {
      if (type === 'start') return dayjs(date).startOf('day').format('DD-MM-YYYY HH:mm');

      return dayjs(date).endOf(intervalType).format('DD-MM-YYYY HH:mm');
    },
    [intervalType],
  );

  const showVehiclesSelect = useCallback(
    (extension?: string) => {
      if (vehiclesSelect) return true;

      return extensions?.find(o => o.value === extension)?.showVehiclesSelect;
    },
    [extensions, vehiclesSelect],
  );

  const showDriversSelect = useCallback(
    (extension?: string) => {
      if (driversSelect) return true;

      return extensions?.find(o => o.value === extension)?.showDriversSelect;
    },
    [extensions, driversSelect],
  );

  const handleSubmit = useCallback(
    (data: CommonFormData) => {
      let payload = {
        ...data,
        vehicles: (data.vehicles as SelectItem[]).map(i => +i.value),
        drivers: (data.drivers as SelectItem[]).map(i => +i.value),
      };

      if (timeIntervalType) {
        payload = {
          ...payload,
          ...handleIntervalTypeDates(data.dateFrom),
        };
      } else {
        payload = {
          ...payload,
          dateFrom: dayjs(data.dateFrom).format(commonDateFormat),
          dateTo: dayjs(data.dateTo).format(commonDateFormat),
        };
      }

      onSubmit(payload);
    },
    [timeIntervalType],
  );

  return (
    <Wrapper>
      <Header>{header}</Header>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue, values }) => (
          <Form>
            {extensions && (
              <>
                <Label>{t('reportType')}</Label>
                <SelectDropdownFormField
                  fillWidth
                  name="extension"
                  size="md"
                  items={extensions}
                  placeholderText={t('select')}
                />
                <Separator />
              </>
            )}
            {timeIntervalType && (
              <>
                <Label>{t('timeIntervalType')}</Label>
                <Toggle
                  options={timeIntervalTypeOptions}
                  value={intervalType}
                  onChange={e => handleDateIntervalTypeChange(e, setFieldValue, values)}
                />
                <Label>{t('select')}</Label>
                <DateTimePickerFormField
                  name="dateFrom"
                  type={intervalType === TimeIntervalTypes.MONTH ? 'month' : 'date'}
                  disableTime
                  onDateChange={(value: Date) => handleDateChange(value, setFieldValue)}
                  width="100%"
                />
                <TimeIntervalDates>
                  <span>{formatTimeIntervalDate(values.dateFrom, 'start')}</span>
                  {' > '}
                  <span>{formatTimeIntervalDate(values.dateTo, 'end')}</span>
                </TimeIntervalDates>
              </>
            )}
            {!noAutomation && (
              <>
                <Label>{t('automation')}</Label>
                <ToggleFormField
                  name="automation"
                  firstOption={{ label: t('yes'), value: ReportAutomation.ON.toString() }}
                  secondOption={{ label: t('no'), value: ReportAutomation.OFF.toString() }}
                />
              </>
            )}
            {values.automation === ReportAutomation.ON.toString() && !noInterval && (
              <>
                <Label marginTop>{t('frequency')}</Label>
                <ToggleFormField
                  name="frequency"
                  options={[
                    { label: t('daily'), value: ReportAutomationFrequency.DAILY.toString() },
                    { label: t('weekly'), value: ReportAutomationFrequency.WEEKLY.toString() },
                    { label: t('biweekly'), value: ReportAutomationFrequency.BIWEEKLY.toString() },
                    { label: t('monthly'), value: ReportAutomationFrequency.MONTHLY.toString() },
                  ]}
                />
              </>
            )}
            {!noInterval && <Separator />}
            {!timeIntervalType &&
              values.automation !== ReportAutomation.ON.toString() &&
              !noInterval && (
                <>
                  <Label>{t('timeInterval')}</Label>
                  <DateTimeBoundaryFormField
                    fillWidth
                    disableTime={disableTime}
                    start={values.dateFrom}
                    end={values.dateTo}
                  />
                  <Separator />
                </>
              )}
            {stopTime && (
              <>
                <Label>{t('type')}</Label>
                <ToggleFormField
                  name="stopTimeType"
                  firstOption={{ label: t('from'), value: StopTimeTypes.FROM }}
                  secondOption={{ label: t('stopPause'), value: StopTimeTypes.STOP_PAUSE }}
                />
                <RangeInputFormField name="stopTime" timeLabel />
                <Separator />
              </>
            )}
            {children && childrenBeforeVehiclesSelect && <>{children}</>}
            {showVehiclesSelect(values.extension) && (
              <>
                <Label>
                  {t('vehicle')}{' '}
                  <span>
                    ({values.vehicles.length} {t('selected')})
                  </span>
                </Label>
                <SearchDropdownFormField
                  name="vehicles"
                  fillWidth
                  disabled={!!values.drivers.length}
                  items={vehicles}
                  placeholderText={t('select')}
                  validate={composeValidators(!values.drivers.length && isRequired())}
                  multiple={!canSelectOneVehicle}
                />
              </>
            )}
            {showDriversSelect(values.extension) && (
              <>
                <Label marginTop={vehiclesSelect}>
                  {t('drivers')}{' '}
                  <span>
                    ({values.drivers.length} {t('selected')})
                  </span>
                </Label>
                <SearchDropdownFormField
                  name="drivers"
                  fillWidth
                  disabled={!!values.vehicles.length}
                  items={drivers}
                  placeholderText={t('select')}
                  validate={composeValidators(!values.vehicles.length && isRequired())}
                />
              </>
            )}
            {children && !childrenBeforeVehiclesSelect && (
              <>
                <Separator />
                {children}
              </>
            )}
            <Separator />
            <ButtonWrapper>
              <Button primary type="submit" fillWidth={isMobile}>
                {buttonText ?? t('generateReport')}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default memo(CommonForm);

import Toast from 'components/Toast';
import { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { useDelayedTask } from 'utils';

import Context, { Content } from './ToastContext';

const ToastContainer = styled.div`
  position: absolute;
  width: calc(100% - 4.8rem);
  max-width: 500px;
  top: 2rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: ${p => p.theme.layersOrder.modal};
  box-shadow: ${p => p.theme.shadows.xl};
`;

interface ToastContextProviderProps {
  children: ReactNode;
}

type State = {
  content: Content;
  isVisible: boolean;
};

const defaultState: State = {
  content: { content: '' },
  isVisible: false,
};

const ToastContextProvider = ({ children }: ToastContextProviderProps) => {
  const [state, setState] = useState(defaultState);
  const delayedTask = useDelayedTask();

  const handleClose = useCallback(() => {
    delayedTask.cleanup();
    setState(defaultState);
  }, [delayedTask]);

  const showToast = useCallback(
    (content: Content) => {
      setState({
        isVisible: true,
        content,
      });

      delayedTask.start(() => handleClose(), 5000);
    },
    [handleClose, delayedTask],
  );

  return (
    <Context.Provider
      value={{
        isVisible: state.isVisible,
        showToast,
      }}
    >
      {state.isVisible && (
        <ToastContainer>
          <Toast text={state.content.content} type={state.content.type} onClose={handleClose} />
        </ToastContainer>
      )}
      {children}
    </Context.Provider>
  );
};

export default ToastContextProvider;

import { AllowedModules } from 'common';
import { ObjectControllsItemDto } from 'modules/objects/dtos/ObjectsResponse.dto';
import { useMemo } from 'react';
import { useCheckModuleAccess } from 'utils';

/**
 * This hooks is used to conditionally render the CarToggleSwitch component based on the accounts id.
 * @returns boolean
 */
const useCarToggleSwitch = (ignition?: ObjectControllsItemDto): { renderToggleSwitch: boolean } => {
  const checkModuleAccess = useCheckModuleAccess();

  /**
   * Conditionally render the CarToggleSwitch component based on the accounts id.
   */
  const renderToggleSwitch = useMemo(() => {
    if (checkModuleAccess(AllowedModules.VEHICLE_COMMAND_IGNITION) && !!ignition) {
      return true;
    }

    return false;
  }, [checkModuleAccess, ignition]);

  return {
    renderToggleSwitch,
  };
};

export default useCarToggleSwitch;

import Tabs, { SingleTab, TabsWrapper } from 'components/Tabs';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import styled, { css } from 'styled-components';
import { useWindowSize } from 'utils';

import { GroupItem } from '../dtos';
import { GroupsAndLoginsTabs } from '../pages/LoginsAndGroups';
import { createGroup, getGroupsList } from '../queries';
import GroupForm from './GroupForm';
import GroupList from './GroupList';

export const Container = styled.div<{ isMobile?: boolean }>`
  margin-top: ${({ isMobile, theme }) => (!isMobile ? theme.spacing.xxLarge : '0')};
  height: 100%;
  background: ${({ isMobile, theme }) => (isMobile ? theme.colors.white : 'inherit')};
`;

export interface GroupListProps {
  groups: GroupItem[];
  handleGroupForm: () => void;
  handleGroupId: (id: number) => void;
  searchInputValue?: string;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTabClick: (value: string) => void;
  tabs: SingleTab[];
  activeTab: GroupsAndLoginsTabs;
  handleGroupClick: (id: number | null) => void;
  selectedGroupId: number | null;
}

export const StyledTabsWrapper = styled(TabsWrapper)<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    !isMobile &&
    css`
      position: absolute;
      left: 45%;
      margin-bottom: 40px;
    `}
  width: max-content;
  height: 6rem;
  padding: ${({ theme, isMobile }) => isMobile && theme.spacing.large};
`;

export const StyledTopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.secondaryBackground};
`;

const Groups = ({
  activeTab,
  tabs,
  handleTabClick,
}: {
  activeTab: GroupsAndLoginsTabs;
  tabs: SingleTab[];
  handleTabClick: (value: string) => void;
}) => {
  const [isGroupForm, setIsGroupForm] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<number | null>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);

  const handleGroupClick = (id: number | null) => {
    setSelectedGroupId(id);
  };

  const { isMobile } = useWindowSize();
  const { data: groups } = useQuery(getGroupsList());
  const { mutate: createGroupMutation, isLoading: isCreateGroupLoading } = useMutation(
    createGroup(),
  );

  const handleGroupForm = () => setIsGroupForm(prev => !prev);

  return (
    <Container isMobile={isMobile}>
      {isGroupForm ? (
        <GroupForm
          handleGroupForm={handleGroupForm}
          handleGroupId={setGroupId}
          groupHandler={createGroupMutation}
          isLoading={isCreateGroupLoading}
          groupId={groupId}
        />
      ) : (
        <>
          {isMobile && !selectedGroupId && (
            <StyledTopWrapper>
              <StyledTabsWrapper isMobile={isMobile}>
                <Tabs options={tabs} activeTab={activeTab} onClick={handleTabClick} />
              </StyledTabsWrapper>
            </StyledTopWrapper>
          )}
          <GroupList
            groups={groups?.data.groups || []}
            handleGroupForm={handleGroupForm}
            handleGroupId={setGroupId}
            activeTab={activeTab}
            tabs={tabs}
            handleTabClick={handleTabClick}
            selectedGroupId={selectedGroupId}
            handleGroupClick={handleGroupClick}
          />
        </>
      )}
    </Container>
  );
};

export default Groups;

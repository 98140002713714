import Button from 'components/Button';
import Header from 'components/Header';
import InputFormField from 'components/InputFormField';
import Paragraph from 'components/Paragraph';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useUserData } from 'utils';
import { isEmail } from 'utils/validation';

import { sendReportMail } from '../../queries';

type FormData = {
  comment: string;
  mail: string;
};

type ReportRow = {
  objects: string[];
  path: string;
};

type SendReportMailFormProps = {
  selectedReport?: ReportRow;
  onClose: () => void;
};

const SendReportMailForm = ({ selectedReport, onClose }: SendReportMailFormProps) => {
  const { isLoading, mutate } = useMutation(sendReportMail());
  const { clientCode } = useUserData();
  const { t } = useTranslation();
  const initialValues: FormData = useMemo(
    () => ({
      comment: '',
      mail: '',
    }),
    [],
  );

  const handleSubmit = useCallback(
    (data: FormData) => {
      if (!data || !selectedReport) return;

      const { comment, mail } = data;
      const { path, objects } = selectedReport;

      const payload = {
        client: clientCode,
        link: path,
        mail: mail,
        notes: comment,
        objects: objects.toString(),
        type: 'report',
      };
      mutate(payload);

      onClose();
    },
    [mutate, selectedReport, clientCode],
  );

  return (
    <>
      <Header>{t('sendReport')}</Header>
      <Paragraph setMarginTop>
        {t('mailForSendReport')} {t('optionalShortNote')}
      </Paragraph>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <InputFormField
            noMargin
            name="comment"
            label={t('comment')}
            inputSize="md"
            placeholder={t('comment')}
            data-testid="commentInput"
          />
          <InputFormField
            inputSize="md"
            name={'mail'}
            label={t('email')}
            type={'email'}
            placeholder={t('email')}
            validate={isEmail()}
            data-testid="mailInput"
          />
          <Button isLoading={isLoading} primary>
            {t('sendReport')}
          </Button>
        </Form>
      </Formik>
    </>
  );
};

export default React.memo(SendReportMailForm);

import Tabs, { SingleTab } from 'components/Tabs';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useWindowSize } from 'utils';

import { GroupsAndLoginsTabs } from '../pages/LoginsAndGroups';
import { getLoginsList } from '../queries';
import { Container, StyledTabsWrapper, StyledTopWrapper } from './Groups';
import LoginForm from './LoginForm';
import LoginsList from './LoginsList';

const Logins = ({
  activeTab,
  tabs,
  handleTabClick,
}: {
  activeTab: GroupsAndLoginsTabs;
  tabs: SingleTab[];
  handleTabClick: (value: string) => void;
}) => {
  const { isMobile } = useWindowSize();
  const [isLoginForm, setIsLoginForm] = useState<boolean>(false);
  const [selectedLoginId, setSelectedLoginId] = useState<number | null>(null);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);

  const handleSelectedLoginId = (id: number | null) => setSelectedLoginId(id);
  const handleChangePasssword = () => setIsChangePassword(prev => !prev);

  const { data: logins } = useQuery(getLoginsList());

  const handleLoginForm = () => setIsLoginForm(prev => !prev);

  return (
    <Container isMobile={isMobile}>
      {!isLoginForm ? (
        <>
          {isMobile && !selectedLoginId && (
            <StyledTopWrapper>
              <StyledTabsWrapper isMobile={isMobile}>
                <Tabs options={tabs} activeTab={activeTab} onClick={handleTabClick} />
              </StyledTabsWrapper>
            </StyledTopWrapper>
          )}
          <LoginsList
            logins={logins?.data.logins}
            groups={logins?.data.groups}
            handleLoginForm={handleLoginForm}
            selectedLoginId={selectedLoginId}
            handleSelectedLoginId={handleSelectedLoginId}
            handleChangePasssword={handleChangePasssword}
          />
        </>
      ) : (
        <LoginForm
          handleLoginForm={handleLoginForm}
          selectedLoginId={selectedLoginId}
          handleSelectedLoginId={handleSelectedLoginId}
          isChangePassword={isChangePassword}
          handleChangePasssword={handleChangePasssword}
        />
      )}
    </Container>
  );
};

export default Logins;

import { ReactComponent as VehiclesPlaceholder } from 'assets/vehicles-list-placeholder.svg';
import { RouteContext } from 'modules/route/contexts';
import { memo, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useWindowSize } from 'utils';

import ListItem from './ListItem';

const Wrapper = styled.div`
  background: ${p => p.theme.colors.secondaryBackground};
  height: 100%;
  padding: 2rem;
  position: relative;
  overflow: auto;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    padding: 0.8rem;
  }
`;

const Header = styled.div`
  color: ${p => p.theme.colors.text};
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 2rem;
`;

export const NoVehicle = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: ${p => p.theme.colors.textGray};
  margin-top: 1rem;
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    svg {
      width: 50%;
      height: auto;
    }
  }
`;

const ObjectsList = () => {
  const { t } = useTranslation();
  const { selectedItems: data, setCheckedVehicles } = useContext(RouteContext);
  const { isMobile } = useWindowSize();

  const items = useMemo(() => data.filter(i => i.data.route.length), [data]);

  useEffect(() => {
    const checkedItems = items.slice(0, 10).map(item => item.params.objectId.toString());

    setCheckedVehicles(checkedItems);
  }, [items]);

  return (
    <Wrapper>
      {!isMobile && <Header>{t('selectOrCompareVehicles')}</Header>}
      {!items.length && (
        <Placeholder>
          <VehiclesPlaceholder />
          <NoVehicle>{t('noVehicleSelected')}</NoVehicle>
        </Placeholder>
      )}
      {!!items.length && items.map(i => <ListItem key={i.params.objectId} params={i.params} />)}
    </Wrapper>
  );
};
export default memo(ObjectsList);

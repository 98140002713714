import { useState } from 'react';
import { useWindowSize } from 'utils';

import { GroupObject } from '../dtos';
import DesktopGroupList from './DesktopGroupList';
import { GroupListProps } from './Groups';
import MobileGroupList from './MobileGroupList';

export interface GroupListItemsProps {
  label: string;
  value: number;
  comment: string;
  objects: GroupObject[];
  color: string;
}

const GroupList = ({
  groups,
  handleGroupForm,
  handleGroupId,
  handleGroupClick,
  selectedGroupId,
}: GroupListProps) => {
  const { isMobile } = useWindowSize();
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInputValue(e.target.value);

  const groupsList = [...Object.values(groups)].map(o => ({
    label: o?.name,
    value: o?.groupId,
    comment: o?.comment,
    objects: o?.objects,
    color: o?.color,
  }));

  const filteredGroupsList = searchInputValue
    ? groupsList.filter(group => group.label.toLowerCase().includes(searchInputValue))
    : groupsList;

  return isMobile ? (
    <MobileGroupList
      searchInputValue={searchInputValue}
      handleInputChange={handleInputChange}
      groups={filteredGroupsList}
      handleGroupForm={handleGroupForm}
      handleGroupId={handleGroupId}
      handleGroupClick={handleGroupClick}
      selectedGroupId={selectedGroupId}
    />
  ) : (
    <DesktopGroupList
      searchInputValue={searchInputValue}
      handleInputChange={handleInputChange}
      groups={filteredGroupsList}
      handleGroupForm={handleGroupForm}
      handleGroupId={handleGroupId}
      handleGroupClick={handleGroupClick}
      selectedGroupId={selectedGroupId}
    />
  );
};

export default GroupList;

import styled from 'styled-components/macro';

const ErrorMessage = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.colors.error};
  padding: ${p => p.theme.spacing.regular};
  border-radius: 4px;
  background-color: ${p => p.theme.colors.error100};
  border: 1px solid ${p => p.theme.colors.error200};
  font-weight: bold;
`;

export default ErrorMessage;

import React from 'react';
import styled from 'styled-components/macro';

const Flex = styled.div<{
  noWrap?: boolean;
  gap?: number;
  justify?: React.CSSProperties['justifyContent'];
}>`
  display: flex;
  flex-wrap: ${p => (p.noWrap ? 'nowrap' : 'wrap')};
  gap: ${p => (p.gap ? `${p.gap}px` : p.theme.spacing.regular)};
  align-items: center;
  justify-content: ${p => p.justify ?? 'start'};
`;

export default Flex;

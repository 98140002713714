import { AllowedModules, LocalStorageKeys } from 'common';
import { SelectItem } from 'components/common';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import { logout } from 'modules/auth/queries';
import { routes } from 'navigation/routes';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAccountCircle, MdLogout, MdPeople, MdSettings } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  temporarilyHidden,
  useAuth,
  useCheckModuleAccess,
  useMainLayout,
  useUserData,
} from 'utils';

const SelectIcon = styled.div`
  svg {
    height: 2rem;
    width: auto;
    color: ${p => p.theme.colors.gray400};
  }
`;

enum Options {
  SETTINGS = 'settings',
  MULTILOGIN = 'multilogin',
  LOGOUT = 'logout',
}

const UserOptions = () => {
  const { t } = useTranslation();
  const { mutate } = useMutation(logout());
  const { logout: authLogout } = useAuth();
  const navigate = useNavigate();
  const { clientEmail } = useUserData();
  const { setObjectsGroupId } = useMainLayout();
  const checkModuleAccess = useCheckModuleAccess();

  const clearGroupLs = useCallback(() => {
    if (!setObjectsGroupId) return;
    localStorage.removeItem(LocalStorageKeys.OBJECTS_GROUP_ID);
    setObjectsGroupId('');
  }, []);

  /**
   * Options should be disabled if user have access to PUESC_LIMITED module
   */
  const isSettingsDisabled = useMemo(() => checkModuleAccess(AllowedModules.PUESC_LIMITED), []);

  const options: SelectItem[] = useMemo(
    () => [
      {
        label: t('settings.settings'),
        value: Options.SETTINGS,
        disabled: isSettingsDisabled,
        icon: (
          <SelectIcon>
            <MdSettings />
          </SelectIcon>
        ),
      },
      {
        label: t('multilogin'),
        value: Options.MULTILOGIN,
        icon: (
          <SelectIcon>
            <MdPeople />
          </SelectIcon>
        ),
        addSeparator: true,
        disabled: temporarilyHidden,
      },
      {
        label: t('logout'),
        value: Options.LOGOUT,
        icon: (
          <SelectIcon>
            <MdLogout />
          </SelectIcon>
        ),
      },
    ],
    [isSettingsDisabled],
  );

  const handleUserOptions = useCallback((option: string) => {
    if (option === Options.SETTINGS) {
      navigate(routes.settings);
    }

    if (option === Options.LOGOUT) {
      clearGroupLs();
      authLogout();
      mutate();
      navigate(routes.login);
    }
  }, []);

  return (
    <SelectDropdown
      isDropdown
      noBackground
      placeholderText={clientEmail}
      items={options}
      onChange={handleUserOptions}
      icon={<MdAccountCircle />}
      size="md"
      blockSelectedOption
      fillWidth
    />
  );
};

export default memo(UserOptions);

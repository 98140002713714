import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import { ReactComponent as StepTwoIcon } from 'assets/icons/full-circle.svg';
import LockIcon from 'assets/icons/lock.svg';
import DeleteIcon from 'assets/icons/trash.svg';
import Pencil from 'assets/pencil.svg';
import Badge from 'components/Badge';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import ConfirmationModal from 'components/ConfirmationModal';
import LoadingIndicator from 'components/LoadingIndicator';
import { ItemName, ItemNameWrapper } from 'modules/driver/components/SpeditionObjectsList';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';

import { GroupItem } from '../dtos';
import { addGroupsToLogin, deleteLogin, getLoginsList } from '../queries';
import AddGroupSteps from './AddGroupSteps';
import { GroupColor } from './GroupItem';
import { StyledObjectItem } from './GroupObjectsList';
import { GroupNameWrapper } from './LoginGroupList';
import {
  ConfirmSection,
  Container,
  HandleGroupButton,
  SearchSection,
  StyledObjectsList,
  Title,
} from './MobileObjectsList';

const StyledBadge = styled(Badge)`
  padding: 0 1rem;
`;

interface MobileGroupsListProps {
  searchInputValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleForm: () => void;
  selectedLoginId: number | null;
  handleSelectedLoginId: (id: number | null) => void;
  handleChangePasssword?: () => void;
}

const MobileLoginGroupsList = ({
  searchInputValue,
  handleInputChange,
  handleForm,
  selectedLoginId,
  handleSelectedLoginId,
  handleChangePasssword,
}: MobileGroupsListProps) => {
  const { t } = useTranslation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const { data: logins, isFetching: isGroupListFetching } = useQuery(getLoginsList());
  const { mutate: addGroupsToLoginMutation, isLoading: isAddingGroupsToLoginLoading } = useMutation(
    addGroupsToLogin(),
  );

  const loginGroups = logins?.data.logins.find(login => login.loginId === selectedLoginId)?.groups;

  const [selectedItems, setSelectedItems] = useState<GroupItem[] | undefined>(loginGroups);

  const handleModal = () => setIsConfirmationModalOpen(prev => !prev);

  const { mutate, isLoading: isDeletingLoading } = useMutation(deleteLogin());

  const handleDeleteLogin = (id: number) => {
    mutate({ id: id });
  };

  const handleSelectedItems = (item: GroupItem) => {
    if (!selectedItems) return;

    if (selectedItems?.find(obj => obj.groupId === item.groupId)) {
      setSelectedItems(selectedItems?.filter(obj => obj.groupId !== item.groupId));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
    setIsButtonDisabled(false);
  };

  useEffect(() => {
    setSelectedItems(loginGroups);
  }, [loginGroups]);

  const allGroups = logins?.data.groups;

  const filteredGroupsList = searchInputValue
    ? allGroups?.filter(group => group.name.toLowerCase().includes(searchInputValue))
    : allGroups;

  const handleConfirm = (id: number) => {
    handleDeleteLogin(id);
    handleModal();
    handleSelectedLoginId?.(null);
  };

  const handleEdit = () => handleForm();

  const handleAddGroupsToLogin = (id: number) => {
    addGroupsToLoginMutation({
      login: id,
      groups: selectedItems ? selectedItems?.map(item => item.groupId) : [],
    });
    handleSelectedLoginId(null);
  };

  const selectedLogin = logins?.data.logins.find(login => login.loginId === selectedLoginId);

  return (
    <Container>
      <Title onClick={() => handleSelectedLoginId(null)}>
        <ArrowLeft />
        {selectedLogin?.user}
        <StyledBadge>@kandk</StyledBadge>
      </Title>
      {!selectedLogin?.groups.length && (
        <AddGroupSteps text={t('selectGroups')} number={2} icon={<StepTwoIcon />} />
      )}
      <SearchSection>
        <SpeditionSearchInput
          searchInputValue={searchInputValue}
          handleInputChange={e => handleInputChange?.(e)}
        />
        <HandleGroupButton
          onClick={() => {
            handleForm?.();
            handleChangePasssword?.();
          }}
        >
          <SVG
            src={LockIcon}
            width={16}
            height={16}
            preProcessor={code => code.replace(/fill=".*?"/g, 'fill="#252E3B"')}
          />
        </HandleGroupButton>
        <HandleGroupButton onClick={handleEdit}>
          <SVG
            src={Pencil}
            width={16}
            height={16}
            preProcessor={code => code.replace(/fill=".*?"/g, 'fill="#252E3B"')}
          />
        </HandleGroupButton>
        <HandleGroupButton
          onClick={() => {
            setIsConfirmationModalOpen(true);
          }}
        >
          <SVG
            src={DeleteIcon}
            width={16}
            height={16}
            preProcessor={code => code.replace(/fill=".*?"/g, 'fill="#252E3B"')}
          />
        </HandleGroupButton>
      </SearchSection>
      <StyledObjectsList isNewGroup={!!selectedLogin}>
        {isGroupListFetching ? (
          <LoadingIndicator />
        ) : (
          filteredGroupsList?.map(group => (
            <StyledObjectItem
              key={group.groupId}
              isMobile
              isActive={false}
              onClick={() => handleSelectedItems(group as unknown as GroupItem)}
            >
              <ItemNameWrapper>
                <Checkbox
                  checkboxSize={'md'}
                  checked={!!selectedItems?.find(item => item.groupId === group.groupId)}
                />
                <GroupNameWrapper>
                  <GroupColor color={group.color ?? ''} />
                  <ItemName>{group.name}</ItemName>
                </GroupNameWrapper>
              </ItemNameWrapper>
            </StyledObjectItem>
          ))
        )}
      </StyledObjectsList>
      <ConfirmSection>
        <Button
          primary
          fillWidth
          disabled={isButtonDisabled || isAddingGroupsToLoginLoading}
          onClick={() => selectedLoginId && handleAddGroupsToLogin(selectedLoginId)}
        >
          {`${t('confirmGroups')} (${selectedItems?.length})`}
        </Button>
      </ConfirmSection>
      <ConfirmationModal
        size="small"
        isOpen={isConfirmationModalOpen}
        title={t('confirmYourChoice')}
        message={t('confirmDeleteLogin')}
        confirmText={t('delete')}
        onConfirm={() => selectedLoginId && handleConfirm(selectedLoginId)}
        onClose={() => setIsConfirmationModalOpen(false)}
        disabled={isDeletingLoading}
        isLoading={isDeletingLoading}
      />
    </Container>
  );
};

export default MobileLoginGroupsList;

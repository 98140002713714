import Button from 'components/Button';
import { MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const ObjectInfoWrapper = styled.div`
  background: white;
  z-index: 3;
  border-radius: 0.6rem;
  overflow: hidden;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  background: ${p => p.theme.colors.secondaryBackground};
  padding: 1.8rem;
  text-align: center;
  max-height: 146px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Plate = styled.div`
  color: ${p => p.theme.colors.text};
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 1rem;
  height: 100%;
`;

export const CloseIcon = styled(MdClose)`
  position: absolute;
  right: 1rem;
  cursor: pointer;
  height: 1.3rem;
  width: auto;
  color: ${p => p.theme.colors.gray400};
  top: 1rem;
`;

export const Content = styled.div<{ height?: string }>`
  padding: 1.5rem;
  background: ${p => p.theme.colors.primaryBackground};
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  min-width: 280px;
  height: ${p => p.height ?? '315px'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RowWrapper = styled.div`
  max-width: 280px;
  max-height: 300px;
  overflow: auto;
  padding-right: 0.5rem;
  margin-right: -0.5rem;
  max-height: 250px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  padding: 1rem;
  border-bottom: 1px solid ${p => p.theme.colors.onPrimaryBorder};
  margin-right: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

export const Description = styled.div`
  color: ${p => p.theme.colors.text};
  display: flex;
  gap: 0.3rem;
  align-items: center;
`;

export const Value = styled.div<{ wrap?: boolean }>`
  margin-left: auto;
  color: ${p => p.theme.colors.textGray};
  max-width: 12rem;
  text-align: right;

  ${p =>
    p.wrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const RouteButton = styled(Button)`
  margin-top: 0.5rem;
  width: 100%;
`;

export const RouteLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

export const IconWrapper = styled.div`
  cursor: pointer;

  svg {
    height: 2rem;
    width: auto;
  }
`;

const StyledObjectInfoContent = {
  ObjectInfoWrapper,
  Wrapper,
  Header,
  Plate,
  CloseIcon,
  Content,
  RowWrapper,
  Row,
  Description,
  Value,
  ButtonWrapper,
  RouteButton,
  RouteLink,
  IconWrapper,
};

export default StyledObjectInfoContent;

import WWLogo from 'assets/ww-logo-small.svg';
import { driverMenuItems } from 'common/menuItems';
import Button from 'components/Button';
import LanguageSelect from 'components/LanguageSelect';
import { GroupElements } from 'components/MobileTopbar';
import AddAttachement from 'modules/driver/components/AddAttachement';
import DriverStatusToggler from 'modules/driver/components/DriverStatusToggler';
import { statusElementType, StatusStateType } from 'modules/driver/dtos';
import { getStatusList, updateStatus } from 'modules/driver/queries';
import { routes } from 'navigation/routes';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';
import { useMainLayout } from 'utils';

import StatusSection from './StatusSection';

const statusContainer = document.getElementById('status-container');

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1.6rem;
  height: 7.7rem;
  gap: ${p => p.theme.spacing.regular};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  overflow: auto;
`;

export const Img = styled.img`
  height: 40px;
  margin-right: 1rem;
`;

export const StyledButton = styled(Button)<{ isActive: boolean }>`
  border: ${({ isActive, theme }) =>
    isActive ? `1px solid ${theme.colors.onPrimaryBorder}` : 'none'};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary600};
  }
`;

const DriverTopbar = () => {
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { showMobileModal, setShowMobileModal } = useMainLayout();

  const [isMoreSectionOpen, setIsMoreSectionOpen] = useState<boolean>(false);

  const handleMoreClick = () => {
    setShowMobileModal(!showMobileModal);
    setIsMoreSectionOpen(prev => !prev);
  };

  const handleMenuItemClick = useCallback(
    (route?: string) => {
      if (!route) return;

      if (route === routes.driverGallery) {
        handleMoreClick();
      } else navigate(route);
    },
    [navigate],
  );

  const handleStatusClick = () => {
    setShowMobileModal(!showMobileModal);
  };

  const { data: status, isFetching: isStatusFetching } = useQuery(getStatusList());
  const { mutate } = useMutation(updateStatus());

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [statusList, setStatusList] = useState<statusElementType[]>(status?.data || []);

  useEffect(() => {
    if (!status) return;

    setStatusList(status?.data);
  }, [status]);

  const handleStatusItemClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setStatusList((prevState: statusElementType[]) => {
      const updatedStatusList = prevState.map(status => {
        if (e.target instanceof HTMLElement && status.id === Number(e.target.id)) {
          return { ...status, state: 1 as StatusStateType };
        }
        return { ...status, state: 0 as StatusStateType };
      });
      return updatedStatusList;
    });
    setIsEditable(true);
  };

  const handleToggle = (id: number | string) => {
    setStatusList((prevState: statusElementType[]) => {
      const updatedStatusList = prevState.map(status => {
        if (typeof id === 'number' && id === status.id) {
          return { ...status, state: 1 as StatusStateType };
        }
        return { ...status, state: 0 as StatusStateType };
      });
      return updatedStatusList;
    });
    setIsEditable(true);
  };

  return (
    <Wrapper>
      <Img src={WWLogo} onClick={() => navigate(routes.driverMap)} />

      {Object.entries(driverMenuItems).map(
        ([name, { icon, isDropdown, hideArrow, route, disabled }]) => (
          <StyledButton
            key={name}
            icon={icon}
            secondary
            isIconOrange={false}
            isDropdown={isDropdown}
            hideArrow={hideArrow}
            backgroundColor={location.pathname === route ? colors.primaryBackground : undefined}
            isActive={location.pathname === route}
            onClick={() => handleMenuItemClick(route)}
            disabled={disabled}
          >
            {t(name)}
          </StyledButton>
        ),
      )}
      <StatusSection onClickHandler={handleStatusClick} />
      <GroupElements>
        {/* <NotificationsIcon disabled={temporarilyHidden} /> */}
        <LanguageSelect />
      </GroupElements>
      {showMobileModal &&
        createPortal(
          isMoreSectionOpen ? (
            <AddAttachement onClose={handleMoreClick} />
          ) : (
            <DriverStatusToggler
              handleConfirm={() => mutate({ data: statusList })}
              handleStatusItemClick={handleStatusItemClick}
              statusList={statusList}
              isStatusFetching={isStatusFetching}
              handleToggle={handleToggle}
              isEditable={isEditable}
            />
          ),
          statusContainer!,
        )}
    </Wrapper>
  );
};

export default DriverTopbar;

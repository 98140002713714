import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import { ReactComponent as StepOneIcon } from 'assets/icons/half-circle.svg';
import { GROUPS } from 'common';
import Button from 'components/Button';
import InputFormField from 'components/InputFormField';
import { MobileTitle } from 'components/Table/style';
import TextAreaFormField from 'components/TextAreaFormField';
import { Form, Formik } from 'formik';
import i18n from 'locales/i18n';
import { Title } from 'modules/driver/pages/Spedition';
import { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { useWindowSize } from 'utils';
import * as Yup from 'yup';

import { CreateGroupDto, GroupItem, GroupsResponseDto, UpdateGroupDto } from '../dtos';
import AddGroupSteps from './AddGroupSteps';
import ColorSelect from './ColorSelect';

export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile, theme }) => (isMobile ? '0' : theme.spacing.large)};
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  margin: 0 auto;
  padding: ${({ isMobile }) => (isMobile ? '0' : '40px')};

  ${({ theme }) => theme.mq.lg} {
    width: 40%;
  }
`;

export const NewGroupWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isMobile, theme }) => (isMobile ? theme.spacing.large : '0')};
`;

export const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing.large};
  padding: ${({ isMobile, theme }) => (isMobile ? theme.spacing.large : '0')};
`;

export const StyledInputFormField = styled(InputFormField)`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  padding: ${({ theme }) => theme.spacing.large};
  border: none;

  color: ${({ theme }) => theme.colors.gray700};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  label {
    color: ${({ theme }) => theme.colors.gray800};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const StyledMobileTitle = styled(MobileTitle)`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${({ theme }) => theme.colors.gray100};
  width: 100%;
  padding: 1rem 2rem;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
`;

const GroupFormSchema = (t: TFunction) =>
  Yup.object().shape({
    color: Yup.string().required(t('required')),
    name: Yup.string()
      .min(3, t('minCharacters', { number: 3 }))
      .max(30, t('maxCharacters', { number: 30 }))
      .required(t('required')),
    comment: Yup.string().max(100, t('maxCharacters', { number: 100 })),
  });

const GroupForm = ({
  handleGroupForm,
  groupHandler,
  isLoading,
  groupId,
  handleGroupId,
}: {
  handleGroupForm: () => void;
  groupHandler: (data: UpdateGroupDto | CreateGroupDto) => void;
  isLoading: boolean;
  groupId: number | null;
  handleGroupId: (id: number | null) => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const [scheme, setScheme] = useState<Yup.AnyObjectSchema>(GroupFormSchema(t));

  const queryClient = useQueryClient();

  const groupData: GroupItem | undefined =
    (queryClient.getQueryData(GROUPS) as GroupsResponseDto) &&
    (queryClient.getQueryData(GROUPS) as GroupsResponseDto).data.groups.find(
      group => group.groupId === groupId,
    );

  const initialValues = {
    color: groupData?.color ?? '#FFF',
    name: groupData?.name ?? '',
    comment: groupData?.comment ?? '',
  };

  useEffect(() => {
    async function onLanguageChanged() {
      setScheme(GroupFormSchema(t));
    }

    i18n.on('languageChanged', onLanguageChanged);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [i18n, t, GroupFormSchema]);

  const handleSubmit = (data: CreateGroupDto | UpdateGroupDto) => {
    if (groupId) {
      (data as UpdateGroupDto).groupId = groupId;
    }
    groupHandler(data);
    handleGroupForm();
    handleGroupId(null);
  };

  return (
    <Container isMobile={isMobile}>
      {isMobile ? (
        <StyledMobileTitle onClick={handleGroupForm}>
          <ArrowLeft />
          {groupId ? t('editing') : t('addingGroup')}
        </StyledMobileTitle>
      ) : (
        <Title>{t('addNewGroup')}</Title>
      )}
      {isMobile && !groupId && (
        <AddGroupSteps text={t('addGroup')} icon={<StepOneIcon />} number={1} />
      )}
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={scheme}>
        {formikProps => (
          <Form>
            <NewGroupWrapper isMobile={isMobile}>
              <ColorSelect initialValue={initialValues.color} />
              <StyledInputFormField
                width={isMobile ? '100%' : '312px'}
                name={'name'}
                label={t('name')}
                placeholder={t('addName')}
                noLabel
                inputSize="md"
                type="text"
              />
              <TextAreaFormField
                placeholder={t('addComment')}
                name={'comment'}
                label={t('comment')}
              />
            </NewGroupWrapper>
            <ButtonWrapper isMobile={isMobile}>
              {!isMobile && (
                <Button secondary onClick={handleGroupForm}>
                  {t('goBack')}
                </Button>
              )}
              <Button primary fillWidth={isMobile} disabled={isLoading || !formikProps.dirty}>
                {groupId ? t('edit') : t('addGroup')}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default GroupForm;

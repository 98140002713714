import { ReactComponent as ChatSVG } from 'assets/icons/chat.svg';
import { ReactComponent as MapSVG } from 'assets/icons/map.svg';
import { ReactComponent as MoreSVG } from 'assets/icons/more.svg';
import { ReactComponent as Spedition } from 'assets/icons/spedition.svg';
import i18n from 'locales/i18n';
import { routes } from 'navigation/routes';
import { FaUserCog } from 'react-icons/fa';
import {
  MdAssignment,
  MdDirections,
  MdDirectionsCar,
  MdSettingsInputHdmi,
  MdShare,
  MdStar,
} from 'react-icons/md';

import { AllowedModules } from './AllowedModules';
import { Roles } from './Roles';

i18n.loadNamespaces('general');

export type NestedDetails = {
  route: string;
  label: string;
  value: string;
  module?: AllowedModules;
};

export type Details = {
  isDropdown?: boolean;
  icon?: React.ReactElement;
  options?: NestedDetails[];
  route?: string;
  hideArrow?: boolean;
  label: string;
  disabled?: boolean;
  module?: AllowedModules;
  role?: Roles;
};

export enum AppModules {
  OBJECTS = 'objects',
  ROUTES = 'routes',
  REPORTS = 'reports',
  SHARING = 'sharing',
  PREMIUM = 'premium',
  INTEGRATIONS = 'integrations',
  SPEDITION = 'spedition',
  CHAT = 'chat',
  ADMIN = 'admin',
}

export enum AppModulesPremium {
  SENT = 'sent-geo',
  ETOLL = 'etoll',
}

export enum AppModulesIntegrations {
  SAMSUNG = 'samsung',
}

export enum AppModulesAdmin {
  LOGINS_AND_GROUPS = 'loginsAndGroups',
}

// TODO: fill routes
export const menuItems: Record<string, Details> = {
  objects: {
    icon: <MdDirectionsCar />,
    route: routes.objects,
    hideArrow: true,
    label: i18n.t('objects'),
    role: Roles.USER,
  },
  routes: {
    icon: <MdDirections />,
    route: routes.route,
    hideArrow: true,
    label: i18n.t('routes'),
    role: Roles.USER,
  },
  reports: {
    icon: <MdAssignment />,
    route: routes.reports,
    hideArrow: true,
    label: i18n.t('reports'),
    role: Roles.USER,
  },
  sharing: {
    icon: <MdShare />,
    hideArrow: true,
    label: i18n.t('sharing'),
    role: Roles.USER,
    route: routes.sharing,
  },
  premium: {
    isDropdown: true,
    icon: <MdStar />,
    options: [
      {
        route: routes.premiumSentGeo,
        label: i18n.t('sent'),
        value: 'sent-geo',
        module: AllowedModules.PUESC_ACCESS,
      },
      {
        route: routes.premiumEtoll,
        label: i18n.t('toll'),
        value: 'etoll',
        module: AllowedModules.PUESC_ACCESS,
      },
    ],
    label: i18n.t('premium'),
    module: AllowedModules.PUESC_ACCESS,
    role: Roles.USER,
  },
  integrations: {
    isDropdown: true,
    options: [
      {
        route: routes.samsungIntegration,
        label: i18n.t('samsung'),
        value: 'samsung',
        module: AllowedModules.INTEGRATIONS_SAMSUNG,
      },
    ],
    label: i18n.t('integrations'),
    icon: <MdSettingsInputHdmi />,
    module: AllowedModules.INTEGRATIONS_SAMSUNG,
    role: Roles.USER,
  },
  spedition: {
    icon: <Spedition />,
    route: routes.spedition,
    hideArrow: true,
    label: i18n.t('spedition'),
    module: AllowedModules.DRIVER_STATUS,
    role: Roles.USER,
  },
  chat: {
    icon: <ChatSVG />,
    route: routes.chat,
    hideArrow: true,
    label: i18n.t('chat'),
    module: AllowedModules.DRIVER_STATUS,
    role: Roles.USER,
  },
  admin: {
    isDropdown: true,
    icon: <FaUserCog />,
    options: [
      {
        route: routes.loginsAndGroups,
        label: i18n.t('logins&groups'),
        value: 'loginsAndGroups',
      },
    ],
    label: i18n.t('admin'),
    role: Roles.ADMIN,
  },
};

export const menuItemsList = Object.keys(menuItems);

/**
 * This function is used to get menu item data
 * @param menuItem
 */
export const getMenuItemData = (menuItem: string) => menuItems[menuItem] ?? null;

/**
 * This function is used to get sub menu item data
 * @param menuItem
 * @param subMenuItem
 */
export const getSubMenuItemData = (menuItem: string, subMenuItem: string) => {
  const itemData = menuItems[menuItem] ?? null;
  if (!itemData) return null;

  return itemData.options?.find(option => option.value === subMenuItem) ?? null;
};

export const driverMenuItems: Record<string, Details> = {
  map: {
    icon: <MapSVG />,
    route: routes.driverMap,
    hideArrow: true,
    label: i18n.t('map'),
    disabled: false,
  },
  messenger: {
    icon: <ChatSVG />,
    route: routes.driverMessenger,
    hideArrow: true,
    label: i18n.t('driverMessenger'),
    disabled: false,
  },
  more: {
    icon: <MoreSVG />,
    route: routes.driverGallery,
    hideArrow: true,
    label: i18n.t('more'),
    disabled: false,
  },
};

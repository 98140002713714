export default class StringBuilder {
  private createdString = '';

  /**
   * Add additional text to create string
   * @param text
   */
  public add(text: string | number): StringBuilder {
    this.createdString = this.createdString + text;

    return this;
  }

  /**
   * Returns created string
   */
  public getText(): string {
    return this.createdString;
  }
}

import Arrow from 'assets/arrow-right.svg';
import CloseIcon from 'assets/close.svg';
import { LocalStorageKeys } from 'common';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';
import { useMainLayout, useWindowSize } from 'utils';

import { statusElementType, StatusStateType } from '../dtos';
import DriverStatusToggler from './DriverStatusToggler';

export const Icon = styled.div<{ isExpanded: boolean; isMobile?: boolean }>`
  transform: rotate(${({ isExpanded }) => (isExpanded ? '90deg' : '-90deg')});
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  top: 0.55rem;
  right: 1rem;
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: rgba(56, 68, 84, 0.5);

  path {
    fill: ${p => p.theme.colors.primary600};
  }
`;

const LegendWrapper = styled.div<{ isMobile?: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
  width: ${({ isMobile }) => (isMobile ? '70vw' : '25vw')};
  font-size: ${({ isMobile }) => (isMobile ? '1rem' : '1.3rem')};

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    top: 6rem;
  }
`;

const LegendListWrapper = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  opacity: 1;
  border-radius: 6px 6px 0 0;
  transition: opacity 0.2s ease-out;
  background-color: rgba(37, 46, 59, 0.3);
  padding: 8px 8px 8px 8px;

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      padding-bottom: 0.5rem;
      border-radius: 6px;
    `}
`;

const LegendListWrapperCollapsible = styled(LegendListWrapper)<{ isExpanded?: boolean }>`
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  border-radius: 0 0 6px 6px;
  padding-top: 0;
  padding-bottom: 8px;
`;

const LegendItem = styled.li<{ isFilterOn?: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background: ${p => p.theme.colors.gray500};
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray800};
  padding: ${p => p.theme.spacing.xxSmall} ${p => p.theme.spacing.regular};

  ${({ isFilterOn }) =>
    isFilterOn &&
    css`
      background: ${({ theme }) => theme.colors.gray800};
    `};
`;

const StatusColor = styled.div<{ color: string }>`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${({ color }) => color};
  border-radius: 3rem;
`;

const StatusLabel = styled.div`
  font-weight: 600;
`;

export interface statusElementTypeWithFilters extends statusElementType {
  isFilterOn: boolean;
}

const statusContainer = document.getElementById('status-container');

const StatusExplanatory = ({ statuses }: { statuses?: statusElementType[] }) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const { statusFilters, setStatusFilters, showMobileModal, setShowMobileModal } = useMainLayout();

  const [isLegendShown, setIsLegendShown] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const handleClick = () => {
    if (isMobile) {
      setShowMobileModal(true);
    } else {
      setIsLegendShown(!isLegendShown);
    }
  };

  const handleFilterChange = (label: string | number) => {
    if (!statusFilters || typeof label !== 'string') return;
    const newFilters = statusFilters.includes(label)
      ? [...statusFilters].filter(filter => filter !== label)
      : [...statusFilters, label];

    localStorage.setItem(LocalStorageKeys.STATUS_FILTERS, JSON.stringify(newFilters));
    setStatusFilters?.(newFilters);
    setIsEditable(true);
  };

  const withFilters = statuses?.map((status: statusElementType) => ({
    ...status,
    isFilterOn: !!statusFilters?.find(filter => filter === status.label),
  }));

  const statusList = withFilters?.map(el => ({
    ...el,
    state: statusFilters?.includes(el.label) ? (1 as StatusStateType) : (0 as StatusStateType),
  }));

  const handleLegendClickOnMobile = () => {
    if (isMobile) {
      setShowMobileModal(true);
    }
  };

  return (
    <LegendWrapper isMobile={isMobile}>
      <div onClick={handleLegendClickOnMobile}>
        {/* <--- visible statuses ---> */}
        <LegendListWrapper isExpanded={isLegendShown && !isMobile}>
          {withFilters?.slice(0, 2).map((status: statusElementTypeWithFilters) => (
            <LegendItem
              onClick={() => handleFilterChange(status.label)}
              key={status.id}
              isFilterOn={statusFilters?.includes(status.label)}
            >
              <StatusColor color={status.color} />
              <StatusLabel>{t(status.label)}</StatusLabel>
              {statusFilters?.includes(status.label) && (
                <SVG src={CloseIcon} width={!isMobile ? 16 : 12} height={!isMobile ? 16 : 12} />
              )}
            </LegendItem>
          ))}
        </LegendListWrapper>

        {/* <--- hidden statuses ---> */}
        <LegendListWrapperCollapsible isExpanded={isLegendShown && !isMobile}>
          {withFilters?.slice(2).map((status: statusElementTypeWithFilters) => (
            <LegendItem
              onClick={() => handleFilterChange(status.label)}
              key={status.id}
              isFilterOn={statusFilters?.includes(status.label)}
            >
              <StatusColor color={status.color} />
              <StatusLabel>{t(status.label)}</StatusLabel>
              {statusFilters?.includes(status.label) && (
                <SVG src={CloseIcon} width={!isMobile ? 16 : 12} height={!isMobile ? 16 : 12} />
              )}
            </LegendItem>
          ))}
        </LegendListWrapperCollapsible>
      </div>

      {/* <--- open/close icon ---> */}
      <Icon isExpanded={isLegendShown && !isMobile} onClick={handleClick} isMobile={isMobile}>
        <SVG src={Arrow} width={24} />
      </Icon>

      {showMobileModal &&
        createPortal(
          <DriverStatusToggler
            handleConfirm={() => setShowMobileModal(false)}
            statusList={statusList ?? []}
            handleToggle={handleFilterChange}
            isEditable={isEditable}
          />,
          statusContainer!,
        )}
    </LegendWrapper>
  );
};

export default StatusExplanatory;

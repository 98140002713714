import './styles.css';

import { useGoogleMap } from '@react-google-maps/api';
import { MapTypes } from 'common';
import CarToggleSwitch from 'components/CarToggleSwitch';
import useCarToggleSwitch from 'components/CarToggleSwitch/useCarToggleSwitch';
import LoadingIndicator from 'components/LoadingIndicator';
import StyledObjectInfoContent from 'components/ObjectInfo/ObjectInfoContent.styled';
import { useInfoboxDataFetching } from 'components/OpenStreetMap/useInfoboxDataFetching';
import Tabs from 'components/Tabs';
import { routes } from 'navigation/routes';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { useMainLayout, useWindowSize } from 'utils';

import { ObjectInfoProps, ObjectInfoTypes } from '.';
import { generalContent } from './generalContent';

export enum ObjectInfoTabs {
  GENERAL = 'general',
  CAN = 'can',
}

type ObjectInfoContentProps = ObjectInfoProps;

const ObjectInfoContent = ({
  objectId,
  location,
  type = ObjectInfoTypes.DEFAULT,
  fuelData,
  duration,
  stopTime,
}: ObjectInfoContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { isMobile } = useWindowSize();

  const { mapType, setIsInfoboxVisible, setSelectedVehicleId } = useMainLayout();

  const map = mapType === MapTypes.GOOGLE ? useGoogleMap() : undefined;

  const [showFullAddress, setShowFullAddress] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(ObjectInfoTabs.GENERAL);

  const {
    currentObject,
    isObjectDataFetching,
    isObjectExtendedDataFetching,
    objectExtendedData,
    refetchObjectExtendedData,
    objectGeocodingData,
    isGeocodingDataFetching,
    getGeocodingRefetch,
  } = useInfoboxDataFetching(objectId?.toString(), location ?? { lat: 0, lng: 0 });

  const { renderToggleSwitch } = useCarToggleSwitch(currentObject?.controls?.ignition);

  const handleActiveTab = useCallback((value: string) => setActiveTab(value as ObjectInfoTabs), []);

  useEffect(() => {
    if (objectId) {
      refetchObjectExtendedData();
      getGeocodingRefetch();
    }
  }, [objectId, location]);

  const handleShowMoreAddress = useCallback(() => setShowFullAddress(current => !current), []);

  useEffect(() => {
    if (type !== ObjectInfoTypes.DEFAULT) setShowFullAddress(true);
  }, [type]);

  const generalContentData = generalContent(
    objectId,
    currentObject,
    objectGeocodingData,
    objectExtendedData,
    showFullAddress,
    duration,
    type,
    fuelData,
    stopTime,
    handleShowMoreAddress,
  );

  const tabsOptions = [
    {
      value: ObjectInfoTabs.GENERAL,
      label: t('general'),
    },
    {
      value: ObjectInfoTabs.CAN,
      label: t('can'),
    },
  ];

  const canContent = useMemo(
    () =>
      Object.values(currentObject?.details ?? [])
        .filter(i => i !== null)
        .map(el => ({ label: el.name, value: `${el.value} ${el.units}` })),
    [currentObject?.details],
  );

  const content = Object.values(
    activeTab === ObjectInfoTabs.GENERAL ? generalContentData : canContent,
  );

  const routeUrl = `${routes.route}?objectId=${objectId}&objectName=${currentObject?.number}`;

  useEffect(() => {
    if (!map || !location) return;
    map.setCenter(location);

    if (!isMobile) {
      map.panBy(0, -250);
    }
  }, [objectId, location]);

  const handleCloseClick = () => {
    setIsInfoboxVisible(false);
    setSelectedVehicleId?.(undefined);
  };

  /**
   * This is used to render indicator for loading data
   * for first time when object is selected
   */
  const isDataLoading = useMemo(() => {
    if (objectGeocodingData) return false;

    return isObjectExtendedDataFetching || isObjectDataFetching || isGeocodingDataFetching;
  }, [
    objectGeocodingData,
    isObjectExtendedDataFetching,
    isObjectDataFetching,
    isGeocodingDataFetching,
  ]);

  return (
    <StyledObjectInfoContent.ObjectInfoWrapper>
      <StyledObjectInfoContent.Wrapper>
        {isDataLoading ? (
          <StyledObjectInfoContent.Content height={'453px'}>
            <LoadingIndicator centerVertically />
          </StyledObjectInfoContent.Content>
        ) : (
          <>
            <StyledObjectInfoContent.Header>
              <StyledObjectInfoContent.CloseIcon onClick={handleCloseClick} />
              <StyledObjectInfoContent.Plate>
                {currentObject?.number ? currentObject?.number : ''}
              </StyledObjectInfoContent.Plate>

              {renderToggleSwitch && (
                <CarToggleSwitch
                  withoutBorder
                  withBackground
                  objectId={currentObject?.objectId}
                  initialStatus={!!currentObject?.controls?.ignition?.state}
                  isPending={!!currentObject?.controls?.ignition?.pending}
                  isCanAvailable={!!currentObject?.controls?.ignition?.canStatus}
                />
              )}

              <Tabs small activeTab={activeTab} options={tabsOptions} onClick={handleActiveTab} />
            </StyledObjectInfoContent.Header>
            <StyledObjectInfoContent.Content>
              <StyledObjectInfoContent.RowWrapper>
                {content.map((data, index) => (
                  <StyledObjectInfoContent.Row key={`${data.index}-${data.value}-${index}`}>
                    <>
                      <StyledObjectInfoContent.Description>
                        {data.label}
                        {data.showExpandMore && (
                          <StyledObjectInfoContent.IconWrapper onClick={data.onExpandMoreClick}>
                            {data.isExpanded ? (
                              <MdExpandLess color={theme.colors.primary600} />
                            ) : (
                              <MdExpandMore color={theme.colors.primary600} />
                            )}
                          </StyledObjectInfoContent.IconWrapper>
                        )}
                      </StyledObjectInfoContent.Description>
                      <StyledObjectInfoContent.Value wrap={!data.isExpanded}>
                        {data.value ?? '-'}
                      </StyledObjectInfoContent.Value>
                    </>
                  </StyledObjectInfoContent.Row>
                ))}
              </StyledObjectInfoContent.RowWrapper>

              {type === ObjectInfoTypes.DEFAULT && currentObject && (
                <StyledObjectInfoContent.ButtonWrapper>
                  <StyledObjectInfoContent.RouteLink to={routeUrl}>
                    <StyledObjectInfoContent.RouteButton
                      fillWidth
                      onClick={handleCloseClick}
                      secondary
                      size="xs"
                    >
                      {t('route')}
                    </StyledObjectInfoContent.RouteButton>
                  </StyledObjectInfoContent.RouteLink>
                  <StyledObjectInfoContent.RouteLink
                    to={routes.roadCardPreview(currentObject.objectId)}
                  >
                    <StyledObjectInfoContent.RouteButton
                      fillWidth
                      onClick={handleCloseClick}
                      secondary
                      size="xs"
                    >
                      {t('report')}
                    </StyledObjectInfoContent.RouteButton>
                  </StyledObjectInfoContent.RouteLink>
                </StyledObjectInfoContent.ButtonWrapper>
              )}
            </StyledObjectInfoContent.Content>
          </>
        )}
      </StyledObjectInfoContent.Wrapper>
    </StyledObjectInfoContent.ObjectInfoWrapper>
  );
};

export default memo(ObjectInfoContent);

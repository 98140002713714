import StyledTable from 'components/StyledTable';
import SharingListSkeleton from 'modules/sharing/components/sharing-list/SharingList.skeleton';
import StyledSharingList from 'modules/sharing/components/sharing-list/SharingList.styled';
import SharingListItem from 'modules/sharing/components/sharing-list/SharingListItem';
import { getShareList } from 'modules/sharing/queries';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const SharingList = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(getShareList());

  /**
   * Render memoized list
   */
  const renderList = useMemo(() => {
    if (!data && isLoading) {
      return <SharingListSkeleton />;
    }

    if (!data && !isLoading) {
      return (
        <StyledTable.Row>
          <StyledTable.Cell>{t('noData')}</StyledTable.Cell>
        </StyledTable.Row>
      );
    }

    return data?.map(item => <SharingListItem key={item.id} data={item} />);
  }, [data, isLoading, t]);

  return (
    <StyledSharingList.Wrapper>
      <StyledTable.Wrapper>
        <StyledTable.Table>
          <StyledTable.RowHead>
            <StyledTable.HeadCell>{t('vehicle')}</StyledTable.HeadCell>
            <StyledTable.HeadCell>{t('range')}</StyledTable.HeadCell>
            <StyledTable.HeadCell>{t('actions')}</StyledTable.HeadCell>
          </StyledTable.RowHead>

          <StyledTable.Body>{renderList}</StyledTable.Body>
        </StyledTable.Table>
      </StyledTable.Wrapper>
    </StyledSharingList.Wrapper>
  );
};

export default SharingList;

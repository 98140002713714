import { t } from 'i18next';
import Toggle from 'react-styled-toggle';
import styled from 'styled-components';

import { StatusStateType } from '../dtos';

interface StatusTileProps {
  color: string;
  isOn: boolean;
  isEditable?: boolean;
  onClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
  handleToggle: (val: number | string) => void;
  label: string;
  state: StatusStateType;
  id: number;
  secondaryColor: string;
}

const StatusTileWrapper = styled.div<{
  color: string;
  state: StatusStateType;
  secondaryColor: string;
}>`
  display: flex;
  height: 5rem;
  width: 100%;
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  border-left: 1rem solid ${({ color }) => color};
  background: ${({ state, secondaryColor, theme }) =>
    state ? secondaryColor : theme.colors.gray100};
  font-weight: 600;
`;

const StatusLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
`;

const StatusDot = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: ${({ color }) => color};
`;

const StatusTile = ({
  color,
  secondaryColor,
  onClickHandler,
  handleToggle,
  label,
  state,
  id,
}: StatusTileProps) => (
  <StatusTileWrapper
    color={color}
    secondaryColor={secondaryColor}
    onClick={onClickHandler}
    id={id.toString()}
    state={state}
  >
    <StatusLabel id={id.toString()}>
      <StatusDot color={color} id={id.toString()} />
      {t(label)}
    </StatusLabel>
    <Toggle
      checked={!!state}
      backgroundColorChecked={'#25B060'}
      backgroundColorUnchecked={'#C0C5D0'}
      name={'toggle'}
      onChange={() => handleToggle(id)}
      disabled={false}
      value={state.toString()}
      labelRight={''}
      labelLeft={''}
      sliderHeight={24}
      sliderWidth={24}
      height={24}
      width={50}
    />
  </StatusTileWrapper>
);

export default StatusTile;

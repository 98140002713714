import AddIcon from 'assets/icons/add.svg';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';

import { ItemContainer } from './GroupItem';

const StyledItemContainer = styled(ItemContainer)<{ isMobile?: boolean }>`
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};

  color: ${({ theme }) => theme.colors.gray700};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  &:hover:not([disabled]),
  &:active {
    border-color: ${p => p.theme.colors.secondaryBackground};
  }

  &:active {
    background: ${p => p.theme.colors.primary200};
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: fixed;
      bottom: 0;
      margin-bottom: 15px;
      width: 95vw;
      left: 2.2vw;
      height: 4rem;
    `}
`;

const AddGroupItem = ({
  clickHandler,
  isMobile,
  addButtonText,
}: {
  clickHandler?: () => void;
  isMobile?: boolean;
  addButtonText: string;
}) => (
  <StyledItemContainer onClick={clickHandler} isMobile={isMobile}>
    <SVG src={AddIcon} width={16} height={16} />
    {addButtonText}
  </StyledItemContainer>
);

export default AddGroupItem;

import NotFoundImg from 'assets/images/404.png';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useWindowSize } from 'utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vh;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.secondaryBackground}};
`;

const NotFoundImage = styled.img<{ isMobile: boolean }>`
  height: ${p => (p.isMobile ? '176px' : '300px')};
  width: ${p => (p.isMobile ? '346px' : '590px')};
`;

const Message = styled.p<{ isMobile?: boolean; small?: boolean }>`
  color: ${p => (p.small ? p.theme.colors.gray800 : p.theme.colors.gray700)};
  font-size: ${p => (p.isMobile ? '18px' : p.small ? '16px' : '24px')};
  font-weight: 600;
  line-height: 30px;
`;

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  return (
    <Container>
      <NotFoundImage src={NotFoundImg} isMobile={isMobile} />
      <Message isMobile={isMobile}>{t('notFound')}</Message>
      <Message small>{t('tryLater')}</Message>
      <Button secondary onClick={() => navigate(-1)}>
        {t('returnToPreviousPage')}
      </Button>
    </Container>
  );
};

export default NotFound;

import { DefaultOptions, QueryClient } from 'react-query';

const defaultOptions: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: Infinity,
  },
};

const queryClient = new QueryClient({ defaultOptions });

const toggleAllQueries = (enabled: boolean) => {
  queryClient.setDefaultOptions({
    queries: {
      ...defaultOptions.queries,
      enabled,
    },
  });
};

export const enableAllQueries = () => toggleAllQueries(true);
export const disableAllQueries = () => toggleAllQueries(false);

export default queryClient;

import { IntegrationObjectStatus } from 'modules/integrations/dtos/IntegrationObjectResponse.dto';
import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
  width: 50vw;
  height: 100%;
  margin: auto;
  overflow: auto;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    width: 100%;
    padding: 2.4rem 1rem;
  }
`;

interface ChipProps {
  status: IntegrationObjectStatus;
}

export const Chip = styled.div<ChipProps>`
  display: inline-flex;
  color: ${p => p.theme.colors.white};
  background: ${p => (p.status > 0 ? p.theme.colors.success300 : p.theme.colors.error300)};
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
  white-space: nowrap;
`;

import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ObjectStatus } from 'components/common/ObjectStatus';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import { ReceiversData } from '../queries/socket';
import { prepareTime } from './utils';

const ListItemWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
  padding: ${({ theme }) => theme.spacing.small};
  background: ${({ isActive, theme }) => (isActive ? theme.colors.secondaryBackground : 'inherit')};
`;

export const IsReadDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.objectStatusColor.moving};
`;

const ListItemName = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  display: flex;
  flex-direction: column;
`;

const VehiclesNumber = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
`;

const Message = styled.div<{ isBold?: boolean; isItalic?: boolean }>`
  font-size: 14px;
  font-weight: ${({ isBold }) => (isBold ? '600' : '400')};
  line-height: 22px;
  font-style: ${({ isItalic }) => (isItalic ? 'italic' : 'normal')};
`;

export const DateTime = styled.div`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-left: auto;
  margin-right: ${({ theme }) => theme.spacing.xSmall};
`;

const PhotoInfo = styled.p`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xSmall};
  font-style: italic;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray400};
`;

interface ListItemsProps {
  message: ReceiversData;
  onClickHandler?: (message: ReceiversData) => void;
  messageClickedId?: number;
  status: ObjectStatus;
  type: string | boolean;
  isTypingContent: boolean;
}

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const ChatListItem = ({
  message,
  onClickHandler,
  messageClickedId,
  status,
  type,
  isTypingContent,
}: ListItemsProps) => {
  const { t } = useTranslation();

  const prepareMessageContent = () => {
    if (isTypingContent) {
      return t('isTyping') + '...';
    }

    if (message.Message?.document) {
      return (
        <PhotoInfo>
          <CameraIcon />
          {t('sendsPhoto')}
        </PhotoInfo>
      );
    }

    if (message.Message?.content && message.Message?.content.length > 20) {
      return message.Message?.content.substring(0, 20) + '...';
    }
    return message.Message?.content;
  };

  return (
    <ListItemWrapper
      onClick={() => onClickHandler?.(message)}
      isActive={message.Account.id === messageClickedId}
    >
      {!!message?.Message && !message?.Message?.isSpeditorAccount && !message?.Message?.isRead && (
        <IsReadDot />
      )}
      <img
        src={require(`assets/vehicles/${type || 'car'}.svg`).default}
        style={{ filter: getObjectFilterColor(status) }}
      />
      <ListItemName>
        <VehiclesNumber>{message.Account.object.number}</VehiclesNumber>
        <Message
          isBold={!message?.Message?.isSpeditorAccount && !message.Message?.isRead}
          isItalic={isTypingContent}
        >
          {prepareMessageContent()}
        </Message>
      </ListItemName>
      {message.Message?.updatedAt && (
        <DateTime>{prepareTime(message.Message?.updatedAt ?? '', t)}</DateTime>
      )}
    </ListItemWrapper>
  );
};

export default ChatListItem;

import { memo } from 'react';
import styled, { keyframes } from 'styled-components/macro';

interface Props extends SvgProps {
  centerVertically?: boolean;
  small?: boolean;
}

const speed = 1.5;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 150;
    stroke-dashoffset: -24;
  }
  100% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: -124;
  }
`;

interface SvgProps {
  small?: boolean;
}

const Svg = styled.svg<SvgProps>`
  animation: ${rotate} ${speed * 1.75}s linear infinite;
  height: ${p => (p.small ? '1.25rem' : '3rem')};
  width: ${p => (p.small ? '1.25rem' : '3rem')};
  transform-origin: center;
`;

const Circle = styled.circle<Props>`
  animation: ${dash} ${speed}s ease-in-out infinite;
  stroke: ${p => p.theme.colors.primary700};
  stroke-linecap: round;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingIndicator = (props: Props) => {
  const Component = (
    <Svg data-testid="loading" {...props} viewBox="-24 -24 48 48">
      <Circle cx="0" cy="0" r="20" fill="none" strokeWidth="4"></Circle>
    </Svg>
  );

  return props.centerVertically ? <Wrapper>{Component}</Wrapper> : Component;
};

export default memo(LoadingIndicator);

import { memo, ReactNode } from 'react';
import styled from 'styled-components/macro';

import Tab from './Tab';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
`;

export type SingleTab = {
  value: string;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
};

type TabsProps = {
  activeTab: string;
  options: SingleTab[];
  onClick: (value: string) => void;
  noBorderRadius?: boolean;
  small?: boolean;
};

const Tabs = ({
  activeTab,
  options,
  onClick,
  noBorderRadius = false,
  small = false,
}: TabsProps) => (
  <Wrapper>
    <TabsWrapper>
      {options.map(({ value, label, icon, disabled }) => (
        <Tab
          onClick={() => onClick(value)}
          disabled={disabled}
          active={activeTab === value}
          value={value}
          key={value}
          noBorderRadius={noBorderRadius}
          small={small}
        >
          {icon} {label}
        </Tab>
      ))}
    </TabsWrapper>
  </Wrapper>
);

export default memo(Tabs);

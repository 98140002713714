import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  gap: ${({ theme }) => theme.spacing.large};
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.spacing.large} ${theme.spacing.large} ${theme.spacing.large} ${theme.spacing.small}`};
  gap: ${({ theme }) => theme.spacing.regular};
  position: relative;
`;

export const VehicleInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.regular};
`;

export const VehiclesNumber = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
`;

export const DriverInfo = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.borderGray};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.regular};
`;

export const DriversName = styled.div`
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const MessagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.large};
  overflow-y: auto;
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing.xLarge}`};

  ::before {
    content: '';
    height: 100%;
  }
`;

export const MessageItemContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.large};
  padding-right: ${({ theme }) => theme.spacing.large};
  width: 100%;
`;

export const Circle = styled.div`
  display: flex;
  width: 56px;
  height: 56px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.secondaryBackgroundDark};
`;

export const TopMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
`;

export const MessageContent = styled.div`
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  width: 100%;
  word-break: break-all;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.xxLarge};
  margin-top: 8px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const TextAreaWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  padding: ${({ isMobile, theme }) =>
    isMobile
      ? `${theme.spacing.regular} ${theme.spacing.xSmall}`
      : `${theme.spacing.regular} ${theme.spacing.xLarge}`};
  display: flex;
  gap: ${({ theme }) => theme.spacing.small};
  align-items: center;
`;

export const StyledTextArea = styled.textarea`
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.large}`};
  min-height: 42px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  color: ${({ theme }) => theme.colors.gray800};
  border: none;
  font-weight: 600;
  width: 100%;
  resize: none;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid #f44542;
  }
`;

export const StyledInput = styled.input`
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.large}`};
  height: 42px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  color: ${({ theme }) => theme.colors.gray800};
  border: none;
  font-weight: 600;
  width: 100%;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid #f44542;
  }
`;

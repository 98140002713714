import { useGoogleMap } from '@react-google-maps/api';
import { memo, useEffect } from 'react';
import { useMainLayout } from 'utils';

import { MarkerProps } from '.';

type MapSpyProps = {
  markers: MarkerProps[];
};

/**
 * This hook is used to calibrate map
 * Center on current markers
 *
 * it should run after setFitMapBounds in:
 * - change objectsGroupId
 * - change filteredObjectIds
 *
 * - on mount - default value (true)
 */
const MapSpy = ({ markers }: MapSpyProps) => {
  const map = useGoogleMap();
  const { fitMapBounds, setFitMapBounds } = useMainLayout();

  useEffect(() => {
    if (!map || !fitMapBounds || !markers.length) return;

    const bounds = new window.google.maps.LatLngBounds();
    markers.map(marker => {
      bounds.extend({
        lat: marker?.position?.lat,
        lng: marker?.position?.lng,
      });
    });
    map.fitBounds(bounds);

    setFitMapBounds(false);
  }, [map, markers, fitMapBounds]);

  return null;
};

export default memo(MapSpy);

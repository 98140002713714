import { ObjectsParamsDto } from 'modules/objects/dtos';
import { ExtendedDataOptionsDto } from 'modules/objects/dtos/ObjectsExtendedData.dto';
import { getGeocodingAddress, getObjects, getObjectsExtendedData } from 'modules/objects/queries';
import { Coordinates } from 'modules/route/common/Coordinates';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export const useInfoboxDataFetching = (id: string, coordinates: Coordinates) => {
  const objectsParams = useMemo(() => {
    if (!id) return undefined;

    return {
      ...(id && { 'object-filtering': [id] }),
    };
  }, [id]);

  const { data: objectsData, isFetching: isObjectDataFetching } = useQuery(
    getObjects(objectsParams as ObjectsParamsDto),
  );

  const currentObject = useMemo(
    () => objectsData?.data?.find(o => o.objectId === +id!),
    [objectsData, id],
  );

  const {
    isFetching: isObjectExtendedDataFetching,
    data: objectExtendedData,
    refetch: refetchObjectExtendedData,
  } = useQuery(
    getObjectsExtendedData(
      {
        objects: [+id!],
        request: [
          ExtendedDataOptionsDto.WORKTIME,
          ExtendedDataOptionsDto.DISTANCE,
          ExtendedDataOptionsDto.START_IGNITION,
        ],
      },
      { enabled: !!id },
    ),
  );

  const {
    data: objectGeocodingData,
    isFetching: isGeocodingDataFetching,
    refetch: getGeocodingRefetch,
  } = useQuery(
    getGeocodingAddress(
      {
        lat: coordinates?.lat ?? 0,
        lon: coordinates?.lng ?? 0,
        cachable: 1,
      },
      { enabled: !!id && !!coordinates?.lat },
    ),
  );

  return {
    currentObject,
    objectsData,
    isObjectDataFetching,
    isObjectExtendedDataFetching,
    objectExtendedData,
    refetchObjectExtendedData,
    objectGeocodingData,
    isGeocodingDataFetching,
    getGeocodingRefetch,
  };
};

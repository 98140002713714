import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${p => p.theme.colors.primaryBackground};
  border-radius: 4px;
`;

const StyledSharingList = {
  Wrapper,
};

export default StyledSharingList;

import { LocalStorageKeys } from 'common';
import { SelectItem } from 'components/common';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMainLayout, useUserData } from 'utils';

const mainGroupId = '-1';

const GroupVehicles = () => {
  const { t } = useTranslation();
  const { groups } = useUserData();
  const { setObjectsGroupId, objectsGroupId, setFilterdObjectIds, setFitMapBounds } =
    useMainLayout();

  const sortGroupOptions = useCallback((options: SelectItem[]) => {
    const mainOption = options.find(o => o.value === mainGroupId);

    return [
      ...(mainOption ? [mainOption] : []),
      ...options
        .filter(o => o.value !== mainGroupId)
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, []);

  const groupsOptions = () => {
    if (!groups) return [];
    const options = [groups.main, ...Object.values(groups.others)].map(o => ({
      label: o?.groupId === +mainGroupId ? t('groupAllVehicles') : o?.name,
      value: o?.groupId.toString(),
    }));

    return sortGroupOptions(options);
  };

  const handleGroup = useCallback(
    (value: string) => {
      setFitMapBounds(true);
      if (!setObjectsGroupId || !setFilterdObjectIds) return;

      if (objectsGroupId !== value) {
        setFilterdObjectIds(undefined);
      }

      if (value === mainGroupId) {
        localStorage.removeItem(LocalStorageKeys.OBJECTS_GROUP_ID);
        setObjectsGroupId('');
        return;
      }

      localStorage.setItem(LocalStorageKeys.OBJECTS_GROUP_ID, value);
      setObjectsGroupId(value);
    },
    [objectsGroupId, setFilterdObjectIds],
  );

  return (
    <SelectDropdown
      placeholderText={t('groupAllVehicles')}
      items={groupsOptions()}
      onChange={handleGroup}
      size="md"
      value={objectsGroupId}
      fillWidth
    />
  );
};

export default GroupVehicles;

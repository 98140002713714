import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import IconButton from 'components/IconButton';
import PlaceHolder from 'modules/driver/components/PlaceHolder';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { Title } from 'modules/driver/pages/Spedition';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { deleteGroup } from '../queries';
import AddGroupItem from './AddGroupItem';
import GroupItem from './GroupItem';
import { GroupListItemsProps } from './GroupList';
import GroupObjectsList from './GroupObjectsList';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
  margin: 8px 0;
`;

export const StyledTitle = styled(Title)`
  margin-right: auto;
`;

export const AddButton = styled(IconButton)`
  display: flex;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  background: ${({ theme }) => theme.colors.white};
`;

export const GroupListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 44rem;
  overflow-y: scroll;
`;

export interface GroupItemsProps {
  groups: GroupListItemsProps[];
  handleGroupForm: () => void;
  handleGroupId: (id: number) => void;
  searchInputValue?: string;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleGroupClick: (id: number | null) => void;
  selectedGroupId: number | null;
}

const DesktopGroupList = ({
  groups,
  handleGroupForm,
  handleGroupId,
  searchInputValue,
  handleInputChange,
  handleGroupClick,
  selectedGroupId,
}: GroupItemsProps) => {
  const { t } = useTranslation();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const { mutate, isLoading: isDeletingLoading } = useMutation(deleteGroup());

  const handleDeleteGroup = (id: number) => {
    mutate({ id: id });
  };

  const handleConfirm = (id: number) => {
    handleDeleteGroup(id);
    setIsConfirmationModalOpen(false);
    handleGroupClick?.(null);
  };

  return (
    <Container>
      <Wrapper>
        <TopWrapper>
          <StyledTitle>{t('objectsGroups')}</StyledTitle>
          <SpeditionSearchInput
            searchInputValue={searchInputValue}
            handleInputChange={e => handleInputChange?.(e)}
            placeholder={t('searchGroup')}
          />
          <AddButton icon={<AddIcon />} onClick={handleGroupForm} />
        </TopWrapper>
        <GroupListWrapper>
          {groups.map(group => (
            <GroupItem
              key={group.value.toString()}
              label={group.label}
              comment={group.comment}
              objectsNumber={group.objects.length}
              color={group.color}
              groupId={group.value}
              onClickHandler={handleGroupClick}
              handleDeleteGroup={() => setIsConfirmationModalOpen(true)}
              handleGroupForm={handleGroupForm}
              handleGroupId={handleGroupId}
              isItemActive={selectedGroupId === group.value}
            />
          ))}
        </GroupListWrapper>
        <AddGroupItem addButtonText={t('addGroup')} clickHandler={handleGroupForm} />
      </Wrapper>
      {selectedGroupId ? (
        <GroupObjectsList
          groupId={selectedGroupId}
          objects={groups.find(group => group.value === selectedGroupId)?.objects!}
        />
      ) : (
        <PlaceHolder text={t('noGroupSelected')} />
      )}
      <ConfirmationModal
        size="small"
        isOpen={isConfirmationModalOpen}
        title={t('confirmYourChoice')}
        message={t('confirmDeleteGroup')}
        confirmText={t('delete')}
        onConfirm={() => selectedGroupId && handleConfirm(selectedGroupId)}
        onClose={() => setIsConfirmationModalOpen(false)}
        disabled={isDeletingLoading}
        isLoading={isDeletingLoading}
      />
    </Container>
  );
};

export default DesktopGroupList;

import { FieldWrapper, Label } from 'components/FormField';
import Radio, { RadioProps } from 'components/Radio';
import { FieldAttributes, useField } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledFieldWrapper = styled(FieldWrapper)<{ isActive?: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing.large};
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => theme.colors.white};
      border-radius: 8px;
    `};
`;

export type RadioFormFieldProps = FieldAttributes<RadioProps> & {
  name?: string;
  label?: string;
  height?: 'sm' | 'md';
  noMargin?: boolean;
  isActive?: boolean;
};

const RadioFormField = ({ label, height, isActive, ...props }: RadioFormFieldProps) => {
  const [field] = useField(props);

  return (
    <StyledFieldWrapper noMargin isActive={isActive}>
      <Radio height={height} {...field} {...props} />
      {label && <Label noMargin>{label}</Label>}
    </StyledFieldWrapper>
  );
};

export default React.memo(RadioFormField);

import MainLayoutContext from 'components/MainLayout/context/MainLayoutContext';
import { ComponentPropsWithRef, memo, ReactNode, useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import { useAppContext } from 'utils/appContext';

type Option = {
  label: string;
  value: string | number;
  icon?: ReactNode;
  disabled?: boolean;
};

export type ToggleProps = ComponentPropsWithRef<'input'> &
  (
    | {
        dark?: boolean;
        firstOption: Option;
        secondOption: Option;
        thirdOption?: Option;
        fillWidth?: boolean;
        options?: never;
      }
    | {
        dark?: boolean;
        firstOption?: never;
        secondOption?: never;
        thirdOption?: never;
        fillWidth?: boolean;
        options: Option[];
      }
  );

type ButtonsGroupProps = React.HTMLProps<HTMLDivElement> & {
  dark?: boolean;
  fillWidth?: boolean;
};

type ToggleButtonProps = React.HTMLProps<HTMLDivElement> & {
  active: boolean;
  dark?: boolean;
};

const ButtonsGroup = styled.div<ButtonsGroupProps>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: ${[({ dark, theme }) => (dark ? theme.colors.gray500 : theme.colors.gray200)]};
  padding: 5px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid ${({ dark, theme }) => (dark ? theme.colors.gray500 : theme.colors.gray200)};

  ${p =>
    p.fillWidth &&
    css`
      width: 100%;
    `}

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    width: 100%;
    height: unset;
    flex-wrap: wrap;
  }
`;

const ToggleButton = styled.label<ToggleButtonProps>`
  cursor: pointer;
  justify-content: center;
  min-width: 120px;
  flex-grow: 1;
  ${p =>
    p.active &&
    css`
      background: ${p.theme.colors.white};
      box-shadow: 0px 1px 0px rgba(129, 129, 129, 0.25);
    `}
  color: ${p =>
    p.active ? p.theme.colors.gray500 : p.dark ? p.theme.colors.white : p.theme.colors.gray400};
  font-weight: 600;
  line-height: 22px;
  padding: 5px 13px;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  border-radius: 4px;
  transition: 0.2s ease;
  border: ${p =>
    `1px solid ${
      p.active ? p.theme.colors.gray300 : p.dark ? p.theme.colors.gray500 : p.theme.colors.gray200
    }`};
  ${p =>
    p.dark &&
    p.active &&
    css`
      background: ${p.theme.colors.primaryBackground};
      color: ${p.theme.colors.white};
      border: 1px solid ${p => p.theme.colors.primaryBackground};
    `}

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    min-width: unset;
    text-align: center;
  }
`;

const IconWrapper = styled.div`
  margin-right: 1rem;

  svg {
    height: 2rem;
    width: auto;
  }
`;

const StyledInput = styled.input`
  display: none;
`;

const Toggle = ({
  firstOption,
  secondOption,
  thirdOption,
  value,
  onChange,
  dark,
  fillWidth,
  options,
  ...restProps
}: ToggleProps) => {
  const { darkMode } = useAppContext();
  const { vehiclesNameShown } = useContext(MainLayoutContext);

  const currentOptions = options ?? [firstOption, secondOption, thirdOption];

  return (
    <ButtonsGroup dark={dark || darkMode} fillWidth={fillWidth} role="radiogroup">
      {currentOptions.map(option => {
        if (!option) return;

        return (
          <ToggleButton
            key={`toggle_${option.value}`}
            active={option.value === value}
            aria-checked={option.value === value}
            role="radio"
            dark={dark || darkMode}
            disabled={
              option.disabled ||
              ((option.value === 'plates' || option.value === 'comment') && !vehiclesNameShown)
            }
          >
            <StyledInput
              type="radio"
              name={restProps.name}
              value={option.value}
              checked={option.value === value}
              onChange={onChange}
            />
            {option.icon && <IconWrapper>{option.icon}</IconWrapper>}
            {option.label}
          </ToggleButton>
        );
      })}
    </ButtonsGroup>
  );
};

export default memo(Toggle);

export const endpoints = {
  object: '/api-react/object',
  extendedData: '/api-react/object/extended',
  getGeocoding: '/api/node/geocoding/get',
  vehiclesTypes: '/api/applicationSetting/client/vehicleTypes',
  updateObject: '/api/object/update',
  updateObjectWithVRN: (id: number) => `/rest-api/vehicle/${id}`,
  getRoadCard: (id: string) => `/rest-api/vehicle/${id}/road-card`,
  getIgnitionControllsHistory: (id: string) => `/rest-api/vehicle/${id}/controls/ignition/history`,
  updateIgnitionControlls: (id: string) => `/rest-api/vehicle/${id}/controls/ignition`,
};

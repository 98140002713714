/**
 * This function is used to display notification on desktop
 * @param title - title of notification
 * @param options - notification options, body, icon etc.
 * @param onClick - function called when notification is clicked
 */
const desktopNotification = (
  title: string,
  options: NotificationOptions,
  onClick?: () => void,
): void => {
  const notification = new Notification(title, options);

  notification.onclick = () => {
    onClick && onClick();
  };
};

export default desktopNotification;

import { COMMUNIQUE, HttpError, LOGIN, MESSAGES, VERIFY_PASSWORD_TOKEN } from 'common';
import queryClient from 'lib/react-query';
import { UpdateMenuResponseDto } from 'modules/auth/dtos/UpdateMenu.dto';
import { AppModuleDto } from 'modules/auth/dtos/UpdateMenuResponse.dto';
import { UseMutationOptions, UseQueryOptions } from 'react-query';

import { requests } from '../api';
import {
  LoginDto,
  LoginResponseDto,
  LoginResponseErrorDto,
  ResetPasswordDto,
  ResetPasswordUnverifiedDto,
  VerifyLoginResponseDto,
  VerifyLoginResponseErrorDto,
} from '../dtos';
import { MessagesResponseDto } from '../dtos/Messages.dto';

export const getCommunique = () => ({
  queryKey: COMMUNIQUE,
  queryFn: () => requests.getCommunique(),
});

export const login = (
  config: UseMutationOptions<LoginResponseDto, HttpError<LoginResponseErrorDto>, LoginDto> = {},
) => ({
  mutationFn: requests.login,
  ...config,
});

export const verifyLogin = (
  config: UseQueryOptions<
    unknown,
    HttpError<VerifyLoginResponseErrorDto>,
    VerifyLoginResponseDto
  > = {},
) => ({
  queryKey: LOGIN,
  queryFn: () => requests.verifyLogin(),
  cacheTime: 60000,
  staleTime: 60000,
  ...config,
});

export const forgotPassword = () => ({
  mutationFn: requests.forgotPassword,
});

export const resetPasswordUnverified = (
  config: UseMutationOptions<unknown, HttpError<unknown>, ResetPasswordUnverifiedDto> = {},
) => ({
  mutationFn: requests.resetPasswordUnverified,
  ...config,
});

export const verifyPasswordToken = (
  token: string,
  config: UseQueryOptions<unknown, HttpError<{ message: string }>> = {},
) => ({
  queryKey: VERIFY_PASSWORD_TOKEN,
  queryFn: () => requests.verifyResetPasswordToken(token),
  cacheTime: 0,
  staleTime: 0,
  ...config,
});

export const resetPassword = (
  config: UseMutationOptions<unknown, HttpError<unknown>, ResetPasswordDto> = {},
) => ({
  mutationFn: requests.resetPassword,
  ...config,
});

export const logout = (config: UseMutationOptions = {}) => ({
  mutationFn: requests.logout,
  ...config,
  onSuccess: () => {
    queryClient.clear();
  },
});

export const showMessages = (
  config: UseQueryOptions<unknown, HttpError<unknown>, MessagesResponseDto> = {},
) => ({
  queryKey: MESSAGES,
  queryFn: () => requests.showMessages(),
  ...config,
});

export const updateMenu = (
  config: UseMutationOptions<unknown, HttpError<unknown>, UpdateMenuResponseDto> = {},
) => ({
  mutationFn: requests.updateMenu,
  onSuccess: () => {
    queryClient.invalidateQueries(LOGIN);
  },
  ...config,
});

export const resetMenu = (
  config: UseMutationOptions<{ access: AppModuleDto[] }, HttpError<unknown>> = {},
) => ({
  mutationFn: requests.resetMenu,
  ...config,
});

import { AllowedModules } from 'common';
import Button from 'components/Button';
import Flex from 'components/Dashboard/Flex';
import Label from 'components/Dashboard/Label';
import { AppModuleDto } from 'modules/auth/dtos/UpdateMenuResponse.dto';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { getMenuItemData } from '../../../../common/menuItems';
import { isAdmin, Roles } from '../../../../common/Roles';
import i18n from '../../../../locales/i18n';
import { routes } from '../../../../navigation/routes';
import { useCheckModuleAccess, useClose, useUserData } from '../../../../utils';
import { Wrapper } from '../../../Dropdown/style';
import { SubMenuStateProps } from '../../index';
import SubMenu from '../../SubMenu';
import {
  StyledSubMenuConfigureOrderWrapper,
  StyledSubMenuListWrapper,
  StyledSubMenuMoreList,
} from '../../SubMenu.styled';
import TopbarChatItem from '../chat/TopbarChatItem';

interface ITopbarMoreItemProps {
  elements: AppModuleDto[];
}

const initialSubmenuState = {
  integrations: false,
  admin: false,
};

const TopbarMoreItem: React.FC<ITopbarMoreItemProps> = props => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef(null);
  useClose(ref, () => setIsOpen(false));
  const { t } = useTranslation();
  const { allowedModules, roleId } = useUserData();
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [subMenuState, setSubMenuState] = useState<SubMenuStateProps>(initialSubmenuState);
  const checkModuleAccess = useCheckModuleAccess();

  const handleClickOutside = () => {
    setSubMenuState(initialSubmenuState);
  };

  /**
   * This function is used to handle click on element
   */
  const handleMenuItemClick = useCallback(
    (name: string, route?: string, isDropdown?: boolean) => {
      if (isDropdown) {
        const newState = { ...subMenuState };
        newState[name as keyof SubMenuStateProps] = true;
        setSubMenuState(newState);
      }

      if (!route) return;
      navigate(route);
    },
    [subMenuState],
  );

  /**
   * Renders top bar section, allow to insert dependencies
   */
  const renderTabByName = useCallback(
    (name: string): React.ReactNode => {
      switch (name) {
        case 'chat': {
          return <TopbarChatItem name={t(name)} />;
        }

        default: {
          return t(name);
        }
      }
    },
    [t],
  );

  /**
   * Options should be disabled if user have access to PUESC_LIMITED module
   */
  const isSettingsDisabled = useMemo(() => checkModuleAccess(AllowedModules.PUESC_LIMITED), []);

  return (
    <Wrapper>
      <Button isDropdown onClick={() => setIsOpen(true)}>
        {t('moreEllipsis')}
      </Button>

      {isOpen && (
        <StyledSubMenuListWrapper ref={ref} isOpen={isOpen} maxHeight={999}>
          <StyledSubMenuMoreList maxHeight={999}>
            {/* <--- render elements ---> */}
            {props.elements.length !== 0 &&
              props.elements.map(appModule => {
                const elementData = getMenuItemData(appModule.id);
                if (!elementData) return;

                const { disabled, hideArrow, role, icon, isDropdown, module, route } = elementData;

                if (!isAdmin(roleId) && role === Roles.ADMIN) return;

                if (isDropdown && !disabled) {
                  return (
                    <SubMenu
                      key={appModule.id}
                      items={appModule.subModules ?? []}
                      backgroundColor={
                        location.pathname === appModule.id ? colors.primaryBackground : ''
                      }
                      onClick={() => handleMenuItemClick(appModule.id, route, isDropdown)}
                      disabled={disabled}
                      label={i18n.t(appModule.id)}
                      callback={handleClickOutside}
                      icon={icon}
                      isOpen={subMenuState[appModule.id as keyof SubMenuStateProps]}
                      parentMenuItem={appModule.id}
                      isNew={appModule.isNew}
                    />
                  );
                }

                return (
                  <Wrapper key={appModule.id}>
                    <Button
                      icon={icon}
                      isDropdown={isDropdown}
                      hideArrow={hideArrow}
                      backgroundColor={
                        location.pathname === route ? colors.primaryBackground : undefined
                      }
                      onClick={() => handleMenuItemClick(appModule.id, route, isDropdown)}
                      disabled={module && !allowedModules.includes(module) ? true : disabled}
                    >
                      <Flex gap={10}>
                        {renderTabByName(appModule.id)}
                        {appModule.isNew && <Label>NEW</Label>}
                      </Flex>
                    </Button>
                  </Wrapper>
                );
              })}

            {!isSettingsDisabled && (
              <StyledSubMenuConfigureOrderWrapper onClick={() => navigate(routes.otherMenuBar)}>
                {t('linkOrder')}
              </StyledSubMenuConfigureOrderWrapper>
            )}
          </StyledSubMenuMoreList>
        </StyledSubMenuListWrapper>
      )}
    </Wrapper>
  );
};

export default TopbarMoreItem;

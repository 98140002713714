import { AllowedModules } from 'common';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import DateTimePickerFormField from 'components/DateTimePickerFormField';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import { Label } from 'components/FormField';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useCheckModuleAccess, useWindowSize } from 'utils';

import { ShareGroupLinkDto, ShareLinkDataDto, ShareObjectsLinkDto } from '../dtos/ShareLink.dto';

const dateFormat = 'YYYY-MM-DD';

const initialGroupValues: ShareGroupLinkDto = {
  date_start: dayjs().startOf('day').format(dateFormat),
  date_end: dayjs().endOf('day').add(1, 'week').format(dateFormat),
  type: 2,
  group_id: '0',
  speditor: false,
  has_route_preview: false,
};

const initialObjectsValues: ShareObjectsLinkDto = {
  date_start: dayjs().startOf('day').format(dateFormat),
  date_end: dayjs().endOf('day').add(1, 'week').format(dateFormat),
  type: 1,
  objects: '[]',
  walter: false,
  has_route_preview: false,
};

const DateWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: ${({ isMobile, theme }) => (!isMobile ? theme.spacing.xxLarge : '0')};
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'start')};
`;

const FormWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xLarge};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'start')};
  padding: ${({ theme }) =>
    `${theme.spacing.large} ${theme.spacing.xLarge} ${theme.spacing.xxLarge} ${theme.spacing.xLarge}`};

  label {
    color: ${({ theme }) => theme.colors.gray800};
  }
`;

const WidthWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '33%')};
`;

const StyledButton = styled(Button)<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? 'calc(100% - 48px)' : '30%')};

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: absolute;
      bottom: ${({ theme }) => theme.spacing.xxLarge};
    `}
`;

const listItems = [{ label: 'fm@fml', value: 'fm@fml' }];

const ShareForm = ({
  selectedGroupId,
  shareMutation,
  isShareGroupLoading,
  selectedObjectsIds,
  isLinkSharingSuccess,
}: {
  selectedGroupId?: number;
  selectedObjectsIds?: number[];
  shareMutation: (data: ShareLinkDataDto) => void;
  isShareGroupLoading: boolean;
  isLinkSharingSuccess: boolean;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const checkModuleAccess = useCheckModuleAccess();
  const isSpeditorSharingAccount = checkModuleAccess(AllowedModules.SPEDITOR_SHARING);

  const handleGroupSubmit = (values: ShareGroupLinkDto | ShareObjectsLinkDto) => {
    const formFields = { ...values } as ShareGroupLinkDto;
    formFields.type = 2;
    if (selectedGroupId) formFields.group_id = selectedGroupId.toString();
    formFields.date_start = dayjs(values.date_start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    formFields.date_end = dayjs(values.date_end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    if (isSpeditorSharingAccount) formFields.speditor = true;

    shareMutation({ data: formFields });
  };

  const handleObjectsSubmit = (values: ShareGroupLinkDto | ShareObjectsLinkDto) => {
    const formFields = { ...values } as ShareObjectsLinkDto;
    formFields.type = 1;
    if (selectedObjectsIds?.length) formFields.objects = '[' + selectedObjectsIds.toString() + ']';
    formFields.date_start = dayjs(values.date_start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    formFields.date_end = dayjs(values.date_end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    if (isSpeditorSharingAccount) formFields.walter = true;
    shareMutation({ data: formFields });
  };

  return (
    <Formik
      initialValues={selectedGroupId ? initialGroupValues : initialObjectsValues}
      onSubmit={selectedGroupId ? handleGroupSubmit : handleObjectsSubmit}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form>
          <FormWrapper isMobile={isMobile}>
            {isSpeditorSharingAccount && (
              <WidthWrapper isMobile={isMobile}>
                <Label>{t('for')}</Label>
                <SelectDropdown
                  disableInitialWidth
                  size="md"
                  items={listItems}
                  placeholderText={listItems[0].label}
                />
              </WidthWrapper>
            )}
            <DateWrapper isMobile={isMobile}>
              <DateTimePickerFormField
                name="date_start"
                type={'date'}
                disableTime
                onChange={handleChange}
                width={isMobile ? '40%' : '33%'}
                label={t('dateFrom')}
                autoComplete="off"
                value={values.date_start}
              />
              <DateTimePickerFormField
                name="date_end"
                type={'date'}
                disableTime
                onChange={handleChange}
                width={isMobile ? '40%' : '33%'}
                label={t('dateTo')}
                autoComplete="off"
                value={values.date_end}
              />
            </DateWrapper>

            <Checkbox
              checked={values.has_route_preview}
              checkboxSize="md"
              name={'has_route_preview'}
              onClick={() => setFieldValue('has_route_preview', !values.has_route_preview)}
              label={'Podgląd trasy z dnia'}
            />

            {!isLinkSharingSuccess && (
              <StyledButton
                isMobile={isMobile}
                primary
                type="submit"
                disabled={isShareGroupLoading}
              >
                {t('generateLink')}
              </StyledButton>
            )}
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default ShareForm;

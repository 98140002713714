import { ObjectStatus } from 'components/common/ObjectStatus';
import styled, { css, keyframes, useTheme } from 'styled-components/macro';

export const getObjectStatusColor = (status: ObjectStatus) => {
  const theme = useTheme();
  switch (status) {
    case ObjectStatus.MOVING:
      return theme.objectStatusColor.moving;
    case ObjectStatus.NO_GPS:
      return theme.objectStatusColor.noGps;
    case ObjectStatus.ENGINE_OFF:
      return theme.objectStatusColor.engineOff;
    case ObjectStatus.PAUSE:
      return theme.objectStatusColor.pause;
    default:
      return theme.objectStatusColor.noGps;
  }
};

type FilterProps = {
  isVisible: boolean;
};

export const ActionWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  pointer-events: none;
  touch-action: none;
  transition: opacity 0.2s linear;
  border-radius: 0.8rem;
  background-color: rgba(57, 75, 106, 0.7);
`;

export const ActionWrapperExtended = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  pointer-events: none;
  touch-action: none;
  box-shadow: 0px 3px 30px 8px rgba(0, 0, 0, 0.1);
  width: 100%;

  padding: 12px 4px;
  background: ${({ theme }) => theme.colors.white};
`;

export const Data = styled.div<{ isVisible?: boolean }>`
  font-size: 1.15rem;
  font-weight: 600;
  margin-left: auto;
  white-space: nowrap;
  display: flex;
  gap: 5px;
  align-items: center;

  ${({ isVisible }) =>
    !isVisible &&
    `
    display: none;
  `}
`;

export const ObjectName = styled.div<{ isSidebarOpen?: boolean; isVisible: boolean }>`
  font-size: 1.3rem;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${p => (p.isSidebarOpen ? '13rem' : '13rem')};

  ${({ isVisible }) =>
    !isVisible &&
    `
    display: none;
  `}
`;

export const Row = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;

  &:nth-of-type(2n) {
    ${Data} {
      display: none;
    }
  }
`;

export const Wrapper = styled.div<{ isEdit?: boolean; height?: number; isSidebarOpen?: boolean }>`
  overflow: hidden;
  padding: ${p => (p.isSidebarOpen ? 'none' : '0rem 1rem')};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  color: ${({ theme }) => theme.colors.text};
  ${p =>
    p.isEdit &&
    p.height &&
    css`
      height: ${p.height}px;
    `}

  border: 1px solid ${p => (p.isEdit ? p.theme.colors.primary600 : p.theme.colors.onPrimaryBorder)};

  width: 100%;
  border-radius: 4px;

  ${Row} {
    &:nth-child(2n) {
      border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    }
  }

  &:hover ${ActionWrapper}, &:hover ${ActionWrapperExtended} {
    opacity: 1;
    pointer-events: initial;
    touch-action: auto;
  }

  ${({ theme }) => theme.mq.lg} {
    height: 13rem;
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const Header = styled.div<{ isSearchbarOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: ${({ isSearchbarOpen }) => (isSearchbarOpen ? '300px' : '150px')};
`;

export const Comment = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
`;

export const Status = styled.div<{ status: ObjectStatus }>`
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: ${({ status }) => getObjectStatusColor(status)};
`;

export const LocationWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 1rem;

  svg {
    width: 1.05rem;
    height: 1.5rem;
  }

  ${Data} {
    display: none;
    ${({ theme }) => theme.mq.md} {
      display: block;
    }
  }
`;

export const Location = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const FiltersData = styled.div`
  display: none;

  ${({ theme }) => theme.mq.lg} {
    padding: 0 1.1rem;
  }
`;

export const Filter = styled.div<FilterProps>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray500};
`;

const fadeIt = keyframes`
  from {
    background-color: #FFDD2A;
  }

  to {
    background-color: #FFFFFF;
  }
`;

export const AnimatedWrapper = styled.div<{ isUpdated?: boolean }>`
  padding: 0.5rem;
  border-radius: 0.8rem;
  animation: 3s linear;
  animation-name: ${p => (p.isUpdated ? fadeIt : null)};
  display: flex;
  justify-content: center;
`;

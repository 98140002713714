import Radio from 'components/Radio';
import { t } from 'i18next';
import styled from 'styled-components';

import { vehiclesTranslations } from '../EditCommentAndVehicle/vehiclesTranslations';

const Listwrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
  max-height: 60vh;
  overflow-y: scroll;
  margin-left: 0;

  ::-webkit-scrollbar {
    display: block;
  }
`;

const VehiclesListItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
  width: 100%;
  padding: 8px 16px;
`;

const MobileVehiclesList = ({
  vehiclesTypes,
  setFieldValue,
  typeValue,
}: {
  typeValue: string | boolean;
  vehiclesTypes?: string[];
  setFieldValue: (field: string, value: string) => void;
}) => (
  <Listwrapper>
    {vehiclesTypes?.map(type => (
      <VehiclesListItem key={type} onClick={() => setFieldValue('type', type)}>
        <Radio checked={type === typeValue} />
        <img src={require(`assets/vehicles/${type}.svg`).default} />
        {vehiclesTranslations(t)[type]}
      </VehiclesListItem>
    ))}
  </Listwrapper>
);

export default MobileVehiclesList;

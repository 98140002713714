import Label from 'modules/reports/components/Label';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

export type TextareaProps = React.ComponentPropsWithoutRef<'textarea'> & {
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  width?: string;
  label?: string;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledTextarea = styled.textarea`
  display: flex;
  height: 88px;
  width: 100%;
  padding: 9px ${({ theme }) => theme.spacing.large};
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border: none;
  resize: none;
  outline: none;

  &:hover:not([disabled]),
  &:focus,
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.gray400};
    outline: none;
  }

  color: ${({ theme }) => theme.colors.gray700};
  font-style: normal;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

const Textarea = ({ placeholder, value, onChange, label, ...rest }: TextareaProps) => (
  <Wrapper>
    <Label>{label}</Label>
    <StyledTextarea
      spellCheck={false}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...rest}
    />
  </Wrapper>
);

export default Textarea;

import Tabs from 'components/Tabs';
import { routes } from 'navigation/routes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAssignment, MdList } from 'react-icons/md';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useWindowSize } from 'utils';

const Wrapper = styled.div`
  padding: 2rem;
  min-height: 100%;
  background: ${p => p.theme.colors.secondaryBackground};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    padding: 0;
  }
`;

const TabsWrapper = styled.div`
  max-width: 50vw;
  margin: auto;
  margin-bottom: 3.2rem;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
`;

const Content = styled.div``;

enum SamsungIntegrationTabs {
  FORM = 'form',
  LIST = 'list',
}

const tabRoutes = {
  [SamsungIntegrationTabs.FORM]: routes.samsungIntegrationForm,
  [SamsungIntegrationTabs.LIST]: routes.samsungIntegrationList,
};

const SamsungIntegration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<SamsungIntegrationTabs>(SamsungIntegrationTabs.FORM);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (pathname === routes.samsungIntegrationList) {
      setActiveTab(SamsungIntegrationTabs.LIST);
    }

    if (pathname === routes.samsungIntegrationForm) {
      navigate(routes.samsungIntegrationForm);
      setActiveTab(SamsungIntegrationTabs.FORM);
    }

    if (pathname === routes.samsungIntegration) {
      navigate(routes.samsungIntegrationForm);
      setActiveTab(SamsungIntegrationTabs.FORM);
    }
  }, [pathname]);

  const tabs = [
    { label: t('form'), value: SamsungIntegrationTabs.FORM, icon: !isMobile && <MdAssignment /> },
    { label: t('list'), value: SamsungIntegrationTabs.LIST, icon: !isMobile && <MdList /> },
  ];

  const handleTabClick = (value: string) => {
    setActiveTab(value as SamsungIntegrationTabs);
    navigate(tabRoutes[value as SamsungIntegrationTabs]);
  };

  return (
    <Wrapper>
      <TabsWrapper>
        <Tabs
          options={tabs}
          activeTab={activeTab}
          onClick={handleTabClick}
          noBorderRadius={isMobile}
        />
      </TabsWrapper>
      <Content>
        <Outlet />
      </Content>
    </Wrapper>
  );
};

export default SamsungIntegration;

import {
  DISTANCE,
  HttpError,
  REFILLINGS,
  ROUTE_DETAILS,
  ROUTE_STATISTICS,
  SIMPLE_ROUTE,
} from 'common';
import { UseQueryOptions } from 'react-query';

import { requests } from '../api';
import {
  DistanceParamsDto,
  DistanceResponseDto,
  RefiilingsResponseDto,
  RefillingsParamsDto,
  RouteDetailsParamsDto,
  RouteDetailsResponseDto,
  RouteStatisticsParamsDto,
  RouteStatisticsResponseDto,
  SimpleRouteRequestParamsDto,
  SimpleRouteResponseDto,
} from '../dtos';

export const getSimpleRoute = (
  objectId: string,
  params: SimpleRouteRequestParamsDto,
  config: UseQueryOptions<SimpleRouteResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [SIMPLE_ROUTE, { objectId }],
  queryFn: () => requests.getSimpleRoute({ objectId, params }),
  ...config,
});

export const getRouteDetails = (
  objectId: string,
  params: RouteDetailsParamsDto,
  config: UseQueryOptions<RouteDetailsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [ROUTE_DETAILS, { objectId }],
  queryFn: () => requests.getRouteDetails({ objectId, params }),
  ...config,
});

export const getDistance = (
  params: DistanceParamsDto,
  config: UseQueryOptions<DistanceResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [DISTANCE, params],
  queryFn: () => requests.getDistance(params),
  ...config,
});

export const getRefillings = (
  objectId: string,
  params: RefillingsParamsDto,
  config: UseQueryOptions<RefiilingsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [REFILLINGS, { objectId }],
  queryFn: () => requests.getRefillings(params),
  ...config,
});

export const getRouteStatistics = (
  params: RouteStatisticsParamsDto,
  config: UseQueryOptions<RouteStatisticsResponseDto> = {},
) => ({
  queryKey: [ROUTE_STATISTICS, { objectId: params.objectId }],
  queryFn: () => requests.getRouteStatistics(params),
  ...config,
});

import FormField from 'components/FormField';
import Textarea, { TextareaProps } from 'components/Textarea';
import { FieldAttributes, useField } from 'formik';
import React from 'react';

export type TextareaFormFielProps = FieldAttributes<TextareaProps> & {
  name: string;
  label?: string;
  noMargin?: boolean;
};

const TextareaFormField = ({ label, noMargin, width, ...props }: TextareaFormFielProps) => {
  const [field, meta] = useField(props);
  const errorId = `${field.name}-error`;

  return (
    <FormField
      errorId={errorId}
      touched={meta.touched}
      error={meta.error}
      label={label}
      width={width}
      noMargin={noMargin}
    >
      <Textarea {...field} {...props} width={width} />
    </FormField>
  );
};

export default React.memo(TextareaFormField);

import Axios from 'axios';
import { endpoints } from 'modules/auth/api';

export let controller = new AbortController();

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
});

axios.interceptors.request.use(config => ({
  ...config,
  ...(config.url !== endpoints.logout &&
    config.url !== endpoints.login && { signal: controller.signal }),
}));

axios.interceptors.response.use(data => {
  if (data.config.url === endpoints.logout) {
    controller = new AbortController();
  }

  return data;
});

import styled, { css } from 'styled-components/macro';

interface TrProps {
  positive?: boolean;
  negative?: boolean;
}

export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto max-content;
  min-height: 200px;
`;

export const Wrapper = styled.table<{ marginBottom?: boolean }>`
  font-size: 1.4rem;
  width: 100%;
  ${p =>
    p.marginBottom &&
    css`
      margin-bottom: 6rem;
    `}
`;

export const Th = styled.th`
  padding: 1.6rem 2rem;
  text-align: left;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
`;

export const Td = styled.td`
  padding: 1.6rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray200};
  color: ${({ theme }) => theme.colors.textGray};

  &:first-child {
    font-weight: 600;
  }
`;

export const Tbody = styled.tbody`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
`;

export const Tr = styled.tr<TrProps>`
  position: relative;

  ${({ theme, positive, negative }) => {
    if (positive) {
      return `background-color: ${theme.colors.success100};`;
    } else if (negative) return `background-color: ${theme.colors.error100};`;
    else {
      return `
        &:hover {
          background-color: ${theme.colors.gray200};
        }
      `;
    }
  }}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    ${({ positive, theme }) =>
      positive ? `border-left: 6px solid ${theme.colors.success300};` : null};

    ${({ negative, theme }) =>
      negative ? `border-left: 6px solid ${theme.colors.error300};` : null};
  }
`;

export const MobileWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-radius: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

export const MobileRow = styled.div<{ styleLastRow?: boolean }>`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryBackgroundDark};
  margin: 0 1.5rem;
  padding: 1.2rem 0;
  justify-content: space-between;

  &:first-child {
    border: none;
  }

  ${({ styleLastRow, theme }) =>
    styleLastRow &&
    css`
      &:last-child {
        border: none;
        margin: 0;
        background-color: ${theme.colors.secondaryBackground};
        border-radius: 0 0 0.8rem 0.8rem;
        justify-content: center;
      }
      &:last-child span {
        display: none;
      }
    `}
`;

export const MobileTitle = styled.span`
  color: ${({ theme }) => theme.colors.textGray};
  align-self: center;
`;

export const MobileContent = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
  text-align: right;
  align-self: center;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: ${p => p.theme.colors.secondaryBackground};
  padding-top: 0.5rem;
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const PaginationItem = styled.button<{ dark?: boolean; isActive?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  width: 42px;
  height: 42px;
  border-radius: 6px;
  color: ${p => (p.disabled ? p.theme.colors.textLightGray : p.theme.colors.text)};

  ${p => !p.disabled && `cursor: pointer;`}
  background: ${p =>
    p.dark && p.disabled ? p.theme.colors.gray500 : p.theme.colors.primaryBackground};
  border: 1px solid ${p => (p.disabled ? p.theme.colors.gray200 : p.theme.colors.gray300)};

  ${p =>
    p.isActive &&
    css`
      background: ${p => p.theme.colors.primary600};
      color: ${p => p.theme.colors.white};
      font-weight: 600;
    `}
`;

export const PaginationDetails = styled.div`
  color: ${p => p.theme.colors.text};
  font-size: 1.2rem;
`;

import React, { useContext } from 'react';

type ContextType = {
  darkMode: boolean;
};

const ContextDefault: ContextType = {
  darkMode: false,
};

const AppContext = React.createContext<ContextType>(ContextDefault);

export const useAppContext = () => useContext(AppContext);

export default AppContext;

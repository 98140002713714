import Tippy from '@tippyjs/react';
import Logo from 'assets/ww-logo.svg';
import { AllowedModules, MapTypes } from 'common';
import { getMenuItemData } from 'common/menuItems';
import { isAdmin, Roles } from 'common/Roles';
import Button from 'components/Button';
import Flex from 'components/Dashboard/Flex';
import Label from 'components/Dashboard/Label';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import Header from 'components/Header';
import IconButton from 'components/IconButton';
import LanguageSelect from 'components/LanguageSelect';
import MainLayoutContext from 'components/MainLayout/context/MainLayoutContext';
import i18n from 'locales/i18n';
import { routes } from 'navigation/routes';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaYoutube } from 'react-icons/fa';
import { MdKeyboardArrowLeft, MdLocationOn } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { temporarilyHidden, useUserData, useWindowSize } from 'utils';
import useResetMap from 'utils/hooks/useResetMap';
import redirectToExternal from 'utils/redirectToExternal';

import FilterVehicles from './FilterVehicles';
import GroupVehicles from './GroupVehicles';
import {
  BottomTopbar,
  GroupActions,
  IconsWrapper,
  Img,
  InfoIcon,
  MainTopbar,
  MapType,
  MenuItems,
  NotificationsIcon,
  StyledIcon,
} from './style';
import SubMenu from './SubMenu';
import TopbarChatItem from './tabs/chat/TopbarChatItem';
import TopbarSpeditionItem from './tabs/chat/TopbarSpeditionItem';
import TopbarMoreItem from './tabs/more/TopbarMoreItem';
import UserOptions from './UserOptions';

const initialSubmenuState = {
  integrations: false,
  admin: false,
};

export interface SubMenuStateProps {
  integrations: boolean;
  admin: boolean;
}

const Topbar = ({ isNotificationOpen }: { isNotificationOpen: boolean }) => {
  const [subMenuState, setSubMenuState] = useState<SubMenuStateProps>(initialSubmenuState);

  const { t } = useTranslation();
  const { colors } = useTheme();
  const { setShowMapSettings, showMapSettings, mapType, setMapType } =
    useContext(MainLayoutContext);
  const location = useLocation();
  const { width } = useWindowSize();
  const { allowedModules, roleId, appModules } = useUserData();

  const navigate = useNavigate();
  const isSettingsPage = useMemo(() => location.pathname.includes(routes.settings), [location]);
  const isObjectsPage = useMemo(() => location.pathname === routes.objects, [location]);
  const isRoutePage = useMemo(() => location.pathname === routes.route, [location]);
  const isSpeditionPage = useMemo(() => location.pathname === routes.spedition, [location]);

  const showRightSection = useMemo(
    () => isRoutePage || isObjectsPage,
    [isRoutePage, isObjectsPage],
  );
  const showSmallLogo = useMemo(() => width <= 1024, [width]);

  const { handleRefreshClick, refetchObjects } = useResetMap();

  const handleMapSettings = useCallback(() => {
    setShowMapSettings(!showMapSettings);
  }, [setShowMapSettings, showMapSettings]);

  const handleMenuItemClick = useCallback(
    (name: string, route?: string, isDropdown?: boolean) => {
      if (isDropdown) {
        const newState = { ...subMenuState };
        newState[name as keyof SubMenuStateProps] = true;
        setSubMenuState(newState);
      }
      if (!route) return;
      if (route === routes.objects) {
        refetchObjects();

        // reset InfoBox visibility on map reset
        handleRefreshClick();
      }
      navigate(route);
    },
    [navigate, refetchObjects, handleRefreshClick],
  );

  /**
   * Display elements based on screen width
   */
  const elementsToShow = useMemo(() => {
    if (width > 1800) return 7;
    if (width > 1600) return 6;
    if (width > 1400) return 5;
    if (width > 1200) return 4;

    return 3;
  }, [width]);

  /**
   * List of visible elements
   */
  const visibleElements = useMemo(() => appModules.slice(0, elementsToShow), [appModules]);

  /**
   * List of hidden elements
   */
  const hiddenElements = useMemo(
    () => appModules.slice(elementsToShow, appModules.length),
    [appModules],
  );

  const redirectToOldAppVersion = useCallback(() => {
    window.open(process.env.REACT_APP_OLD_VERSION_URL);
  }, []);

  const handleMapTypeChange = useCallback(
    value => {
      setMapType(value);
    },
    [setMapType],
  );

  const handleClickOutside = () => {
    setSubMenuState(initialSubmenuState);
  };

  /**
   * This flag is used to render
   * group select for specific pages
   */
  const displayGroupActions = useMemo(
    () => isObjectsPage || isRoutePage || isSpeditionPage,
    [isObjectsPage, isRoutePage, isSpeditionPage],
  );

  /**
   * Available map types list
   */
  const availableMapsTypes = useMemo(() => {
    // set default map types
    const items = [
      { label: t('googleMaps'), value: MapTypes.GOOGLE },
      { label: t('openStreetMaps'), value: MapTypes.OSM },
    ];

    // add HERE maps if allowed
    if (allowedModules.includes(AllowedModules.MAP_PROVIDER_HERE)) {
      items.push({ label: t('hereMaps'), value: MapTypes.HERE });
    }

    return items;
  }, [allowedModules, t]);

  /**
   * Renders top bar section, allow to insert dependencies
   */
  const renderTabByName = useCallback(
    (name: string): React.ReactNode => {
      switch (name) {
        case 'chat': {
          return <TopbarChatItem name={t(name)} />;
        }

        case 'spedition': {
          return <TopbarSpeditionItem name={t(name)} />;
        }

        default: {
          return t(name);
        }
      }
    },
    [t],
  );

  return (
    <>
      <MainTopbar justify={!isSettingsPage} isNotificationOpen={isNotificationOpen}>
        <Flex noWrap>
          <Link to={routes.objects}>
            <Img src={Logo} small={showSmallLogo} />
          </Link>

          <IconsWrapper>
            <StyledIcon
              onClick={() => redirectToExternal('https://www.facebook.com/widziszwszystko')}
              title={'Facebook'}
            >
              <FaFacebook />
            </StyledIcon>

            <StyledIcon
              onClick={() => redirectToExternal('https://www.youtube.com/@WidziszWszystko_pl')}
              title={'Youtube'}
            >
              <FaYoutube />
            </StyledIcon>
          </IconsWrapper>
        </Flex>

        {displayGroupActions && (
          <GroupActions>
            <GroupVehicles />
            <FilterVehicles />
          </GroupActions>
        )}

        {isSettingsPage && (
          <GroupActions>
            <Button
              secondary
              hideArrow
              icon={<MdKeyboardArrowLeft />}
              onClick={() => navigate(routes.objects)}
            >
              {t('goBack')}
            </Button>
            <Header noMargin>{t('settings.settings')}</Header>
          </GroupActions>
        )}
        <GroupActions right={isSettingsPage}>
          <NotificationsIcon disabled={temporarilyHidden} />
          <UserOptions />
          <Button secondary onClick={redirectToOldAppVersion}>
            <Tippy content={t('returnToOldApp')}>
              <div>{t('previousVersion')}</div>
            </Tippy>
          </Button>
          <LanguageSelect />
        </GroupActions>
      </MainTopbar>

      {!isSettingsPage && (
        <BottomTopbar isNotificationOpen={isNotificationOpen}>
          <MenuItems>
            {visibleElements.map(appModule => {
              const data = getMenuItemData(appModule.id);
              if (!data) return;

              const { disabled, hideArrow, icon, isDropdown, module, role, route } = data;

              if (!isAdmin(roleId) && role === Roles.ADMIN) return;

              if (isDropdown && !disabled) {
                return (
                  <SubMenu
                    key={appModule.id}
                    items={appModule.subModules ?? []}
                    icon={icon}
                    backgroundColor={
                      location.pathname === appModule.id ? colors.primaryBackground : ''
                    }
                    onClick={() => handleMenuItemClick(appModule.id, route, isDropdown)}
                    disabled={disabled}
                    label={i18n.t(appModule.id)}
                    callback={handleClickOutside}
                    isOpen={subMenuState[appModule.id as keyof SubMenuStateProps]}
                    parentMenuItem={appModule.id}
                    isNew={appModule.isNew}
                  />
                );
              }

              return (
                <Button
                  key={appModule.id}
                  icon={icon}
                  isDropdown={isDropdown}
                  hideArrow={hideArrow}
                  backgroundColor={
                    location.pathname === route ? colors.primaryBackground : undefined
                  }
                  onClick={() => handleMenuItemClick(appModule.id, route, isDropdown)}
                  disabled={module && !allowedModules.includes(module) ? true : disabled}
                >
                  <Flex gap={10}>
                    {renderTabByName(appModule.id)}
                    {appModule.isNew && <Label>NEW</Label>}
                  </Flex>
                </Button>
              );
            })}

            <TopbarMoreItem elements={hiddenElements} />
          </MenuItems>

          {showRightSection && (
            <GroupActions right>
              <MapType>
                <SelectDropdown
                  size="md"
                  disableInitialWidth
                  items={availableMapsTypes}
                  value={mapType}
                  onChange={handleMapTypeChange}
                />
                <Tippy content={t('differentMapOptionsInfo')}>
                  <div>
                    <InfoIcon />
                  </div>
                </Tippy>
              </MapType>

              <div />

              {isObjectsPage && (
                <>
                  <Button secondary icon={<MdLocationOn />} onClick={handleMapSettings}>
                    {t('mapSettings')}
                  </Button>
                  <IconButton icon="refresh" onClick={handleRefreshClick} />
                </>
              )}
            </GroupActions>
          )}
        </BottomTopbar>
      )}
    </>
  );
};

export default Topbar;

import SelectDropdown, { SelectDropdownProps } from 'components/Dropdown/SelectDropdown';
import FormField from 'components/FormField';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import { memo, useCallback } from 'react';
import styled from 'styled-components/macro';

const SelectWrapper = styled.div<{ size?: string }>`
  height: ${p => (p.size === 'md' ? `42px` : `56px`)};
`;

type SelectDropdownFormFieldProps = FieldAttributes<SelectDropdownProps> & {
  name: string;
  noMargin?: boolean;
  label?: string;
};

const SelectDropdownFormField = ({ noMargin, label, ...props }: SelectDropdownFormFieldProps) => {
  const [field, meta] = useField(props);
  const errorId = `${field.name}-error`;
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (value: string) => {
      if (!setFieldValue) {
        props?.onChange?.(value);
        return;
      }

      setFieldValue(field.name, value);
    },
    [setFieldValue, field],
  );

  return (
    <FormField
      errorId={errorId}
      touched={meta.touched}
      error={meta.error}
      noMargin={noMargin}
      label={label}
    >
      <SelectWrapper size={props.size}>
        <SelectDropdown {...field} {...props} onChange={handleChange} />
      </SelectWrapper>
    </FormField>
  );
};

export default memo(SelectDropdownFormField);

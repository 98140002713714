import { memo, ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

export const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 4px;
  color: ${p => p.theme.colors.error300};
`;

export const FieldWrapper = styled.div<{ noMargin?: boolean; width?: string }>`
  ${p =>
    !p.noMargin &&
    css`
      margin: 15px 0;
    `}

  ${p =>
    p.width &&
    css`
      width: ${p.width};
    `}
`;

export const Label = styled.label<{ noMargin?: boolean }>`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${p => p.theme.colors.gray600};
  margin-bottom: ${p => (p.noMargin ? '0' : '0.5rem')};
`;

export type FormFieldProps = {
  children?: ReactNode;
  errorId: string;
  touched: boolean;
  error?: string;
  noMargin?: boolean;
  label?: string;
  width?: string;
};

const FormField = ({
  children,
  touched,
  error,
  errorId,
  noMargin,
  label,
  width,
}: FormFieldProps) => (
  <FieldWrapper noMargin={noMargin} width={width}>
    {label && <Label>{label}</Label>}
    {children}
    {touched && error && (
      <ErrorMessage id={errorId} aria-live="polite">
        {error.split('\n').map(err => (
          <p key={err}>{err}</p>
        ))}
      </ErrorMessage>
    )}
  </FieldWrapper>
);

export default memo(FormField);

import { axios } from 'lib/axios';
import {
  AddGroupsToLoginDto,
  AddObjectsToGroupDto,
  CreateGroupDto,
  CreateLoginDto,
  DeleteGroupDto,
  DeleteLoginDto,
  UpdateLoginDto,
} from 'modules/admin/dtos';

import { endpoints } from '.';

export const getGroupsList = () => axios.get(endpoints.groupList).then(res => res.data);

export const createGroup = (payload: CreateGroupDto) =>
  axios.post(endpoints.createGroup, payload).then(res => res.data);

export const addObjectsToGroup = (payload: AddObjectsToGroupDto) =>
  axios.post(endpoints.addObjectsToGroup, payload).then(res => res.data);

export const deleteGroup = (payload: DeleteGroupDto) =>
  axios.post(endpoints.deleteGroup, payload).then(res => res.data);

export const getLoginsList = () => axios.get(endpoints.loginsList).then(res => res.data);

export const createLogin = (payload: CreateLoginDto) =>
  axios.post(endpoints.createLogin, payload).then(res => res.data);

export const deleteLogin = (payload: DeleteLoginDto) =>
  axios.post(endpoints.deleteLogin, payload).then(res => res.data);

export const addGroupsToLogin = (payload: AddGroupsToLoginDto) =>
  axios.post(endpoints.addGroupsToLogin, payload).then(res => res.data);

export const updateLogin = (payload: UpdateLoginDto) =>
  axios.post(endpoints.updateLogin, payload).then(res => res.data);

import { Object } from 'modules/objects/dtos/ObjectsResponse.dto';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IsTypingResponse, ReceiversData } from '../queries/socket';
import ChatListItem from './ChatListItem';
import SpeditionSearchInput from './SpeditionSearchInput';

const ChatListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.small};
  background-color: ${({ theme }) => theme.colors.white};
  height: calc(100vh - 150px);
  border-right: 1px solid ${({ theme }) => theme.colors.borderGray};
  cursor: pointer;
  height: 100%;
  width: 50%;
`;

const ChatListItemsWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column-reverse;
  gap: ${({ theme }) => theme.spacing.large};
  overflow-y: auto;
  height: 100%;
`;

const NoItemsPlaceholder = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 14px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-style: italic;
`;

const ChatList = ({
  searchInputValue,
  handleInputChange,
  messagesReceived,
  handleMessageClicked,
  messageClickedId,
  objectsData,
  isTypingResponse,
}: {
  searchInputValue?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  messagesReceived: ReceiversData[];
  handleMessageClicked: (data: ReceiversData) => void;
  messageClickedId?: number;
  objectsData?: Object[];
  isTypingResponse?: IsTypingResponse;
}) => {
  const { t } = useTranslation();

  const items = searchInputValue
    ? messagesReceived.filter(msg =>
        msg.Account.object.number.toLowerCase().includes(searchInputValue.toLocaleLowerCase()),
      )
    : messagesReceived;

  return (
    <ChatListWrapper>
      {items.length ? (
        <>
          <SpeditionSearchInput
            fullWidth
            searchInputValue={searchInputValue}
            handleInputChange={handleInputChange}
          />
          <ChatListItemsWrapper>
            {items.map(item => (
              <ChatListItem
                key={item.Account.object.objectId}
                message={item}
                onClickHandler={handleMessageClicked}
                messageClickedId={messageClickedId}
                type={
                  objectsData?.find(obj => obj.objectId === item.Account.object.objectId)?.type ??
                  'car'
                }
                status={
                  objectsData?.find(obj => obj.objectId === item.Account.object.objectId)?.status ??
                  1
                }
                isTypingContent={
                  isTypingResponse?.id === item.Account.id ? isTypingResponse?.isTyping : false
                }
              />
            ))}
          </ChatListItemsWrapper>
        </>
      ) : (
        <NoItemsPlaceholder>({`${t('addConnectedVehicles')}`})</NoItemsPlaceholder>
      )}
    </ChatListWrapper>
  );
};

export default ChatList;

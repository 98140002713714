import LoadingPage from 'components/LoadingPage';
import { LoginResponseDto } from 'modules/auth/dtos';
import { verifyLogin } from 'modules/auth/queries';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from 'utils';

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { data, isLoading, isError } = useQuery(verifyLogin());
  // Workaround to prevent react-query infinite refetch
  const [error, setError] = useState(false);

  useEffect(() => {
    if (data?.logged) {
      setIsAuthenticated(data.logged);
    }
  }, [data]);

  useEffect(() => {
    if (isError && !error) setError(true);
  }, [isError, error]);

  const loginSuccess = useCallback((body: LoginResponseDto) => {
    if (body.identity) {
      setIsAuthenticated(true);
    }
  }, []);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
  }, []);

  if (isLoading && !error) {
    return <LoadingPage />;
  }

  return (
    <AuthContext.Provider
      value={{
        loginSuccess,
        isAuthenticated,
        setIsAuthenticated,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

import { RefObject, useCallback, useLayoutEffect, useState } from 'react';

type Options = {
  enabled: boolean;
};

export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  options: Options = { enabled: true },
) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
    const [entry] = entries ?? [];

    if (!entry) return;

    setHeight(entry.contentRect.height);
    setWidth(entry.contentRect.width);
  }, []);

  useLayoutEffect(() => {
    if (!ref.current || !options.enabled) {
      return;
    }

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => handleResize(entries));
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, handleResize, options.enabled]);

  return [height, width];
};

import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import Button from 'components/Button';
import { Overlay } from 'components/Overlay';
import { routes } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useMainLayout } from 'utils';

import { SummaryDataType } from '../pages/Spedition';
import SpeditionObjectsList from './SpeditionObjectsList';

export const MobileTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  background: ${({ theme }) => theme.colors.gray100};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1rem 2rem;
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
`;

const ButtonWrapper = styled.div`
  bottom: 0;
  padding: 1rem 1rem 2rem 1rem;
  background: ${({ theme }) => theme.colors.white};
`;

const SpeditionMobile = ({
  selectedItems,
  searchInputValue,
  handleInputChange,
  handleSelectedItems,
  summaryData,
  checkAllBindedItems,
}: {
  selectedItems: SummaryDataType[];
  setSelectedItems: (items: SummaryDataType[]) => void;
  searchInputValue?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectedItems: (item: SummaryDataType) => void;
  summaryData: SummaryDataType[];
  checkAllBindedItems: () => void;
}) => {
  const { t } = useTranslation();
  const { showMobileModal, setShowMobileModal } = useMainLayout();

  return (
    <>
      {showMobileModal && <Overlay />}
      <MobileTitle>
        <Link to={routes.objects}>
          <ArrowLeft />
        </Link>
        {t('spedition')}
      </MobileTitle>
      <MobileContainer>
        <SpeditionObjectsList
          selectedItems={selectedItems}
          handleSelectedItems={handleSelectedItems}
          summaryData={summaryData ?? []}
          searchInputValue={searchInputValue}
          checkAllBindedItems={checkAllBindedItems}
          handleInputChange={handleInputChange}
        />
        <ButtonWrapper>
          <Button
            primary
            fillWidth
            disabled={!selectedItems.length}
            onClick={() => setShowMobileModal(true)}
          >
            {t('chooseVehicle')}
          </Button>
        </ButtonWrapper>
      </MobileContainer>
    </>
  );
};

export default SpeditionMobile;

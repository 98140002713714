import { InfoBox } from '@react-google-maps/api';
import { AllowedModules, Coordinates } from 'common';
import { ObjectStatus } from 'components/common/ObjectStatus';
import MainLayoutContext from 'components/MainLayout/context/MainLayoutContext';
import { statusElementType } from 'modules/driver/dtos';
import { memo, useContext } from 'react';
import styled from 'styled-components/macro';
import { useCheckModuleAccess } from 'utils';

import { InfoBoxContent } from './InfoBoxContent';

const InfoBoxWrapper = styled.div`
  position: relative;
  top: -1rem;
`;

const InfoBoxContentWrapper = styled.div`
  background: ${p => p.theme.colors.gray600};
  color: ${p => p.theme.colors.white};
  font-weight: 600;
  font-size: 1.3rem;
  padding: 0.8rem;
  border-radius: 6px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const InfoBoxTail = styled.div`
  height: 12.2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 2.5rem;

  &::after {
    background: ${p => p.theme.colors.gray600};
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    content: '';
    height: 1.2rem;
    left: 0;
    position: absolute;
    top: -0.1rem;
    width: 2.5rem;
  }
`;

interface InfoBoxComponentProps {
  position: Coordinates;
  handleOnMarkerClick: () => void;
  title?: string;
  status?: ObjectStatus;
  driverStatuses?: statusElementType[];
  isMarkerVisible?: boolean;
}

const InfoBoxComponent = ({
  position,
  handleOnMarkerClick,
  title,
  status,
  driverStatuses,
  isMarkerVisible,
}: InfoBoxComponentProps) => {
  const { vehiclesNameShown } = useContext(MainLayoutContext);
  const checkModuleAccess = useCheckModuleAccess();
  const isDriverStatusAccount = checkModuleAccess(AllowedModules.DRIVER_STATUS);

  return (
    <InfoBox
      position={position as unknown as google.maps.LatLng}
      options={{
        closeBoxURL: '',
        enableEventPropagation: true,
        disableAutoPan: true,
        visible: vehiclesNameShown && (isDriverStatusAccount ? isMarkerVisible : true),
      }}
    >
      <InfoBoxWrapper onClick={handleOnMarkerClick}>
        <InfoBoxContentWrapper>
          <InfoBoxContent title={title} status={status} driverStatuses={driverStatuses ?? []} />
        </InfoBoxContentWrapper>
        <InfoBoxTail />
      </InfoBoxWrapper>
    </InfoBox>
  );
};
export default memo(InfoBoxComponent);

import { Dispatch, SetStateAction, useState } from 'react';

/**
 * This hook is used to handle type-safety variant state
 * @param defaultVariant
 */
export function useVariants<T>(defaultVariant: T): [T, Dispatch<SetStateAction<T>>] {
  const [currentVariant, setCurrentVariant] = useState(defaultVariant);

  return [currentVariant, setCurrentVariant];
}

import SpeditionIcon from 'assets/icons/notification-spedition-icon.png';
import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';

import { CookiesKeys } from '../../../../common';
import { LastDriversStatusResponse } from '../../../../modules/driver/dtos';
import { getLastDriversStatus } from '../../../../modules/driver/queries';
import desktopNotification from '../../../../utils/desktopNotification';
import { isDriverStatusAccount } from '../../../../utils/isDriverStatusAccount';
import StringBuilder from '../../../../utils/stringBuilder';

interface ITopbarSpeditionItemProps {
  name: string;
}

const MAX_EVENT_COOKIE_AGE = 259200; // 3 days

/**
 * This function is used to prepare array of cookies
 * JSID + ELEMENT_ID + TIMESTAMP
 * separated by semicolons
 * @param response
 */
const parseResponseToCookies = (response: LastDriversStatusResponse): string[] => {
  const cookiesArray: string[] = [];

  if (!response.event) return cookiesArray;

  for (const event of response.event) {
    const sb = new StringBuilder();
    sb.add(response.jsid).add(';').add(event.id).add(';').add(event.timestamp);
    cookiesArray.push(sb.getText());
  }

  return cookiesArray;
};

/**
 * This function is used to format notification content
 * @param response
 * @param eventsCount
 */
const getMessageContent = (
  response: LastDriversStatusResponse,
  eventsCount: number,
): { title: string; options: { body: string } } | null => {
  const sb = new StringBuilder();
  for (const event of response.event) {
    const oldStatus = i18next.t(event.data.statuses.old.label);
    const newStatus = i18next.t(event.data.statuses.new.label);

    sb.add(event.data.account.account.name)
      .add(': ')
      .add(oldStatus)
      .add(' -> ')
      .add(newStatus)
      .add('\n');
  }

  switch (eventsCount) {
    case 1: {
      return {
        title: i18next.t('driverStatusChanged'),
        options: { body: sb.getText() },
      };
    }

    default: {
      return {
        title: i18next.t('driversStatusChanged'),
        options: {
          body: sb.getText(),
        },
      };
    }
  }
};

/**
 * This function is used to display notification
 * about driver's status changes
 * @param response
 */
const handleDisplayNotification = (response: LastDriversStatusResponse): void => {
  const eventsCount = response.event?.length ?? 0;
  if (eventsCount === 0) return;

  const messageContent = getMessageContent(response, eventsCount);
  if (!messageContent) return;

  desktopNotification(messageContent.title, { ...messageContent.options, icon: SpeditionIcon });
};

const TopbarSpeditionItem: React.FC<ITopbarSpeditionItemProps> = ({ name }) => {
  const [currentCookie, setCookie] = useCookies([CookiesKeys.EVENT_DRIVER_STATUSES]);

  /**
   * This function is used to read notifications in cookies
   */
  const handleSetCookiesArray = useCallback(
    (response: LastDriversStatusResponse) => {
      const currentEventCookies = currentCookie[CookiesKeys.EVENT_DRIVER_STATUSES] ?? [];
      const cookiesArray = parseResponseToCookies(response);
      setCookie(CookiesKeys.EVENT_DRIVER_STATUSES, [...currentEventCookies, ...cookiesArray], {
        path: '/',
        maxAge: MAX_EVENT_COOKIE_AGE,
      });
    },
    [currentCookie, setCookie],
  );

  useQuery(
    getLastDriversStatus({
      refetchInterval: 15000,
      enabled: isDriverStatusAccount(),
      onSuccess: data => {
        handleSetCookiesArray(data);
        handleDisplayNotification(data);
      },
    }),
  );

  return <>{name}</>;
};

export default TopbarSpeditionItem;

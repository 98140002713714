export const endpoints = {
  verifyLogin: '/api/auth',
  login: '/rest-api/auth',
  logout: '/rest-api/auth',
  communique: '/api/auth/getCommunique',
  forgotPassword: '/api-react/account/forget-password',
  resetPassword: (token: string) => `/api-react/account/reset-password/${token}`,
  resetPasswordUnverified: '/api-react/account/support-password',
  messages: '/api/message/show',
  menuModules: '/rest-api/account/settings/modules',
};

import styled from 'styled-components/macro';

const Badge = styled.span`
  display: inline-block;
  padding: 0.5rem 1.2rem;
  border-radius: 1.5rem;
  font-size: 1.2rem;
  margin-right: 0.5rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.gray300};
  color: ${({ theme }) => theme.colors.gray500};
  font-weight: 600;
`;

export default Badge;

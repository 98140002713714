import styled, { css } from 'styled-components/macro';

type ListItemProps = {
  highlighted?: boolean;
  active?: boolean;
  disabled?: boolean;
};

export const Wrapper = styled.div`
  position: relative;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    flex-grow: 1;
  }
`;

export const Button = styled.div`
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.colors.primary500};
  border: none;
  outline: none;
  border-radius: 1rem;
  cursor: pointer;
`;

export const ListWrapper = styled.div<{
  showOnTop?: boolean;
  preventOffScreenDisplay?: boolean;
  maxHeight?: number;
  minWidth?: number;
  selectWidth?: number;
  isOpen?: boolean;
}>`
  position: absolute;
  margin-top: 1.5rem;
  padding: 2.4rem 1.6rem;
  filter: drop-shadow(0px 4px 44px rgba(0, 0, 0, 0.25));
  will-change: filter;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0.6rem;
  z-index: ${({ theme }) => theme.layersOrder.topbar};
  max-width: 90vw;
  min-width: ${({ minWidth }) => (minWidth ? `max(${minWidth}rem, 100%)` : 'max(24rem, 100%)')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

  ${p =>
    p.maxHeight &&
    css`
      max-height: ${p.maxHeight}px;
    `}

  ${p =>
    p.showOnTop &&
    css`
      margin-top: unset;
      top: 0;
      transform: translate(-50%, calc(-100% - 1.5rem));
    `}

  ${p =>
    p.preventOffScreenDisplay &&
    css`
      left: 0;
      transform: unset;
    `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 0.8rem);
    margin-top: -0.8rem;
    width: 0;
    height: 0;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-bottom: 0.8rem solid ${({ theme }) => theme.colors.secondaryBackground};

    ${p =>
      p.preventOffScreenDisplay &&
      p.selectWidth &&
      css`
        left: ${p.selectWidth / 2 + 16}px;
      `}

    ${p =>
      p.showOnTop &&
      css`
        transform: rotate(180deg);
        top: unset;
        bottom: -0.8rem;
      `}
  }
`;

export const FullScreenListWrapper = styled.div<{ screenHeight: number | null; isOpen?: boolean }>`
  position: fixed;
  left: 0;
  top: 70px;
  width: 100vw;
  height: calc(${p => (p.screenHeight ? `${p.screenHeight}px` : `100vh`)} - 70px);
  padding: 2.4rem 1.6rem;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-top: 1px solid ${p => p.theme.colors.separator};
  display: grid;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

export const List = styled.ul<{ maxHeight?: number }>`
  list-style: none;
  padding-right: 1rem;
  max-height: ${p => (p.maxHeight ? `${p.maxHeight}px` : `40rem`)};
  overflow-y: auto;
`;

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.2rem;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  font-weight: 600;
  color: ${({ theme, active }) => (active ? theme.colors.gray600 : theme.colors.text)};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.gray100 : theme.colors.secondaryBackground};
  cursor: pointer;

  label {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  label:last-child {
    justify-content: right;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryBackground};
  }

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const ListItemHeader = styled.li<ListItemProps>`
  display: flex;
  align-items: left;
  gap: 1rem;
  padding: 1.2rem;
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.textGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};

  svg {
    width: 2.3rem;
    height: 2.3rem;
    color: ${({ theme }) => theme.colors.textGray};
  }
`;

export const ListItemHeaderLabel = styled.label`
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: end;
`;

export const LabelIcon = styled.label`
  width: 12rem !important;
`;

export const Accordion = styled.details`
  padding: 1.5rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};

  &:last-of-type {
    border-bottom: none;
  }

  summary {
    list-style-image: none;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
  }

  ul {
    margin-top: 1.5rem;
    padding-left: 1rem;
    border-left: 1px solid ${({ theme }) => theme.colors.primary600};
  }

  &[open] {
    summary svg {
      transform: rotate(180deg);
    }
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.6rem;
  font-weight: 600;
  padding-bottom: 1rem;
  text-align: center;
`;

export const SearchInputWrapper = styled.label`
  display: block;
  position: relative;

  svg {
    position: absolute;
    top: 3.6rem;
    left: 1.1rem;
    transform: translateY(-50%);
  }
`;

export const SearchInput = styled.input`
  background: ${({ theme }) => theme.colors.primaryBackground};
  color: ${({ theme }) => theme.colors.text};
  padding: 1.2rem 1.5rem 1.2rem 3.6rem;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 1.5rem 0;
  border: none;
  outline: none;
  width: 100%;
  height: 4.2rem;
  ${p =>
    p.disabled &&
    css`
      pointer-event: none;
    `}
`;

export const SubmitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.4rem;
  width: 100%;
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  box-shadow: 0px 1px 0px rgba(129, 129, 129, 0.25);
  border-radius: 0.6rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.gray500};
  cursor: pointer;
`;

export const BadgesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  border: 1px solid ${({ theme }) => theme.colors.secondaryBackground};
  background: ${({ theme }) => theme.colors.primaryBackground};
  color: ${({ theme }) => theme.colors.text};
  padding: 8px 8px 8px 16px;
  border-radius: 4rem;
  line-height: 1;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.6rem;
    height: 1.6rem;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.gray400};
    cursor: pointer;
    padding-top: 3px;
  }
`;

export const IconWrapper = styled.img`
  height: 3rem;
  width: auto;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${p => p.theme.colors.gray400};
  margin: 1rem 0;
`;

export const SelectAll = styled.div`
  text-align: right;
  display: grid;
  grid-template-columns: auto auto;
  padding: 0.6rem 1.2rem;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const SelectedInfo = styled.div`
  text-align: right;
  padding: 0.6rem 1.2rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
`;

export const SearchSingleInputWrapper = styled.div`
  margin-bottom: ${p => p.theme.spacing.regular};
`;

export const Label = styled.label`
  min-width: 200px;
  max-width: 300px;
`;

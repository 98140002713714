import * as qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const search = useMemo(() => {
    if (location.search?.length) {
      return location.search.slice(1);
    }

    return '';
  }, [location.search]);

  const queryParams = useMemo(
    () => qs.parse(search) as Record<string, string | string[]>,
    [search],
  );

  const setQueryParams = useCallback(
    (object: Record<string, string | string[] | undefined>) => {
      const parsedObject = Object.fromEntries(
        Object.entries(object).filter(([, value]) => !!value),
      ) as Record<string, string | string[]>;

      const parsedString = qs.stringify(parsedObject, { encode: false });
      navigate(`${location.pathname}?${parsedString}`, { replace: true });
    },
    [location.pathname, navigate],
  );

  const result = useMemo(
    () => ({
      queryParams,
      setQueryParams,
    }),
    [queryParams, setQueryParams],
  );

  return result;
};

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as CameraMirroredIcon } from 'assets/icons/camera-mirrored.svg';
import { ReactComponent as GalleryIcon } from 'assets/icons/gallery.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import Button from 'components/Button';
import MobileModal from 'components/MobileModal/MobileModal';
import { routes } from 'navigation/routes';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import { useToast } from 'toast';
import { useWindowSize } from 'utils';

import { addDocument } from '../queries';
import { socket } from '../queries/socket';
import {
  ButtonContent,
  ButtonWrapper as MobileModalButtonWrapper,
  StyledButtonWrapper,
} from './AddAttachement';

const WebCamContainer = styled.div<{ isLandscape: boolean }>`
  background: ${({ theme }) => theme.colors.black};
  padding: ${({ isLandscape }) => (isLandscape ? '0' : '4rem')};
  display: flex;
  flex-direction: ${({ isLandscape }) => (isLandscape ? 'row' : 'column')};
  align-items: center;
  gap: 4rem;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
`;

const CaptureButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 9px solid grey;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 auto;
`;

const ButtonWrapper = styled.div<{ isLandscape: boolean }>`
  display: flex;
  flex-direction: ${({ isLandscape }) => (isLandscape ? 'column' : 'row')};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ isLandscape }) => (isLandscape ? '2rem' : '0')};

  svg {
    fill: ${({ theme }) => theme.colors.white};

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const LandscapeButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 3rem;
  svg {
    width: 24px;
    height: 24px;

    fill: ${({ theme }) => theme.colors.white};

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
`;

const BottomButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 20%;
  gap: ${({ theme }) => theme.spacing.large};
`;

const ScreenShot = styled.div<{ src: string }>`
  width: 100vw;
  height: 100vh;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
`;

const CameraView = () => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast } = useToast();

  const isLandscape = size.height <= size.width;

  const [image, setImage] = useState<string | null>(null);
  const [isCameraMirrored, setIsCameraMirrored] = useState<boolean>(false);

  const webcamRef = useRef<Webcam>(null);

  const { data: imageResponse, mutate: addPhoto } = useMutation(addDocument());

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      addPhoto({ content: imageSrc });

      const img = new Image();
      img.src = imageSrc;
      setImage(img.src);
    }
  }, [webcamRef]);

  const videoConstraints = {
    facingMode: isCameraMirrored ? 'environment' : 'user',
    aspectRatio: isLandscape ? 4 / 3 : 3 / 4,
  };

  const handleGallery = () => navigate(routes.driverGallery);

  const sendPhoto = () => {
    socket.connect();

    socket.emit(
      'server:message',
      { content: '', id: imageResponse?.accountId, document: imageResponse?.id },
      () => {
        showToast({ content: t('sendDocumentSuccess') });
        socket.disconnect();
        setImage(null);
      },
    );
  };

  return (
    <>
      {image ? (
        <>
          <ScreenShot src={image} />
          <MobileModal noPadding>
            <MobileModalButtonWrapper>
              <Button secondary fillWidth onClick={sendPhoto}>
                <ButtonContent>
                  <SendIcon /> {t('sendToSpeditor')}
                </ButtonContent>
              </Button>
              <Button secondary fillWidth onClick={() => setImage(null)}>
                <ButtonContent>
                  <CameraIcon /> {t('takeAnotherPhoto')}
                </ButtonContent>
              </Button>
            </MobileModalButtonWrapper>
            <StyledButtonWrapper>
              <Button secondary fillWidth onClick={() => setImage(null)}>
                {t('close')}
              </Button>
            </StyledButtonWrapper>
          </MobileModal>
        </>
      ) : (
        <WebCamContainer isLandscape={isLandscape}>
          {isLandscape ? (
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                height={isLandscape ? size.height : undefined}
                width={isLandscape ? undefined : size.width}
                screenshotQuality={1}
                imageSmoothing
                videoConstraints={videoConstraints}
              />
              <LandscapeButtonWrapper>
                <TopButtonWrapper>
                  <CloseIcon onClick={() => navigate(routes.driverMap)} />
                  <CaptureButton onClick={capture} />
                </TopButtonWrapper>
                <BottomButtonWrapper>
                  <CameraMirroredIcon onClick={() => setIsCameraMirrored(prev => !prev)} />
                  <GalleryIcon onClick={handleGallery} />
                </BottomButtonWrapper>
              </LandscapeButtonWrapper>
            </>
          ) : (
            <>
              <ButtonWrapper isLandscape={isLandscape}>
                <CloseIcon onClick={() => navigate(routes.driverMap)} />
                <CameraMirroredIcon onClick={() => setIsCameraMirrored(prev => !prev)} />
              </ButtonWrapper>

              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                height={isLandscape ? size.height : undefined}
                width={isLandscape ? undefined : size.width}
                screenshotQuality={1}
                imageSmoothing
                videoConstraints={videoConstraints}
              />
              <ButtonWrapper isLandscape={isLandscape}>
                <GalleryIcon onClick={handleGallery} />
                <CaptureButton onClick={capture} />
              </ButtonWrapper>
            </>
          )}
        </WebCamContainer>
      )}
    </>
  );
};

export default CameraView;

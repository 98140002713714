import { DateTimeFormat } from 'common';
import moment from 'moment';

/**
 * This function is used to return the formatted date
 * @param date
 * @param format
 * @returns
 */
const dateF = (date?: string | Date, format?: string): string => {
  if (!date) return '';
  const momentDate = moment(date);
  return momentDate.format(format ?? DateTimeFormat);
};

export default dateF;

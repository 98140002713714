import Badge from 'components/Badge';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  ItemName,
  ItemNameWrapper,
  ObjectItem,
} from 'modules/driver/components/SpeditionObjectsList';
import SpeditionSearchInput from 'modules/driver/components/SpeditionSearchInput';
import { Title } from 'modules/driver/pages/Spedition';
import { getAllObjects } from 'modules/objects/queries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { getObjectIcon, useWindowSize } from 'utils';
import { getObjectFilterColor } from 'utils/getObjectFilterColor';

import { GroupObject } from '../dtos';
import { addObjectsToGroup } from '../queries';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.large};
  gap: ${({ theme }) => theme.spacing.large};
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

export const ObjectsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  height: calc(100vh - 65rem);
  overflow-y: scroll;
`;

export const ConfirmSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.large};
  border-top: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
`;

export const StyledObjectItem = styled(ObjectItem)`
  width: 100%;
  border: 1px solid transparent;

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  }
`;

export const BadgeSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const StyledBadge = styled(Badge)`
  cursor: pointer;
  display: flex;
  gap: 2px;
  align-items: center;
`;

const GroupObjectsList = ({ groupId, objects }: { groupId: number; objects: GroupObject[] }) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<GroupObject[]>(objects);
  const { data: allObjects, isFetching: isObjectsListFetching } = useQuery(getAllObjects());
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const { mutate } = useMutation(addObjectsToGroup());

  const groupObjectsIds = selectedItems?.map(el => el.objectId);

  const allObjectsItemsWithFilters = allObjects?.data
    .map(el => ({
      objectId: el.objectId,
      number: el.number,
      comment: el.comment,
      status: el.status,
      type: el.type,
    }))
    .filter(obj => obj.number.toLocaleLowerCase().includes(searchInputValue.toLowerCase()));

  const handleSelectedItems = (item: GroupObject) => {
    if (selectedItems?.find(obj => obj.objectId === item.objectId)) {
      setSelectedItems(selectedItems?.filter(obj => obj.objectId !== item.objectId));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
    setIsButtonDisabled(false);
  };

  useEffect(() => {
    setSelectedItems(objects);
  }, [objects]);

  const handleAddObjectsToGroup = () =>
    mutate({ groupId: groupId, objects: selectedItems?.map(item => item.objectId) });

  useEffect(() => {
    if (
      selectedItems?.length === objects?.length &&
      selectedItems?.every(item => objects?.some(({ objectId }) => item.objectId === objectId))
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [selectedItems, objects]);

  return (
    <ListWrapper>
      <TopSection>
        <Title>{t('vehicles')}</Title>
        <SpeditionSearchInput
          searchInputValue={searchInputValue}
          handleInputChange={e => setSearchInputValue(e.target.value)}
        />
      </TopSection>
      {selectedItems.length > 0 && (
        <BadgeSection>
          {selectedItems.map((item, index) => (
            <StyledBadge key={`selected-item-${index}`} onClick={() => handleSelectedItems(item)}>
              {item.number} {item.comment ? `(${item.comment})` : ''}
              <span>&#10005;</span>
            </StyledBadge>
          ))}
        </BadgeSection>
      )}
      <ObjectsList>
        {isObjectsListFetching ? (
          <LoadingIndicator />
        ) : (
          allObjectsItemsWithFilters?.map(object => (
            <StyledObjectItem
              key={object.objectId}
              isMobile={isMobile}
              isActive={false}
              onClick={() => handleSelectedItems(object as unknown as GroupObject)}
            >
              <ItemNameWrapper>
                <Checkbox
                  checkboxSize={'md'}
                  checked={groupObjectsIds?.includes(object.objectId)}
                />
                <img
                  src={getObjectIcon(object.type)}
                  style={{ filter: getObjectFilterColor(object.status) }}
                />
                <ItemName>
                  {object.number} {object.comment ? `(${object.comment})` : ''}
                </ItemName>
              </ItemNameWrapper>
            </StyledObjectItem>
          ))
        )}
      </ObjectsList>
      <ConfirmSection>
        <Button primary disabled={isButtonDisabled} onClick={handleAddObjectsToGroup}>
          {`${t('confirmVehicles')} (${selectedItems.length})`}
        </Button>
      </ConfirmSection>
    </ListWrapper>
  );
};

export default GroupObjectsList;

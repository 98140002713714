import i18n from 'locales/i18n';

import { composeValidators } from '.';
import { minLength } from './minLength';

i18n.loadNamespaces('general');

const securePasswordMinLength = 8;
const lowercaseLetterRegexp = /(?=.*[a-z])/;
const uppercaseLetterRegexp = /(?=.*[A-Z])/;
const specialCharacterRegexp = /(?=.*[@$!%*?&])/;
const digitRegexp = /(?=.*[0-9])/;

const isLowercaseLetterIncluded = () => (value?: string) => {
  if (!value?.length) {
    return;
  }
  const valid = lowercaseLetterRegexp.test(value);

  if (!valid) {
    return i18n.t('validation.atLeastOneLowercaseLetter');
  }
};

const isUppercaseLetterIncluded = () => (value?: string) => {
  if (!value?.length) {
    return;
  }
  const valid = uppercaseLetterRegexp.test(value);

  if (!valid) {
    return i18n.t('validation.atLeastOneUppercaseLetter');
  }
};

const isSpecialCharacterIncluded = () => (value?: string) => {
  if (!value?.length) {
    return;
  }
  const valid = specialCharacterRegexp.test(value);

  if (!valid) {
    return i18n.t('validation.atLeastOneSpecialCharacter');
  }
};

const isDigitIncluded = () => (value?: string) => {
  if (!value?.length) {
    return;
  }
  const valid = digitRegexp.test(value);

  if (!valid) {
    return i18n.t('validation.atLeastOneDigitIncluded');
  }
};

export const isSecurePassword = () => (value?: string) =>
  composeValidators(
    isLowercaseLetterIncluded(),
    isUppercaseLetterIncluded(),
    isSpecialCharacterIncluded(),
    isDigitIncluded(),
    minLength(securePasswordMinLength),
  )(value);

import { memo } from 'react';
import styled from 'styled-components/macro';

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${p => p.theme.colors.separator};
  margin: 4.2rem 0;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    margin: 1.6rem 0;
  }
`;

export default memo(Separator);

import 'react-datepicker/dist/react-datepicker.css';

import { ReactComponent as Icon } from 'assets/calendar.svg';
import { sharedInputStyles } from 'components/common';
import en from 'date-fns/locale/en-GB';
import pl from 'date-fns/locale/pl';
import dayjs from 'dayjs';
import i18n from 'locales/i18n';
import { memo, useCallback, useEffect, useMemo } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components/macro';
import { useWindowSize } from 'utils';
import { useAppContext } from 'utils/appContext';

const Wrapper = styled.div<{ medium: boolean; smallWidth: boolean; dark?: boolean }>`
  position: relative;
  min-width: ${p => (p.smallWidth ? `12.5rem` : `15rem`)};

  input {
    ${p => sharedInputStyles(p)};
    padding-left: 3.5rem;
    font-size: 1.2rem !important;
  }

  .react-datepicker {
    font-size: 1.2rem;
    border-radius: 0.6rem;
    overflow: hidden;
    background: ${p => p.theme.colors.primaryBackground};
    min-width: ${p => (p.smallWidth ? `200px` : `315px`)};

    @media (max-width: ${p => p.theme.breakpoints.md}px) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .react-datepicker,
  .react-datepicker-time__header,
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: ${p => p.theme.colors.text} !important;
  }

  .react-datepicker__time {
    background: ${p => p.theme.colors.primaryBackground} !important;
  }

  .react-datepicker-popper {
    left: 50% !important;
    transform: translate(-50%, 42px) !important;
    width: fit-content;
    z-index: 10;

    @media (max-width: ${p => p.theme.breakpoints.md}px) {
      position: fixed !important;
      left: 50% !important;
      top: 60% !important;
      transform: translate(-50%, -50%) !important;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 1.2rem;
  }

  .react-datepicker__month .react-datepicker__month-text {
    width: 7rem;
    white-space: nowrap;
    margin: 2px;
    padding: 0.6rem;
    border-radius: 0.6rem;
  }

  .react-datepicker__month-text--keyboard-selected {
    background: ${p => p.theme.colors.primary500};
  }

  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${p => p.theme.colors.primary500} !important;
  }

  .react-datepicker__month-text:hover {
    background: ${p => p.theme.colors.primary600};
    color: white;
  }

  .react-datepicker__header {
    background: ${p => p.theme.colors.secondaryBackground};
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 2.8rem;
    line-height: 2.4rem;
  }

  .disabled {
    opacity: 0.5;
  }
`;

const CalendarIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 1.2rem;
  transform: translateY(-50%);
`;

const StyledDatePicker = styled(DatePicker)`
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: ${p => p.theme.colors.text};
`;

export type DateTimePickerProps = {
  disableTime?: boolean;
  onChange?: (value: Date) => void;
  type?: 'month' | 'date';
  min?: Date;
  max?: Date;
  name?: string;
  value?: string;
  dayClassName?: (date: Date) => string | null;
};

const DateTimePicker = ({
  disableTime = false,
  onChange,
  type,
  min,
  max,
  name,
  value,
  dayClassName,
}: DateTimePickerProps) => {
  const locale = useMemo(() => (i18n.language === 'pl' ? 'pl' : 'en-gb'), []);
  const { isMobile } = useWindowSize();
  const { darkMode } = useAppContext();

  useEffect(() => {
    registerLocale(locale, locale === 'pl' ? pl : en);
  }, [locale]);

  const showMonths = useMemo(() => type === 'month', [type]);

  const dateFormat = useMemo(() => {
    if (disableTime) {
      if (type === 'month') return 'MM';
      if (type === 'date') return 'dd.MM.YYYYY';
    } else {
      if (type === 'month') return 'MM HH:mm';
      if (type === 'date') return 'dd.MM.YYYYY HH:mm';
    }

    return 'dd.MM.YYYYY HH:mm';
  }, [type, disableTime]);

  const placeholder = useMemo(() => {
    if (type === 'month') return '-';
    if (disableTime) return '--.--.----';

    return '--.--.-- --:--';
  }, [type, disableTime]);

  const parsedValue = useMemo(() => {
    if (!value) return;

    const format = disableTime ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm';

    return dayjs(value).format(format);
  }, [value, disableTime]);

  const selected = useMemo(() => {
    if (!value) return;

    return new Date(value);
  }, [value]);

  const handleOnChange = useCallback(
    value => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const ref = useCallback(
    node => {
      if (node !== null && isMobile) {
        node.input.readOnly = true;
      }
    },
    [isMobile],
  );

  return (
    <Wrapper medium smallWidth={disableTime} dark={darkMode}>
      <CalendarIcon />
      <StyledDatePicker
        ref={ref}
        name={name}
        value={parsedValue}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        onChange={handleOnChange}
        selected={selected}
        locale={locale}
        showTimeSelect={!disableTime}
        timeIntervals={5}
        showMonthYearPicker={showMonths}
        showFullMonthYearPicker={showMonths}
        minDate={min}
        maxDate={max}
        dayClassName={dayClassName}
      />
    </Wrapper>
  );
};

export default memo(DateTimePicker);
